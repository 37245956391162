import { Box, Button, Stack } from "@mui/material"

export const ReplayAudio = ({
    t = () => false,
    classes = {},
    audioBlob = {},
    handleRetakeAudio = () => false,
    handleReplayAudioDialogClose = () => false,
    handleAddAudio = () => false,
}) => {
    return (
        <Stack rowGap={"16px"}>
            <Box p={"24px 24px 0px"} width={"100%"} alignItems={"center"} display={"flex"}>
                {audioBlob &&
                    <audio controls style={{ width: "inherit" }}>
                        <source src={URL.createObjectURL(audioBlob)} type="audio/mpeg" />
                    </audio>}
            </Box>
            <Stack direction={"row"} columnGap={"8px"} p={"16px"} justifyContent={"space-between"} alignItems={"center"}>
                <Button className={classes.cancelButton} fullWidth
                    onClick={handleReplayAudioDialogClose}>
                    {t("Cancel")}
                </Button>
                <Button fullWidth className={classes.reTakeButton}
                    onClick={handleRetakeAudio}>
                    {t("Re-Take")}
                </Button>
                <Button fullWidth className={classes.addVideoButton}
                    onClick={handleAddAudio}>
                    {t("Add")}
                </Button>
            </Stack>
        </Stack>
    )
}