import { Box, Button } from "@mui/material";
import React from "react";
import { DocumentViewer } from "../../components";
import { saveAs } from "file-saver";
import NoDataFound from "../../components/noDataFound/noDataFound";
import { AgreementSimmer } from "../../components/simmer/agreementSimmer";

const AgreementDownlaod = ({ t = () => false, agreementData = {}, pdfData = {} }) => {

  return (
    <Box p={1}>
        {pdfData?.load ? <AgreementSimmer height={"55vh"} />
          :
          <>
            <div style={{
              height: "50vh",
              msOverflowY: "scroll",
            }}>
              {pdfData?.data?.length > 0 ? (
                <DocumentViewer url={`data:application/pdf;base64,${pdfData?.data}`} />
              ) : (
                <NoDataFound height={210} content="No Pdf" />
              )}
            </div>
            <Box height={"16px"}/>
            <Button
              variant="contained"
              fullWidth={true}
              sx={{ padding: "12px", borderRadius: "10px", marginTop: "12px" }}
              onClick={() =>
                saveAs(`data:application/pdf;base64,${pdfData?.data}`, `${agreementData?.id}.pdf`)
              }
            >
              {t("Download")}
            </Button>
          </>
        }

    </Box>
  );
}

export default AgreementDownlaod;
