import { Box, Button, Container, Dialog, Drawer, Grid, Hidden, IconButton, InputAdornment, Skeleton, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { BackendRoutes, Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, Message, NetWorkCallMethods, RetainLocalStorage } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
import PasswordInfo from "../../components/passwordInfo";
import { TitleBarNew } from "../../components/titlebarNew";
import { LightTooltip, setupAccountUseStyles } from "./style";
import { Label } from "../../components/label";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { InfoIcon } from "../../assets/infoIcon";
import { initital_screen_state } from "../../utils/login/utils";
import { Close } from "@mui/icons-material";
import { TermsAndCondition } from "./component/termsAndCondition";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { getCurrentBuildNumber } from "../../utils/version";
const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundColor: "#F2F4F7",
    height: "213px",
    backgroundImage: `url("/images/Select house-cuate.svg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "100%"
  },
  content: {
    width: "inherit",
  },
  text: {
    fontSize: "1.5rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#5078E1",
    },
  },
  buttonTextStyle: {
    fontSize: "0.875rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
  },
  img: {
    height: "8%",
    width: "28%",
  },
  btnCard: {
    position: "sticky",
    bottom: "0",
    padding: "16px",
    backgroundColor: "#fff"
  },
  // main:{
  //   height:"100vh"
  // }
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
}));

const InitialState = {
  email: "",
  enterPassword: "",
  confirmPassword: "",
  error: {
    email: "",
    enterPassword: "",
    confirmPassword: "",
  },
};

const SetupAccountDetails = ({ t = () => false }) => {
  const [app_version, set_app_version] = React.useState(null);
  const size = useWindowDimensions();
  const classes = useStyles();
  const classes_1 = setupAccountUseStyles({ size });
  const history = useHistory();
  const [account, setAccount] = React.useState({ ...InitialState });
  const alert = React.useContext(AlertContext);

  const search = useLocation().search;
  const welcomePageAuthToken = new URLSearchParams(search).get(
    "welcomeAuthToken"
  );
  console.log("welcomePageAuthToken",jwt_decode(welcomePageAuthToken))
  const [decoded, setDecoded] = React.useState(null);
  const [is_loading, set_is_loading] = useState(true);
  const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);
  const [screen_state, set_screen_state] = useState(initital_screen_state);
  const [isInfoOpen, setIsInfoOpen] = React.useState(false)
  const [disable, setDisable] = React.useState(false);
  const [termCondition, setTermCondition] = React.useState("")
  const [tcStatus, setTCStatus] = React.useState(false)
  const [show, setShow] = React.useState({
    show1: false,
    show2: false
  })
  const updateShow = (key, value) => {
    setShow({ ...show, [key]: value })
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  React.useEffect(() => {
    getCurrentBuild();
    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      setDecoded(jwt_decode(welcomePageAuthToken));
      initialLoad();
      getTermsAndCondition();
    }
    // eslint-disable-next-line
  }, []);

  const getCurrentBuild = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const [temp_app_version] = await Promise.all([getCurrentBuildNumber()]);
        set_app_version(temp_app_version);
      } catch (error) {
        reject(error);
      }
    });
  }

  const initialLoad = () => {
    getInitialRender();
  }

  const getInitialRender = () => {
    NetworkCall(
      `${config?.auth_api_url}${BackendRoutes?.login_domain_initial_render}`,
      NetWorkCallMethods?.post, {}, null, false, false)
      .then((res) => {
        let is_custom_domain = res?.data?.data?.is_custom_domain;
        let _ = res?.data?.data?.render_data?.[0]
        if (_) {
          let temp_screen_state = {
            ...screen_state,
            [_?.id && "id"]: _?.id,
            [_?.region && "region"]: _?.region,
            [_?.login_logo && "logo"]: _?.login_logo,
            [_?.web_background && "web_background"]: _?.web_background,
            [_?.mobile_background && "mob_background"]: _?.mobile_background,
            [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
            [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
            [_?.flag && "flag"]: _?.flag,
            [_?.domain && "domain"]: _?.domain,
            [is_custom_domain && "is_custom_domain"]: is_custom_domain,
          }
          set_screen_state(temp_screen_state);
        }
        set_is_loading(false);
      }).catch((error) => {
        alert.setSnack({
          ...alert, open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
        set_is_loading(false);
      });
  }

  const updateState = (key, value) => {
    let error = account.error;
    error[key] = "";
    setAccount({ ...account, [key]: value, error });
  };
  const isIamValideToCreateAccount = () => {
    let isValid = true;
    let error = account.error;

    const is_password_strong_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
    const is_password_strong = is_password_strong_regex.test(account.enterPassword)


    //Checking enterPassword
    if (account.enterPassword.length === 0) {
      isValid = false;
      error.enterPassword = Message.English.requiredMessage(t("Password"));
    } else if (!Boolean(is_password_strong)) {
      isValid = false;
      error.enterPassword = t("Password is not stong");
    }

    //Checking confirmPassword
    if (account.confirmPassword.length === 0) {
      isValid = false;
      error.confirmPassword =
        Message.English.requiredMessage(t("ConfirmPassword"));
    }
    if (account.confirmPassword.length > 0) {
      if (
        account.enterPassword.length > 0 &&
        account.enterPassword !== account.confirmPassword
      ) {
        isValid = false;
        error.confirmPassword = t("PassworddoesnotMatch");
      }
    }
    if (
      account.email &&
      account.enterPassword !== "" &&
      account.confirmPassword !== ""
    ) {
      if (account.enterPassword === account.confirmPassword) {
        isValid = true;
      }
    }

    setAccount({ ...account, error });
    return isValid;
  };

  const onSendAccountBtnClicked = () => {
    setDisable(true);
    if (isIamValideToCreateAccount()) {
      const payload = {
        password: account.confirmPassword,
      };
      NetworkCall(
        `${config.auth_api_url}/auth/updatepassword/?token=${welcomePageAuthToken}`,
        NetWorkCallMethods.post,
        payload,
        null,
        false,
        false
      )
        .then((response) => {
          if (response.status === 201) {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: t("Passwordcreatedsuccessfully"),
            });
            RetainLocalStorage();
            history.push(
              Routes.login + "?welcomeAuthToken=" + welcomePageAuthToken
            );
          }
        })
        .catch((err) => {
          setDisable(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
    } else {
      setDisable(false);
      return false;
    }
  };

  const handleGoBack = () => {
    history.goBack(-1);
  }

  const getTermsAndCondition = (decoded_data = decoded) => {

    const payload = {
      client: decoded_data?.client,
    };
    NetworkCall(
      `${config.auth_api_url}/client/terms_and_conditions`,
      NetWorkCallMethods.post,
      payload,
      { "Referer": "https://resident.pms2.propgoto.com/" },
      false,
      false
    )
      .then((response) => {
        // if (response?.data?.is_allowed) {
        //   history.push(
        //     Routes.setupAccount + "?welcomeAuthToken=" + welcomePageAuthToken
        //   );
        // } else {
        //   history.push(Routes.login);
        // }
        setTermCondition(response?.data?.data)
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  const handleClose = () => {
    setTCStatus(false)
  }

  const handleImageOnLoad = () => {
    setIsBackgroundImageLoading(false);
  }

  const handleImageOnError = () => {
    set_screen_state({ ...screen_state, mob_background: initital_screen_state?.mob_background });
  }

  return (
    is_loading
      ? <></>
      : <>
        <Container className={classes_1.mob_right_section_card} maxWidth="sm">
          <TitleBarNew is_back_button title={t("Set Up New Account")} goBack={handleGoBack} />
          <Grid container className={classes_1.mob_content}>
            <Grid item xs={12} className={classes_1.mob_body_items}
              sx={{ height: size?.height - 160, overflow: "overlay" }}>
              <Box className={classes_1.mob_background}>
                {isBackgroundImageLoading && <Skeleton variant="rounded" className={classes_1.mob_background_image} />}
                <img className={classes_1.mob_background_image}
                  style={{ display: isBackgroundImageLoading ? "none" : "block" }}
                  src={screen_state?.mob_background} alt=""
                  onLoad={handleImageOnLoad}
                  onError={handleImageOnError} />
              </Box>
              <Box height={"16px"} />
              <Typography className={classes_1.web_title}>
                {t("Set up your account details below")}
              </Typography>
              <Box height={"16px"} />
              {!Boolean(screen_state?.is_custom_domain) &&
                <>
                  <Label label={t("Region")} isRequired />
                  <Stack className={classes_1.flag_dropdown}
                    direction={"row"} columnGap={"8px"}
                    justifyContent={"space-between"} alignItems={"center"}>
                    <Stack direction={"row"} columnGap={"8px"}
                      justifyContent={"space-between"} alignItems={"center"}>
                      <img className={classes_1.region_flag} src={screen_state?.flag} alt="" />
                      <Typography className={classes_1.region_text}>
                        {screen_state?.region}
                      </Typography>
                    </Stack>
                    <KeyboardArrowDownRoundedIcon fontSize="small" color="#4E5A6B" />
                  </Stack>
                  <Box height={"16px"} />
                </>
              }
              <TextBox
                label={Message.English.createAccount.email.label}
                placeholder={Message.English.createAccount.email.placeholder}
                isError={account.error.email.length > 0}
                errorMessage={account.error.email}
                value={decoded && decoded.email_id ? decoded.email_id : ""}
                isReadonly
                disabled
                isRequired
              />
              <Box height={"16px"} />
              <TextBox
                value={account.enterPassword}
                onChange={(e) => updateState("enterPassword", e.target.value)}
                label={Message.English.createAccount.enterPassword.label}
                placeholder={
                  Message.English.createAccount.enterPassword.placeholder
                }
                isError={account.error.enterPassword.length > 0}
                errorMessage={account.error.enterPassword}
                isRequired
                type={show?.show1 ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => updateShow("show1", !show?.show1)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {show?.show1 ? (
                        <RemoveRedEyeOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Box height={"16px"} />
              <TextBox
                value={account.confirmPassword}
                onChange={(e) =>
                  updateState("confirmPassword", e.target.value)
                }
                label={Message.English.createAccount.confirmPassword.label}
                placeholder={
                  Message.English.createAccount.confirmPassword.placeholder
                }
                isError={account.error.confirmPassword.length > 0}
                errorMessage={account.error.confirmPassword}
                isRequired
                type={show?.show2 ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => updateShow("show2", !show?.show2)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {show?.show2 ? (
                        <RemoveRedEyeOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Box height={"10px"} />
              <Box display={"flex"} justifyContent={"end"}>
                <LightTooltip title={<PasswordInfo t={t} />} placement="left" open={isInfoOpen} arrow>
                  <Box
                    onClick={() => setIsInfoOpen(!isInfoOpen)}
                    onMouseEnter={() => setIsInfoOpen(true)}
                    onMouseLeave={() => setIsInfoOpen(false)}>
                    <InfoIcon />
                  </Box>
                </LightTooltip>
              </Box>
            </Grid>
            <Grid item xs={12} alignSelf={"self-end"} className={classes_1.mob_bottom_items}>
              <Stack direction={"column"} alignItems={"start"}>
                <Button className={classes_1.mob_set_up_new_button} disabled={disable}
                  onClick={onSendAccountBtnClicked}>
                  <Typography className={classes_1.mob_set_up_new_button_text}>
                    {t("Set Password")}
                  </Typography>
                </Button>
                <Box height={"16px"} />
                <Stack direction={"row"} columnGap={"16px"} width={"100%"} display={"flex"}
                  justifyContent={"space-between"} alignItems={"center"}>
                  {/* <Typography className={classes_1.terms_of_use_word}
                    onClick={() => setTCStatus(true)}>
                    {t("Terms of Use")}
                  </Typography> */}
                  <img className={classes_1.powered_by_logo} src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                  {app_version &&
                    <Typography className={classes_1.version_no}>
                      {`V ${app_version}`}
                    </Typography>}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>


        <Hidden smDown>
          <Dialog
            className={classes.dialog}
            maxWidth="sm"
            fullWidth
            open={tcStatus}
            onClose={() => handleClose()}
          >
            <Grid container>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }} p={2}>
                <Close sx={{ color: "#4E5A6B" }} onClick={() => handleClose()} />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ height: "450px", overflow: "auto" }} p={2}>
                  <TermsAndCondition termsCondition={termCondition} t={t} />
                </Box>
              </Grid>
            </Grid>
          </Dialog>
        </Hidden>
        <Hidden smUp>
          <Drawer
            anchor="bottom"
            className={classes.drawer}
            open={tcStatus}
            onClose={() => handleClose()}
          >
            <Grid container>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }} p={2}>
                <Close sx={{ color: "#4E5A6B" }} onClick={() => handleClose()} />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ height: "450px", overflow: "auto" }} p={2}>
                  <TermsAndCondition termsCondition={termCondition} t={t} />
                </Box>
              </Grid>
            </Grid>
          </Drawer>
        </Hidden>
      </>
  );
};
export default withNamespaces("welcomePage")(SetupAccountDetails)
