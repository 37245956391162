import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DocumentViewer, PayForm } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  bottomTitle: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "6px 8px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  titles: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  totalAmount: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },

  subTitle: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
  },
  subTitlecash: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary,
  },
  btn: {
    padding: "10px",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "none",
  },
  btn1: {
    padding: "10px",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "white",
    border: "1px solid #5078E1",
    color: "#5078E1",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "white",
    },
  },

  companyName: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  companyRoot: {
    cursor: "pointer",
    borderBottom: "1px solid #E4E8EE",
  },
}));

const DashboardPay = (props) => {
  const { t=()=>false } = props
  const classes = useStyles();
  const history = useHistory();
  const [paymentoption, setPaymentoption] = React.useState(false);
  const [formopen, setformopen] = React.useState(false);
  const [pdf, setpdf] = React.useState(false);
  const [companys, setCompnays] = React.useState({});
  const [companysopen, setCompnaysopen] = React.useState(false);
  const [details, setDetails] = React.useState([]);
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);

  const getBankDetails = (val) => {
    if (val) {
      const params = {
        id: [val]
      };
      NetworkCall(
        `${config.api_url}/queries/company_master/get`,
        NetWorkCallMethods.post,
        params,
        null,
        true,
        false
      )
        .then((response) => {
          setDetails(response.data.data.company_master);
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some thing went wrong",
          });
        });
    }
  };
  React.useEffect(() => {
    getBankDetails(companys?.company_id);
    // eslint-disable-next-line
  }, [companys]);
  // const invoicedata = {
  //   currency_master: {
  //     symbol: props?.currency_symbol,
  //   },
  //   description: "",
  //   invoice_due_amount: props?.Outstanding_amount,
  //   invoice_no: "K-124-33",
  //   id: "K-124-33",
  // };


  const renderInvoicePay = () => {
    return (
      <div>
        <Box display="flex" alignItems="center" className={classes.titleroot}>
          <Box flexGrow={1}>
            <Typography className={classes.bottomTitle}>{t("dashInvoices")}</Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setCompnaysopen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Grid item xs={12} style={{ padding: "0px 12px 12px 12px" }}>
          {/* <InvoicePayNowList
            InvoicePay={invoicedata}
            dashboard={true}
            delete={true}
            type="dashboard"
            viewinvoice={() => setpdf(true)}
          /> */}
          <Grid item xs={12}>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              spacing={1}
            >
              <Grid item xs={12}>
                <Typography
                  className={classes.titles}
                  style={{ marginTop: "8px" }}
                >
                  {t("dashBillSummary")}
                </Typography>
              </Grid>

              <Grid
                container
                alignItems="center"
                style={{ padding: "4px 8px 8px 8px" }}
              >
                <Grid item xs={6}>
                  <Typography className={classes.subTitle}>
                    {t("dashInvoiceAmount")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ float: "right" }}>
                    <Typography className={classes.subTitle}>
                      {companys.currency_symbol +
                        Intl.NumberFormat("en-IN", {
                          minimumFractionDigits: 0,
                        }).format(Math.round(companys?.total_amount))}
                    </Typography>
                  </div>
                </Grid>

                {/* <Grid item xs={6}>
                  <Box height="4px" />
                  <Typography className={classes.subTitle}>
                    VAT Amount
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box height="4px" />
                  <div style={{ float: "right" }}>
                    <Typography className={classes.subTitle}>4.00</Typography>
                  </div>
                </Grid> */}
              </Grid>
              <Grid
                container
                alignItems="center"
                style={{
                  display: "flex",
                  borderBottom: "2px dashed #CED3DD",
                  borderTop: "2px dashed #CED3DD",
                  padding: "4px 8px 4px 8px",
                }}
              >
                <Grid item xs={6}>
                  <Typography className={classes.totalAmount}>
                    {t("dashTotalAmountToPay")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ float: "right" }}>
                    <Typography className={classes.totalAmount}>
                      {companys.currency_symbol +
                        Intl.NumberFormat("en-IN", {
                          minimumFractionDigits: 0,
                        }).format(Math.round(companys?.total_amount))}
                    </Typography>
                  </div>
                  <br />
                </Grid>
              </Grid>

              <Grid
                item
                container
                spacing={1}
                xs={12}
                sx={{ marginTop: "6px" }}
              >
                {
                  props?.invoices?.length === 1 &&
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      fullWidth
                      className={classes.btn1}
                      onClick={() => setformopen(true)}
                    >
                      {t("dashAlreadyPaid")}?
                    </Button>
                  </Grid>
                }
                <Grid item xs={props?.invoices?.length === 1 ? 6 : 12}>
                  <Button
                    disabled
                    variant="contained"
                    fullWidth
                    className={classes.btn}
                    onClick={() => {
                      setPaymentoption(true);
                      props?.onClose();
                    }}
                  >
                    {t("dashPayNow")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };
  const renderPayoption = () => {
    return (
      <div>
        <Box display="flex" alignItems="center" className={classes.titleroot}>
          <Box flexGrow={1}>
            <Typography className={classes.bottomTitle}>
              {t("dashPaymentOptions")}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setPaymentoption(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <Box>
          <Box style={{ padding: "4px 12px" }}>
            <Typography className={classes.bottomTitle}>{t("dashCash")}</Typography>
            <Typography className={classes.subTitlecash}>
              {details?.[0]?.cash_collection_office}
            </Typography>
          </Box>
          <Divider sx={{ margin: "4px 0px", border: "2px solid #F2F4F7" }} />
          <Box style={{ padding: "4px 12px" }}>
            <Typography className={classes.bottomTitle}>{t("dashCheque")}</Typography>
            <Typography className={classes.subTitlecash}>
              {details?.[0]?.cheque_name}
            </Typography>
          </Box>
          <Divider sx={{ margin: "4px 0px", border: "2px solid #F2F4F7" }} />
          <Box style={{ padding: "4px 12px" }}>
            <Typography className={classes.bottomTitle}>
              {t("dashBanktransfer")}
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Typography className={classes.subTitle}>{t("dashBankName")}</Typography>
              </Grid>
              <Grid item xs={6}>
                <div style={{ float: "right" }}>
                  <Typography className={classes.subTitle}>
                    {details?.[0]?.primary_bank_name}
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={6}>
                <Box height="4px" />
                <Typography className={classes.subTitle}>
                  {t("dashIBANNumber")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box height="4px" />
                <div style={{ float: "right" }}>
                  <Typography className={classes.subTitle}>
                    {details?.[0]?.iban}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                <Box height="4px" />
                <Typography className={classes.subTitle}>{t("dashIFSCCode")}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Box height="4px" />
                <div style={{ float: "right" }}>
                  <Typography className={classes.subTitle}>
                    SABBB0139412
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ margin: "4px 0px", border: "2px solid #F2F4F7" }} />
          <Box style={{ padding: "4px 12px" }}>
            <Typography className={classes.bottomTitle}> {t("dashPayNow")} </Typography>
            <Typography className={classes.subTitlecash}>
              Click pay now button to pay instantly through our payment Gateway
              with more payment options
            </Typography>
          </Box>
          <Divider sx={{ margin: "4px 0px", border: "1px solid #F2F4F7" }} />
          <Box style={{ padding: "4px 12px" }}>
            <Button
              className={classes.btn}
              variant="contained"
              fullWidth
              onClick={() => {
                history.push({
                  pathname: Routes.payment,
                  state: {
                    amount: `${companys?.currency_symbol}  ${companys?.total_amount}`
                  },
                });
              }}
            >
              {t("dashPayNow")} ({" "}
              {companys?.currency_symbol +
                Intl.NumberFormat("en-IN", {
                  minimumFractionDigits: 0,
                }).format(Math.round(companys?.total_amount))}
              )
            </Button>
          </Box>
        </Box>
      </div>
    );
  };
  const renderPdf = () => {
    return (
      <div>
        <Box display="flex" alignItems="center" className={classes.titleroot}>
          <Box flexGrow={1}>
            <Typography className={classes.bottomTitle}> {t("dashInvoice")} </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setpdf(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <div style={{ height: size?.height - 200, overflow: "auto" }}>
          <DocumentViewer url="https://protomate.objectstore.e2enetworks.net/b375c08e69402892f02b496da77f4d0b.pdf" />
        </div>
      </div>
    );
  };
  const renderCompanyList = () => {
    return (
      <div>
        <Box display="flex" alignItems="center" className={classes.titleroot}>
          <Box flexGrow={1}>
            <Typography className={classes.bottomTitle}>
              {t("dashCompanyDetails")}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => props?.onClose()}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box p={1} minHeight="200px">
          {props?.companyList?.map((val) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                className={classes.companyRoot}
                onClick={() => {
                  setCompnays(val);
                  props?.onClose();
                  setCompnaysopen(true);
                }}
                p={1}
              >
                <Box flexGrow={1}>
                  <Typography className={classes.companyName}>
                    {val?.company_name}
                  </Typography>
                </Box>
                <Box>
                  <Typography className={classes.companyName}>
                    {val.currency_symbol +
                      Intl.NumberFormat("en-IN", {
                        minimumFractionDigits: 0,
                      }).format(Math.round(val?.total_amount))}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </div>
    );
  };

  return (
    <>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={companysopen}
          onClose={() => setCompnaysopen(false)}
        >
          <div>{renderInvoicePay()}</div>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          open={companysopen}
          anchor="bottom"
          className={classes.drawer}
          onClose={() => setCompnaysopen(false)}
        >
          <div>{renderInvoicePay()}</div>
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={paymentoption}
          onClose={() => setPaymentoption(false)}
        >
          <div>{renderPayoption()}</div>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          anchor="bottom"
          className={classes.drawer}
          open={paymentoption}
          onClose={() => setPaymentoption(false)}
        >
          <div>{renderPayoption()}</div>
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={formopen}
          onClose={() => setformopen(false)}
        >
          <div>
            <PayForm
              currency_id={companys?.currency_id}
              company_id={companys?.company_id}
              invoices={props?.invoices}
              onClose={() => setformopen(false)}
            />
          </div>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          anchor="bottom"
          className={classes.drawer}
          open={formopen}
          onClose={() => setformopen(false)}
        >
          <div>
            <PayForm
              currency_id={companys?.currency_id}
              invoices={props?.invoices}
              company_id={companys?.company_id}
              onClose={() => setformopen(false)}
            />
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={pdf}
          onClose={() => setpdf(false)}
        >
          <div>{renderPdf()}</div>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          anchor="bottom"
          className={classes.drawer}
          open={pdf}
          onClose={() => setpdf(false)}
        >
          <div>{renderPdf()}</div>
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={props?.open}
          onClose={() => props?.onClose()}
        >
          <div>{renderCompanyList()}</div>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          anchor="bottom"
          className={classes.drawer}
          open={props?.open}
          onClose={() => props?.onClose()}
        >
          <div>{renderCompanyList()}</div>
        </Drawer>
      </Hidden>
    </>
  );
};
export default withNamespaces("dashboard")(DashboardPay)