import React from "react";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";
import { DialogDrawer, NoService } from "../../../components";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../router/routes";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    backgroundColor: (props) => props?.icon_bg ?? "#F6F9FF",
    padding: "12px",
    height: "48px",
    width: "48px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "75px"
  },
  name: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    fontWeight: "500",
    marginTop: "10px !important",
    color: theme.typography.color.primary,
  },
}));
function UnitDetails(props) {
  const { t = () => false } = props
  const classes = useStyles(props);
  const [open, setopen] = React.useState(false);
  const history = useHistory();

  return (
    <>
      <Grid container spacing={2} >
        {props?.data?.map((item, index) => (
          item?.show && (
            <Grid
              key={index}
              item
              xs={4}
              sm={3}
              onClick={() => {
                if (item?.noavailable) {
                  if (!props?.openDrawer) {
                    setopen(true);
                  } else {
                    props.openDrawer();
                  }
                } else if (item?.link === "/vehicle" || item?.link === "/occupants" || item?.link === Routes.inspectionOrder || item?.link === Routes.serviceRecordOrders) {
                  history.push({
                    pathname: item.link,
                    state: {
                      main: {
                        agreement: item.data,
                      },
                    },
                  });
                } else if (item?.type === "method") {
                  item?.link();
                } else if (item?.link) {
                  let route_link = item?.link;
                  if (item?.data) {
                    route_link = route_link + "?Id="+ item?.data;
                  }
                  history.push(route_link);
                } else if(item?.onClick) {
                  item?.onClick()
                }
                else{
                  
                }
              }}
            >
              <Box key={index} className={classes.container}>
                {item?.is_image ?
                  <IconButton className={classes.IconButton} size="small">
                    <img src={item.svg} alt="icon" />
                  </IconButton>
                  :
                  <IconButton className={classes.IconButton} size="small">
                    {item?.svg}
                  </IconButton>
                }
                <Typography className={classes.name} align="center">
                  {props.t(item?.name)} {props.t(item?.sub)}
                </Typography>
              </Box>
            </Grid>
          )

        ))}
      </Grid>
      <DialogDrawer
        height={"300px"}
        maxWidth={"sm"}
        open={open}
        header={" "}
        noService={true}
        drawerComponent={
          <>
            <NoService
              main={t("This feature is either not enabled or not opted by your organization.")}
              sub={t("Please contact your administrator for details.")}
              buttonName={t("Close")}
              mobile
              fullWidth
              onClose={() => setopen(false)}
            />
          </>
        }
        dialogComponent={
          <>
            <NoService
              main={t("This feature is either not enabled or not opted by your organization.")}
              sub={t("Please contact your administrator for details.")}
              buttonName={t("Close")}
              fullWidth
              onClose={() => setopen(false)}
            />
          </>
        }
        onClose={() => setopen(false)}
      />
    </>
  );
}

export default withNamespaces("dashboard")(UnitDetails);
