import { Box, Stack } from '@mui/material'
import React from 'react'
import { LoderSimmer } from '../../components'

export const AnnouncementSimmer = () => {
    return (
        <Stack sx={{ background: "#fff", borderRadius: "8px" }}>
            <Stack direction={"row"} columnGap={1}>
                <Box sx={{ height: "40px", width: "65px" }}>
                    <LoderSimmer card count={1} size={40} borderRadius={"50px"} />
                </Box>
                <Box sx={{ width: "150px" }}>
                    <LoderSimmer card count={1} size={40} />
                </Box>
            </Stack>
            <LoderSimmer card count={2} size={30} />
            <LoderSimmer card count={1} size={180} />

        </Stack>
    )
}