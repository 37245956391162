import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, FontSizeCalc } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: FontSizeCalc(14),
        fontFamily: FontFamilySwitch().bold,
        color: "#404E61",
    },
    radioButtonLabel: {
        fontSize: FontSizeCalc(14),
        fontFamily: FontFamilySwitch().semiBold,
        color: "#404E61",
    },
    radioButton: {
        color: "#404E61",
        [`&.Mui-checked`]: { color: "#5078E1" },
        [`&.Mui-disabled`]: { color: "#A4B2C1" },
    },
    checkBoxLabel: {
        fontSize: FontSizeCalc(14),
        fontFamily: FontFamilySwitch().semiBold,
        color: "#404E61",
    },
    checkBox: {
        color: "#404E61",
        [`&.Mui-checked`]: { color: "#5078E1" },
        [`&.Mui-disabled`]: { color: "#A4B2C1" },
    },
}));