import moment from "moment-timezone";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import {
  AlertProps,
  NetWorkCallMethods,
  ValidateEmail,
} from "../../utils";
export const PassContext = React.createContext();
moment.defaultFormat = "DD MMM YY";

const PassContextProvider = (props) => {
  const { t=()=>false } = props;
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const history = useHistory();
  const searchURL = useLocation().search;
  const passId = new URLSearchParams(searchURL).get("passId");
  const [disable, setDisable] = React.useState(null);
  const [agreementList, setAgreementLists] = React.useState([]);
  const [property, setProperty] = React.useState({});
  const [openConfirmationDrawer, setOpenConfirmationDrawer] = React.useState(false);
 
  React.useEffect(() => {
    if (passId) {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "Loading",
      });
      const data = {
        tenantId: `${config.tenantid}`,
        request_id: passId,
      };
      NetworkCall(
        `${config.api_url}/security/request`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          const temp = response?.data?.data[0];
          updateAllForms(temp);
          // const InitialState = {
          //   passType: temp?.pass_type,
          //   //  {
          //   //   value: temp?.request_type,
          //   //   label:
          //   //     temp?.request_type === "WGR"
          //   //       ? "Worker Gate Pass"
          //   //       : "Visitor Gate Pass",
          //   // },
          //   unit: {
          //     value: temp?.agreement_unit_id ?? "",
          //     label: temp?.unit?.[0]?.name ?? "",
          //     unit_id: temp?.unit?.[0]?.id ?? "",
          //   },
          //   entryType: temp?.entry_type,
          //   stayType: temp?.stay_type,
          //   startData: new Date(temp?.request_from),
          //   endDate: new Date(temp?.request_to),
          //   gate: {
          //     value: temp?.gate_id,
          //     label: temp?.gate,
          //   },
          //   profileImg: "",
          //   name: "",
          //   mobile: "",
          //   mail: "",
          //   idType: { label: data?.id_type, value: data?.identification_master_id },
          //   idproof: "",
          //   imgURL: "",
          //   gender: "",
          //   error: {
          //     passType: "",
          //     unit: "",
          //     stayType: "",
          //     startData: "",
          //     endDate: "",
          //     gate: "",
          //     profileImg: "",
          //     name: "",
          //     mobile: "",
          //     mail: "",
          //     idType: "",
          //     idproof: "",
          //     gender: "",
          //     entryType: "",
          //     group: "",
          //     groupCount: "",
          //   },
          // };
          setProperty({
            id: temp?.property_id,
            name: temp?.property_name,
          });
          // setdata(InitialState);
          // setVisitList(temp?.visitors);
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
        })
        .catch(() => {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
    }
    // eslint-disable-next-line
  }, []);
  // const updateState = (key, value) => {
  //   if (key === "unit") {
  //     getpropertyUnitid(value);
  //   }
  //   let error = data?.error;
  //   error[key] = "";
  //   setdata({ ...data, [key]: value, error });
  // };

  const addPass = (payload) => {

    // const payload = {
    //   tenantId: `${config.tenantid}`,
    //   pass_type: "VGR",
    //   pass_class: data?.passType,
    //   visitor_type: "Visitor",
    //   stay_type: data?.stayType,
    //   start_date: data?.startData,
    //   end_date: data?.stayType === "Short" ? data?.startData : data?.endDate,
    //   gate_no: data?.gate?.value === "Any" ? undefined : data?.gate?.value,
    //   user_profile_id: localStorage.getItem("userProfileId"),
    //   entry_type: data?.entryType,
    //   profiles: visitList.map((visitor) => {
    //     return {
    //       name: visitor?.name,
    //       gender: visitor?.gender,
    //       mobile_no: visitor?.mobile_no,
    //       mobile_country_code: visitor?.mobile_country_code,
    //       email_id: visitor?.email_id,
    //       visitor_image: visitor?.visitor_image,
    //       id_type: visitor?.id_type?.value,
    //       identification_no: visitor?.identification_no,
    //       is_group: visitor?.group === "Yes",
    //       group_count: visitor?.groupCount,
    //     };
    //   }),
    //   property_id: property?.id,
    //   unit_id: data?.unit?.unit_id,
    //   agreement_unit_id: data?.unit?.value,
    // };

    // const payload2 = {
    //   tenantId: `${config.tenantid}`,
    //   pass_type: "VGR",
    //   pass_class: data?.passType,
    //   visitor_type: data?.passType === "VGR" ? "Visitor" : "Worker",
    //   stay_type: data?.stayType,
    //   start_date: data?.startData,
    //   end_date: data?.endDate,
    //   property_id: property?.id,
    //   unit_id: data?.unit?.unit_id,
    //   entry_type: data?.entryType,
    //   agreement_unit_id: data?.unit,
    //   user_profile_id: localStorage.getItem("userProfileId"),
    // };

    NetworkCall(
      `${config.api_url}/security/create`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("PassSuccessfullyCreated"),
        });
        history.goBack(-1);
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
        setDisable(false);
      });
  };
  const update = (payload) => {
    // let result2 = visitList.filter((val) => !val.id);
    // const payload = {
    //   tenantId: `${config.tenantid}`,
    //   pass_type: "VGR",
    //   pass_class: data?.passType,
    //   visitor_type: "Visitor",
    //   entry_type: data?.entryType,
    //   stay_type: data?.stayType,
    //   start_date: data?.startData,
    //   end_date: data?.endDate,
    //   gate_no: data?.gate?.label === "Any" ? undefined : data?.gate?.value,
    //   request_id: passId,
    //   user_profile_id: localStorage.getItem("userProfileId"),
    //   profiles: result2.map((visitor) => {
    //     return {
    //       name: visitor?.name,
    //       gender: visitor?.gender,
    //       mobile_no: visitor?.mobile_no,
    //       mobile_country_code: visitor?.mobile_country_code,
    //       email_id: visitor?.email_id,
    //       visitor_image: visitor?.visitor_image,
    //       id_type: visitor?.id_type?.value,
    //       identification_no: visitor?.identification_no,
    //       group_count: visitor?.groupCount,
    //       is_group: visitor?.group === "Yes",
    //     };
    //   }),
    //   property_id: property?.id,
    //   unit_id: data?.unit?.unit_id,
    //   agreement_unit_id: data?.unit?.value,
    //   deleteProfiles: editVisitList,
    // };

    NetworkCall(
      `${config.api_url}/security/update`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("PassSuccessfullyUpdated"),
        });
        history.goBack(-1);
        setDisable(false);
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
        setDisable(false);
      });
  };

  const getUnitIDS = () => {
    NetworkCall(
      `${config.api_url}/community/community_units`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let data = response?.data?.data?.map((x) => x?.agreement_unit).flat();
        const _tempUnitList = data?.map((_uitem) => {
          let _u;
          _u = {
            label: _uitem?.name ?? "",
            value: _uitem?.id ?? "",
            unit_id: _uitem?.unit_id ?? "",
          };

          return _u;
        });
        setAgreementLists(_tempUnitList);
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  const getpropertyUnitid = (v) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    const payload = {
      ids: [v?.unit_id],
      offset: 0,
      limit: 10,
      search: "",
    }
      
      NetworkCall(
        `${config.api_url}/queries/community_contacts/getByID`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      ).then((res) => {
        setProperty(res?.data?.data?.unit?.[0]?.property);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch(() => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };


  // ===============================================================================
  // ====================000000=================000000000===========================
  // ===============================================================================
  // ===============================0000000=========================================
  // ===============================================================================
  // ====================000000=================000000000===========================
  // ===============================================================================

  const visitor_initial_state = {
    profileImg: "",
    name: "",
    mobile: "",
    mail: "",
    idType: "",
    idproof: "",
    imgURL: "",
    gender: "",
    entryType: "single",
    is_edited: false,
    is_deleted: false,
    view_type: "edit",
    previous_state: "",
    error: {
      profileImg: "",
      name: "",
      mobile: "",
      mail: "",
      idType: "",
      idproof: "",
      imgURL: "",
      gender: "",
      entryType: "",
    }
  }

  const request_initial_state = {
    request_details: {
      passType: "Individual",
      unit: "",
      stayType: "Short",
      startData: new Date(),
      endDate: new Date(),
      gate: {
        label: "Any",
        value: "Any"
      },
      entryType: "single",
      groupCount: null,
      view_type: "edit",
      is_edited: false,
      is_updated: false,
      previous_state: "",
      error: {
        passType: "",
        unit: "",
        stayType: "",
        startData: "",
        endDate: "",
        gate: "",
        groupCount: "",
        entryType: "",
      },
    },
    visitor_details: []
  }

  const [data, setData] = React.useState(request_initial_state)

  const updateState = (k, v) => {// updateState("request_details",{_k:"unit",_v:"unit" }) 

    let { _k, _v, index } = v // updateState("visitor_details",{_k:"unit",_v:"unit",index:0 })

    switch (k) {
      case "request_details":
        if (_k === "unit") {
          getpropertyUnitid(_v);
        }
        let tempState  ={};
        if (_k === "passType" && _v === "Individual") {
          tempState = { ...data, [k]: { ...data?.[k], [_k]: _v, groupCount:"", error: { ...data?.[k]?.error, [_k]: "" } } }
        }else{
          tempState = { ...data, [k]: { ...data?.[k], [_k]: _v, is_updated:true, error: { ...data?.[k]?.error, [_k]: "" } } }
        }
        setData(tempState);
        break;

      case "visitor_details":
        let temp_visitor_details = data?.visitor_details

        let temp_visitors = updateVisitorArray(temp_visitor_details, index, _k, _v)

        setData({ ...data, [k]: temp_visitors })
        break;

      default:
        break;
    }

  }

  const addVisitor = () => {
    setData({ ...data, visitor_details: [...data?.visitor_details, visitor_initial_state] })
  }
  const validateRequest = () => {
    let is_valid = true;
    let error = data?.request_details?.error;
    let temData = data?.request_details;

    if (temData?.passType?.length === 0) {
      is_valid = false;
      error.passType = t("PassTypeisRequired");
    }

    if (temData?.unit?.length === 0) {
      is_valid = false;
      error.unit = t("unitisRequired");
    }

    if (temData?.stayType?.length === 0) {
      is_valid = false;
      error.stayType = t("StayTypeisRequired");
    }

    if (temData?.entryType?.length === 0) {
      is_valid = false;
      error.entryType = t("EntryTypeisRequired");
    }

    if (temData?.startData?.length === 0) {
      is_valid = false;
      error.startData = t("StartDateisRequired");
    }

    if (temData?.stayType === "Long") {
      if (temData?.endDate?.length === 0) {
        is_valid = false;
        error.endDate = t("EndDateisRequired");
      }
    } else {
      error.endDate = "";
    }

    if (!temData?.gate) {
      is_valid = false;
      error.gate = t("GateisRequired");
    }
    if (temData?.passType === "Group") {
      if (!temData?.groupCount || (temData?.groupCount?.length === 0)) {
        is_valid = false;
        error.groupCount = t("GroupCountisRequired");
      }
    }
    // let initialDate = moment(temData.startData);
    // if (temData?.stayType === "Long" && temData?.endDate) {
    //   let endDate = moment(temData.endDate);
    //   if (endDate?.diff(initialDate) >= 0) {
    //   } else {
    //     is_valid = false;
    //     error.endDate = t("EndDateisMorethenStartDate");
    //   }
    // }
    setData({ ...data, request_details: { ...temData, error: error } })
    return is_valid;
  }
  const validateVisitor = (index) => {
    let is_valid = true;

    let temp_visitor_details = data?.visitor_details

    let temp_visitor_detail = temp_visitor_details?.[index]

    let error = temp_visitor_detail?.error;

    // if (temp_visitor_detail?.name?.length === 0) {
    //   is_valid = false;
    //   error.name = "Name is Required";
    // }
    if (temp_visitor_detail?.name?.length === 0) {
      is_valid = false;
      error.name = t("NameisRequired");
    }
    if (temp_visitor_detail?.mobile?.length === 0) {
      is_valid = false;
      error.mobile = t("MobileisRequired");
    }

    if (temp_visitor_detail?.gender?.length === 0) {
      is_valid = false;
      error.gender = t("GenderisRequired");
    }
    if (temp_visitor_detail?.mail?.length > 0) {
      if (ValidateEmail(temp_visitor_detail?.mail) === false) {
        is_valid = false;
        error.mail = t("InvalidMail");
      } else {
        error.mail = ""
      }
    }
    // else {
    //   is_valid = false;
    //   error.mail = t("EmailisRequired");
    // }
    // if (temp_visitor_detail?.passType === "Group") {
    //   if (temp_visitor_detail?.group === "Yes") {
    //   if (temp_visitor_detail?.groupCount?.length === 0) {
    //     is_valid = false;
    //     error.groupCount = t("GroupCountisRequired");
    //   }
    // }
    //========================
    let temp_visitors = updateVisitorArray(temp_visitor_details, index, "error", error)

    setData({ ...data, visitor_details: temp_visitors })
    return is_valid;
  }

  const updateVisitorArray = (array, index, k, v) => {
    let temp_visitors = []

    for (let i = 0; i < array?.length; i++) {
      temp_visitors = [
        ...temp_visitors,
        i === index ?
          { ...array[i], [k]: v, error: { ...array[i]?.error, [k]: "" } } :
          { ...array[i] }
      ]
    }

    return temp_visitors
  }

  const CreateVisitor = () => {
    // request_id: passId,

    const visitor_request = visitor_request_payload();
    const visitor_details = visitor_details_payload();
    const payload = { ...visitor_request, ...visitor_details }
    if (passId) {
      payload["request_id"] = passId;
      const deletedList = data?.visitor_details?.filter(({ is_deleted }) => is_deleted === true);
      payload["deleteProfiles"] = deletedList?.map(({ id }) => id);
      update(payload);
    } else {
      addPass(payload);
    }
  }
  const visitor_request_payload = () => {
    const temp_payload = data?.request_details;
    let payload_data = {
      tenantId: `${config.tenantid}`,
      pass_type: "VGR",
      pass_class: temp_payload?.passType,
      visitor_type: "Visitor",
      stay_type: temp_payload?.stayType,
      start_date: temp_payload?.startData,
      end_date: temp_payload?.stayType === "Short" ? temp_payload?.startData : temp_payload?.endDate,
      gate_no: temp_payload?.gate?.value === "Any" ? undefined : temp_payload?.gate?.value,
      user_profile_id: localStorage.getItem("userProfileId"),
      entry_type: temp_payload?.entryType,
      property_id: property?.id,
      unit_id: temp_payload?.unit?.unit_id,
      agreement_unit_id: temp_payload?.unit?.value,
      group_count: temp_payload?.groupCount,
    }
    return payload_data;
  }
  const visitor_details_payload = () => {
    const temp_payload = data?.visitor_details;
    const temp_visitor_list = temp_payload?.filter(({ is_deleted }) => is_deleted === false);
    let payload_data = {
      profiles: temp_visitor_list.map((visitor) => {
        return {
          id: visitor?.id,
          name: visitor?.name,
          gender: visitor?.gender?.value,
          mobile_no: visitor?.mobile?.mobile,
          mobile_country_code: visitor?.mobile?.mobile_code,
          email_id: visitor?.mail,
          visitor_image: visitor?.profileImg,
          id_type: visitor?.idType?.value,
          identification_no: visitor?.idproof,
        };
      }),
    }
    return payload_data;
  }

  const updateAllForms = (temp) => {
    const InitialState_request = {
      request_details: {
        passType: temp?.pass_type,
        unit: {
          value: temp?.agreement_unit_id ?? "",
          label: temp?.unit?.[0]?.name ?? "",
          unit_id: temp?.unit?.[0]?.id ?? "",
        },
        entryType: temp?.entry_type,
        stayType: temp?.stay_type,
        startData: new Date(temp?.request_from),
        endDate: new Date(temp?.request_to),
        gate: {
          value: temp?.gate === "Any" ? undefined : temp?.gate_id,
          label: temp?.gate,
        },
        groupCount: temp?.group_count,
        view_type: "view",
        is_edited: false,
        error: {
          passType: "",
          unit: "",
          stayType: "",
          startData: "",
          endDate: "",
          gate: "",
          groupCount: "",
          entryType: "",
        },
      }
    };
    const InitialState_details = {
      visitor_details: temp?.visitors?.map((visitor) => {
        return {
          id: visitor?.id,
          profileImg: visitor?.visitor_image,
          name: visitor?.name,
          mobile: { mobile_code: visitor?.mobile_country_code ?? "", mobile: visitor?.mobile_no ?? "" },
          mail: visitor?.email_id,
          idType: visitor?.id_type ? { label: visitor?.id_type, value: visitor?.identification_master_id } : "",
          idproof: visitor?.identification_no,
          gender: { label: visitor?.gender, value: visitor?.gender },
          entryType: "single",
          is_edited: false,
          is_deleted: false,
          view_type: "view",
          error: {
            profileImg: "",
            name: "",
            mobile: "",
            mail: "",
            idType: "",
            idproof: "",
            imgURL: "",
            gender: "",
            entryType: "",
          },
        }
      }),
    }
    const allState = { ...InitialState_request, ...InitialState_details }
    setData(allState);
  }
  return (
    <PassContext.Provider
      value={{
        data,
        updateState,
        getUnitIDS,
        setData,
        addVisitor,
        validateVisitor,
        validateRequest,
        CreateVisitor,
        agreementList,
        disable,
        property,
        openConfirmationDrawer, setOpenConfirmationDrawer
      }}
    >
      {props.children}
    </PassContext.Provider>
  );
};

export default withNamespaces("createPass")(PassContextProvider);
