import {
  Box,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
  Filter,
  LoderSimmer,
  UnitAndRequestCard,
} from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import {
  AlertProps,
  FontFamilySwitch,
  NetWorkCallMethods,
} from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import UnitDetails from "./unitDetails";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    "& .MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  cardImage: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    borderRadius: "12px",
  },
  total: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
  },

  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  filterdata: {
    padding: "8px 12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    width: "100%",
  },

  filterleft: {
    borderRight: "1px solid #c1c1c1",
  },
  active: {
    fontSize: "0.75rem",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
    width: "100%",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  filterdataActive: {
    padding: "8px 12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: theme.palette.background.secondary,
    width: "100%",
  },
  bottomTitle: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px",
  },
  filterDrawer: {
    "& .MuiDrawer-paper": {
      width: "-webkit-fill-available",
    },
  },
  contentBottom: {
    padding: "12px",
  },
  unitImg: {
    height: "250px",
    width: "100%",
    borderRadius: "4px",
    objectFit: "cotain",
  },
  titleroot2: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "8px",
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
  },
  phone: {
    borderRadius: "4px",
    border: "1px solid #c1c1c1",
  },
  bottomDetails: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  viewHandbook: {
    color: theme.palette.primary.main,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  call: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().semiBold,
    textDecoration: "none",
    color: theme.typography.color.secondary,
  },
  callbox: {
    cursor: "pointer",
  },
  menuText: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    marginTop: "30%",
  },
}));
const Units = (props) => {
  const { t = () => false, handleTopBarState } = props;

  const search = useLocation().search;
  const alert = React.useContext(AlertContext);
  const requestId = new URLSearchParams(search).get("Id");
  const size = useWindowDimensions();
  const classes = useStyles();
  const [drawer, setDrawer] = React.useState(null);
  const [unitDrawer, setunitDrawer] = React.useState(false);
  const [units, setUnits] = React.useState([]);
  const [unitdetails, setunitdetails] = React.useState({});
  const [agreementfilters, setAgreemnetFilters] = React.useState([]);
  const [handBookPdf, setHandbookPdf] = React.useState("");
  // const [totalCount, setTotalCount] = React.useState("");
  const [offSet, setOffSet] = React.useState(0);
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [agreementunits, setAgreemnetunits] = React.useState([]);
  const getUnits = (unitId, filter, offset) => {
    if (unitId?.length > 0) {
      const data = {
        tenantId: `${config.tenantid}`,
        unit_id: unitId,
        offset: offset,
        limit: 20,
      };
      NetworkCall(
        `${config.api_url}/agreement-unit-info`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          if (filter === true) {
            setUnits(response?.data?.data?.results);
          } else {
            setUnits(units?.concat(response?.data?.data?.results));
          }
          // setTotalCount(response?.data?.data?.totalCount);
          setLoading(false);
          setDrawer(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  const getUnitids = () => {
    NetworkCall(
      `${config.api_url}/agreement-unit-info/switcher`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        setAgreemnetunits(response?.data?.data);
        //all filterData common
        const agreementFilter = response?.data?.data?.map((val, index) => {
          let _d;
          try {
            _d = {
              value: val.id,
              label: val.agreement_no,
              key: "agreement",
              all: "true" + index,
            };
          } catch (err) {
            console.log(err);
          }
          return _d;
        });
        setAgreemnetFilters(agreementFilter);
        //set selected filters
        if (requestId) {
          getUnits(
            response?.data?.data
              .filter((val) => val.id === requestId)
              .map((val) => val.units)
              .flat()
          );
          setSelectedFilter([requestId]);
        } else {
          setSelectedFilter(response?.data?.data?.map((val) => val.id));
          getUnits(
            response?.data?.data?.map((val) => val.units).flat(),
            false,
            0
          );
        }
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.info,
          msg: t("NoRecordFound"),
        });
      });
  };
  React.useState(() => {
    handleTopBarState({
      text: t("My Units")
    })
    getUnitids();
  }, []);
  const handleOnClick = (data) => {
    setunitdetails(data);
    setunitDrawer(true);
    const payload = {
      unit_id: data?.id,
      asset_type: 4,
    }
    NetworkCall(
      `${config.api_url}/queries/unit/asset`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setHandbookPdf(response?.data?.data?.asset?.[0]?.url);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const applyFilter = (val) => {
    if (val?.agreement?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectoneagreement"),
      });
      return false;
    }
    setOffSet(0);
    let result = val?.agreement
      .map((item) => {
        let data = agreementunits?.find((i) => i?.id === item);
        return data?.units;
      })
      .flat();
    setSelectedFilter(val?.agreement);
    getUnits(result, true, 0);
  };

  const filterData = [
    {
      id: "1",
      title: t("Agreement"),
      key: "agreement",
      filterType: "CHECKBOX",
      values: agreementfilters,
    },
  ];
  const fetchMoreData = () => {
    let result = selectedFilter?.map((item) => {
      let data = agreementunits?.find((i) => i?.id === item);
      return data?.units;
    });
    setOffSet(offSet + 20);
    getUnits(result?.flat(), false, offSet + 20);
  };
  return (
    <>
      {/* // <Container */}
      {/* //   maxWidth="sm"
      //   sx={{ padding: "0px", overflow: "hidden" }}
      // > */}
      {/* <TitleBar
                  text={t("My Units")}
                  goBack={() => window.history.go(-1)}
                /> */}

      <div className={classes.root} style={{ height: size?.height - 55, overflow: "overlay" }}>
        <Grid container sx={{ backgroundColor: "#F6F6F6" }}>
          <Grid
            className={classes.screen}
            item
            xs={12}
          >
            <Grid className={classes.topNavBarStyle}>

              <Grid
                item
                xs={12}
                style={{ padding: "0px 12px", marginTop: "8px" }}
              >
                {/* filter btn and units length */}
                {/* <Box display="flex" alignItems="center" marginBottom="10px">
                  <Box flexGrow={1}>
                    <Typography className={classes.total}>
                      &nbsp;{t("Units")}({units.length <= 0 ? "0" : totalCount})
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      className={classes.filter}
                      onClick={() => setDrawer(true)}
                    >
                      {selectedFilter?.agreement?.length > 0 ? (
                        <Badge variant="dot" color="primary">
                          <img src="/images/icons8-funnel.svg" alt="filter" />
                        </Badge>
                      ) : (
                        <img src="/images/icons8-funnel.svg" alt="filter" />
                      )}
                    </IconButton>
                  </Box>
                </Box> */}
              </Grid>
            </Grid>
            <Grid className={classes.screenPadding}>
              <Grid item xs={12}>
                {loading ? (
                  <Box p={2}>
                    <LoderSimmer count={10} />
                  </Box>
                ) : (
                  <>
                    {units.length <= 0 ? (
                      <Typography
                        className={classes.menuText}
                        textAlign="center"
                      >
                        {t("Norecordsfound")}
                      </Typography>
                    ) : (
                      <InfiniteScroll
                        dataLength={units.length}
                        next={fetchMoreData}
                        hasMore={true}
                        height={size?.height - 70}
                      >
                        <Box height="12px" />
                        {units?.map((val, index) => {
                          return (
                            <div
                              key={index}
                              style={{ padding: "0px 16px 0px 16px" }}
                            >
                              <UnitAndRequestCard
                                data={val}
                                onClick={handleOnClick}
                                type="unit"
                                view_components={["unit_purpose"]}
                                t={t}
                                isUnit={true}
                              />
                              <Box height="12px" />
                            </div>
                          );
                        })}
                      </InfiniteScroll>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {/* filter drawer */}
      <Hidden smDown>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={drawer}
          onClose={() => setDrawer(false)}
          className={classes.dialog}
        >
          <Grid container>
            <Grid item xs={12}>
              <Filter
                filterData={filterData}
                onGoBack={() => setDrawer(false)}
                selectedList={filterData[0]}
                filters={{
                  agreement: selectedFilter,
                }}
                onApply={applyFilter}
                onClose={() => setDrawer(false)}
              />
            </Grid>
          </Grid>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.filterDrawer}
          open={drawer}
          fullWidth={true}
          onClose={() => setDrawer(false)}
        >
          <Grid container>
            <Grid item xs={12}>
              <Filter
                filterData={filterData}
                onGoBack={() => setDrawer(false)}
                selectedList={filterData[0]}
                filters={{
                  agreement: selectedFilter,
                }}
                onApply={applyFilter}
                onClose={() => setDrawer(false)}
              />
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
      {/* unit detalis */}

      <Hidden smDown>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={unitDrawer}
          sx={{ borderRadius: "4px" }}
          className={classes.dialog}
          onClose={() => setunitDrawer(false)}
        >
          <UnitDetails
            t={t}
            unitdetails={unitdetails}
            setunitDrawer={setunitDrawer}
            handBookPdf={handBookPdf}
          />
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          open={unitDrawer}
          anchor="bottom"
          className={classes.drawer}
          onClose={() => setunitDrawer(false)}
        >
          <UnitDetails
            t={t}
            unitdetails={unitdetails}
            setunitDrawer={setunitDrawer}
            handBookPdf={handBookPdf}
          />
        </Drawer>
      </Hidden>
      {/* </Container> */}
    </>
  );
};
export default withNamespaces("units")(Units);
