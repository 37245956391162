import React from "react";
import { withBottombar } from "../../HOCs";
import { withNamespaces } from "react-i18next";
import { Box, Button, Dialog, Drawer, Grid, Hidden, Radio, Stack, Typography } from "@mui/material";
import { previewStyles } from "./style";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { DialogDrawer } from "../../components";
import BigGreenTick from "../../assets/bigGreenTick";
import ClearIcon from '@mui/icons-material/Clear';



const Preview = ({ t = () => false, handleTopBarState }) => {

    const classes = previewStyles();
    const size = useWindowDimensions();
    const [paynowDrawer, setPaynowDrawer] = React.useState(false);
    const [invoiceDrawer, setInvoiceDrawer] = React.useState(false);
    const [cashDrawer, setCashDrawer] = React.useState(false);

    React.useEffect(() => {
        handleTopBarState({
            text: "Preview"
        })
        // eslint-disable-next-line 
    }, [])

    return (
        <>
            <div style={{ height: size?.height - 140, overflow: "auto" }}>
                <Box p={2} className={classes.root}>
                    <Box className={classes.summaryBox}>
                        <Box className={classes.previewBox}>
                            <Stack direction={"row"} alignItems={"center"} pb={1}>
                                <img
                                    src="/images/unFurnished.svg"
                                    alt=""
                                    style={{ objectFit: "cover", marginTop: "4px", borderRadius: "50%", height: "48px", width: "48px" }}
                                />
                                <Box flexGrow={1}>
                                    <Stack direction={"column"}>
                                        <Typography className={classes.problem}>{t("Water Leakage")}</Typography>
                                        <Typography className={classes.label}>MNT-112</Typography>
                                    </Stack>
                                </Box>
                                <Typography className={classes.totalCost}>$40.00</Typography>
                            </Stack>
                            <Box className={classes.divider} />
                            <Stack direction={"row"} justifyContent={"space-between"} my={"12px"}>
                                <Typography className={classes.label}>{t("Problem Since")}</Typography>
                                <Typography className={classes.description}>19 Jan 22</Typography>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"} mb={"12px"}>
                                <Typography className={classes.label}>{t("Raised On")}</Typography>
                                <Typography className={classes.description}>19 Jan 22, Monday</Typography>
                            </Stack>
                            <Box className={classes.dotted} mb={"12px"} />
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Typography className={classes.label}>{t("Unit")}</Typography>
                                <Typography className={classes.description}>Unit Name</Typography>
                            </Stack>
                        </Box>
                        <Box className={classes.bigDivider} my={2} />
                        <Typography className={classes.billSummary}>{t("Bill Summary")}</Typography>
                        <Stack direction={"row"} justifyContent={"space-between"} my={2}>
                            <Typography className={classes.label}>White Cement</Typography>
                            <Typography className={classes.individualCost}>$10.00</Typography>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"space-between"} my={2}>
                            <Typography className={classes.label}>White Cement</Typography>
                            <Typography className={classes.individualCost}>$10.00</Typography>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"space-between"} my={2}>
                            <Typography className={classes.label}>White Cement</Typography>
                            <Typography className={classes.individualCost}>$10.00</Typography>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"space-between"} my={2}>
                            <Typography className={classes.label}>White Cement</Typography>
                            <Typography className={classes.individualCost}>$10.00</Typography>
                        </Stack>
                        <Box className={classes.dotted} mb={"12px"} />
                        <Stack direction={"row"} justifyContent={"space-between"} my={2}>
                            <Typography className={classes.problem}>{t("Total Amount To pay")}</Typography>
                            <Typography className={classes.totalCost}>$40.00</Typography>
                        </Stack>
                        <Box className={classes.dotted} mb={"12px"} />
                    </Box>
                    <Box className={classes.summaryBox} mt={2}>
                        <Typography mb={2} className={classes.billSummary}>{t("Payment Options")}</Typography>
                        <Box className={classes.divider} mb={2} />
                        <Stack direction={"row"} alignItems={"center"}>
                            <Radio />
                            <Typography className={classes.billSummary}>{t("Pay now")}</Typography >
                        </Stack>
                        <Box className={classes.bigDivider} my={"12px"} />
                        <Stack direction={"row"} alignItems={"center"}>
                            <Radio />
                            <Typography className={classes.billSummary}>{t("Invoice now")}</Typography>
                        </Stack>
                        <Box className={classes.bigDivider} my={"12px"} />
                        <Stack direction={"row"} alignItems={"center"}>
                            <Radio />
                            <Typography className={classes.billSummary}>{t("Cash now")}</Typography>
                        </Stack>
                    </Box>
                </Box>
            </div>
            <Box className={classes.continueBox}>
                <Grid container>
                    <Grid item xs={12}>
                        <Button
                            className={classes.continueButton}
                            variant="contained"
                            fullWidth
                            onClick={() =>
                                // setCashDrawer(true)
                                // setInvoiceDrawer(true)
                                setPaynowDrawer(true)
                            }
                        >
                            {t("Continue")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            {/* PAY-NOW DRAWER */}
            <DialogDrawer
                open={paynowDrawer}
                onClose={() => setPaynowDrawer(false)}
                header="Payment Options"
                component={
                    <>
                        <div style={{ height: "300px", overflow: "scroll" }}>
                            <Typography className={classes.billSummary} mb={1}>Cash</Typography>
                            <Typography className={classes.location}>At nearing branch office or Curia Head Office</Typography>
                            <Box className={classes.bigDivider} my={"12px"} />
                            <Typography className={classes.billSummary} mb={1}>Cheque</Typography>
                            <Typography className={classes.label}>At nearing branch office or Curia Head Office</Typography>
                            <Box className={classes.bigDivider} my={"12px"} />
                            <Typography mb={1} className={classes.billSummary}>Bank Transfer</Typography>
                            <Stack direction={"row"} justifyContent={"space-between"} mb={"12px"}>
                                <Typography className={classes.label}>Bank Name</Typography>
                                <Typography className={classes.description}>ICICI bank</Typography>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"} mb={"12px"}>
                                <Typography className={classes.label}>IBAN Number</Typography>
                                <Typography className={classes.description}>8765678</Typography>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Typography className={classes.label}>IFSC Code</Typography>
                                <Typography className={classes.description}>876545678</Typography>
                            </Stack>
                            <Box className={classes.bigDivider} my={"12px"} />
                            <Typography className={classes.billSummary} mb={1}>Pay Now</Typography>
                            <Typography className={classes.label}>Click pay now button to pay instantly through our payment
                                Gateway with more payment options
                            </Typography>
                        </div>
                        <Box className={classes.divider} mb={2} />
                        <Grid container>
                            <Grid item xs={12}>
                                <Button
                                    className={classes.continueButton}
                                    variant="contained"
                                    fullWidth
                                    onClick={() => setPaynowDrawer(true)}
                                >
                                    {t("Pay now")}
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
            />
            {/* INVOICE NOW DRAWER */}
            <Hidden smDown>
                <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth
                    open={invoiceDrawer}
                    onClose={() => setInvoiceDrawer(false)}
                >
                    <Box p={2}>
                        <Box mb={3} textAlign={"right"} onClick={() => setInvoiceDrawer(false)}>
                            <ClearIcon />
                        </Box>
                        <Box textAlign={"center"}>
                            <BigGreenTick />
                        </Box>
                        <Box textAlign={"center"}>
                            <Typography className={classes.recentNote}>Thank You!<br /> Invoice is sent successfully</Typography>
                        </Box>
                    </Box>
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    className={classes.drawer}
                    anchor="bottom"
                    open={invoiceDrawer}
                    onClose={() => setInvoiceDrawer(false)}
                >
                    <Box p={2}>
                        <Box mb={3} textAlign={"right"} onClick={() => setInvoiceDrawer(false)}>
                            <ClearIcon />
                        </Box>
                        <Box textAlign={"center"}>
                            <BigGreenTick />
                        </Box>
                        <Box textAlign={"center"}>
                            <Typography className={classes.recentNote}>Thank You!<br /> Invoice is sent successfully</Typography>
                        </Box>
                    </Box>
                </Drawer>
            </Hidden>
            {/* CASH DRAWER */}
            <Hidden smDown>
                <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth
                    open={cashDrawer}
                    onClose={() => setCashDrawer(false)}
                >
                    <Box p={2}>
                        <Box mb={3} textAlign={"right"} onClick={() => setCashDrawer(false)}>
                            <ClearIcon />
                        </Box>
                        <Box textAlign={"center"}>
                            <BigGreenTick />
                        </Box>
                        <Box textAlign={"center"}>
                            <Typography className={classes.recentNote}>Thank You!<br /> Cash will be collected soon</Typography>
                        </Box>
                    </Box>
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    className={classes.drawer}
                    anchor="bottom"
                    open={cashDrawer}
                    onClose={() => setInvoiceDrawer(false)}
                >
                    <Box p={2}>
                        <Box mb={3} textAlign={"right"} onClick={() => setCashDrawer(false)}>
                            <ClearIcon />
                        </Box>
                        <Box textAlign={"center"}>
                            <BigGreenTick />
                        </Box>
                        <Box textAlign={"center"}>
                            <Typography className={classes.recentNote}>Thank You!<br /> Cash will be collected soon</Typography>
                        </Box>
                    </Box>
                </Drawer>
            </Hidden>
        </>
    )
}

const props = {
    top_bar: "title_bar",
}

export default withBottombar(withNamespaces("preview")(Preview), props);