import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F8F8F8",
    padding: "0px",
  },
  mainPadding: {
    padding: "14px 16px 16px 16px",
  },
  mainPadding2: {
    padding: "14px 14px 16px 16px",
  },
  unitNameContainer: {
    border: "1px solid #E4E8EE",
    borderRadius: "8px",
    padding: "2px 12px",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
  },
  unitTitle: {
    fontSize: "0.75rem",
    color: "#091B29",
    fontFamily: FontFamilySwitch().bold,
  },
  title: {
    fontSize: "1rem",
    color: "#091B29",
    fontFamily: FontFamilySwitch().bold,
  },
  listContainer: {
    padding: "0 16px",
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 3px 30px #4354721F",
  },
  formContainer: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 3px 30px #4354721F",
  },
  cardContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "12px",
    padding: "12px 0",
    cursor: "pointer",
  },
  cardImg: {
    height: "48px",
    width: "48px",
    objectFit: "cover",
    borderRadius: "50%",
  },
  verifedImg: {
    position: "absolute",
    bottom: "6px",
    right: "0px",
  },
  verifedImgPdf: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
  },
  cardTitle: {
    fontSize: "0.875rem",
    color: "#091B29",
    fontFamily: FontFamilySwitch().bold,
    marginBottom: "5px",
  },
  cardSubTitle: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().regular,
    [theme.breakpoints.down("sm")]: {
      width: "100px"
    },
  },
  supTitle: {
    fontSize: "0.875rem",
    color: "#404E61",
    fontFamily: FontFamilySwitch().bold,
  },
  dot: {
    display: "inline-block",
    width: "6px",
    height: "6px",
    margin: "0 6px",
    backgroundColor: "#CED3DD",
    borderRadius: "50%",
  },
  typeValid: {
    fontSize: "0.75rem",
    color: "#5AC782",
    fontFamily: FontFamilySwitch().bold,
    marginLeft: "6px",
    backgroundColor: "#EEF9EE",
    padding: "2px 4px",
    borderRadius: "4px",
    alignSelf: "flex-start",
    marginTop: "12px",
  },
  typeExpired: {
    fontSize: "0.75rem",
    color: " #98A0AC",
    fontFamily: FontFamilySwitch().bold,
    marginLeft: "6px",
    backgroundColor: "#E4E8EE",
    padding: "2px 4px",
    borderRadius: "4px",
    alignSelf: "flex-start",
    marginTop: "4px",
  },
  editContainer: {
    padding: "6px 6px",
  },
  label: {
    fontSize: "0.75rem",
    color: "#98A0AC",
    fontFamily: FontFamilySwitch().bold,
    marginBottom: "8px",
  },
  labelItalic: {
    fontSize: "0.875rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
    marginBottom: "8px",
  },
  contentGrp: {
    marginBottom: "10px",
  },
  note: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
    fontStyle: "italic",
    backgroundColor: "#F2F4F7",
    borderRadius: "4px",
    padding: "6px",
  },
  imgGrp: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "column",
  },
  cardImgGrp: {
    height: "40px",
    width: "40px",
    objectFit: "cover",
    border: "2px solid #fff",
    borderRadius: "50%",
    marginLeft: "-28px !important",
    cursor:"pointer",
    "& img": {
      height: "48px",
      width: "48px",
    },
  },
  secondary: {
    padding: "11px 15px",
    textAlign: "center",
    borderRadius: "4px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#5078E1",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
    backgroundColor: "#FFF",
    border: "1px solid #5078E1",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#FFF",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  primary: {
    padding: "12px 16px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
    backgroundColor: "#5078E1",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#5078E1",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      bottom: "35px",
      right: "35px",
    },
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "35px",
      right: "35px",
    },
  },
  close: {
    position: "absolute",
    right: "0px",
    top: "0px",
    backgroundColor: "red",
    borderRadius: "50%",
    color: "white",
    fontSize: "1.25rem",
  },
  imgdiv: {
    position: "relative",
    display: "inline-block",
    borderRadius: "50%",
  },
  profileicon: {
    fontSize: "80px",
    padding: "14px",
  },
  profileimgs: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    border: "2px solid white",
    borderRadius: "50%",
  },
  profileimg: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "relative",
  },
  profilesection: {
    marginTop: "14px",
  },
  formTitel: {
    fontSize: "0.75rem",
    color: "#404E61",
    fontFamily: FontFamilySwitch().bold,
    marginBottom: "8px",
  },
  input: {
    margin: "10px 0 16px 0", 
  },
}));
