import {
  Box,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { DatePickerNew, DocumentViewer, Filter, Statement } from "../../components";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import InfiniteScroll from "react-infinite-scroll-component";
import { lightFormat } from "date-fns";
import moment from "moment";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    background: "#F6F6F6",
  },
  bottomTitle: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    paddingBottom: "8px",
  },
  agreement: {
    backgroundColor: "#FFFFFF",
    borderRadius: theme.palette.borderRadius,
    padding: "4px 8px",
    margin: "8px 16px",
    cursor: "pointer",
    border: "1px solid #E4E8EE"
  },
  disableAgreement: {
    backgroundColor: theme.palette.background.tertiary,
    borderRadius: theme.palette.borderRadius,
    padding: "4px 8px",
    margin: "8px 16px",
    cursor: "pointer",
    border: "1px solid #E4E8EE"
  },
  statementtotal: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold
  },
  disableAgreementLabel: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary
  },
  iconbtn: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
  },
  statement: {
    background: theme.palette.background.paper,
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  filterdata: {
    padding: "8px 12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    width: "100%",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    width: "100%",
  },

  filterleft: {
    borderRight: "1px solid #c1c1c1",
  },
  active: {
    fontSize: "0.75rem",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
    width: "100%",
    marginLeft: "4px",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  filterdataActive: {
    padding: "8px 12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: theme.palette.background.secondary,
    width: "100%",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 4px",
    },
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  list: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E4E8EE",
    padding: '12px',
    cursor: "pointer"
  },
  Tittle: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
  },
  agreementImg: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    padding: "6px 6px 0px 6px",
    // height:"400px"
  },
  downloadsub: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    display: "flex",
  },
  contentBottom: {
    // padding: "12px",
  },
}));
const InitialDate = {
  startDate: null,
  endDate: null,
};
const Statementofaccounts = (props) => {
  const { t = () => false, handleTopBarState } = props
  const limit = 10;
  const classes = useStyles();
  const defaultFilter = {};
  const [dateFilter, setdateFilter] = React.useState({ ...InitialDate });
  const size = useWindowDimensions();
  const backdrop = React.useContext(BackdropContext);
  const [openList, setopenList] = React.useState(false);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [drawer, setDrawer] = React.useState(null);
  const [state, setState] = React.useState([]);
  const [selectedStatement, setSelectedStatement] = React.useState([]);
  const [selectedAgreement, setSelectedAgreement] = React.useState([])
  const [agreementList, setAgreementList] = React.useState([])
  const [openAgreement, setOpenAgreement] = React.useState(false)
  const [pdfData, setPdfData] = React.useState([]);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [receipt, setRecipt] = React.useState(false)
  const [aggreOffset, setAggreOffset] = React.useState(0)
  const initialState = {
    startDate: new Date(),
    endDate: new Date(),
    error: {
      startDate: "",
      endDate: ""
    }
  }
  const [data, setData] = React.useState({ ...initialState });
  const [Statementdata, setStatementdata] = React.useState({
    total: 0,
    list: []
  });
  const alert = React.useContext(AlertContext);
  const [selectedFilter, setSelectedFilter] = React.useState(defaultFilter);
  const [offset, setOffset] = React.useState(0);
  const [disableAgreement, setDisableAgreement] = React.useState(false)
  // const downloadURI = () => {
  //   saveAs(pdfData?.asset_url, `${pdfData?.title} ${pdfData?.account_no}.pdf`);
  // };

  const onClose = () => {
    setShowDrawer(false)
  }
  const updateState = (key, value) => {
    setdateFilter({ ...dateFilter, [key]: value });
  };
  const drawerclose = () => {
    setDrawer(false);
  };
  const validation = () => {
    let isValid = true;
    if (dateFilter?.startDate?.length === 0) {
      isValid = false;

    }
    if (dateFilter?.endDate?.length === 0) {
      isValid = false;
    }
    return isValid;
  };

  const fetchMoreData = () => {
    setOffset(offset + limit);
    generateStatement(false, offset + limit, data?.startDate, data?.endDate, selectedStatement?.account_no, selectedAgreement?.value)
  };

  const generateStatement = (filter, offSet, startDate = data?.startDate, endDate = data?.endDate, account_no, agreement_id) => {
    const payload = {
      account_no: account_no,
      agreement_id: agreement_id === "All" ? undefined : agreement_id,
      start_date: `${moment(new Date(startDate)).format("YYYY-MM-DD")} 00:00:00`,
      end_date: `${moment(new Date(endDate)).format("YYYY-MM-DD")} 23:59:59`,
      search: "",
      offset: offSet,
      limit: limit
    };
    NetworkCall(
      `${config.api_url}/account-statement/get-account-statement`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter === true) {
          setStatementdata({ total: response?.data?.count, list: response?.data?.data });
        } else {
          setStatementdata({ total: response?.data?.count, list: Statementdata?.list?.concat(response?.data?.data) });

        }
        drawerclose()
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });

  }


  const updateState2 = (key, value) => {
    let error = data.error;
    error[key] = "";
    if (key === "startDate") {
      setData({ ...data, [key]: value, endDate: value > data?.endDate ? value : data?.endDate, error });
      setOffset(0);
      generateStatement(true, 0, value, value > data?.endDate ? value : data?.endDate, selectedStatement?.account_no, selectedAgreement?.value)
    } else {
      setData({ ...data, [key]: value, error });
    }

  };

  //download pdf function
  const DownloadPdf = () => {
    if (selectedStatement?.account_no && data.startDate && data.endDate) {
      const payLoad = {
        account_no: selectedStatement?.account_no,
        start_date: `${moment(new Date(data?.startDate)).format("YYYY-MM-DD")} 00:00:00`,
        end_date: `${moment(new Date(data?.endDate)).format("YYYY-MM-DD")} 23:59:59`,
        search: "",
        agreement_id: selectedAgreement?.value === "All" ? undefined : selectedAgreement?.value,

      };
      NetworkCall(
        `${config.api_url}/account-statement/generate_pdf`,
        NetWorkCallMethods.post,
        payLoad,
        null,
        true,
        false,
        { responseType: "arraybuffer" }
      ).then((response) => {
        var blob = new Blob([response.data], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var date = lightFormat(new Date(), 'yyyy-MM-dd')
        var fileName = `Account-Statement_${date}.pdf`;
        link.download = fileName;
        link.click();
      }).catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      })
    }
  }



  React.useEffect(() => {
    handleTopBarState({
      text: t("Statement Of Account")
    })
    if (authToken !== null && authToken !== "") {
      NetworkCall(
        `${config.api_url}/user-accounts`,
        NetWorkCallMethods.post,
        {},
        null,
        true,
        false
      )
        .then((response) => {
          const data = response?.data?.data;

          setSelectedStatement(data?.[0])
          getAgreement(0, 10, data?.[0]?.account_no, "", true)
          const fetch = response?.data?.data?.map((val, index) => {
            let _d;
            try {
              _d = {
                id: val.id,
                account_no: val.account_no,
                account_name: val.account_name,
                key: "account_no",
              };
            } catch (err) {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("SomethingWentwrong"),
              });
            }
            return _d;
          });
          setState(fetch);
          const defaultFilters = response?.data?.data?.map((val, index) => {
            let _d = [];
            try {
              _d.push(val?.account_no);
            } catch (err) {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("SomethingWentwrong"),
              });
            }
            return _d;
          });
          defaultFilter.account_no = defaultFilters.flat();

        })
        .catch((err) => {
          console.log("err", err)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
    }
    // eslint-disable-next-line
  }, []);

  const getAgreement = (offset, limit, account_no, search = "", bool) => {
    // GET_AGREEMENT_AGAINST_ACCOUT

    // const payload = {
    //   query: GET_AGREEMENT_AGAINST_ACCOUT(offset, limit, account_no, search).loc.source.body,
    //   variables: {},
    // };
    const payload = {
      offset,
      limit,
      search,
      account_id: account_no
    }
    NetworkCall(
      `${config.api_url}/queries/agreement/by_account`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        if (bool) {
          setAgreementList([{ label: "All", value: "All" }, ...res?.data?.data?.agreement])
          setDisableAgreement(res?.data?.data?.agreement?.length > 0 ? false : true)
          setSelectedAgreement({ label: "All", value: "All" })
          setOffset(0);
          generateStatement(true, 0, data?.startDate, data?.endDate, account_no, "All")
        } else {
          setAgreementList([...agreementList, ...res?.data?.data?.agreement])
        }

      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  }

  const applyFilter = (data) => {
    if (dateFilter?.startDate > dateFilter?.endDate) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Enddatemustbegreaterthanstartdate"),
      });
      return false;
    }
    if (validation()) {
      setSelectedFilter(data);
      setOffset(0);
      generateStatement(true, 0, data?.startDate, data?.endDate, selectedStatement?.account_no, selectedAgreement?.value)
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("please fill both start date & end date fields"),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center
      })
    }
  };
  const filterData = [
    {
      id: "1",
      title: t("Date"),
      key: "date",
      filterType: "DATE",
      values: [],
    },
    // {
    //   id: "1",
    //   title: "Account No",
    //   key: "account_no",
    //   filterType: "CHECKBOX",

    //   values: state,
    // },
  ];
  const updateStatement = (data) => {
    setopenList(false);
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: ("switching account"),
    });
    getAgreement(0, 10, data?.account_no, "", true)
    setSelectedStatement(data);
    // setStatementdata({
    //   total: 0,
    //   list: []
    // })
    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: ("switching account"),
    });
  };

  const updateAgreement = (value) => {
    setSelectedAgreement(value)
    setOffset(0);
    generateStatement(true, 0, data?.startDate, data?.endDate, selectedStatement?.account_no, value?.value)
    setOpenAgreement(false)
  }

  const fetchMoreAgreementData = () => {
    setAggreOffset(aggreOffset + limit)
    getAgreement(aggreOffset + limit, 10, selectedStatement?.account_no, "", false)
  }

  const StatementDrawer = () => {
    return (
      <>
        <Box display="flex" alignItems="center" className={classes.titleroot} style={{ padding: '12px' }}>
          <Box flexGrow={1}><Typography className={classes.bottomTitle}>{t("Accounts")}</Typography></Box>
          <Box><IconButton size="small" onClick={() => setopenList(false)}><CloseIcon /></IconButton></Box>
        </Box>
        {state?.map((val) => {
          return (
            <Box
              className={classes.list}
              onClick={() => updateStatement(val)}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* account image */}
                <Box className={classes.agreementImg}>
                  <img src="/images/agreementnew.svg" alt="agreement_img" />
                </Box>
                {/* account number */}
                <Box style={{ marginInlineStart: "10px" }}>
                  <div>
                    <Typography className={classes.Tittle}>
                      {val.account_name}
                    </Typography>
                  </div>
                </Box>
              </Box>
              <Box>
                {val.id ===
                  selectedStatement.id &&
                  <img src="/images/ticknew.svg" alt="tick_img" />
                }
              </Box>
            </Box>
          )
        })}
      </>
    )
  }


  const AgreementDrawer = () => {
    return (
      <>
        <Box display="flex" alignItems="center" className={classes.titleroot} style={{ padding: '12px' }}>
          <Box flexGrow={1}><Typography className={classes.bottomTitle}>{t("Agreements")}</Typography></Box>
          <Box><IconButton size="small" onClick={() => setOpenAgreement(false)}><CloseIcon /></IconButton></Box>
        </Box>
        <InfiniteScroll
          dataLength={agreementList?.length}
          next={fetchMoreAgreementData}
          hasMore={true}
          height={310}
        >
          {agreementList?.map((val) => {
            return (
              <Box
                className={classes.list}
                onClick={() => updateAgreement(val)}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* account image */}
                  <Box className={classes.agreementImg}>
                    <img src="/images/agreementnew.svg" alt="agreement_img" />
                  </Box>
                  {/* account number */}
                  <Box style={{ marginInlineStart: "10px" }}>
                    <div>
                      <Typography className={classes.Tittle}>
                        {val?.label}
                      </Typography>
                    </div>
                  </Box>
                </Box>
                <Box>
                  {val?.value ===
                    selectedAgreement?.value &&
                    <img src="/images/ticknew.svg" alt="tick_img" />
                  }
                </Box>
              </Box>
            )
          })}
        </InfiniteScroll>
      </>
    )
  }

  // const getPDFDetails = (id, title, account_no) => {
  //   viewinvoice()
  //   client
  //     .query({
  //       query: GET_INVOICE_DETAILS,
  //       fetchPolicy: "network-only",
  //       variables: {
  //         id: id,
  //       },
  //     })
  //     .then((res) => {
  //       setPdfData({ ...res?.data?.invoice?.[0], title, account_no })
  //       setShowDrawer(true)
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // React.useEffect(() => {
  //   if (selectedStatement?.account_no?.length > 0) {
  //     generateStatement(true, 0)
  //   }
  //   // eslint-disable-next-line
  // }, [selectedStatement?.account_no, selectedAgreement?.value])


  const viewinvoice = (id, statement_data) => {
    // setInvoicepdf({ load: true, data: "" })
    // setShowpdf(true)
    if (statement_data?.type === "reciepts") {
      setPdfData(statement_data?.url)
      setShowDrawer(true)
      setRecipt(true)
    } else {
      setRecipt(false)
      const payload = {
        invoice_id: id,
      }
      NetworkCall(
        `${config.api_url}/invoice/template`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((res) => {
          setPdfData(res?.data)
          setShowDrawer(true)

        })
        .catch((error) => {
          setShowDrawer(false)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some thing went wrong",
          });
        });
    }
  };


  return (
    <div>
      {/* <Container maxWidth="sm" sx={{ padding: 0 }}> */}
      {/* <TitleBar text={t("StatementOfAccount")} goBack={goBack} /> */}
      <div className={classes.root} style={{ height: size?.height - 55, overflow: "overlay" }}>
        <Grid
          className={classes.screen}
          item
          xs={12}
        >
          <Grid className={classes.topNavBarStyle}>

            <Grid container>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.agreement}
                  onClick={() => setopenList(true)}
                >
                  <Box flexGrow={1}>
                    <Typography className={classes.statementtotal}>
                      {t("Account No")} :  {state?.map((val) => {
                        return (
                          <>
                            {val?.id === selectedStatement?.id
                              ? val?.account_no
                              : ""}
                          </>
                        );
                      })}
                    </Typography>
                  </Box>

                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => setopenList(true)}
                      style={{
                        color: "black",
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </IconButton>
                  </Box>
                </Box>

              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  className={disableAgreement ? classes.disableAgreement : classes.agreement}
                  onClick={() => !disableAgreement && setOpenAgreement(true)}
                >
                  <Box flexGrow={1}>
                    <Typography className={disableAgreement ? classes.disableAgreementLabel : classes.statementtotal}>
                      {t("Agreement No")} :  {selectedAgreement?.label}
                    </Typography>
                  </Box>

                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => setOpenAgreement(true)}
                      style={{
                        color: "black",
                      }}
                    >
                      <KeyboardArrowDownIcon sx={{ color: disableAgreement ? "#98A0AC" : "#000" }} />
                    </IconButton>
                  </Box>
                </Box>

              </Grid>
            </Grid>
            <Grid container px={2} py={1}>
              <Grid xs={5.5}>
                <DatePickerNew
                  label={t("Start Date")}
                  value={data?.startDate ?? null}
                  onChange={(val) =>
                    updateState2("startDate", val)
                  }
                  placeholder={t("Start Date")}
                />
              </Grid>
              <Grid xs={1}></Grid>
              <Grid xs={5.5}>
                <DatePickerNew
                  label={t("End Date")}
                  value={data?.endDate}
                  minDate={new Date(data?.startDate)}
                  onChange={(val) => {
                    updateState2("endDate", val)
                    setOffset(0);
                    generateStatement(true, 0, data?.startDate, val, selectedStatement?.account_no, selectedAgreement?.value)
                  }

                  }
                  placeholder="End Date"
                />
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Box display="flex" px={2} py={1}>
                <Box flexGrow={1}>
                  <Typography className={classes.statementtotal}>
                    {Statementdata?.total} {t("Statements")}
                  </Typography>
                </Box>
                <Box>
                  <IconButton className={classes.iconbtn} disabled={Statementdata?.list?.length === 0} onClick={() => DownloadPdf()}>
                    <img src="/images/downlodestatement.svg" alt="downlode" />
                  </IconButton>
                </Box>
                {/* <Box marginLeft={1}>
                    <IconButton
                      className={classes.iconbtn}
                      onClick={draweropen}
                    >
                      <Badge variant="dot" color="primary">
                        <img src="/images/icons8-funnel.svg" alt="filter" />
                      </Badge>
                    </IconButton>
                  </Box> */}
              </Box>
            </Grid>
          </Grid>

          <InfiniteScroll
            dataLength={Statementdata?.list?.length}
            next={fetchMoreData}
            hasMore={true}
            height={size?.height - 320}
          >
            <Grid Container className={classes.statement}>
              {Statementdata?.list?.length > 0 && (
                <>
                  {Statementdata?.list?.map((item) => {
                    return (
                      <Grid xs={12}>
                        <Statement
                          t={t}
                          Statementdata={item}
                          getPDFDetails={viewinvoice}
                          currencySymbol={pdfData?.currency_master?.symbol}
                        />
                      </Grid>
                    );
                  })}
                </>
              )}
            </Grid>
          </InfiniteScroll>
        </Grid>
      </div>
      {/* </Container> */}

      {/* filter drawer */}
      <Hidden smDown>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={drawer}
          onClose={drawerclose}
          className={classes.dialog}
        >
          <Grid container>
            <Grid item xs={12}>
              <Filter
                filterData={filterData}
                onGoBack={drawerclose}
                selectedList={filterData[0]}
                filters={{
                  account_no: selectedFilter.account_no,
                }}
                allFilter={true}
                onApply={applyFilter}
                onClose={drawerclose}
                updateState={updateState}
                data={dateFilter}
              />
            </Grid>
          </Grid>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer open={drawer} onClose={drawerclose}>
          <Grid container>
            <Grid item xs={12}>
              <Filter
                filterData={filterData}
                onGoBack={drawerclose}
                selectedList={filterData[0]}
                filters={{
                  account_no: selectedFilter.account_no,
                }}
                allFilter={true}
                onApply={applyFilter}
                onClose={drawerclose}
                updateState={updateState}
                data={dateFilter}
              />
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
      {/* statement Drawer */}
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={openList}
          onClose={() => setopenList(false)}
        >
          <>
            {
              StatementDrawer()
            }
          </>
          {/* <AgreementDrawer
            agreementList={agreementList}
            agreement={agreement}
            updateagrement={updateagrement}
            onclose={() => setopenList(false)}
            expiresOn={props.t("expires_on")}
          /> */}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={openList}
          onClose={() => setopenList(false)}
          className={classes.dialog}
        >
          {
            StatementDrawer()
          }
          {/* <AgreementDrawer
            agreementList={agreementList}
            agreement={agreement}
            updateagrement={updateagrement}
            onclose={() => setopenList(false)}
          /> */}
        </Dialog>
      </Hidden>

      {/* Agreement Drawer */}
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={openAgreement}
          onClose={() => setOpenAgreement(false)}
        >
          <>
            {
              AgreementDrawer()
            }
          </>
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={openAgreement}
          onClose={() => setOpenAgreement(false)}
          className={classes.dialog}
        >
          {
            AgreementDrawer()
          }
        </Dialog>
      </Hidden>
      {/*  */}
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          open={showDrawer}
          anchor="bottom"
          onClose={onClose}
        >
          {/* <Container maxWidth="sm" style={{ overflow: "scroll" }}> */}
          <div className={classes.contentBottom}>
            <Box
              display="flex"
              p={1}
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.bottomTitle}>
                  {receipt ? t("View Receipt") : t("View Invoice")}
                </Typography>

              </Box>
              <Box>
                <IconButton size="small" onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <div className={classes.agreementimg} style={{ height: "600px" }}>
              <DocumentViewer url={receipt ? pdfData : `data:application/pdf;base64,${pdfData}`} />
            </div>
          </div>
          {/* </Container> */}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={showDrawer}
          className={classes.dialog}
        >
          <div className={classes.contentBottom}>
            <Box
              display="flex"
              p={1}
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.bottomTitle}> {receipt ? "View Receipt" : "View Invoice"}</Typography>
              </Box>
              <Box>
                <IconButton size="small" onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <div className={classes.agreementimg} style={{ height: "600px" }}>

              <DocumentViewer url={receipt ? pdfData : `data:application/pdf;base64,${pdfData}`} />

            </div>
          </div>
        </Dialog>
      </Hidden>
    </div>
  );
};
export default withNamespaces("statementofaccount")(Statementofaccounts)
