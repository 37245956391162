import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    cursor: "pointer",
    padding: "0px 10px",
  },
  container: {
    borderBottom: "1px solid #cfd3d5",
    padding: "15px 8px"
  },
  propertyImage: {
    height: 75,
    width: 75,
    marginTop: 4,
    borderRadius: 4,
    objectFit: "cover"
  },
  content: {
    width: "100%",
    marginInlineStart: "12px",
    cursor: "pointer"
  },
  status_success: {
    fontSize: "0.75rem",
    padding: 4,
    borderRadius: 4,
    textTransform: "capitalize",
    fontFamily: FontFamilySwitch().bold,
    textAlign: "center",

  },
  status_pending: {
    fontSize: "0.75rem",
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.light,
    padding: 4,
    borderRadius: 4,
    fontFamily: FontFamilySwitch().bold,
  },
  propertyId: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  propertyName: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary,
  },
  unitName: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().SemiBold,
    color: theme.typography.color.primary,
    marginTop: "4px",

  },
  dateAndType: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    paddingInlineStart: 8
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },

  // unit_category: {
  //   position: "absolute",
  //   backgroundColor: "#071741",
  //   color: "white",
  //   fontFamily: FontFamilySwitch().semiBold,
  //   fontSize: "0.75rem",
  //   bottom: "0px",
  //   padding: "4px",
  //   width: "100%",
  //   textAlign: "center"
  // },
  iconColor: {
    color: theme.typography.color.Tertiary
  }
}));
