import { Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { FontFamilySwitch } from "../../../utils";
const useStyles = makeStyles((theme) => ({
    root: {
        // borderBottom: "4px solid #F2F4F7",
        padding: "10px 0px",
    },
    checkBox: {
        color: theme.palette.primary.main,
        borderRadius: "4px",
    },
    title: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
    },
    subTitle: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary,
    },
    roundShape: {
        width: "6px",
        height: "6px",
        backgroundColor: theme.palette.border.primary,
        borderRadius: "100%",
        display: "inline-block",
        marginBottom: "2px",
    },
    money: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().extraBold,
        color: theme.typography.color.secondary,
    },
    viewInvoice: {
        color: "#5078e1",
        cursor: "pointer",
    },
}));

const InvoiceList = ({
    t = () => false,
    invoice = {},
    currency = {},
    handleViewInvoice = () => false,
    handleDelete = () => false,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container alignItems="center">
                <Grid item xs={8}>
                    <Typography className={classes.title}>
                        {invoice?.description}
                    </Typography>

                    <span className={classes.subTitle}>
                        {invoice?.invoice_no}&nbsp;{" "}
                        <>
                            <span className={classes.roundShape}></span>&nbsp;&nbsp;
                            <span
                                className={classes.viewInvoice}
                                onClick={(val) => handleViewInvoice(invoice)}
                            >
                                {t("view_invoice")}
                            </span>
                        </>
                    </span>
                </Grid>
                <Grid item xs={4}>
                    <div style={{ float: "right" }}>
                        <Typography className={classes.money} noWrap>
                            {(currency?.symbol ?? "") + (" ") +
                                Intl.NumberFormat("en-IN", {
                                    minimumFractionDigits: 0,
                                }).format(Math.round(invoice?.invoice_due_amount))}
                            &nbsp;&nbsp;
                            <IconButton sx={{ marginBottom: "3px" }} onClick={() => handleDelete(invoice)}>
                                <img src="/images/delete.svg" alt="delete" />
                            </IconButton>
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
export default withNamespaces("invoice")(InvoiceList);
