//import ImageViewer from "react-simple-image-viewer";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { BackdropContext } from "../../contexts";
import { assestType, generateImageUrl, img_size, NetWorkCallMethods } from "../../utils";
import { DialogDrawer } from "../dialogDrawer";
import { SelectBox } from "../dropDown";
import { DocumentViewer } from "../pdfviewer";
import AssetSlideViewer from "./assetSlideViewer";
import { useStyles } from "./styles";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";

const Assets = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(2);
  const [listing, setlisting] = React.useState([]);
  const backdrop = React.useContext(BackdropContext);
  const [asset, setAsset] = React.useState({
    open: false,
    url: "",
    title: "",
    asset_type: ""
  });
  React.useEffect(() => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
      NetworkCall(
        `${config.api_url}/queries/asset_master/get`,
        NetWorkCallMethods.post,
        {},
        null,
        true,
        false
    ).then((res) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Some Thing Went Wrong",
        });
        setlisting(res.data?.data?.assets_master);
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Some Thing Went Wrong",
        });
      });
    // eslint-disable-next-line
  }, []);

  const handleList = (val) => {
    setValue(val);
  };
  const onClose = () => {
    setAsset({
      open: false,
      title: "",
      url: "",
      asset_type: ""
    });
  };
  const onOpen = (title, url, asset_type) => {
    setAsset({
      open: true,
      title: title,
      url: url,
      asset_type: asset_type
    });
  };
  const assetList = listing
    .filter((x) => x?.id !== assestType?.Thumbnails)
    .map((val) => ({ label: val.type, value: val.id }));
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        p={1}
        className={classes.titleBar}
      >
        <Box flexGrow={1}>
          <Typography className={classes.detailTitle}>
            {props?.title}
          </Typography>
        </Box>
        <Box>
          <IconButton size="small" onClick={props?.onClose}>
            <CloseIcon style={{ fontSize: "1.25rem" }} />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.imagebox1}>
        <>
          {/* <List style={{ display: "flex" }} className={classes.list}>
            {listing
              .filter((x) => x?.id !== assestType?.Thumbnails)
              .map((val) => {
                return (
                  <ListItem
                    className={
                      val.id === value ? classes.listText : classes.list
                    }
                    onClick={() => handleList(val.id)}
                  >
                    <Typography className={classes.tabname} noWrap>
                      {val.type}
                    </Typography>
                  </ListItem>
                );
              })}
          </List> */}
          <SelectBox
            isRequired
            label={"Asset Type"}
            labelColor={"#98A0AC"}
            placeholder={"Select Asset Type"}
            value={listing
              .filter((x) => x?.id === value)
              .map((val) => ({ label: val.type, value: val.id }))}
            options={assetList}
            onChange={(val) => {
              handleList(val.value);
            }}
          />
          <div style={{ marginTop: "8px",height:"280px",overflow:"scroll" }}>
            <Grid container>
              {props?.assets
                ?.filter((x) => x?.id !== assestType?.Thumbnails)
                ?.map((val) => {
                  return (
                    <>
                      {val?.asset_type === value && (
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          spacing={1}
                          style={{
                            borderRadius: "12px",
                            overflow: "hidden",
                          }}
                        >
                          {val?.asset_type === assestType?.Documents ? (
                            <div
                              style={{
                                height: "150px",
                                border: "2px solid #E4E8EE",
                                overflow: "hidden",
                              }}
                              onClick={() => onOpen("Pdf", val?.url,val?.asset_type )}
                            >
                              <DocumentViewer url={val?.url} />
                            </div>
                          ) : (
                            <>
                              {val?.asset_type === assestType?.Videos ? (
                                <video
                                  style={{
                                    objectFit: "cover",
                                    height: "150px",
                                    width: "100%",
                                    borderRadius: "12px",
                                  }}
                                  onClick={() => onOpen("Video", val?.url, val?.asset_type)}
                                  controls
                                >
                                  <source src={val.url} type="video/mp4" />
                                </video>
                              ) : val?.asset_type ===
                                assestType?.Three_sixty_Images ? (
                                <img
                                  src={generateImageUrl(val.url,img_size.small_rectangle)}
                                  alt=""
                                  onClick={() => onOpen("360", val?.url, val?.asset_type)}
                                  style={{
                                    objectFit: "cover",
                                    height: "150px",
                                    width: "100%",
                                    borderRadius: "12px",
                                    cursor: "pointer",
                                    border: "2px solid #E4E8EE",
                                  }}
                                />
                              ) : val?.asset_type ===
                                assestType?.Facility_Images ? (
                                <img
                                  src={generateImageUrl(val.url,img_size.small_rectangle)}
                                  alt=""
                                  onClick={() =>
                                    onOpen("Facility Images", val?.url, val?.asset_type)
                                  }
                                  style={{
                                    objectFit: "cover",
                                    height: "150px",
                                    width: "100%",
                                    borderRadius: "12px",
                                    cursor: "pointer",
                                    border: "2px solid #E4E8EE",
                                  }}
                                />
                              ) : val?.asset_type ===
                                assestType?.General_Images ? (
                                <img
                                  src={generateImageUrl(val.url,img_size.small_rectangle)}
                                  alt=""
                                  onClick={() =>
                                    onOpen("General Images", val?.url, val?.asset_type)
                                  }
                                  style={{
                                    objectFit: "cover",
                                    height: "150px",
                                    width: "100%",
                                    borderRadius: "12px",
                                    cursor: "pointer",
                                    border: "2px solid #E4E8EE",
                                  }}
                                />
                              ) : (
                                <img
                                  src={generateImageUrl(val.url,img_size.small_rectangle)}
                                  alt=""
                                  onClick={() => {
                                    onOpen("Image", val?.url, val?.asset_type);
                                  }}
                                  style={{
                                    objectFit: "cover",
                                    height: "150px",
                                    width: "100%",
                                    borderRadius: "12px",
                                    cursor: "pointer",
                                    border: "2px solid #E4E8EE",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </Grid>
                      )}
                    </>
                  );
                })}
            </Grid>
          </div>
        </>
      </Box>
      <DialogDrawer
        open={asset?.open}
        onClose={() => onClose}
        isnotTitle={true}
        padding={0}
        component={
          <AssetSlideViewer
            asset={asset}
            assets={props.assets}
            onClose={onClose}
          />
        }
      />
    </>
  );
};
export default Assets;
