import { Box, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { DetailHeadCard } from './detailHeadCard'
import { DetailCardStyle } from './style'

const DetailCard = ({ data = {}, t = () => false }) => {
    const classes = DetailCardStyle(data)
    const [open, setOpen] = React.useState(false)
    const show = () => {
        open === false ? setOpen(true) : setOpen(false)
    }
    const taxPercentage = data?.property_facility?.vat_group_master?.vat_item?.map(v => v?.rate)?.reduce((a, b) => a + b, 0)
    return (
        <Box p={1} onClick={show}>
            <Stack spacing={1}>
                <Box>
                    <Typography p={1} className={classes.bookingStatusBox}
                        // sx={{
                        //     color: data?.booking_status_obj?.color,
                        //     background:data?.booking_status_obj?.bgcolor
                        // }}
                    >
                        {data?.booking_status_obj?.value}
                    </Typography>
                </Box>
                <DetailHeadCard open={open} data={data} />
                <Divider />
                <Stack spacing={1}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography className={classes.title}>{t("InvoiceAmount")}</Typography>
                        <Typography className={classes.content}>{data?.currency} {(data?.paid_amount && data?.tax_amount) ? (data?.paid_amount - Number(data?.tax_amount)).toFixed(2) : 0}</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography className={classes.title}>{t("Participant Count")}</Typography>
                        <Typography className={classes.content}>{data?.overbooking_count}</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography className={classes.title}>{t("Taxes")}{taxPercentage ? ` ${taxPercentage}%` : ""}</Typography>
                        <Typography className={classes.content}>{data?.currency} {data?.tax_amount ? Number(data?.tax_amount).toFixed(2) : 0}</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography className={classes.total}>{t("Total")}</Typography>
                        <Typography className={classes.total}>{data?.currency} {data?.paid_amount ? Number(data?.paid_amount).toFixed(2) : 0}</Typography>
                    </Stack>
                </Stack>
                {
                    open &&
                    <Stack spacing={1}>
                        <Divider></Divider>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography className={classes.title}>{t("Unit")}</Typography>
                            <Typography className={classes.content}> {data?.unit_name} </Typography>
                        </Stack>
                    </Stack>
                }
            </Stack>
        </Box>
    )
}

export default withNamespaces("amenitiesBooking")(DetailCard)