import {
  Container,
  Typography,
  Grid,
  Box,
  Button,
  Stack,
  Hidden,
  Dialog,
  Drawer,
  IconButton
} from "@mui/material";
import React from "react";
import { DialogDrawer } from "../../components";
import { AmenitiesBookingStyles } from "./style"
import useWindowDimensions from "../../utils/useWindowDimensions";
import Slots from "../../components/slots";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { withNamespaces } from "react-i18next";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { addDays, eachDayOfInterval, format, subDays } from "date-fns";
import BookingPreview from "../availableSlot/bookingPreview";
import CloseIcon from '@mui/icons-material/Close';
import DetailCard from "../availableSlot/components/detailCard";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import ImageAndPdfViewer from "../../components/assetViewer/imageAndPdfViewer";
import { ParticipantCount } from "../availableSlot/participantCount";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { AmenityDetailCard } from "./components/detailCard";

const AmenitiesBookings = (props) => {
  const Dates = eachDayOfInterval({
    start: new Date(),
    end: new Date(addDays(new Date(), 9))
  })
  const { t = () => false, handleTopBarState } = props
  const alert = React.useContext(AlertContext);
  const history = useHistory()
  const size = useWindowDimensions();
  const classes = AmenitiesBookingStyles();
  const state = useLocation()?.state;
  const [data, setData] = React.useState(
    {
      selected_Date: state?.selectedDate,
      availableSlot: [],
      response: {},
      selected_slot: [],
      slotIndex: null,
      amenityLogo: [],
      peakHourSlot: [],
      nonPeakHourSlot: [],
      peakHourAmount: 0,
      nonPeakHourAmount: 0,
    })
  const [bpOpen, setbpOpen] = React.useState(false)
  const [openImageDialog, setOpenImageDialog] = React.useState(false)
  // eslint-disable-next-line
  const [constructedUrl, setConstructedUrl] = React.useState([])
  const [pcCount, setpcCount] = React.useState("")
  const [pcOpen, setpcOpen] = React.useState(false)
  const [detailOpen, setDetailOpen] = React.useState(false)

  const openBookingPreview = () => {
    if (data?.response?.amenity?.[0]?.is_overbooking) {
      setpcOpen(!pcOpen)
    }
    else {
      setbpOpen(true)
    }
  }
  const onCLoseDrw = () => {
    setbpOpen(!bpOpen)
  }

  const onCloseDetailOpen = () => {
    setDetailOpen(!detailOpen)
  }

  const navigate = () => {
    history.push({
      pathname: Routes.success,
      state: {
        customLabel: "Booking Successful"
      }
    })
  }

  React.useEffect(() => {
    handleTopBarState({
      text: state?.data?.description
    })
    // eslint-disable-next-line
  }, []);

  const mybookingNavigate = () => {
    history.push({
      pathname: Routes.bookingHistory,
      state: {
        facilityId: state?.data?.facility_id,
        amenities_name: state?.data?.amenities_name
      }
    })
  }

  //  select slots
  const selectSlot = (value, index) => {
    let selectedIndex = data?.selected_slot?.map(e => {
      return e.index
    })

    if (selectedIndex?.includes(index)) {
      setData({
        ...data,
        selected_slot: [],
        slotIndex: null,
        peakHourSlot: [],
        nonPeakHourSlot: [],
        peakHourAmount: 0,
        nonPeakHourAmount: 0
      })
    }
    else {
      if (data?.selected_slot?.length === 0) {
        setData({
          ...data,
          selected_slot: [{ value, index }],
          slotIndex: index,
          peakHourSlot: value?.slot_detail?.is_peak ? [{ value, index }] : [],
          nonPeakHourSlot: !value?.slot_detail?.is_peak ? [{ value, index }] : [],
          peakHourAmount: value?.slot_detail?.is_peak ? value?.slot_detail?.rate : data?.peakHourAmount,
          nonPeakHourAmount: !value?.slot_detail?.is_peak ? value?.slot_detail?.rate : data?.nonPeakHourAmount
        })
      } else {
        if (data?.selected_slot?.length < 5) {
          if (value?.check_in_date?.date) {
            if (moment(addDays(new Date(data?.selected_slot[0]?.value?.check_out_date?.date), 1)).format("YYYY-MM-DD") === moment(value?.check_in_date?.date).format("YYYY-MM-DD")) {
              setData({
                ...data,
                selected_slot: [{ value, index }, ...data?.selected_slot],
                slotIndex: index
              })
            }
            else if (moment(subDays(new Date(data?.selected_slot[data?.selected_slot?.length - 1]?.value?.check_out_date?.date), 1)).format("YYYY-MM-DD") === moment(value?.check_in_date?.date).format("YYYY-MM-DD")) {
              setData({
                ...data,
                selected_slot: [...data?.selected_slot, { value, index }],
                slotIndex: index
              })
            }
            else {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Onlyconsecutiveslotscanbebooked"),
              });
            }
          }
          else {
            if ((Number((data?.selected_slot[0]?.value?.check_in_time).slice(0, 2))) === (Number((value?.check_out_time).slice(0, 2)))) {
              setData({
                ...data,
                selected_slot: [{ value, index }, ...data?.selected_slot],
                slotIndex: index,
                peakHourSlot: value?.slot_detail?.is_peak ? [{ value, index }, ...data?.peakHourSlot] : [...data?.peakHourSlot],
                nonPeakHourSlot: !value?.slot_detail?.is_peak ? [{ value, index }, ...data?.nonPeakHourSlot] : [...data?.nonPeakHourSlot],
                peakHourAmount: value?.slot_detail?.is_peak ? value?.slot_detail?.rate : data?.peakHourAmount,
                nonPeakHourAmount: !value?.slot_detail?.is_peak ? value?.slot_detail?.rate : data?.nonPeakHourAmount
              })
            } else if ((Number((data?.selected_slot[data?.selected_slot?.length - 1]?.value?.check_out_time).slice(0, 2))) === Number((value?.check_in_time).slice(0, 2))) {
              setData({
                ...data,
                selected_slot: [...data?.selected_slot, { value, index }],
                slotIndex: index,
                peakHourSlot: value?.slot_detail?.is_peak ? [...data?.peakHourSlot, { value, index }] : [...data?.peakHourSlot],
                nonPeakHourSlot: !value?.slot_detail?.is_peak ? [...data?.nonPeakHourSlot, { value, index }] : [...data?.nonPeakHourSlot],
                peakHourAmount: value?.slot_detail?.is_peak ? value?.slot_detail?.rate : data?.peakHourAmount,
                nonPeakHourAmount: !value?.slot_detail?.is_peak ? value?.slot_detail?.rate : data?.nonPeakHourAmount
              })
            }
            else {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Onlyconsecutiveslotscanbebooked"),
              });
            }
          }
        } else {         
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("You can only book 5 consecutive slots!"),
          });
        }

      }
    }
  }


  const selectedBookingDate = (date) => {
    getAmenitiesBooking(date)
  }

  React.useEffect(() => {
    if (state?.type === "direct") {
      getAmenitiesBooking(data?.selected_Date)
    } else {
      getAmenitiesBooking(data?.selected_Date)
    }
    // eslint-disable-next-line
  }, [])

  // Get amenities Booking 
  const getAmenitiesBooking = (date) => {
    let payload = {}
    if (state?.type === "direct") {
      payload = {
        property_id: state?.selectedProperty?.property_id,
        facility_id: state?.data?.facility_id,
        amenities_type: state?.data?.amenities_type,
        selected_date: moment(date).format("YYYY-MM-DD"),
        period: state?.data?.period,
        current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss")
      }
    } else {
      payload = {
        property_id: state?.propertyId,
        facility_id: state?.amenity_data?.facility_id,
        amenities_type: state?.amenity_data?.amenities_type,
        selected_date: moment(date).format("YYYY-MM-DD"),
        period: state?.data?.period,
        current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss")
      };
    }
    NetworkCall(
      `${config.api_url}/amenities_booking_v2/get_amenities_for_booking`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let constAvailableDates = response?.data?.data?.available_slots?.map((e) => {
          return response?.data?.data?.amenity?.[0].period === "Hourly" ?
            {
              "check_in_time": e[0],
              "check_out_time": `${parseInt(e[1].split(":")?.[0]) + 1}:00:00`.padStart(8, '0'),
              "check_in_value": e[0],
              "check_out_value": e[1],
              "slot_detail": e?.[2],
              "participants_count": response?.data?.data?.amenity?.[0]?.participants_count
            } :
            {
              "check_in_date": e,
              "check_out_date": e,
              "participants_count": response?.data?.data?.amenity?.[0]?.participants_count
            }
        })

        setData({
          ...data,
          availableSlot: constAvailableDates ?? [],
          response: response?.data?.data,
          selected_Date: new Date(date),
          selected_slot: [],
          slotIndex: null,
          amenityLogo: response?.data?.data?.amenity[0]?.amenity_assets[0] ?? ""
        })
      })
      .catch((error) => {
        setData({
          ...data,
          availableSlot: [],
          response: [],
          selected_Date: new Date(date),
          selected_slot: [],
          slotIndex: null
        })
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("No slots available for Booking"),
        });
      });
  };

  // const constructBulkUrl = () => {
  //   let result = state?.data?.facility_assets?.map((e, i) => {
  //     return {
  //       meta_data: {
  //         title: `asset${i}`,
  //         file_type: "image"
  //       },
  //       file_url: e?.url
  //     }
  //   })
  //   setConstructedUrl(result)
  //   setOpenImageDialog(!openImageDialog)
  // }


  // participant count function

  const onCLoseDrwPC = () => {
    setpcCount("")
    setpcOpen(!pcOpen)
  }
  const validateCount = () => {
    let array = []
    // eslint-disable-next-line
    data?.selected_slot?.map((e) => {
      array.push(Number(e?.value?.participants_count) - Number(e?.value?.slot_detail?.book_count))
    })
    let minValue = Math.min(...array)
    if (minValue >= Number(pcCount)) {
      // bookAmenities()
      setbpOpen(true)
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: `${t("Member count should not greater than")} ${Math.min(...array)}`,
      });
    }
  }
  return (
    <Container className={classes.root} maxWidth="sm">
      <div
        className={classes.roots}
        style={{
          height: data?.selected_slot?.length > 0 ? size?.height - 120 : size?.height - 55,
          position: "relative"
        }}
      >

        {/* <div onClick={mybookingNavigate}>
          <Box className={classes.box}
          >
            <Box>
              <Avatar src="/images/bookingHistory.svg" />
            </Box>
            <Box flexGrow={1} display="flex" marginTop="6px" marginLeft="8px" >
              <Box alignItems="center" marginBottom="2px">
                <Typography className={classes.totalAmount} noWrap>
                  {t("My Booking History")}

                </Typography>
                <Typography className={classes.textNotSelect1} noWrap>
                  {t("View & pay your bookings")}
                </Typography>
              </Box>
            </Box>
            <Box display={"flex"}>
              <ArrowForwardIosIcon
                fontSize="small"
                sx={{ fontSize: "22" }}
              />
            </Box>
          </Box>
        </div> */}
        {/* <div className={classes.main}>
          <Card className={classes.imageCard} elevation={0}>
            <Slider {...settings} arrows>
              {state?.data?.facility_assets ?
                state?.data?.facility_assets?.length === 0 ?
                  <Avatar className={classes.cardImage1} src={"/images/Amenity_Image_Place_holder.svg"}></Avatar> :
                  state?.data?.facility_assets?.map((link) => {
                    return (
                      <Avatar className={classes.cardImage} src={state?.from === "amenities" ? link?.url : link?.url} onClick={() => constructBulkUrl()}></Avatar>
                    )
                  }) :
                state?.data?.facility_type?.assets?.length === 0 ?
                  <Avatar className={classes.cardImage1} src={"/images/Amenity_Image_Place_holder.svg"}></Avatar> :
                  state?.data?.facility_type?.assets?.map((link) => {
                    return (
                      <Avatar className={classes.cardImage} src={state?.from === "amenities" ? link?.url : link?.url} onClick={() => constructBulkUrl()}></Avatar>
                    )
                  })
              }
            </Slider>
            <CardContent className={classes.contentText}>
              <Stack className={classes.firstRow} direction="row">
                <Typography className={classes.title}>{state?.data?.description ?? "-"}</Typography>
                {
                  state?.data?.is_chargeable ?
                    <Typography className={classes.dollar}>
                      {state?.from !== "amenities" ?
                        state?.data?.symbol :
                        state?.data?.currency_symbol}
                      {state?.from !== "amenities" ?
                        state?.data?.amount :
                        state?.data?.rate
                      }{state?.data?.period === "Hourly" ? " /Slot" : " /Day"}</Typography>

                    :
                    <Typography className={classes.freeText}>{t("Free")}</Typography>
                }
              </Stack>
              <Stack className={classes.secondRow} direction="row">
                <Typography className={classes.subText}>{state?.data?.amenity_category ?? state?.data?.amenities_category?.name ?? "-"}</Typography>
                {(data?.response?.amenity?.[0]?.slots_info?.[1]?.slots?.length > 0 && state?.data?.is_chargeable) &&
                  <Stack direction={"row"} spacing={1} className={classes.peakBox} alignItems={"center"} px={1}>
                    <ElectricIcon />
                    <Typography className={classes.peakBoxText}>{`Peak Hour  ${state?.from !== "amenities" ? state?.data?.symbol : state?.data?.currency_symbol}${data?.response?.amenity?.[0]?.slots_info?.[1]?.rate} / Slot`}</Typography>
                  </Stack>}
              </Stack>

              <ShowMoreText
                lines={2}
                more="Show More"
                less="Show Less"
                className={classes.desc}
                anchorClass={classes.seeMoreLessTextStyle}
                expanded={false}
                truncatedEndingComponent={"... "}
              >
                <Typography className={classes.descriptionText}>
                  {data?.response?.amenity?.[0]?.amenity_description}
                </Typography>
              </ShowMoreText>

            </CardContent>
          </Card>
        </div> */}

        <Box>
          <AmenityDetailCard mybookingNavigate={mybookingNavigate} state={state} onCloseDetailOpen={onCloseDetailOpen} isPopup={false} t={t} />
        </Box>

        <div className={classes.card}>
          <Box className={state?.data?.period === "Hourly" ? classes.hourDetailCard : classes.dailyDetailCard}>
            <DetailCard
              // back={back} scroll={scroll}
              data={state?.data}
              selecteDate={selectedBookingDate}
              value={data?.selected_Date}
              tabDate={Dates}
            />
          </Box>
          <Box className={state?.data?.period === "Hourly" ? classes.hourSlotCard : classes.dailySlotCard}>
            <div className={classes.padding}>
              {/* {data?.availableSlot?.length > 0 && <Typography className={classes.name}>
                {t("AVAILABLE SLOTS")}
                 - {`${data?.response?.amenity?.[0]?.participants_count} ${t("Members")} | ${t("Slot")} - ${data?.response?.amenity?.[0]?.period === "Hourly" ? data?.response?.amenity?.[0]?.slot_partition : 24} ${t("hrs")}`}
              </Typography>} */}
              {/* <div style={{
                height: state?.data?.facility_assets?.length === 0 ?
                  state?.data?.period !== "Hourly" ? "calc(100vh - 560px)" : "calc(100vh - 535px)" :
                  data?.selected_slot?.length > 0 ? "calc(100vh - 620px)" : "calc(100vh - 570px)", overflow: "auto"
              }}> */}
              <div >
                {/* style={{ height: "100vh" }} */}
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container pt={1} spacing={1}
                      className={state?.data?.period === "Hourly" ? classes.hourSlotPanal : classes.dailySlotPanal} >

                      {
                        data?.availableSlot?.length > 0 ?
                          data?.availableSlot?.map((item, index) => {
                            return (
                              <>
                                <Grid item xs={6}>
                                  <Slots
                                    data={item}
                                    response={data?.response}
                                    index={index}
                                    onclick={selectSlot}
                                    selctedslot={data?.selected_slot}
                                    changeBg={true}
                                  />
                                </Grid>
                              </>
                            )
                          })
                          :
                          <Grid item xs={12} className={classes.noDataBox}>
                            <Typography className={classes.noData}>
                              {t("No slots available for Booking")}
                            </Typography>
                          </Grid>
                      }

                    </Grid>
                  </Grid>
                </Grid>
              </div>

            </div>
          </Box>
          {/* Booking preview  */}
          <Hidden smDown>
            <Dialog
              className={classes.dialog}
              maxWidth="sm"
              fullWidth
              open={bpOpen}
              onClose={onCLoseDrw}
            >
              <div>{bookingPreview(t, onCLoseDrw, navigate, data?.selected_slot, data?.selected_Date, state, data, pcCount)}</div>
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer
              anchor="bottom"
              className={classes.drawer}
              open={bpOpen}
              onClose={onCLoseDrw}
            >
              <div>{bookingPreview(t, onCLoseDrw, navigate, data?.selected_slot, data?.selected_Date, state, data, pcCount)}</div>
            </Drawer>
          </Hidden>

          {/* Participant Count Popup  */}
          <Hidden smDown>
            <Dialog
              className={classes.dialog}
              maxWidth="sm"
              fullWidth
              open={pcOpen}
              onClose={onCLoseDrwPC}
            >
              <div>{<ParticipantCount
                onCLoseDrwPC={onCLoseDrwPC}
                pcCount={pcCount}
                setpcCount={setpcCount}
                validateCount={validateCount} />}</div>
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer
              anchor="bottom"
              className={classes.drawer}
              open={pcOpen}
              onClose={onCLoseDrwPC}
            >
              <div>{<ParticipantCount
                onCLoseDrwPC={onCLoseDrwPC}
                pcCount={pcCount}
                setpcCount={setpcCount}
                validateCount={validateCount} />}</div>
            </Drawer>
          </Hidden>

        </div>

      </div>
      <Box>
        {
          data?.selected_slot?.length > 0 &&
          <Box className={classes.customSnackbar} p={1.4}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography className={classes.snackBarMessage}> {data?.selected_slot?.length} {t("Slot Selected")}</Typography>
              <Button className={classes.snackBarButton} onClick={openBookingPreview} variant={"contained"} >{t("Continue")}</Button>
            </Stack>
          </Box>
        }
      </Box>


      <DialogDrawer
        open={openImageDialog}
        onClose={() => setOpenImageDialog(!openImageDialog)}
        isnotTitle={true}
        padding={0}
        overflow={"overlay"}
        component={
          <ImageAndPdfViewer
            assets={constructedUrl}
            onClose={() => setOpenImageDialog(!openImageDialog)}
          />
        }
      />


      {/* view amenity detail */}
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={detailOpen}
          onClose={onCloseDetailOpen}
          title={t("View More")}
        >
          <div>
            <Grid container>
              <Grid item xs={12}>
                <Box className={classes.flexWithPadding}>
                  <Box flexGrow={1}>
                    <Typography className={classes.modalTitle}>
                      {t("View More")}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={onCloseDetailOpen}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <AmenityDetailCard isPopup state={state} t={t} />
              </Grid>
            </Grid>

          </div>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          anchor="bottom"
          className={classes.drawer}
          open={detailOpen}
          onClose={onCloseDetailOpen}
          title={t("View More")}
        >
          <div>
            <Grid container>
              <Grid item xs={12}>
                <Box className={classes.flexWithPadding}>
                  <Box flexGrow={1}>
                    <Typography className={classes.modalTitle}>
                      {t("View More")}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={onCloseDetailOpen}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <AmenityDetailCard isPopup state={state} t={t} />
              </Grid>
            </Grid>

          </div>
        </Drawer>
      </Hidden>
    </Container >

  );
};

const bookingPreview = (t, onCLoseDrw, navigate, slots, selected_date, stateData, data, pcCount) => {
  const classes = AmenitiesBookingStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box className={classes.flexWithPadding}>
          <Box flexGrow={1}>
            <Typography className={classes.modalTitle}>
              {t("Booking Preview")}
            </Typography>
          </Box>
          <Box>
            <IconButton
              onClick={onCLoseDrw}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} p={"16px 0px 0px"}>
        <BookingPreview
          navigate={navigate}
          slots={slots}
          selectedDate={selected_date}
          stateData={stateData}
          onCLoseDrw={onCLoseDrw}
          data={data}
          pcCount={pcCount}
        />
      </Grid>
    </Grid>
  )
}

export default withNamespaces("amenitiesBooking")(AmenitiesBookings)