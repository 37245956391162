/* eslint-disable array-callback-return */
import {
    Box,
    Button,
    Container,
    Grid,
    Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
// import Bicycle2 from "../../components/svg/bicycle2";
import moment from "moment-timezone";
import Insurance from "../../assets/insurance";
import Unit from "../../components/svg/unit";
import VehicleNo from "../../components/svg/vehicleNo";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { withBottombar } from "../../HOCs";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, generateImageUrl, img_size, NetWorkCallMethods, vehicleTypeSVG } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { ParkingSlotCard } from "./component/parkingSlotCard";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F2F5FA",
        padding: "0px",
    },
    input: {
        marginTop: "10px",
    },
    applybtn: {
        padding: "12px",
        borderRadius: "4px",
    },
    heading1: {
        color: "#404E61",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "1rem"
    },
    profession: {
        height: "44px",
        backgroundColor: "white",
        borderRadius: "4px",
        fontSize: "0.875rem",
        color: "gray",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        border: "1px solid #E4E8EE",
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
        },
        "& .MuiContainer-root": {
            padding: "0px 8px",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "4px",
            padding: "0px",
        },
    },
    bottomBarTitle: {
        fontSize: "1.125rem",
        fontFamily: FontFamilySwitch().extraBold,
        color: theme.typography.color.primary,
    },
    title: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.secondary,
    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        padding: "4px 8px 8px 8px",
    },
    helperImg: {
        height: "40px",
        borderRadius: theme.palette.borderRadius,
        width: "40px",
        objectFit: "cover",
    },
    helperRoot: {
        borderTop: "1px solid #E4E8EE",
        cursor: "pointer",
    },
    name: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#98A0AC",
    },
    pname: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.secondary,
    },
    cancelbtn: {
        padding: "12px",
        border: "1px solid #CED3DD",
        backgroundColor: "white",
        borderRadius: "4px",
        color: theme.typography.color.primary,
    },
    drap2: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        height: "70px",
        borderRadius: "4px",
        textAlign: "center",
        background: "#FFFFFF",
        border: "2px dashed #E4E8EE",
    },
    text2: {
        color: "#404E61",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "0.875rem"
    },
    Title: {
        color: "#091B29",
        fontFamily: FontFamilySwitch().bold,
        fontSize: "1rem"
    },
    label: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#98A0AC",
    },
    value: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#091B29",
    },
    vehicleTypesvg: {
        height: "30px",
        width: "30px"
    },
    validity: {
        color: "#98A0AC",
        fontFamily: FontFamilySwitch().regular,
        fontSize: "0.75rem"
    },
    notes: {
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "0.75rem"
    }
}));

const ViewVehicle = ({ t = () => false, handleTopBarState }) => {
    const language = localStorage.getItem("i18nextLng")
    const size = useWindowDimensions();
    const classes = useStyles();
    const theme = useTheme()
    const history = useHistory();
    const location = useLocation();
    const main = location?.state?.main ?? undefined;
    const [data, setdata] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const alert = React.useContext(AlertContext);


    const getTitle = (data) => {
        let vehicle_type = ""
        switch (data?.vehicle_type) {
            case "BI-CYCLE":
                vehicle_type = data?.parking_areaByID?.vehicle_parking_area
                break;
            case "TWO-WHEELER":
                vehicle_type = data?.parking_areaByID?.vehicle_parking_area
                break;
            default:
                vehicle_type = data?.parking_masterByID?.parking_no
                break;
        }
        handleTopBarState({
            text: vehicle_type
        })
    }

    // let gettitle = (data?.vehicle_type === "BI-CYCLE" || data?.vehicle_type === "TWO-WHEELER") ?
    // data?.parking_areaByID?.vehicle_parking_area : data?.parking_masterByID?.parking_no;

    React.useEffect(() => {
        if (main) {
            getVehicle();
        } else {
            history.goBack(-1);
        }
        // eslint-disable-next-line
    }, []);





    const getVehicle = async () => {
        const payload = {
            id: main?.parkingSlotID
        }

        NetworkCall(
            `${config.api_url}/queries/get_parking_detail`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setdata(response?.data?.data?.parking_slot?.[0]);
            setLoading(false);
            getTitle(response?.data?.data?.parking_slot?.[0]);
        }).catch((error) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("SomethingWentwrong"),
            });
        });

    }

    const deleteVehicle = () => {
        const createdUpdatedBy = localStorage.getItem("userProfileId");
        const currentDateTime = new Date().toISOString();
        if (data?.vehicle_type === "BI-CYCLE" || data?.vehicle_type === "TWO-WHEELER") {

            const payload = {
                id: main?.parkingSlotID,
                payload: {
                    is_active: false,
                    updated_by: createdUpdatedBy,
                    updated_at: currentDateTime,
                }
            }

            NetworkCall(
                `${config.api_url}/queries/parking_slot/update_by_id`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((response) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("VehicleDeletedSuccessfully"),
                });
                history.replace({ pathname: Routes.viewVehicle, state: {} });
                history.goBack(-1);
            }).catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            })
        } else {
            const payload = {
                id: data?.parking_masterByID?.id,
                payload: {
                    parking_status: "Vacant",
                    updated_by: createdUpdatedBy,
                    updated_at: currentDateTime
                }
            }        
            
            NetworkCall(
                `${config.api_url}/queries/parking_master/update`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((response) => {
                const params = {
                    id: main?.parkingSlotID,
                    payload: {
                        company_id: null,
                        property_id: null,
                        agreement_unit_id: null,
                        vehicle_type: null,
                        number_plate: null,
                        updated_by: createdUpdatedBy,
                        updated_at: currentDateTime,
                        vehicle_image: null
                    }
                }
                NetworkCall(
                    `${config.api_url}/queries/parking_slot/update_by_id`,
                    NetWorkCallMethods.post,
                    params,
                    null,
                    true,
                    false
                ).then((response) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: t("VehicleDeletedSuccessfully"),
                    });
                    history.replace({ pathname: Routes.viewVehicle, state: {} });
                    history.goBack(-1);
                }).catch((error) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("SomethingWentwrong"),
                    });
                })
            }).catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            })
        }
    }

    return (<>
        {loading ? <></> : <Container className={classes.root} maxWidth="sm">
            {/* <TitleBar
                text={(data?.vehicle_type === "BI-CYCLE" || data?.vehicle_type === "TWO-WHEELER") ?
                    data?.parking_areaByID?.vehicle_parking_area : data?.parking_masterByID?.parking_no}

                goBack={() => history.goBack(-1)}
            /> */}
            <Grid container>
                <Grid
                    item
                    xs={12}
                    style={{ height: size?.height - 135, overflow: "overlay" }}>
                    <div style={{ margin: "16px", padding: "16px", background: "white", borderRadius: theme.palette.borderRadius, boxShadow: "0px 3px 30px #5C86CB2E" }}>
                        <Box container direction={"column"}>
                            <Typography className={classes.Title}>{t("Details")}</Typography>
                            <Box height={10} />
                            <img src={generateImageUrl(data?.vehicle_image, img_size.small_square)} alt="" style={{ width: "100%", objectFit: "cover", borderRadius: theme.palette.borderRadius, height: "180px" }} />
                            <Box height={10} />
                            <Box display="flex" style={{ padding: "16px", borderRadius: theme.palette.borderRadius, height: "70px", backgroundColor: "rgba(80, 120, 225, .06)" }}>
                                <Box flexGrow={1} >
                                    <Typography className={classes.label}>
                                        {t("Unit ID")}
                                    </Typography>
                                    <Box height={"8px"} />
                                    <Typography className={classes.value}>
                                        {data?.agreement_unitsByID?.unitByID?.unit_no}
                                    </Typography>
                                </Box>
                                <Unit />
                            </Box>
                            <Box height={"15px"} />
                            <Box display="flex" sx={{ flexDirection: { xs: "column", sm: "row" } }}>
                                <Box width={{ xs: "100%", sm: "50%" }} >
                                    <Box display="flex" style={{ padding: "16px", borderRadius: theme.palette.borderRadius, height: "70px", backgroundColor: "rgba(80, 120, 225, .06)" }}>
                                        <Box flexGrow={1} >
                                            <Typography className={classes.label}>
                                                {t("Vehicle")}
                                            </Typography>
                                            <Box height={"8px"} />
                                            <Typography className={classes.value}>
                                                {data?.vehicle_type}
                                            </Typography>
                                        </Box>
                                        {/* <Bicycle2 /> */}
                                        <img className={classes.vehicleTypesvg} src={vehicleTypeSVG[data?.vehicle_type] ?? "/images/Component 149 – 1.svg"} alt="" />
                                    </Box>
                                </Box>
                                <Box width={10} height={"15px"} />
                                <Box width={{ xs: "100%", sm: "50%" }} >
                                    <Box display="flex" style={{ padding: "16px", borderRadius: theme.palette.borderRadius, height: "70px", backgroundColor: "rgba(80, 120, 225, .06)" }}>
                                        <Box flexGrow={1} >
                                            <Typography className={classes.label}>
                                                {t("Vehicle No.")}
                                            </Typography>
                                            <Box height={"8px"} />
                                            <Typography className={classes.value}>
                                                {data?.number_plate}
                                            </Typography>
                                        </Box>
                                        <VehicleNo />
                                    </Box>
                                </Box>
                            </Box>
                            <Box height={"15px"} />
                            <Box>
                                <Box display="flex" style={{ padding: "16px", borderRadius: theme.palette.borderRadius, height: "120px", backgroundColor: "rgba(80, 120, 225, .06)" }}>
                                    <Box width={"85%"}>
                                        <Typography className={classes.label}>
                                            {t("Insurance Details")}
                                        </Typography>
                                        <Box height={"8px"} />
                                        <Typography noWrap className={classes.value}>
                                            {data?.insurance_name}
                                        </Typography>
                                        <Box height={"8px"} />
                                        <Typography className={classes.validity}>
                                            &#x202a;{moment(data?.valid_till)
                                                .tz(moment.tz.guess())
                                                .format("DD MMM YY")}&#x202c;
                                        </Typography>
                                        <Box height={"8px"} />
                                        <Typography className={classes.notes}>
                                            {data?.insurance_notes}
                                        </Typography>
                                    </Box>
                                    <Box width={"15%"} sx={{ textAlign: language === "ar" ? "left" : "right" }}>
                                        <Insurance />
                                    </Box>

                                </Box>
                            </Box>
                            <Box height={"15px"} />
                            <ParkingSlotCard t={t} data={data} />
                        </Box>
                    </div>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    item
                    xs={12}
                    style={{
                        padding: "12px",
                        backgroundColor: "white",
                        boxShadow: "0px -1px 6px #00000021",
                    }}
                >
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            className={classes.cancelbtn}
                            onClick={deleteVehicle}
                        >
                            {t("Delete")}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            className={classes.applybtn}
                            variant="contained"
                            onClick={() => history.push({
                                pathname: Routes.addVehicle,
                                state: {
                                    main: {
                                        agreementID: [data?.agreement_id],
                                        parkingSlotID: data?.id
                                    }
                                }
                            })}>
                            {t("Edit")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
        }</>
    );
};

const props = {
    top_bar: "title_bar",
}


export default withBottombar(withNamespaces("vehicle")(ViewVehicle), props)