import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { withNamespaces } from "react-i18next";
import { DatePickerNew, TextBox } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall/index";
import { agreementEnum, AlertProps, FontFamilySwitch, NetWorkCallMethods } from "../../../utils";
import { addDays } from "date-fns";

const useStyles = makeStyles((theme) => ({
  bottomTitle: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    paddingBottom: "8px",
  },
}));
const initialStaterequset = {
  date: "",
  remarks: "",
  tentative_date: "",
  error: {
    date: "",
    remarks: "",
    tentative_date: "",
  },
};
const TerminationForm = (props) => {
  const classes = useStyles();
  const [agreementrequset, setagreementrequset] = React.useState({
    ...initialStaterequset,
  });
  const [disable, setDisable] = React.useState(false);
  const alert = React.useContext(AlertContext);
  const validateContact = () => {
    let isValid = true;
    let error = agreementrequset.error;
    if (agreementrequset?.tentative_date === "") {
      isValid = false;
      error.tentative_date = `${props.t(
        "Agreement Termination Date"
      )} is Required`;
    }
    if (agreementrequset?.date === "") {
      isValid = false;
      error.date = `${props.t("Tentative_Vacating_Date")} is Required`;
    }
    if (agreementrequset?.remarks?.length === 0) {
      isValid = false;
      error.remarks = `${props.t("reason_for_termination")} is Required`;
    }

    setagreementrequset({ ...agreementrequset, error });

    return isValid;
  };
  //Update Requset
  const upadatestaterequest = (key, value) => {
    let error = agreementrequset.error;
    error[key] = "";
    if (key === "tentative_date") {
      setagreementrequset({
        ...agreementrequset,
        [key]: value,
        date: value < agreementrequset?.date ? value : agreementrequset?.date,
        error
      });
    } else {
      setagreementrequset({ ...agreementrequset, [key]: value, error });
    }
  };
  const requsetsubmit = () => {
    if (validateContact()) {
      setDisable(true);
      props?.onClose();
      const variable = {
        agreement_id: props?.agreement_id,
        tentative_termination_date: moment(agreementrequset.date).format(
          "YYYY/MM/DD"
        ),
        remarks: agreementrequset.remarks,
        request_purpose: agreementEnum.termination,
        termination_date:
          moment(agreementrequset?.tentative_date).format("YYYY/MM/DD") ?? null,
        contact_id: props?.agreementData?.contact_id ?? null,
      };
      NetworkCall(
        `${config.api_url}/agreement_request/create_agreement_request`,
        NetWorkCallMethods.post,
        variable,
        null,
        true,
        false
      )
        .then((response) => {
          setDisable(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: props.t("Requset submitted successfully"),
          });
          props?.reload();
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: props.t("Some thing Went Wrong"),
          });
          setDisable(false);
        });
    }
  };

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        className={classes.titleroot}
        style={{ padding: "12px" }}
      >
        <Box flexGrow={1}>
          <Typography className={classes.bottomTitle}>
            {props.t("agreement_termination")}
          </Typography>
        </Box>
        <Box>
          <IconButton size="small" onClick={props?.onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box p={1}>
        {/* <DateTime
          isRequired
          title={props.t("tentative_vacating_date")}
          value={agreementrequset.date}
          onChange={(value) => upadatestaterequest("date", value)}
        /> */}
        <Box height={"16px"} />
        <DatePickerNew
          isRequired
          disableFuture={true}
          disablePast={false}
          label={props.t("Agreement Termination/Exit Date")}
          placeholder={props.t("Select Date")}
          value={agreementrequset.tentative_date ?? ""}
          onChange={(value) => upadatestaterequest("tentative_date", value)}
          isError={agreementrequset?.error?.tentative_date?.length > 0}
          errorMessage={agreementrequset?.error?.tentative_date}
          minDate={new Date()}
          maxDate={
            props?.agreementData?.lease_end_date &&
            addDays(new Date(props?.agreementData?.lease_end_date), 30)
          }
        />
        <Box height={"16px"} />
        <DatePickerNew
          isRequired
          disableFuture={true}
          disablePast={false}
          label={props.t("Move-out/Tentative Vacating Date")}
          placeholder={props.t("Select Date")}
          value={agreementrequset.date ?? ""}
          onChange={(value) => upadatestaterequest("date", value)}
          isError={agreementrequset?.error?.date?.length > 0}
          errorMessage={agreementrequset?.error?.date}
          minDate={new Date()}
          maxDate={agreementrequset.tentative_date}
        />
        <Box height={"16px"} />
        <TextBox
          isRequired
          label={props.t("reason_for_termination")}
          multiline={true}
          onChange={(value) =>
            upadatestaterequest("remarks", value.target.value)
          }
          value={agreementrequset.remarks}
          isError={agreementrequset?.error?.remarks?.length > 0}
          errorMessage={agreementrequset?.error?.remarks}
        />
        <Button
          variant="contained"
          fullWidth={true}
          sx={{ padding: "12px", borderRadius: "4px", marginTop: "12px", textTransform: "capitalize" }}
          onClick={requsetsubmit}
          disabled={disable}
        >
          {props.t("submit")}
        </Button>
      </Box>
    </div>
  );
};
export default withNamespaces("agreement")(TerminationForm);
