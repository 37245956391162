import React from "react";
import { withBottombar } from "../../HOCs";
import SurveyForm from "./surveyForm";

class SurveyFormParent extends React.Component {
    render() {
        return <SurveyForm {...this.props} />;
    }
}

const props = {
    top_bar: "title_bar",
}

export default withBottombar(SurveyFormParent, props);