export const UnreadNotificationIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 7611" transform="translate(-329 -53)">
            <rect
                width={30}
                height={30}
                fill="none"
                data-name="Rectangle 44485"
                rx={6}
                transform="translate(329 53)"
            />
            <g data-name="Group 838">
                <path
                    fill="none"
                    d="M353.98 62.025v.832h-3.792v-.76l1.916-2.064a3.158 3.158 0 0 0 .5-.676 1.345 1.345 0 0 0 .156-.612.736.736 0 0 0-.22-.58.94.94 0 0 0-.644-.2 2.523 2.523 0 0 0-1.532.592l-.344-.756a2.526 2.526 0 0 1 .86-.476 3.22 3.22 0 0 1 1.052-.18 2.026 2.026 0 0 1 1.344.412 1.386 1.386 0 0 1 .5 1.12 2.068 2.068 0 0 1-.208.916 4.059 4.059 0 0 1-.7.956l-1.392 1.476Z"
                    data-name="Path 4325"
                />
                <path
                    fill={props?.color??"#4e5a6b"}
                    d="M343.853 58.009a7.824 7.824 0 0 0-7.322 7.879v3.727l-1.359 2.735-.007.015a1.818 1.818 0 0 0 1.62 2.559h4.358a3.077 3.077 0 0 0 6.154 0h4.357a1.818 1.818 0 0 0 1.631-2.559l-.007-.015-1.359-2.734v-3.923a7.706 7.706 0 0 0-8.066-7.684Zm.072 1.536a6.141 6.141 0 0 1 6.452 6.147v4.1a.769.769 0 0 0 .08.343l1.428 2.886a.238.238 0 0 1-.232.365h-14.868a.238.238 0 0 1-.231-.365l1.431-2.883a.769.769 0 0 0 .08-.343v-3.907a6.279 6.279 0 0 1 5.86-6.342Zm-1.24 15.379h3.077a1.538 1.538 0 0 1-3.077 0Z"
                    data-name="icons8-notification (2)"
                />
                <g
                    fill="#ff4b4b"
                    stroke="#fafafa"
                    strokeWidth={1.5}
                    data-name="Ellipse 39513"
                    transform="translate(346 56)"
                >
                    <circle cx={4} cy={4} r={4} stroke="none" />
                    <circle cx={4} cy={4} r={4.75} fill="none" />
                </g>
            </g>
        </g>
    </svg>
)