export const Language = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="24 24 24 24"
        {...props}
    >
        <path
            fill="#5078e1"
            d="M29.75 27.001a2.761 2.761 0 0 0-2.75 2.75v9.999a2.76 2.76 0 0 0 2.75 2.75h.301a2.739 2.739 0 0 0 2.7 2.5h9.499A2.762 2.762 0 0 0 45 42.249V32.25a2.762 2.762 0 0 0-2.75-2.75h-.301a2.738 2.738 0 0 0-2.7-2.5l-9.499.001Zm0 1.5h9.5a1.24 1.24 0 0 1 1.25 1.25v.5c0 .414.336.75.75.75h1a1.24 1.24 0 0 1 1.25 1.25v9.998a1.24 1.24 0 0 1-1.25 1.25h-9.5a1.24 1.24 0 0 1-1.25-1.25v-.5a.75.75 0 0 0-.75-.75h-1a1.238 1.238 0 0 1-1.25-1.25v-9.998a1.24 1.24 0 0 1 1.25-1.25Zm2.988.99a.75.75 0 0 0-.738.76v.251h-1.75a.75.75 0 1 0 0 1.5h2.377c.08.013.163.013.243 0h.54c-.05.49-.21.961-.47 1.379a2.212 2.212 0 0 1-.653-.254 3.773 3.773 0 0 1-.512-.406.75.75 0 1 0-1.059 1.06c.212.19.434.37.666.535a4.217 4.217 0 0 1-1.137.185.75.75 0 0 0 0 1.5 4.85 4.85 0 0 0 3.078-1.003.75.75 0 0 0 .63-.462c.024-.028.069-.039.09-.067.525-.72.826-1.578.867-2.467h.337a.75.75 0 0 0 0-1.5h-.877a.708.708 0 0 0-.243 0h-.63v-.251a.75.75 0 0 0-.761-.761l.002.001ZM38.75 31.5a.75.75 0 1 0 .002 1.5.75.75 0 0 0-.002-1.5Zm-1.999 2.501a.75.75 0 1 0 .749.749.749.749 0 0 0-.75-.749h.001Zm2.211 1.5a.749.749 0 0 0-.644.441l-2.25 5.001a.749.749 0 1 0 1.367.615l.194-.433h2.744l.195.433a.75.75 0 1 0 1.367-.615l-2.25-5.001a.753.753 0 0 0-.724-.441h.001Zm-4.211.999a.75.75 0 1 0 .75.75.751.751 0 0 0-.751-.75h.001Zm4.25 1.577.697 1.548h-1.394l.697-1.548Zm-6.25.922a.75.75 0 1 0 .75.75.751.751 0 0 0-.751-.75h.001Z"
            data-name="icons8-translation (1)"
            style={{
                transformOrigin: "27px 27px",
            }}
        />
    </svg>
)