import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";

export const componentsUseStyles = makeStyles((theme) => ({
  listCardRoot: {
    // padding: "12px 2px",
    cursor:"pointer"
  },
  feedbackIcon: {
    width: "48px",
    height: "48px",
    marginInlineEnd: "16px"
  },
  title: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#091B29"
  },
  status: {
    borderRadius: "4px",
    padding: "3px 6px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    textTransform: "uppercase",
    color: "#5AC782",
    backgroundColor: "#EEF9EE"
  },
  statusSubmitted: {
    borderRadius: "4px",
    padding: "3px 6px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    textTransform: "uppercase",
    color: "#5D427F",
    backgroundColor: "#F1E6FE"
  },
  dot: {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    marginInline: "8px"
  },
  label: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    color: "#4E5A6B"
  },
  dateContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    "& img":{
      width:"14px",
      height:"14px",
    },
  },
  statusForm: {
    borderRadius: "5px",
    padding: "3px 10px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    textTransform: "uppercase",
    color: theme.typography.color.primary,
    backgroundColor: "#5078E11E"
  },
  feedbackImage: {
    width: "69px",
    height: "69px",
    objectFit: "cover"
  },
}));