import React from "react";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { AlertProps, allowed_file_size, NetWorkCallMethods, singleFileUpload, ValidateEmail } from "../../utils";
import { useHistory, useLocation } from "react-router";
import { AlertContext, BackdropContext } from "../../contexts";
import { withNamespaces } from "react-i18next";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./style";
import { entryType, request_initial_state, visitor_initial_state } from "./utils";
import moment from "moment";
export const AddVisitorPassContext = React.createContext();

const AddVisitorPassProvider = (props) => {

    const { t = () => false } = props;
    const current_date = new Date()
    const history = useHistory();
    const classes = useStyles();
    const size = useWindowDimensions();
    const [step, setStep] = React.useState(0);
    const [agreementList, setAgreementList] = React.useState([]);
    const backdrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);
    const searchURL = useLocation().search;
    const passID = new URLSearchParams(searchURL).get("passId");
    const [gateList, setGateList] = React.useState([]);
    const [gateListOffset, setGateListOffset] = React.useState(0);
    const [unitDrawer, setUnitDrawer] = React.useState(false);
    const [gateDrawer, setGateDrawer] = React.useState(false);
    const [addDetails, setAddDetails] = React.useState(false);
    const [proofData, setProofData] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [visitorProofDrawer, setVisitorProofDrawer] = React.useState(false)
    const isOptionPopoverOpen = Boolean(anchorEl);
    const optionPopoverID = isOptionPopoverOpen ? 'simple-popover' : undefined;

    const [data, setData] = React.useState(request_initial_state)
    const [visitorFormData, setVisitorData] = React.useState(visitor_initial_state)
    const [isEditable, setIsEditable] = React.useState(false)
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    React.useEffect(() => {
        if (passID) {
            backdrop.setBackDrop({
                ...backdrop,
                open: true,
                message: "Loading",
            });
            const payload = {
                tenantId: `${config.tenantid}`,
                request_id: passID,
            };
            NetworkCall(
                `${config.api_url}/security/request`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((response) => {

                const temp_response = response?.data?.data?.[0]
                const temp_request = {
                    request_details: {
                        unit: {
                            label: temp_response?.unit?.[0]?.name ?? "",
                            value: temp_response?.agreement_unit_id ?? "",
                            id: temp_response?.agreement_unit_id ?? "",
                            name: temp_response?.unit?.[0]?.name ?? "",
                            property_id: temp_response?.property_id ?? "",
                            property_name: temp_response?.property_name ?? "",
                            unit_id: temp_response?.unit?.[0]?.id ?? "",
                            unit_no: temp_response?.unit?.[0]?.unit_no ?? "",
                        },
                        entry_type: entryType?.filter?.(i => i?.value === temp_response?.entry_type)?.[0] ?? entryType?.[0],
                        start_date: temp_response?.request_from ? new Date(temp_response?.request_from) : current_date,
                        end_date: temp_response?.request_to ? new Date(temp_response?.request_to) : current_date,
                        is_any_gate: temp_response?.gate_id ? false : true,
                        gate: temp_response?.gate_id ? { label: temp_response?.gate, value: temp_response?.gate_id } : "",
                        no_of_visitors: temp_response?.group_count ? temp_response?.group_count?.toString() : "",
                        error: {
                            unit: "",
                            entry_type: "",
                            start_date: "",
                            end_date: "",
                            gate: "",
                            no_of_visitors: "",
                        }
                    },
                    visitor_details: temp_response?.visitors?.map((_, i) => {
                        return {
                            index: i,
                            id: _?.id ?? null,
                            profile_image: _?.visitor_image ?? "",
                            name: _?.name ?? "",
                            mobile: _?.mobile_no ? {
                                mobile: _?.mobile_no, mobile_code: _?.mobile_country_code
                            } : "",
                            mail: _?.email_id ?? "",
                            proof_type: _?.id_type ? { label: _?.id_type, value: _?.identification_master_id } : "",
                            proof_number: _?.identification_no ?? "",
                            proof_images: _?.worker_proof,
                            is_deleted: false,
                            error: {
                                profile_image: "",
                                name: "",
                                mobile: "",
                                mail: "",
                                proof_type: "",
                                proof_number: "",
                                proof_images: "",
                            }
                        }
                    })
                };
                setData(temp_request)
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            }).catch(() => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong"),
                });
            });
        }
        getUnitList();
        getProofData();
        // eslint-disable-next-line
    }, [passID]);

    const updateVisitorFormState = (key, value) => {
        let error = visitorFormData?.error;
        error[key] = "";
        setVisitorData({ ...visitorFormData, [key]: value, error });
    }

    const validateRequest = () => {
        let is_valid = true;
        let error = data?.request_details?.error;
        let temData = data?.request_details;

        if (temData?.start_date > temData?.end_date) {
            is_valid = false;
            error.start_date = t("Start Date should be lesser than End Date");
        }

        if (temData?.no_of_visitors?.length === 0 || parseInt(temData?.no_of_visitors) === 0) {
            is_valid = false;
            error.no_of_visitors = t("No Of Visitors Required");
        }

        setData({ ...data, request_details: { ...temData, error: error } })
        return is_valid;
    }

    const getUnitList = () => {
        NetworkCall(
            `${config.api_url}/community/community_units`,
            NetWorkCallMethods.post,
            {},
            null,
            true,
            false
        )
            .then((response) => {
                let data = response?.data?.data?.map((x) => x?.agreement_unit).flat();
                const _tempUnitList = data?.map((_uitem) => {
                    let _u;
                    _u = {
                        label: _uitem?.name ?? "",
                        value: _uitem?.id ?? "",
                        ..._uitem
                    };
                    return _u;
                });
                setAgreementList(_tempUnitList);
                !passID && updateState("request_details", { _k: "unit", _v: _tempUnitList[0] })
            })
            .catch(() => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: ("Something Went Wrong"),
                });
            });
    };


    // UPDATE UNIT STATE
    const updateState = (k, v) => { // updateState("request_details", { _k: "unit", _v: "unit" })
        let { _k, _v } = v

        switch (k) {
            case "request_details":
                let tempState = {};
                switch (_k) {
                    case "start_date":
                        tempState = {
                            ...data,
                            [k]: {
                                ...data?.[k], [_k]: _v,
                                error: {
                                    ...data?.[k]?.error,
                                    [_k]: _v > data?.[k]?.end_date ? t("Start Date should be lesser than End Date") : "",
                                }
                            }
                        }
                        break;
                    case "end_date":
                        tempState = {
                            ...data,
                            [k]: {
                                ...data?.[k], [_k]: _v,
                                error: {
                                    ...data?.[k]?.error,
                                    [_k]: "",
                                    start_date: data?.[k]?.start_date > _v ? t("Start Date should be lesser than End Date") : "",
                                }
                            }
                        }
                        break;
                    case "is_any_gate":
                        tempState = { ...data, [k]: { ...data?.[k], [_k]: _v, gate: "", error: { ...data?.[k]?.error, [_k]: "" } } }
                        break;

                    case "gate":
                        tempState = { ...data, [k]: { ...data?.[k], [_k]: _v, is_any_gate: false, error: { ...data?.[k]?.error, [_k]: "" } } }
                        break;

                    default:
                        tempState = { ...data, [k]: { ...data?.[k], [_k]: _v, error: { ...data?.[k]?.error, [_k]: "" } } }
                        break;
                }
                setData(tempState);
                break;

            default:
                break;
        }
    }

    const getGateList = (offset, fetchMore) => {
        const payload = {
            property_id: data?.request_details?.unit?.property_id,
            offset,
            limit: 10,
            search: ""
        }
        NetworkCall(
            `${config.api_url}/queries/access_gate_master`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            if (fetchMore) {
                setGateList((res) => [...res, ...response?.data?.data?.access_gates_master]);
            } else {
                setGateList(response?.data?.data?.access_gates_master);
            }
        })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: ("Something Went Wrong"),
                });
            });
    };

    const fetchMoreGateList = () => {
        const _gateListOffset = gateListOffset + 20;
        setGateListOffset(gateListOffset);
        getGateList(_gateListOffset, true);
    };

    const submitVisitorForm = () => {
        if (validateVisitorForm()) {
            if (visitorFormData?.index !== null) {
                let temp_visitor_details = []
                for (let i = 0; i < data?.visitor_details?.length; i++) {
                    if (visitorFormData?.index === i) {
                        temp_visitor_details = [...temp_visitor_details, visitorFormData]
                    } else {
                        temp_visitor_details = [...temp_visitor_details, data?.visitor_details?.[i]]
                    }
                }
                setData({
                    ...data,
                    visitor_details: temp_visitor_details
                })
            } else {
                setData({
                    ...data,
                    visitor_details: [
                        ...data?.visitor_details,
                        {
                            ...visitorFormData,
                            index: data?.visitor_details?.length
                        }
                    ]
                })
            }
            setVisitorData({ ...visitor_initial_state, error: {} })
            setAddDetails(false)
        } else {
            return false
        }
    }

    const validateVisitorForm = () => {
        let isValid = true;
        let error = visitorFormData?.error;

        if (visitorFormData?.name?.length === 0) { isValid = false; error.name = t("NameisRequired"); }
        if (visitorFormData?.mobile?.length === 0) { isValid = false; error.mobile = t("MobileisRequired"); }
        if (visitorFormData?.mail?.length > 0) {
            if (ValidateEmail(visitorFormData?.mail) === false) {
                isValid = false;
                error.mail = "Invalid Email";
            } else {
                error.mail = ""
            }
        }
        setVisitorData({ ...visitorFormData, error });
        return isValid;

    }

    const chooseGate = () => {
        getGateList(0, false)
        setGateDrawer(true)
    }

    const handleClick = (event, v, i) => {
        setAnchorEl({ target: event.currentTarget, v: v, i: i });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const updateProofImage = (proofs) => {
        updateVisitorFormState("proof_images", proofs)
    }

    const editOpen = (v) => {
        setVisitorData(v)
        setAddDetails(true)
        setAnchorEl(null)
        setIsEditable(true)
    }

    // IMAGE UPLOAD
    const uploadS3 = async (data) => {
        let uploaded_file = await singleFileUpload(
            data,
            { tenantId: `${config.tenantid}` },
            alert,
            allowed_file_size
        );
        if (uploaded_file?.[0]?.url) {
            updateVisitorFormState("profile_image", uploaded_file?.[0]?.url)
        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went Wrong"),
            });
        }
    };

    // ID PROOF
    const getProofData = () => {
        const payload = {
            proof_type: ["Contact"],
            limit: null,
            api: true
        }
        NetworkCall(
            `${config.api_url}/queries/identification_master`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setProofData(response?.data?.data);
            })
            .catch(() => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong"),
                });
            });
    };
    // CREATE PASS API
    const addPass = (payload) => {
        NetworkCall(
            `${config.api_url}/security/create`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then(() => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: ("Pass Successfully Created"),
                });
                history.goBack(-1);
            })
            .catch(() => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: ("Something Went Wrong"),
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: ("Something Went Wrong"),
                });
                // setDisable(false);
            });
    };

    const visitorDetailsValidate = () => {
        let isValid = true;

        if (checkWorkerListEmpty()) {
            isValid = false;
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("PleaseAddVisitors"),
            });
        }

        return isValid;
    };

    const checkWorkerListEmpty = () => {
        let is_list_empty = true;
        let visitorDetails = data?.visitor_details;
        if (visitorDetails?.length > 0) {
            for (let i = 0; i < visitorDetails?.length; i++) {
                if (visitorDetails?.[i]?.is_deleted === false) {
                    is_list_empty = false;
                }
            }
        }
        return is_list_empty;
    }

    // CREATE PAYLOAD CONSTRUCTION
    const CreateVisitor = () => {
        setIsDisableBtn(true);
        if (visitorDetailsValidate()) {
            const visitor_request = visitor_request_payload();
            const visitor_details = visitor_details_payload();
            const payload = { ...visitor_request, ...visitor_details }
            if (passID) {
                payload["request_id"] = passID;
                const deletedList = data?.visitor_details?.filter(({ is_deleted }) => is_deleted === true);
                payload["deleteProfiles"] = deletedList?.map(({ id }) => id)?.filter(Boolean);
                update(payload);
            } else {
                addPass(payload);
            }
            return true
        } else {
            setIsDisableBtn(false);
            return false
        }
    }
    const visitor_request_payload = () => {
        const temp_payload = data?.request_details;
        const _temp_start_date = moment(temp_payload?.start_date).format("yyyy-MM-DD");
        const _temp_end_date = moment(temp_payload?.end_date).format("yyyy-MM-DD");
        const temp_start_date = new Date(temp_payload?.start_date.setHours(0, 0, 0));
        const temp_end_date = new Date(temp_payload?.end_date.setHours(23, 59, 0));
        const temp_stay_type = _temp_start_date === _temp_end_date ? "Short" : "Long";
        let payload_data = {
            tenantId: `${config.tenantid}`,
            pass_type: "VGR",
            pass_class: parseInt(temp_payload?.no_of_visitors) > 1 ? "Group" : "Individual",
            visitor_type: "Visitor",
            stay_type: temp_stay_type,
            start_date: temp_start_date,
            end_date: temp_end_date,
            gate_no: temp_payload?.gate?.value === "Any" ? undefined : temp_payload?.gate?.value,
            user_profile_id: localStorage.getItem("userProfileId"),
            entry_type: temp_payload?.entry_type?.value,
            property_id: temp_payload?.unit?.property_id,
            unit_id: temp_payload?.unit?.unit_id,
            agreement_unit_id: temp_payload?.unit?.value,
            group_count: temp_payload?.no_of_visitors,
        }
        return payload_data;
    }
    const visitor_details_payload = () => {
        const temp_payload = data?.visitor_details;
        const temp_visitor_list = temp_payload?.filter(({ is_deleted }) => is_deleted === false);
        let payload_data = {
            profiles: temp_visitor_list?.map((visitor) => {
                return {
                    id: visitor?.id ?? undefined,
                    name: visitor?.name,
                    // gender: visitor?.gender?.value,
                    mobile_no: visitor?.mobile?.mobile,
                    mobile_country_code: visitor?.mobile?.mobile_code,
                    email_id: visitor?.mail,
                    visitor_image: visitor?.profile_image ?? null,
                    id_type: visitor?.proof_type?.value,
                    identification_no: visitor?.proof_number,
                    worker_proof: visitor?.proof_images
                };
            }),
        }
        return payload_data;
    }


    const update = (payload) => {
        setIsDisableBtn(true)
        NetworkCall(
            `${config.api_url}/security/update`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then(() => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Pass Successfully Updated"),
                });
                history.goBack(-1);
                setIsDisableBtn(false)
            })
            .catch(() => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong"),
                });
                setIsDisableBtn(false)
            });
    };

    const deleteVisitor = (index) => {
        let temp_visitor_details = []
        for (let i = 0; i < data?.visitor_details?.length; i++) {
            if (index === i) {
                temp_visitor_details = [...temp_visitor_details, { ...data?.visitor_details?.[i], is_deleted: true }]
            } else {
                temp_visitor_details = [...temp_visitor_details, data?.visitor_details?.[i]]
            }
        }
        setData({
            ...data,
            visitor_details: temp_visitor_details
        })
        setAnchorEl(null)
    }

    const nextStep = () => {
        if (validateRequest()) {
            updateState("request_details", { _k: "view_type", _v: "view" })
            setStep(1)
        }
    }

    return (
        <AddVisitorPassContext.Provider
            value={{
                t,
                size,
                classes,
                submitVisitorForm,
                data,
                validateRequest,
                agreementList,
                updateState,
                gateList,
                fetchMoreGateList,
                getGateList,
                visitorFormData,
                updateVisitorFormState,
                setVisitorData,
                visitor_initial_state,
                CreateVisitor,
                unitDrawer,
                setUnitDrawer,
                gateDrawer,
                setGateDrawer,
                chooseGate,
                handleClick,
                handleClose,
                addDetails,
                setAddDetails,
                proofData,
                setProofData,
                anchorEl,
                setAnchorEl,
                visitorProofDrawer,
                setVisitorProofDrawer,
                updateProofImage,
                editOpen,
                uploadS3,
                isOptionPopoverOpen,
                optionPopoverID,
                deleteVisitor,
                nextStep,
                step,
                setStep,
                isEditable,
                isDisableBtn
            }}
        >
            {props?.children}
        </AddVisitorPassContext.Provider>
    )
}

export default withNamespaces("createPass")(AddVisitorPassProvider);