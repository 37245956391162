import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f6f6f6",
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: theme.palette.borderRadius,
      padding: "0px",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  tabs: {
    padding: "4px 10px",
    paddingTop: "10px",
    boxShadow: "0px 2px 6px #00000014",
    backgroundColor: "white",
  },
  bottomTitle: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    paddingBottom: "8px",
  },
  title: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    margin: "12px 0px",
  },
  title2: {
    fontSize: "0.875rem",
    marginBottom: "17px",
    fontFamily: FontFamilySwitch().bold,
  },
  agreementimg: {
    height: "50vh",
    msOverflowY: "scroll",
  },
  actionroot: {
    backgroundColor: "white",
    padding: "8px 12px 12px 12px",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 3px 30px #4354721F",
  },
  manageCard: {
    backgroundColor: "#fff",
    padding: 16,
    paddingTop: 10,
  },
  mangerCard: {
    backgroundColor: "#F5F7FA",
    borderRadius: theme.palette.borderRadius,
    padding: "14px 16px",
  },
  banner: {
    textAlignLast: "right",
  },
  ownerList: {
    backgroundColor: "#091B29",
    borderRadius: "4px",
    color: "#fff",
    fontSize: "0.75rem",
    width: "fit-content",
    padding: "2px 10px",
  },
  ownerCardDeitals: {
    justifyContent: "space-between",
    alignitems: "flex-start",
    display: "flex",
    flexDirection: "column",
  },
  cardTitle: {
    color: "#091B29",
    fontSize: "1.125rem",
    fontWeight: 600,
  },
  cardDes: {
    color: "#091B29",
    fontSize: "0.75rem",
  },
  id: {
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().extraBold,
    fontSize: "0.875rem",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box !important",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    whiteSpace: "normal",
  },
  containers: {
    "&.MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    overflow: "hidden",
  },
  agreement: {
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    padding: "4px 8px",
    margin: "8px",
    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  exporie: {
    backgroundColor: "#5078E114",
    padding: "2px 4px",
    borderRadius: "4px",
    color: theme.palette.primary.main,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
  },
  expired: {
    backgroundColor: theme.palette.error.light,
    padding: "2px 4px",
    borderRadius: "4px",
    color: theme.palette.error.main,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
  },
  invoiceContainer: {
    boxShadow: "0px 3px 30px #4354721F",
    borderRadius: theme.palette.borderRadius2,
  },
  invoiceTitleBlock: {
    backgroundColor: "#A1C9FF80",
    padding: "1px 14px",
    borderTopRightRadius: theme.palette.borderRadius2,
    borderTopLeftRadius: theme.palette.borderRadius2,
  },
  invoice: {
    backgroundColor: "#FFF",
    padding: "14px",
    borderBottomRightRadius: theme.palette.borderRadius2,
    borderBottomLeftRadius: theme.palette.borderRadius2,
  },
  unitDetails: {
    borderRadius: theme.palette.borderRadius2,
    backgroundColor: "#fff",
    padding: "18px 15px",
    boxShadow: "0px 3px 30px #4354721F",
  },
  whiteBackground: {
    backgroundColor: "#fff",
    boxShadow: "0px 3px 30px #4354721F"
  },
  details: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#98A0AC"
  },
  date: {
    backgroundColor: "#FF5C0014",
    color: "#FF5C00",
    padding: "2px 8px",
    borderRadius: "4px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold
  },
  time: {
    color: theme.typography.color.secondary,
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold
  },
  duration: {
    border: "1px solid #CED3DD",
    borderRadius: "13px",
    padding: "4px 8px",
    color: "#98A0AC",
    fontFamily: FontFamilySwitch().bold,
    textAlign: "center",
    display: "inline-block",
    fontSize: "0.75rem"
  },
  propertyName: {
    backgroundColor: "#F1F7FF",
    color: "#78B1FE",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.75rem",
    display: "inline-block",
    padding: "6px 10px",
    borderRadius: "8px"
  },
  bookingDetails: {
    backgroundColor: "#F5F7FA",
    borderRadius: "8px"
  },
  avatar: {
    height: "48px",
    width: "48px"
  },
  amenityName: {
    color: theme.typography.color.primary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold
  },
  info: {
    color: theme.typography.color.primary,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold
  },
  specificInfo: {
    color: theme.typography.color.secondary,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular
  },
  durationLine: {
    backgroundColor: "#CED3DD",
    height: "1px",
    width: "20%"
  },
  dashedLine: {
    border: "1px dashed #CED3DD",
    width: "100%"
  },
  heading: {
    color: "#98A0AC",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold
  },
  avatarImage: {
    height: "42px",
    width: "42px"
  },
  heading2: {
    color: "#031629",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold
  },
  paid: {
    color: "#5AC782",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold
  },
  success: {
    color: "#5AC782",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem"
  },
  successBox: {
    backgroundColor: "#EEF9EE",
    borderRadius: "6px"
  },
  quickLinkImg: {
    height: 28,
    width: 28,
    cursor: "pointer"
  },
  quickLinkLabel: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
    cursor: "pointer"
  },
  quickLinkBox: {
    background: "#fff",
    borderRadius: 12
  }
}));