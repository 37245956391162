import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F6F6F6",
        padding: "0px",
        overflow: "hidden",
        height: '100%'
    },
    unitBox: {
        backgroundColor: "#ffffff",
        padding: "12px",
        boxShadow: " 0px 3px 30px #4250682E"
    },
    unitName: {
        color: "#091B29",
        fontFamily: FontFamilySwitch().bold,
        fontSize: "0.875rem"
    },
    unitLocation: {
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().regular,
        fontSize: "0.75rem"
    },
    change: {
        color: "#5078E1",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        cursor: "pointer"
    },
    header: {
        color: "#98A0AC",
        fontFamily: FontFamilySwitch().bold,
        fontSize: "0.75rem"
    },
    serviceBox: {
        backgroundColor: "#ffffff",
        padding: "12px 12px 0px 12px",
        boxShadow: "0px 3px 30px #4250682E",
        borderRadius: "4px",
        marginTop:"12px"
    },
    avatar: {
        backgroundColor: "#F2F4F7",
        padding: "8px",
        borderRadius: "3px 3px 0px 0px",
        height: "41px",
        width: "41px"

    },
    services: {
        color: "#78B1FE",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold
    },
    box: {
        backgroundColor: "#F1F7FF",
        borderRadius: "4px",
        padding: "3px 6px",
    },

    drawerBox: {
        padding: "14px",
        border: "1px solid #E4E8EE",
        borderRadius: "8px",
        marginBottom: "12px"
    },
    unitBoxDrawer: {
        backgroundColor: "#E4E8EE",
        borderRadius: "4px",
        padding: "4px 5px"
    },
    unitId: {
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().bold,
        fontSize: "0.75rem"
    },
    quantity: {
        color: "#98A0AC",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().regular
    },
    unitAvatar: {
        backgroundColor: "#ebdfff",
        height: "40px",
        width: "40px",
        marginInlineEnd: "8px"
    },
    cleanBox: {
        backgroundColor: "#EEF9EE",
        padidng: "3px 9px",
        borderRadius: "0px 0px 3px 3px"
    },
    clean: {
        fontFamily: FontFamilySwitch().bold,
        fontSize: "0.75rem",
        color: "#5AC782"
    },
    quantities: {
        color: "#98A0AC",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold
    },
    quantityBox: {
        backgroundColor: "#fff",
        boxShadow: "0px 3px 30px #4250682E",
        borderRadius: "4px",
        padding: "6px 12px 0 12px"
    },
    company: {
        color: "#091B29",
        fontFamily: FontFamilySwitch().bold,
        fontSize: "0.875rem"
    },
    model: {
        color: "#091B29",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold
    },
    divider: {
        border: "1px solid #E4E8EE",
        height: "18px",
    },
    new: {
        backgroundColor: "#5AC782",
        color: "#fff",
        padding: "3px 6px",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        borderRadius: "4px"
    },
    used: {
        backgroundColor: "#5078E1",
        color: "#fff",
        padding: "3px 6px",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        borderRadius: "4px"
    },
    repaired: {
        backgroundColor: "#FF9340",
        color: "#fff",
        padding: "3px 6px",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        borderRadius: "4px"
    },
    damaged: {
        backgroundColor: "#FF4B4B",
        color: "#fff",
        padding: "3px 6px",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        borderRadius: "4px"
    },
    default: {
        backgroundColor: "#CED3DD",
        color: "#4E5A6B",
        padding: "3px 6px",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        borderRadius: "4px"
    },
    information: {
        backgroundColor: "#EEF9EE",
        border: "0.5px solid #5AC782",
        borderRadius: "4px",
        padding: "8px",
        boxShadow: "0px 3px 30px #4250682E"
    },
    infotext: {
        color: "#5AC782",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().regular
    }
}));