import React from "react";
import { Stack, Typography, Badge, Container, Box } from "@mui/material";
import { withNamespaces } from "react-i18next";
import { useCorrespondanceStyles } from "./style";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { DialogDrawer, LoderSimmer } from "../../components";
import { useHistory } from "react-router-dom";
import { NetworkCall } from "../../networkcall";
import { AlertContext } from "../../contexts";
import {
  AlertProps,
  enumCatagory,
  enumSelect,
  NetWorkCallMethods,
} from "../../utils";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { Routes } from "../../router/routes";
import { config } from "../../config";
import Filter from "../../components/filter";
import NoDataFound from "../../components/noDataFound/noDataFound";

const CaseManagement = ({ t = () => false, handleTopBarState }) => {
  const size = useWindowDimensions();
  const history = useHistory();
  const classes = useCorrespondanceStyles();
  const alert = React.useContext(AlertContext);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [filterData, setFilterData] = React.useState({
    urgent_type: [],
    correspondences_type: [],
  });
  const myFilterData = [
    {
      id: "1",
      title: "Priority",
      key: "priority",
      showSearch: true,
      filterType: "CHECKBOX",
      values: filterData?.urgent_type,
    },
    {
      id: "2",
      title: "Type",
      key: "type",
      showSearch: true,
      filterType: "CHECKBOX",
      values: filterData?.correspondences_type,
    },
  ];
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [caseManagementList, setCaseManagementList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState(0);

  const handleRoute = (val) => {
    history.push({
      pathname: Routes.correspondanceType,
      state: {
        data: val,
      },
    });
  }
  const applyFilter = (val) => {
    setSelectedFilter(val);
    getCaseManagement(0, false, val?.priority, val?.type);
    setOpenFilter(false);
  };
  const getCaseManagement = (offset, fetchMore, priority, type) => {
    const payload = {
      filters: {
        priority: priority?.map((e) => e?.replace(/^\w/, (firstLetter) => firstLetter.toLowerCase())),
        type: type,
      },
      status: 2,
      limit: 20,
      offset: offset,
    };
    NetworkCall(
      `${config?.api_url}/correspondences/list_for_resident`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (fetchMore) {
          setCaseManagementList((res) => [
            ...res,
            ...response?.data?.data?.list,
          ]);
        } else {
          setCaseManagementList(response?.data?.data?.list);
          setLoading(false);
        }
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went wrong"),
        });
        setLoading(false);
      });
  };
  const fetchMoreData = () => {
    const tempOffset = offset + 20;
    setOffset(tempOffset);
    getCaseManagement(
      tempOffset,
      true,
      selectedFilter?.priority,
      selectedFilter?.type
    );
  };
  React.useEffect(() => {
    handleTopBarState({
      text: t("Letter Management")
    })
    getCorrespondencesType();
    // eslint-disable-next-line
  }, []);
  const getEnum = async (correspondences_type) => {
    const result = await enumSelect([enumCatagory.urgent_type]);
    setFilterData({
      urgent_type: result.urgent_type?.map((e) => {
        return {
          label: e?.label?.replace(/^\w/, (firstLetter) => firstLetter.toUpperCase()),
          value: e?.value?.replace(/^\w/, (firstLetter) => firstLetter.toUpperCase())
        }
      }
      ),
      correspondences_type: correspondences_type,
    });
    const priority = result.urgent_type?.map((val) => val.label);
    const type = correspondences_type?.map((val) => val.value);
    setSelectedFilter({
      priority: priority?.map((e) => e?.replace(/^\w/, (firstLetter) => firstLetter.toUpperCase())),
      type: type,
    });
    getCaseManagement(0, false, priority, type);
  };

  const getCorrespondencesType = () => {
    // client
    //   .query({
    //     query: GET_CORRESPONDENCES_TYPE_MASTER,
    //     variables: {
    //       search: "",
    //     },
    //     fetchPolicy: "network-only",
    //   })
    const payload = {
      search: ""
    }
    NetworkCall(
      `${config?.api_url}/queries/correspondences_type_master/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((res) => {
      getEnum(res?.data?.data?.correspondences_type_master);
    })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went wrong"),
        });
      });
  };
  return (
    <Container className={classes.root} maxWidth="sm">
      <Box className={classes.root}>
        {/* <TitleBar text={t("Case Management")} goBack={GoBack} /> */}
        <Stack
          mt={1}
          mb={1}
          pr={2}
          pl={2}
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography className={classes.caseTxt}>
            {t("Letter Management")} {"(" + caseManagementList?.length + ")"}
          </Typography>
          <Box
            className={classes.filterBlock}
            onClick={() => setOpenFilter(true)}
          >
            <Badge color="primary" variant="dot">
              <img src="/images/funnel.svg" alt="" />
            </Badge>
          </Box>
        </Stack>
        <div
          className={classes.heightOverflow}
          style={{ height: size.height - 112 }}
        >
          {loading ? (
            <Box p={1}>
              <LoderSimmer count={5} />
            </Box>
          ) : (
            <>
              <Box pr={2} pt={1} pl={2}>
                {caseManagementList?.length > 0 ? (
                  <InfiniteScroll
                    dataLength={caseManagementList.length}
                    next={fetchMoreData}
                    hasMore={true}
                    height={size?.height - 125}
                  >
                    {caseManagementList?.map((val, index) => {
                      let priorityColor = "";
                      if (val?.priority === "urgent") {
                        priorityColor = "#FE6B6B";
                      } else if (val?.priority === "high") {
                        priorityColor = "#EFB93C";
                      } else if (val?.priority === "medium") {
                        priorityColor = "#FFD166";
                      } else if (val?.priority === "low") {
                        priorityColor = "#06D6A0";
                      }
                      return (
                        <>
                          <Box
                            key={index}
                            className={classes.caseCard}
                            onClick={() => handleRoute(val)}
                          >
                            <Stack
                              direction="row"
                              className={classes.stackItem}
                            >
                              <Box className={classes.corresType}>
                                <Typography>{val?.type}</Typography>
                              </Box>
                              <Box
                                className={classes.status}
                                style={{
                                  background: priorityColor,
                                }}
                              >
                                <Typography className={classes.priorityTxt}>
                                  {val?.priority}
                                </Typography>
                              </Box>
                            </Stack>
                            <Typography
                              mt={1}
                              mb={1}
                              className={classes.txt}
                              style={{
                                fontWeight:
                                  val?.priority === "urgent" ? "500" : "400",
                              }}
                            >
                              {val?.subject}
                            </Typography>
                            <div direction="row" className={classes.dateDiv}>
                              <Typography component={"p"}>
                                Posted on{" "}
                                {moment(val?.incident_date)
                                  .tz(moment.tz.guess())
                                  .format("DD MMM YY ")}
                              </Typography>
                              <div className={classes.dot}></div>
                              <Typography component={"p"}>
                                Deadline{" "}
                                {moment(val?.dead_line_date)
                                  .tz(moment.tz.guess())
                                  .format("DD MMM YY ")}
                              </Typography>
                              {val?.is_replied && (
                                <div className={classes.replyPoint}>
                                  <div className={classes.dot}></div>
                                  <img src="/images/checkedOk.svg" alt="" />
                                  <Typography component={"p"} ml={0.5}>
                                    {t("Replied")}
                                  </Typography>
                                </div>
                              )}
                            </div>
                          </Box>
                          <Box height={"12px"} />
                        </>
                      );
                    })}
                  </InfiniteScroll>
                ) : (
                  <NoDataFound
                    height={size?.height - 140}
                    content={t("No Data Found")}
                    backgroundColor="#ffffff"
                  />
                )}
              </Box>
            </>
          )}
        </div>
      </Box>
      <DialogDrawer
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        onOpen={() => setOpenFilter(true)}
        isnotTitle={true}
        padding={0}
        height={size?.width <= 600 ? size?.height : "auto"}
        component={
          <Filter
            filterData={myFilterData}
            selectedList={myFilterData[0]}
            filters={{
              priority: selectedFilter.priority,
              type: selectedFilter.type,
            }}
            onApply={applyFilter}
            onGoBack={() => setOpenFilter(false)}
            onClose={() => setOpenFilter(false)}
          />
        }
      />
    </Container>
  );
};
export default withNamespaces("caseManagement")(CaseManagement);
