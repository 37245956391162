import { withNamespaces } from "react-i18next";
import { useStyles } from "./styles";
import { Box, Container, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, timeZoneConverter } from "../../utils";
import { BackendRoutes, Routes } from "../../router/routes";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import SurveyListCard from "./components/surveyListCard";
import InfiniteScroll from "react-infinite-scroll-component";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useHistory } from "react-router";

const SurveyList = ({
    t = () => false,
    handleTopBarState = () => false,
}) => {
    const listLimit = 10;
    const company_id = JSON?.parse?.(localStorage?.getItem?.(LocalStorageKeys?.accessType))?.[0]?.company_id ?? undefined;
    const size = useWindowDimensions();
    const classes = useStyles();

    const history = useHistory();
    const alert = useContext(AlertContext);

    const [listState, setListState] = useState({ data: [], totalCount: 0 });
    const [listOffset, setListOffset] = useState(0);
    const [isListLoading, setIsListLoading] = useState(true);

    useEffect(() => {
        handleTopBarState({ text: t("Survey List") });

        getSurveyList({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSurveyList = ({ offset = listOffset, limit = listLimit, search = "", load_type = "initial" }) => {
        if (["initial"]?.includes(load_type)) {
            setIsListLoading(true);
        }

        const payload = {
            offset, limit, search, company_id,
            is_draft: false, is_active: [true],
        }
        NetworkCall(
            `${config?.api_url}${BackendRoutes?.survey_list}`,
            NetWorkCallMethods?.post, payload, null, true, false
        ).then((response) => {
            let temp_list = response?.data?.data?.data?.map?.(_ => {
                return {
                    id: _?.id,
                    title: _?.title,
                    form_no: _?.form_id ?? "",
                    published_date: _?.published_on ? timeZoneConverter(_?.published_on) : "",
                    status: _?.form_status,
                    all_data: _,
                }
            })
            if (["initial"]?.includes(load_type)) {
                setListState({
                    data: temp_list,
                    totalCount: response?.data?.data?.count ?? 0,
                });
                setIsListLoading(false);
            } else {
                setListState({
                    data: [...listState?.data, ...temp_list],
                    totalCount: response?.data?.data?.count ?? 0,
                });
            }
        }).catch((error) => {
            console.log(error);
            alert.setSnack({
                ...alert, open: true, msg: t("Something went wrong"),
                severity: AlertProps?.severity?.error,
            });
        });
    };

    const fetchMoreData = () => {
        const offset = listOffset + listLimit;
        setListOffset(offset);
        getSurveyList({ offset, load_type: "load_more" });
    }

    const handleSurveyCard = (data) => {
        history?.push(Routes?.surveyForm + "?id=" + data?.id);
    }

    return (
        <Container className={classes.root} maxWidth="sm">
            <Box p={"16px"}>
                <Typography className={classes.total_text}>
                    {`${t("Total Survey")} (${listState?.totalCount})`}
                </Typography>
            </Box>
            <Box >
                {isListLoading
                    ? <Stack direction={"column"} rowGap={"12px"} p={"16px"}>
                        {[1, 2, 3]?.map?.(_ => <SurveyListCard isloading size={size} />)}
                    </Stack>
                    : <InfiniteScroll
                        dataLength={listState?.data?.length ?? 0}
                        next={fetchMoreData}
                        hasMore={listState?.data?.length < listState?.totalCount}
                        loader={<SurveyListCard isloading size={size} />}
                        height={size?.height - 111}>
                        <Stack direction={"column"} rowGap={"12px"} p={"16px"}>
                            {listState?.data?.map?.(_ =>
                                <SurveyListCard t={t} size={size} data={_} handleSurveyCard={handleSurveyCard} />)}
                        </Stack>
                    </InfiniteScroll>}
            </Box>
        </Container>
    )
}

export default withNamespaces("survey")(SurveyList)