import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DatePickerNew, TextBox } from "../../components";
import { SelectBox } from "../../components/dropDown";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods, RetainLocalStorage } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  content: {
    padding: "16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  btnRoot: {
    backgroundColor: "#F5F7FA",
    padding: "24px 16px 24px 16px",
    border: "1px solid #F9FAFE",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  btn: {
    padding: "16px",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#5078E1",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#5078E1",
      color: "#FFFFFF",
    },
  },
  buttonTextStyle: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#FFFFFF",
  },
}));

const General = (props) => {
  const user_id = localStorage?.getItem(LocalStorageKeys?.user_id);
  const { t = () => false, handleTopBarState } = props
  const history = useHistory();
  const classes = useStyles();
  const initialError = {
    agreement: "",
    unit: "",
    category: "",
    subCategory: "",
    problemTitle: "",
    problemSince: "",
  };
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [loading, setLoading] = React.useState(true);
  const [agreement, setAgreement] = React.useState([]);
  const [unit, setUnit] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const [subCategory, setSubCategory] = React.useState([]);
  const [problemTitle, setProblemTitle] = React.useState("");
  const [problemDescription, setProblemDescription] = React.useState("");
  const [problemSince, setProblemSince] = React.useState(new Date());
  const [error, setError] = React.useState({ ...initialError });
  const [agreementLists, setAgreementLists] = React.useState([]);
  const [disable, setDisable] = React.useState(null);
  const alert = React.useContext(AlertContext);
  const size = useWindowDimensions();
  const [loading1, setLoading1] = React.useState(null);
  const userId = localStorage.getItem("userProfileId");
  const [agreeementUnitid, setagreeementUnitid] = React.useState("");

  // const clinet = JSON.parse(localStorage.getItem(LocalStorageKeys.clientID));

  const goBack = () => {
    history.goBack(-1);
  };
  const onAgreementChange = (value) => {
    setAgreement(value);
    setUnit([]);
  };

  const onUnitChange = (value) => {
    setUnit(value);
    getagreementUnitid(agreement?.value, value?.value)
  };
  const onCategoryChange = (value) => {
    setCategory(value);
    setSubCategory(value?.general_sub_category?.length>0?value?.general_sub_category?.[0]:"");
  };
  const onSubCategoryChange = (value) => {
    setSubCategory(value);
  };
  const onProblemTitleChange = (event) => {
    setProblemTitle(event.target.value);
  };
  const onProblemDescriptionChange = (event) => {
    setProblemDescription(event.target.value);
  };

  const onProblemSinceChange = (value) => {
    setProblemSince(value);
  };

  const isRequestValid = () => {
    let isValid = true;
    if (agreement.length === 0) {
      isValid = false;
      error.agreement = t("AgreementisRequired");
    } else {
      error.agreement = "";
    }
    if (unit.length === 0) {
      isValid = false;
      error.unit = t("UnitisRequired");
    } else {
      error.unit = "";
    }
    if (category.length === 0) {
      isValid = false;
      error.category = t("CategoryisRequired");
    } else {
      error.category = "";
    }
    if (subCategory.length === 0) {
      isValid = false;
      error.subCategory = t("SubCategoryisRequired");
    } else {
      error.subCategory = "";
    }
    if (problemTitle.length === 0) {
      isValid = false;
      error.problemTitle = t("ProblemTitleisRequired");
    } else {
      error.problemTitle = "";
    }
    if (problemSince.length === 0) {
      isValid = false;
      error.problemSince = t("ProblemSinceisRequired");
    } else {
      error.problemSince = "";
    }
    if (problemDescription.length === 0) {
      isValid = false;
      error.problemDescription = t("DescriptionisRequired");
    } else {
      error.problemDescription = "";
    }
    setError({ ...error });
    return isValid;
  };


  const getagreementUnitid = (v, x) => {
    const payload = {
      agreement_id: v,
      unit_id: x,
      offset: 0,
      limit: null
    }
    
    NetworkCall(
      `${config.api_url}/queries/get_agreement_units/by_agreement`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((res) => {
      setagreeementUnitid(res?.data?.data?.agreement_units?.[0]?.id)
    }).catch((err) => {

    })
  }
  React.useEffect(() => {
    handleTopBarState({
      text: "New General Request"
    })
    if (authToken !== null && authToken !== "") {
    } else {
      RetainLocalStorage();
      history.push(Routes.welcome);
    }
    NetworkCall(
      `${config.api_url}/agreement-unit-info/switcher`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        const _tempUnitList = response?.data?.data?.map(
          (_uitem, index, array) => {
            let _u;
            try {
              _u = {
                label: _uitem?.agreement_no ? _uitem?.agreement_no : "",
                value: _uitem?.id ? _uitem?.id : "",
                units: _uitem.units,
              };
            } catch (error) {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("SomethingWentwrong"),
              });
            }
            return _u;
          }
        );
        setAgreementLists(_tempUnitList);
        setLoading(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
    // eslint-disable-next-line
  }, []);

  const saveGeneralRequest = () => {
    if (isRequestValid()) {
      setDisable(true);
      const saveGeneralRequestPayload = {
        tenantId: `${config.tenantid}`,
        type: 1,
        subject: problemTitle,
        problem_since: moment(problemSince)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD"),
        category: category.value,
        sub_category: subCategory.value,
        units: [unit?.value],
        userProfileId: userId,
        propertyId: unit?.property?.value,
        agreement_unit_id: agreeementUnitid,
        companyId: unit?.property?.company?.value,
        is_active:true
      };
      if (problemDescription !== "") {
        saveGeneralRequestPayload.description = problemDescription;
      }
      NetworkCall(
        `${config.api_url}/request/create`,
        NetWorkCallMethods.post,
        saveGeneralRequestPayload,
        null,
        true,
        false
      )
        .then((response) => {
          setDisable(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: t("RequestCreatedsuccessfully"),
          });
          goBack();
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
          setDisable(false);
        });
    }
  };

  const loadOptions = async (search = "", array, type) => {
    setLoading1(type);
    let result,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    switch (type) {
      case "unit":
        let id = agreement?.units;
        if (!id?.length) {
          setLoading1(null);
          return { options: [] };
        }
        result = await NetworkCall(
          `${config.api_url}/queries/get_unit_detail_by_ids`,
          NetWorkCallMethods.post,
          {
            ids: agreement?.units,
            offset,
            limit: 10,
            search,
          },
          null,
          true,
          false
        ).then((res)=> {return res?.data})
        
        setLoading1(null);
        return {
          options: [...result?.data?.unit],
          hasMore:
            array?.length + result?.data?.unit?.length <
            result?.data?.count,
        };

      case "category":
        const userId = user_id;
        if (!userId?.length) {
          setLoading1(null);
          return { options: [] };
        }
        result = await NetworkCall(
          `${config.api_url}/general-master/category_master`,
          NetWorkCallMethods.post,
          {
            offset,
            limit: 10,
            search
          },
          null,
          true,
          false
        )
        setLoading1(null);
        return {
          options: [...result?.data?.data],
          hasMore:
            array?.length + result?.data?.data?.length <
            result?.data?.count,
        };

      case "subCategory":
        if (!category.value) {
          setLoading1(null);
          return { options: [] };
        }
        result = await NetworkCall(
          `${config.api_url}/general-master/sub_category_master`,
          NetWorkCallMethods.post,
          {
            offset,
            limit: 10,
            category_id:category?.value ? category?.value : null,
            search
          },
          null,
          true,
          false
        )
        setLoading1(null);
        return {
          options: [...result?.data?.data],
          hasMore:
            array?.length + result?.data?.data?.length <
            result?.data?.count,
        };

      default:
        return { options: [] };
    }
  };

  return (
    <div>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Container
            maxWidth="sm"
            className={classes.root}
            style={{ height: size.height - 155 }}
          >
            {/* <TitleBar

              text={t("NewGeneralRequest")}
              goBack={goBack}
            /> */}
            <Grid
              container
              className={classes.content}
              style={{ height: size.height - (106.5 + 55) }}
            >
              <Box height={16} />
              <Grid item xs={12}>
                <SelectBox
                  isRequired
                  label={t("Agreement")}
                  value={agreement}
                  options={agreementLists}
                  onChange={(value) => onAgreementChange(value)}
                  placeholder={t("SelectAgreement")}
                  isError={error?.agreement?.length > 0}
                  errorMessage={error?.agreement}
                />
              </Grid>
              <Box height={16} />

              <Grid item xs={12}>
                <SelectBox
                  isRequired
                  label={t("Unit")}
                  isPaginate
                  key={JSON.stringify(agreement)}
                  loadOptions={(search, array) =>
                    loadOptions(search, array, "unit")
                  }
                  loading={loading1 === "unit"}
                  debounceTimeout={800}
                  value={unit}
                  options={[]}
                  onChange={(value) => onUnitChange(value)}
                  placeholder={t("SelectUnit")}
                  isError={error?.unit?.length > 0}
                  errorMessage={error?.unit}
                />
              </Grid>
              <Box height={16} />
              <Grid item xs={12}>
                <SelectBox
                  isRequired
                  label={t("Category")}
                  isPaginate
                  loadOptions={(search, array) =>
                    loadOptions(search, array, "category")
                  }
                  loading={loading1 === "category"}
                  debounceTimeout={800}
                  value={category}
                  onChange={(value) => onCategoryChange(value)}
                  placeholder={t("SelectCategory")}
                  isError={error?.category?.length > 0}
                  errorMessage={error?.category}
                />
              </Grid>
              <Box height={16} />
              <Grid item xs={12}>
                <SelectBox
                  isPaginate
                  loadOptions={(search, array) =>
                    loadOptions(search, array, "subCategory")
                  }
                  key={JSON.stringify(category)}
                  loading={loading1 === "subCategory"}
                  debounceTimeout={800}
                  isRequired
                  label={t("SubCategory")}
                  value={subCategory}
                  onChange={(value) => onSubCategoryChange(value)}
                  placeholder={t("SelectSubCategory")}
                  isError={error?.subCategory?.length > 0}
                  errorMessage={error?.subCategory}
                />
              </Grid>
              <Box height={16} />
              <Grid item xs={12}>
                <TextBox
                  color
                  isRequired
                  label={t("Title")}
                  value={problemTitle}
                  onChange={onProblemTitleChange}
                  placeholder={t("EnterTitle")}
                  isError={error?.problemTitle?.length > 0}
                  errorMessage={error?.problemTitle}
                />
              </Grid>
              <Box height={16} />
              <Grid item xs={12}>
                <TextBox
                  color
                  isRequired
                  multiline
                  label={t("Description")}
                  value={problemDescription}
                  onChange={onProblemDescriptionChange}
                  placeholder={t("Describeinbriefifany")}
                  isError={error?.problemDescription?.length > 0}
                  errorMessage={error?.problemDescription}
                />
              </Grid>
              <Box height={16} />
              <Grid item xs={12}>
                {/* <DateTime
                  isRequired
                  disableFuture={true}
                  disablePast={false}
                  title="Since"
                  placeholder="DD/MM/YYYY"
                  value={problemSince}
                  onChange={onProblemSinceChange}
                  isError={error?.problemSince?.length > 0}
                  errorMessage={error?.problemSince}
                /> */}
                <DatePickerNew
                  label={t("Since")}
                  placeholder={t("Since")}
                  value={problemSince ?? ""}
                  isRequired
                  onChange={(date) => onProblemSinceChange(date)}
                  isError={error?.problemSince?.length > 0}
                  errorMessage={error?.problemSince}
                  maxDate={new Date()}
                />
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="sm" className={classes.btnRoot}>
            <Button
              onClick={saveGeneralRequest}
              fullWidth
              variant="contained"
              className={classes.btn}
              disabled={disable}
            >
              {t("Submit")}
            </Button>
          </Container>
        </>
      )}
    </div>
  );
};
export default withNamespaces("general")(General)