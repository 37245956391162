import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";
import ScrollContainer from "react-indiana-drag-scroll";
// import { ArrowRight } from "../../../assets/dashboard/ArrowRight";
import { DialogDrawer, NoService } from "../../../components";
import ExploreProperty from "./ExploreProperty";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  expoloreBlock: {
    cursor: "pointer",
    padding: "14px",
    minWidth: "187px",
    maxWidth: "190px",
    height: "161px",
    borderRadius: theme.palette.borderRadius2,
    marginRight: "12px",
    boxShadow: "0px 3px 30px #5C86CB2E",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  name: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    marginBottom: "13px",
  },
  searchText: {
    fontSize: "0.9375rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.palette.background.tertiary1,
    lineHeight: "21px",
  },
  checkNowText: {
    marginTop: "22px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.palette.background.tertiary1,
  },
  smCard: {
    display: "flex",
    gap: "12px",
    flexDirection: "column",
    marginRight: "12px",
  },
  smBlock: {
    borderRadius: theme.palette.borderRadius2,
    padding: "12px",
    width: "161px",
    height: "74px",
    backgroundColor: theme.palette.background.tertiary1,
    display: "flex",
    backgroundImage: `url(${"/images/ExploreCard2.svg"})`,
    backgroundRepeat: "no-repeat",
    boxShadow: "0px 3px 30px #5C86CB2E",
    cursor: "pointer",
  },
  smBlockArabic: {
    borderRadius: theme.palette.borderRadius2,
    padding: "12px",
    width: "161px",
    height: "74px",
    backgroundColor: theme.palette.background.tertiary1,
    display: "flex",
    backgroundImage: `url(${"/images/homeServices.svg"})`,
    backgroundRepeat: "no-repeat",
    boxShadow: "0px 3px 30px #5C86CB2E",
    cursor: "pointer",
  },
  smBlock1: {
    borderRadius: theme.palette.borderRadius2,
    padding: "12px",
    width: "161px",
    height: "74px",
    backgroundColor: theme.palette.background.tertiary1,
    display: "flex",
    backgroundImage: `url(${"/images/ExploreCard1.svg"})`,
    backgroundRepeat: "no-repeat",
    boxShadow: "0px 3px 30px #5C86CB2E",
    cursor: "pointer",
  },
  smBlock1Arabic: {
    borderRadius: theme.palette.borderRadius2,
    padding: "12px",
    width: "161px",
    height: "74px",
    backgroundColor: theme.palette.background.tertiary1,
    display: "flex",
    backgroundImage: `url(${"/images/referAndWin.svg"})`,
    backgroundRepeat: "no-repeat",
    boxShadow: "0px 3px 30px #5C86CB2E",
    cursor: "pointer",
  },
  cardTitle: {
    width: "80%",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.palette.secondary.main,
    marginBottom: "4px",
    lineHeight: "14px",
  },
}));
const ExploreCard = (props) => {
  const { t = () => false } = props;
  // const classes = useStyles();
  const [drawer, setDrawer] = React.useState(false);
  const [open, setopen] = React.useState(false);
  // const language = localStorage.getItem("i18nextLng")

  return (
    <Box>
      <ScrollContainer className="scroll-container">
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex">
              {(props?.controlList?.amenityGoTo || props?.controlList?.realtyGoTo || props?.controlList?.privateListings) &&
                <CheckNowCard
                  t={t}
                  colors="#0974F1, #9FCCFA"
                  color="#3568A1"
                  openDrawer={() => setDrawer(true)}
                  title={
                    <>
                      {t("buy")} &#8226; {t("dashRent")} &#8226; {t("sale")}
                    </>
                  }
                  subTitle={t("Search, Explore, Get your favourite property")}
                />
              }
              {/* <Box className={classes.smCard}>
                <Box onClick={()=>setopen(true)} className={language === "ar" ? classes.smBlock1Arabic : classes.smBlock1}>
                  <Box sx={{ width: "250px" }}>
                    <Typography className={classes.cardTitle}>{t("ReferWin")}</Typography>
                    <ArrowRight />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >

                  </Box>
                </Box>
                <Box onClick={()=>setopen(true)} className={language === "ar" ? classes.smBlockArabic : classes.smBlock}>
                  <Box sx={{ width: "250px" }}>
                    <Typography className={classes.cardTitle}>{t("HomeServices")}</Typography>
                    <ArrowRight />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >

                  </Box>
                </Box>
                <Box height="10px" />
              </Box> */}
              {props?.controlList?.townShip &&
                <CheckNowCard
                  t={t}
                  color="#ffa3cb"
                  colors="#a01256, #a01256"
                  title={t("Township")}
                  subTitle={t("Explore Your Township")}
                />
              }
            </Box>
          </Grid>
        </Grid>
      </ScrollContainer>
      <DialogDrawer
        open={drawer}
        onClose={() => setDrawer(false)}
        onOpen={() => setDrawer(true)}
        isnotTitle={false}
       
        header={t("Property Listings Portal")}
        component={<ExploreProperty  controlList={props?.controlList}/>}
      />
      <DialogDrawer
        height={"300px"}
        maxWidth={"sm"}
        open={open}
        header={" "}
        noService={true}
        drawerComponent={
          <>
            <NoService
              main={t("This feature is either not enabled or not opted by your organization.")}
              sub={t("Please contact your administrator for details.")}
              buttonName={t("Close")}
              mobile
              fullWidth
              onClose={() => setopen(false)}
            />
          </>
        }
        dialogComponent={
          <>
            <NoService
              main={t("This feature is either not enabled or not opted by your organization.")}
              sub={t("Please contact your administrator for details.")}
              buttonName={t("Close")}
              fullWidth
              onClose={() => setopen(false)}
            />
          </>
        }
        onClose={() => setopen(false)}
      />
    </Box>
  );
};



const CheckNowCard = ({ title, color, colors, subTitle, t, openDrawer }) => {
  const classes = useStyles();
  return (
    <Box
      onClick={openDrawer}
      className={classes.expoloreBlock}
      sx={{
        background: "linear-gradient(291deg," + colors + ")",
        justifyContent: "space-between",
      }}
    >
      <Box className={classes.container}>
        <Box>
          <Typography className={classes.name} sx={{ color: color }}>
            {title}
          </Typography>
          <Typography className={classes.searchText}>{subTitle}</Typography>
        </Box>
        <Typography className={classes.checkNowText}>
          {t("checkNow")} &#8594;
        </Typography>
      </Box>
    </Box>
  );
};

export default withNamespaces("dashboard")(ExploreCard);
