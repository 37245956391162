import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Chart, DialogDrawer, DocumentViewer, Paymenthistory } from "../../components";
import { InvoiceSimmer } from "../../components/simmer/invoiceSimmer";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { getFilterDate, returnChartData } from "../../utils/payment_history";
import { useStyles } from "./styles";
import { DetailCard, PaymentdetailCard } from "./component";

const PaymentHistory = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const yearFilter = ["All", "5Y", "3Y", "1Y", "6M", "1M"];
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  //states
  const [data, setData] = React.useState({
    list: [],
    selected_duration: "All",
    filtered_date: null,
    loading: true,
    pdf: false,
    details: false,
    total: 0,
    paymentDetails: {},
    isChart: false,
    chartData: {
      label: [],
      data: []
    }
  })
  //get all payment history
  const getPaymentsettlement = (agreement_id, date, filterDuration = "All") => {
    setData({ ...data, loading: true })
    const payload = {
      agreement_id,
      date
    }

    NetworkCall(
      `${config.api_url}/payment_history`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const chart_data = returnChartData(res?.data?.data?.length>0?res?.data?.data?.reverse():[]);
        setData({
          ...data,
          list: res?.data?.data,
          total: res?.data?.data?.map(i => i?.amount_tagged)?.reduce((a, b) => a + b, 0),
          loading: false,
          chartData: chart_data,
          filtered_date: date,
          selected_duration: filterDuration
        })

      })
      .catch((error) => {
        console.log(error)
      });

  }
  //update the state
  const updateState = (update_params) => {
    setData({ ...data, ...update_params })
  }
  //change duration
  const handleOnClickFilterDuration = (filterDuration) => {
    const date = getFilterDate(filterDuration);
    getPaymentsettlement(props?.id, date, filterDuration)

  };
  //onClick details
  const handleDetails = (datas = null) => {
    updateState({ details: !data?.details, paymentDetails: datas ?? data?.paymentDetails })
  };
  //handle chart
  const handleChart = () => {
    setData({ ...data, isChart: !data?.isChart })
  };
  //initial load
  React.useEffect(() => {
    if (authToken !== null && authToken !== "" && props.id) {
      getPaymentsettlement(props.id)
    } else {
      localStorage.clear(props.id);
      history.push(Routes.welcome);
    }
    // eslint-disable-next-line
  }, [props.id]);


  return (
    <div className={classes.root}>

      <Box>
        <Grid className={classes.screen} item xs={12}>
          <Grid className={classes.screenScrollEffect}>
            <Grid
              container
              direction={"row"}
              style={{ backgroundColor: "white" }}
            >
              {yearFilter.map((item, index) => {
                return (
                  <>
                    <Grid
                      onClick={() => handleOnClickFilterDuration(item)}
                      className={data?.selected_duration === item ? classes.selectedFilterDurationTabStyle : classes.unSelectedFilterDurationTabStyle}
                      container xs={2} justifyContent={"center"} alignItems={"center"} alignSelf={"center"}
                      direction={"row"}
                    >
                      <Typography
                        className={data?.selected_duration === item ? classes.selectedFilterDurationTabTextStyle : classes.unSelectedFilterDurationTabTextStyle}
                      >
                        {item}
                      </Typography>
                    </Grid>
                  </>
                );
              })}
            </Grid>
            {data?.loading ? (
              <InvoiceSimmer />
            ) : (
              <Box>
                <Grid style={{ padding: "0px 16px" }}>
                  <Box height="12px" />
                  <DetailCard t={props?.t} openChart={handleChart} total={data?.total} symbol={data?.list?.[0]?.symbol} />
                </Grid>
                <Box height={"16px"} />
                <Grid className={classes.paymentHistoryContainerStyle}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    direction={"row"}
                    className={classes.chartRoot}
                  >
                    <Grid>
                      <Typography
                        className={classes.chartTitle}
                      >
                        {props?.t("payment_history") + " (" + (data?.list.length < 10 ? "0" + data?.list.length : data?.list.length) + ")"}
                      </Typography>
                    </Grid>
                  </Grid>
                  {data?.list?.map((val) => {
                    return (
                      <Paymenthistory
                        t={props?.t}
                        onClick={handleDetails}
                        data={val}
                      />
                    );
                  })}
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
        {/*invoice details */}
        <DialogDrawer
          height={"auto"}
          open={data?.details}
          header={props?.t("transaction_details")}
          maxWidth="sm"
          padding={"0px"}
          component={
            <PaymentdetailCard t={props?.t} paymentDetails={data?.paymentDetails} onPdf={() => updateState({ pdf: true })} />
          }
          onClose={handleDetails}
        />
        <DialogDrawer
          height={"auto"}
          open={data?.isChart}
          header={props?.t("spending_trends")}
          maxWidth="sm"
          padding={"16px"}
          component={
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Chart chartData={data?.chartData} />
            </Grid>
          }
          onClose={handleChart}
        />
        {/*receipt details */}
        <DialogDrawer
          height={"auto"}
          isnotTitle={true}
          maxWidth="sm"
          padding={"0px"}
          open={data?.pdf}
          onClose={() => updateState({ pdf: false })}
          component={
            <Grid
              container
              direction="column"
              className={classes.dialogStyle}
            >
              <Grid
                container
                direction="row"
                className={classes.drawerHeaderStyle}
              >
                <Grid xs={12}>
                  <Stack direction="row" justifyContent="space-between">
                  <Typography className={classes.drawerHeaderTextStyle}>
                    {props?.t("receipt")}
                  </Typography>
                  <IconButton
                    style={{
                      padding: "0px",
                    }}
                    onClick={() => updateState({ pdf: false })}
                  >
                    <CloseIcon />
                  </IconButton>
                  </Stack>
                  <Box height="3px" />
                  <Grid container alignItems="center">
                    <Typography
                      style={{
                        fontSize: "0.75rem",
                        color: "#4E5A6B",
                        textAlign: "left",
                      }}
                    >
                      {data?.paymentDetails?.settlement_no}
                    </Typography>
                    <Box className={classes.periodStyle} />
                    <Typography
                      style={{
                        fontSize: "0.75rem",
                        color: "#4E5A6B",
                        textAlign: "left",
                        textTransform: "capitalize",
                      }}
                    >
                      {props?.t("receiptId") + " " +
                        data?.paymentDetails?.reciepts_no}
                    </Typography>
                    <Box className={classes.periodStyle} />
                    <Typography
                      style={{
                        fontSize: "0.75rem",
                        color: "#4E5A6B",
                        textAlign: "left",
                        textTransform: "capitalize",
                      }}
                    >
                      {moment(data?.paymentDetails?.created_at)
                        .tz(moment.tz.guess())
                        .format("DD MMM YY")}
                    </Typography>
                    <Box className={classes.periodStyle} />
                    <Typography
                      style={{
                        fontSize: "0.75rem",
                        color: "#4E5A6B",
                        textAlign: "left",
                        textTransform: "capitalize",
                      }}
                    >
                      {data?.paymentDetails?.payment_mode}
                    </Typography>
                  </Grid>
                </Grid>
                
              </Grid>
              <Divider />
              <Grid className={classes.pdfDrawerBodyStyle}>
                {
                  data?.paymentDetails?.url ?
                    <DocumentViewer url={data?.paymentDetails?.url} />
                    : props?.t("No Receipt Found")
                }
              </Grid>
              
            </Grid>
          }
        />
      </Box>

    </div>
  );
};
export default withNamespaces("invoice")(PaymentHistory);
