/* eslint-disable array-callback-return */
import { Box, Button, Container, Dialog, Grid, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TextBox, DatePickerTimeNew } from "../../components";
import { SelectBox } from "../../components/dropDown";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, NetWorkCallMethods, enumSelect, FontFamilySwitch } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
import { withBottombar } from "../../HOCs";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    padding: "0px",
  },
  input: {
    marginTop: "10px",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
    textTransform: "capitalize"
  },
  rootForm: {
    overflow: "scroll",
    marginRight: "-6px"
  },
  dialog: {
    "& .MuiDialog-paper": {
      // borderRadius: "4px",
      padding: "0px",
      display: "contents"
    },
  },
  textBox: {
    backgroundColor: "#ffffff",
    padding: "13px 14px 10px",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    borderRadius: "6px",
    border: "1px solid #E4E8EE"
  },
  label: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#4E5A6B",
    paddingBottom: "8px"
  }
}));
const InitialState = {
  unit: "",
  vehicleType: "",
  number: "",
  startDate: new Date(),
  endDate: new Date(),
  gate: {
    value: "Any",
    label: "Any",
  },
  error: {
    unit: "",
    vehicleType: "",
    startDate: "",
    endDate: "",
    gate: "",
  },
};
const CreateParking = ({ t = () => false, handleTopBarState }) => {
  const size = useWindowDimensions();
  const classes = useStyles();
  const history = useHistory();
  const searchURL = useLocation().search;
  const [data, setdata] = React.useState({ ...InitialState });
  const [disable, setDisable] = React.useState(null);
  const [openDate, setOpenDate] = React.useState(false);
  const [endDate, setEndDate] = React.useState(false)
  // const [getunit, setgetUnit] = React.useState(null);
  const alert = React.useContext(AlertContext);
  const passId = new URLSearchParams(searchURL).get("passId");
  const [property, setProperty] = React.useState({});
  const [agreementList, setAgreementLists] = React.useState([]);
  const backdrop = React.useContext(BackdropContext);
  const [veicalType, setVeicalType] = React.useState([]);

  const getEnum = async () => {
    const stayType = await enumSelect(['vechicle_type'])
    setVeicalType(stayType?.vechicle_type)
  }

  const getUnitIDS = () => {
    NetworkCall(
      `${config.api_url}/community/community_units`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let data = response?.data?.data?.map(x => x?.agreement_unit).flat()
        const _tempUnitList = data?.map(
          (_uitem) => {
            let _u;
            _u = {
              label: _uitem?.name ?? "",
              value: _uitem?.id ?? "",
              unit_id: _uitem?.unit_id ?? "",
              property:{
                id:_uitem?.property_id,
                name:_uitem?.property_name
              }
            };

            return _u;
          }
        );
        setAgreementLists(_tempUnitList);
        setProperty(_tempUnitList?.[0]?.property)
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  }


  React.useEffect(() => {
    handleTopBarState({
      text: t("CreateParkingSlotRequset")
    })
    if (passId) {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "Loading",
      });
      const data = {
        tenantId: `${config.tenantid}`,
        request_id: passId,
      };
      NetworkCall(
        `${config.api_url}/security/request`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          const temp = response?.data?.data[0];
          const InitialState = {
            unit: {
              value: temp?.agreement_unit_id ?? "",
              label: temp?.unit?.[0]?.name ?? "",
              unit_id: temp?.unit?.[0]?.id ?? "",
            },
            vehicleType: {
              value: temp?.vechicle_type,
              label: temp?.vechicle_type,
            },
            number: temp?.vechicle_number,
            startDate: new Date(temp?.request_from),
            endDate: new Date(temp?.request_to),
            gate: {
              value: temp?.gate_id,
              label: temp?.gate,
            },
            error: {
              unit: "",
              vehicleType: "",
              startDate: "",
              endDate: "",
              gate: "",
            },
          };
          setdata(InitialState);
          setProperty({
            id: temp?.property_id,
            name: temp?.property_name
          })
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
        })
        .catch((err) => {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
    }
    // getUnitids();
    getUnitIDS()
    getEnum()
    // eslint-disable-next-line
  }, []);
  const updateState = (key, value) => {
    if (key === 'unit') {
      setProperty(value?.property)
    }
    let error = data.error;
    error[key] = "";
    if (key === "startDate") {
      setdata({
        ...data,
        [key]: value,
        error: {
          ...error,
          [key]: value > data?.endDate ? t("Start Date should be lesser than End Date") : "",
        }
      });
      setOpenDate(false)
    } else if (key === "endDate") {
      setdata({
        ...data,
        [key]: value,
        error: {
          ...error,
          [key]: "",
          startDate: data?.startDate > value ? t("Start Date should be lesser than End Date") : "",
        }
      });
      setEndDate(false)
    } else {

      setdata({ ...data, [key]: value, error });
    }
  };
  const isIamValideTostep1 = () => {
    let isValid = true;
    let error = data.error;
    //Checking unit
    if (data?.unit?.length === 0) {
      isValid = false;
      error.unit = t("UnitisRequired");
    }
    //Checking vehicleType
    if (data?.vehicleType?.length === 0) {
      isValid = false;
      error.vehicleType = t("VehicleTypeisRequired");
    }
    //Checking startDate
    if (data?.startDate?.length === 0) {
      isValid = false;
      error.startDate = t("StartDateisRequired");
    }
    //Checking endDate
    if (data?.endDate?.length === 0) {
      isValid = false;
      error.endDate = t("EndDateisRequired");
    }
    //Checking gate
    if (data?.gate?.length === 0) {
      isValid = false;
      error.gate = t("GateisRequired");
    }
    let initialDate = moment(data.startDate);
    let endDate = moment(data.endDate);

    if (endDate.diff(initialDate) >= 0) {
    } else {
      isValid = false;
      error.endDate = t("EndDateisMorethenStartDate");
    }
    setdata({ ...data, error });
    return isValid;
  };
  const add = () => {
    const payload2 = {
      tenantId: `${config.tenantid}`,
      pass_type: "PSR",
      start_date: new Date(data?.startDate),
      end_date: new Date(data?.endDate),
      gate_no: data?.gate?.value === "Any" ? undefined : data?.gate?.value,
      vechicle_number: data?.number,
      vechicle_type: data?.vehicleType?.value,
      user_profile_id: localStorage.getItem("userProfileId"),
      property_id: property?.id,
      unit_id: data?.unit?.unit_id,
      agreement_unit_id: data?.unit?.value,
      currency_id: data?.gate?.company
        ? data?.gate?.company[0]?.currency_id
        : 1,
    };
    NetworkCall(
      `${config.api_url}/security/create`,
      NetWorkCallMethods.post,
      payload2,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("ParkingSlotSuccessfullyCreated"),
        });
        history.goBack(-1);
        setDisable(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
        setDisable(false);
      });
  };
  const updatePass = () => {
    const payload2 = {
      tenantId: `${config.tenantid}`,
      pass_type: "PSR",
      start_date: new Date(data?.startDate),
      end_date: new Date(data?.endDate),
      gate_no: data?.gate?.value === "Any" ? undefined : data?.gate?.value,
      vechicle_number: data?.number,
      vechicle_type: data?.vehicleType?.value,
      user_profile_id: localStorage.getItem("userProfileId"),
      property_id: property?.id,
      unit_id: data?.unit?.unit_id,
      agreement_unit_id: data?.unit?.value,
      request_id: passId,
      profiles: [],
      deleteProfiles: [],
      currency_id: data?.gate?.company?.[0]?.currency_id,
    };
    NetworkCall(
      `${config.api_url}/security/update`,
      NetWorkCallMethods.post,
      payload2,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("ParkingSlotSuccessfullyUpdated"),
        });
        history.goBack(-1);
        setDisable(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
        setDisable(false);
      });
  };
  const createPass = () => {
    if (isIamValideTostep1()) {
      if (passId?.length > 0) {
        updatePass();
      } else {
        add();
      }
      setDisable(true);
    } else {
      return false;
    }
  };

  let boxParent = document.querySelector('#create_parking')?.offsetHeight;
  let fullSize = size?.height - 135

  const manualAccessGateMasterResponse = (array) => {
    return array?.access_gates_master
  }

  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TitleBar
        text={t("CreateParkingSlotRequset")}

        goBack={() => history.push(Routes.parkingPass)}
      /> */}
      <Grid container>
        <Grid item className={classes.rootForm} xs={12} style={{ height: size?.height - 135 }}>
          <div style={{ padding: "12px" }} id="create_parking">
            <div className={classes.input}>
              <SelectBox
                isRequired
                label={t("ChooseUnit")}
                placeholder={t("SelectUnit")}
                value={data.unit}
                onChange={(value) => {
                  updateState("unit", value);
                }}
                options={agreementList}
                isError={data?.error?.unit?.length > 0}
                errorMessage={data?.error?.unit}
              />
            </div>
            <div className={classes.input}>
              <SelectBox
                isRequired
                label={t("ChooseVehicleType")}
                placeholder={t("VehicleType")}
                value={data.vehicleType}
                options={veicalType}
                onChange={(value) => updateState("vehicleType", value)}
                isError={data?.error?.vehicleType?.length > 0}
                errorMessage={data?.error?.vehicleType}
              />
            </div>
            <div className={classes.input}>
              <TextBox
                color
                label={t("EnterVehicleNumberOptional")}
                placeholder={t("EnterVehicleNumberOptional")}
                value={data.number}
                onChange={(e) => updateState("number", e.target.value)}
              />
            </div>

            <Grid container spacing={1} sx={{ marginTop: "4px" }}>
              <>
                <Grid item xs={size.width > 375 ? 6 : 12}>
                  {size.width < 600 ?
                    <><Typography className={classes.label}>{t("Tentative Start Date and Time")}</Typography>
                      <Box onClick={setOpenDate}
                        className={classes.textBox}>
                        <Stack direction={"row"} justifyContent="space-between">
                          &#x202a;{moment(data?.startDate)
                            .tz(moment.tz.guess())
                            .format("MM/DD/YY hh:mm A")}&#x202c;
                          <img src={'/images/icons8-calendar (5).svg'} alt="" />
                        </Stack>
                      </Box>
                      {data?.error?.startDate?.length > 0 && (
                        <Typography variant={"caption"} color={"error"}>
                          {data?.error?.startDate ?? ""}
                        </Typography>
                      )}
                    </> :
                    <DatePickerTimeNew
                      isRequired
                      label={t("TentativeStartDateandTime")}
                      placeholder={t("TentativeStartDateandTime")}
                      value={data.startDate}
                      onChange={(value) => updateState("startDate", value)}
                      isError={data?.error?.startDate?.length > 0}
                      errorMessage={data?.error?.startDate}
                      minDate={new Date()} />
                  }
                </Grid>
                <Grid item xs={size.width > 375 ? 6 : 12}>
                  {size?.width < 600 ?
                    <><Typography className={classes.label}>{t("Tentative Start Date and Time")}</Typography>
                      <Box onClick={setEndDate}
                        className={classes.textBox}>
                        <Stack direction={"row"} justifyContent="space-between">
                          &#x202a;{moment(data?.endDate)
                            .tz(moment.tz.guess())
                            .format("MM/DD/YY hh:mm A")}&#x202c;
                          <img src={'/images/icons8-calendar (5).svg'} alt="" />
                        </Stack>
                      </Box>
                      {data?.error?.endDate?.length > 0 && (
                        <Typography variant={"caption"} color={"error"}>
                          {data?.error?.endDate ?? ""}
                        </Typography>
                      )}
                    </> :
                    <DatePickerTimeNew
                      isRequired
                      label={t("TentativeEndDateandTime")}
                      placeholder={t("TentativeEndDateandTime")}
                      value={data.endDate}
                      onChange={(value) => updateState("endDate", value)}
                      isError={data?.error?.endDate?.length > 0}
                      errorMessage={data?.error?.endDate}
                      minDate={new Date(data?.startDate)}
                    />
                  }
                </Grid>
              </>
            </Grid>
            <Grid container spacing={1} sx={{ marginTop: "4px" }}>
              <Grid item xs={12}>
                <SelectBox
                  isRequired
                  label={t("ChooseGate")}
                  placeholder={t("ChooseGate")}
                  value={data.gate}
                  onChange={(value) => updateState("gate", value)}
                  isError={data?.error?.gate?.length > 0}
                  errorMessage={data?.error?.gate}
                  isPaginate
                  key={JSON.stringify(data.unit)}
                  loadOptions={(search, array, handleLoading) =>
                    loadOptionsApis(
                      "queries/access_gate_master",
                      { property_id: property?.id, any: true },
                      search,
                      array,
                      handleLoading,
                      "data",
                      {},
                      manualAccessGateMasterResponse
                    )
                  }
                  debounceTimeout={800}
                  menuPlacement={boxParent + 10 > fullSize ? "top" : "bottom"}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              padding: "12px",
              backgroundColor: "white",
              boxShadow: "0px -1px 6px #00000021",
            }}
          >
            <Button
              fullWidth
              disabled={disable}
              className={classes.applybtn}
              variant="contained"
              onClick={createPass}
            >
              {t("Submit")}
            </Button>
          </div>
        </Grid>
      </Grid>
      <Dialog
        maxWidth="sm"
        onClose={() => setOpenDate(false)}
        open={openDate}
        fullWidth
        className={classes.dialog}
      >
        <Box>
          <DatePickerTimeNew
            // isRequired
            // label={t("TentativeStartDateandTime")}
            placeholder={t("TentativeStartDateandTime")}
            value={data.startDate}
            onChange={(value) => updateState("startDate", value)}
            // isError={data?.error?.startDate?.length > 0}
            // errorMessage={data?.error?.startDate}
            minDate={new Date()}
            inline
          />
        </Box>
      </Dialog>

      <Dialog
        maxWidth="sm"
        onClose={() => setEndDate(false)}
        open={endDate}
        fullWidth
        className={classes.dialog}
      >
        <Box>
          <DatePickerTimeNew
            // isRequired
            // label={t("TentativeStartDateandTime")}
            placeholder={t("TentativeStartDateandTime")}
            value={data.startDate}
            onChange={(value) => updateState("endDate", value)}
            // isError={data?.error?.startDate?.length > 0}
            // errorMessage={data?.error?.startDate}
            minDate={new Date(data?.startDate)}
            inline
          />
        </Box>
      </Dialog>
    </Container>
  );
};



const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("parkingpass")(CreateParking), props)