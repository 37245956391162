import { Avatar, Box, Button, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { withNamespaces } from "react-i18next";
import Slider from "react-slick";
import { withBottombar } from "../../HOCs";
import { EditOutlineIcon } from "../../assets/editOutlineIcon";
import { LastServiceIcon } from "../../assets/lastServiceIcon";
import { LocationAssetIcon } from "../../assets/locationAsset";
import { NonAssetIcon } from "../../assets/nonAssetIcon";
import { ServiceAssetIcon } from "../../assets/serviceAsset";
import { DatePickerNew, TextBox } from "../../components";
import { SelectBox } from "../../components/dropDown";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./style";



const useUnload = (fn) => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args) => cb.current?.(...args);

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);
};
const Step1 = (props) => {
  const user_id = localStorage?.getItem(LocalStorageKeys?.user_id);
  const { t = () => false, handleTopBarState, unit = "", product = "" } = props
  const classes = useStyles();
  const size = useWindowDimensions();
  const [loading1, setLoading1] = React.useState(null);
  const [slotsData, setSlotsData] = React.useState([])
  // const clinet = JSON.parse(localStorage.getItem(LocalStorageKeys.client));
  const alert = React.useContext(AlertContext)
  const TimeIn24HrFormat = [null, null, { start: "08", end: "10" }, { start: "10", end: "12" }, { start: "12", end: "14" }, { start: "14", end: "16" }, { start: "16", end: "18" }, { start: "18", end: "20" }]
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    speed: 1000,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  };
  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });
  // const handleCategotyList = (value) => {
  //   props?.getSubCategorys(value);
  //   props.request.category = value;
  //   props.request.subCategory = value.maintenance_sub_category?.[0]
  //   props.request.error.category = "";
  //   props.request.subCategory = [];
  //   props.setRequest(props?.request);
  // };

  // const handleAgreementList = (value) => {
  //   // props?.getUnit(value);
  //   props.request.agreement = value;
  //   props.request.unit = [];
  //   props.setRequest(props?.request);
  // };

  const loadOptions = async (search = "", array, type) => {
    setLoading1(type);
    let result,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    switch (type) {
      case "category":
        const userId = user_id;
        if (!userId?.length) {
          setLoading1(null);
          return { options: [] };
        }
        result = await NetworkCall(
          `${config.api_url}/maintenance-master/category_master`,
          NetWorkCallMethods.post,
          {
            offset,
            limit: 10,
            search
          },
          null,
          true,
          false
        )
        setLoading1(null);
        return {
          options: [...result?.data?.data],
          hasMore:
            array?.length + result?.data?.data?.length <
            result?.data?.count,
        };

      case "subCategory":
        if (!props?.request?.category?.value) {
          setLoading1(null);
          return { options: [] };
        }
        result = await NetworkCall(
          `${config.api_url}/maintenance-master/sub_category_master`,
          NetWorkCallMethods.post,
          {
            offset,
            limit: 10,
            category_id: props?.request?.category?.value ? props?.request?.category?.value : null,
            search
          },
          null,
          true,
          false
        )
        setLoading1(null);
        return {
          options: [...result?.data?.data],
          hasMore:
            array?.length + result?.data?.data?.length <
            result?.data?.count,
        };

      case "unit":
        let id = props?.request?.agreement?.units;
        if (!id?.length) {
          setLoading1(null);
          return { options: [] };
        }

        result = await NetworkCall(
          `${config.api_url}/queries/get_unit_detail_by_ids`,
          NetWorkCallMethods.post,
          {
            ids: id,
            offset,
            limit: 10,
            search,
          },
          null,
          true,
          false
        )

        setLoading1(null);
        return {
          options: [...result?.data?.data?.unit],
          hasMore:
            array?.length + result?.data?.data?.unit?.length <
            result?.data?.count[0]?.count,
        };

      default:
        return { options: [] };
    }
  };
  const getSlotMaster = (offset = 0, limit = 10, search = "") => {
    const payload = {
      offset: offset,
      limit: limit,
      search: search
    }
    NetworkCall(
      `${config.api_url}/time_slot_master/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        const result = response?.data?.data?.map((x, i) => {
          return {
            ...x,
            time: TimeIn24HrFormat[i]
          }
        })
        setSlotsData(result)
      })
      .catch((error) => {
        console.log(error)
      });
  };
  React.useEffect(() => {
    handleTopBarState({
      text: t("New Maintenance Request"),
      customBackHandle: () => props?.goBack()
    })
    if (props?.product?.selectedProduct?.name?.length > 0 && props?.product?.selectedProduct?.location_id !== null) {
      props?.updateRequestState("location", { value: props?.product?.selectedProduct?.location_id, label: props?.product?.selectedProduct?.location_name })
    }
    getSlotMaster()
    // eslint-disable-next-line
  }, []);

  const handleEdit = () => {
    props?.next(0)
    props?.updateRequestState("location", "")
  }
  const handleChangeSlot = (val) => {
    if (val?.time !== null && moment(props?.request?.preferredTime).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD")) {
      const currentTime = moment(new Date())
      let fromTime = new Date(currentTime).setHours(val?.time?.start, 0, 0, 0);
      let toTime = (new Date(currentTime)).setHours(val?.time?.end, 0, 0, 0);
      if ((fromTime <= currentTime && currentTime <= toTime) || fromTime >= currentTime) {
        props?.updateRequestState("selectedSlot", val)
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Please Choose time Slot greater than Current Time"),
        });
      }
    }
    else {
      props?.updateRequestState("selectedSlot", val)
    }
  }
  return (
    <Container maxWidth="sm" className={classes.root}>
      <Box className={classes.content} style={{ height: size?.height - (55 + 110), overflow: "scroll" }}>
        <Box p={2}>
          <Stack direction="row" alignItems="center">
            <Box style={{ marginInlineEnd: "8px" }}><ServiceAssetIcon /></Box>
            <Box>
              <Typography className={classes.serviceTitle}>{t("Service Details")}</Typography>
              <Typography className={classes.steptitle}>{t("STEP")} {props?.step} / 4</Typography>
            </Box>
          </Stack>
        </Box>
        <Box>
          <Divider />
        </Box>
        <Box p={2}>
          <Box>
            <Box className={classes.productBox}>
              {(product?.selectedProduct?.item_name?.length > 0 || product?.selectedProduct?.name?.length > 0) ?
                <>
                  <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems="center">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Avatar src={product?.selectedProduct?.assets !== null ? JSON.parse(product?.selectedProduct?.assets)?.[0]?.url : "../images/inspectionItem.svg"} variant="square" className={classes.inspectionItemIcon} />
                      <Box style={{ marginInlineStart: "8px" }}>
                        <Typography className={classes.title}>{product?.selectedProduct?.item_name ?? product?.selectedProduct?.name ?? "-"}</Typography>
                        <Typography className={classes.subTitle}>{product?.selectedProduct?.manufacturer ?? product?.selectedProduct?.manufaturer_name ?? "-"}</Typography>
                      </Box>
                    </Stack>
                    <Box style={{ cursor: "pointer" }} onClick={() => handleEdit()}>
                      <EditOutlineIcon />
                    </Box>
                  </Box>
                  <Divider />

                  <Box p={2}>
                    <Slider {...settings}>
                      {product?.selectedProduct?.location_id !== null &&
                        <Grid item xs={6} sm={6} md={6}>
                          <Stack direction="row" alignItems="center">
                            <Box style={{ marginInlineEnd: "8px" }}><LocationAssetIcon /></Box>
                            <Box>
                              <Typography className={classes.productDetails} noWrap>{product?.selectedProduct?.location_name ?? "-"}</Typography>
                              <Typography className={classes.productDetailsTitle} noWrap>{t("Location")}</Typography>
                            </Box>
                          </Stack>
                        </Grid>
                      }

                      <Grid item xs={6} sm={6} md={6}>
                        <Stack direction="row" alignItems="center">
                          <Box style={{ marginInlineEnd: "8px" }}><img src="../images/serialNumber.svg" alt="icon" /></Box>
                          <Box>
                            <Typography className={classes.productDetails} noWrap>{product?.selectedProduct?.serial_number ?? "-"}</Typography>
                            <Typography className={classes.productDetailsTitle}>{t("Serial Number")}</Typography>
                          </Box>
                        </Stack>
                      </Grid>


                      <Grid item xs={6} sm={6} md={6}>
                        <Stack direction="row" alignItems="center">
                          <Box style={{ marginInlineEnd: "8px" }}><img src="../images/modelNumber.svg" alt="icon" /></Box>
                          <Box>
                            <Typography className={classes.productDetails} noWrap>{product?.selectedProduct?.model_number ?? "-"}</Typography>
                            <Typography className={classes.productDetailsTitle}>{t("Model Number")}</Typography>
                          </Box>
                        </Stack>
                      </Grid>

                      {product?.selectedProduct?.last_service_date !== null &&
                        <Grid item xs={6} sm={6} md={6}>
                          <Stack direction="row" alignItems="center">
                            <Box style={{ marginInlineEnd: "8px" }}><LastServiceIcon /></Box>
                            <Box>
                              <Typography className={classes.productDetails} noWrap>{moment(product?.selectedProduct?.last_service_date).format("DD MMM YYYY")}</Typography>
                              <Typography className={classes.productDetailsTitle} noWrap>{t("Last Service")}</Typography>
                            </Box>
                          </Stack>
                        </Grid>
                      }
                      {product?.selectedProduct?.item_condition !== null &&
                        <Grid item xs={6} sm={6} md={6}>
                          <Box className={classes.itemBox}>
                            <Typography className={classes.itemCondition}>{product?.selectedProduct?.item_condition}</Typography>
                          </Box>
                        </Grid>
                      }
                    </Slider>
                  </Box>

                </>
                :
                <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems="center">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <NonAssetIcon />
                    <Box style={{ marginInlineStart: "8px" }}>
                      <Typography className={classes.title}>{unit?.selectedUnit?.name}</Typography>
                      <Typography className={classes.subTitle}>{unit?.selectedUnit?.unit_no}</Typography>
                    </Box>
                  </Stack>
                  <Box onClick={() => props?.next(0)} style={{ cursor: "pointer" }}>
                    <EditOutlineIcon />
                  </Box>
                </Box>
              }
            </Box>
          </Box>
          <Box mt={2}>
            <SelectBox
              label={t("Asset Location")}
              placeholder={t("Select Location")}
              onChange={(value) =>
                props?.updateRequestState("location", value)
              }
              value={props?.request?.location}
              loadOptions={(search, array, handleLoading) =>
                loadOptionsApis(
                  "inspection_item_location_master",
                  {},
                  search,
                  array,
                  handleLoading,
                  "list",
                )}
              labelColor="#071741"
              isPaginate={true}
              menuOptionHeight={200}
              isRequired
              isError={props?.request?.error?.location?.length > 0}
              errorMessage={props?.request?.error?.location}
              isDisabled={(props?.product?.selectedProduct?.name?.length > 0 && props?.product?.selectedProduct?.location_id !== null) ? true : false}
            />
          </Box>
          <Box mt={2}>
            <SelectBox
              isRequired
              label={t("Category")}
              placeholder={t("SelectCategory")}
              value={props?.request?.category}
              onChange={(value) => props?.updateRequestState("category", value, "subCategory", value?.maintenance_sub_category?.length > 0 ? value?.maintenance_sub_category?.[0] : "")}
              isError={props?.request?.error?.category?.length > 0}
              errorMessage={props?.request?.error?.category}
              isPaginate
              labelColor="#071741"
              menuOptionHeight={200}
              loadOptions={(search, array) =>
                loadOptions(search, array, "category")
              }
              loading={loading1 === "category"}
              debounceTimeout={800}
              menuPlacement={"top"}
            />
          </Box>
          <Box mt={2}>
            <SelectBox
              isRequired
              label={t("Sub-Category")}
              placeholder={t("SelectSubCategory")}
              value={props?.request?.subCategory}
              isPaginate
              key={JSON.stringify(props?.request?.category)}
              loadOptions={(search, array) =>
                loadOptions(search, array, "subCategory")
              }
              loading={loading1 === "subCategory"}
              onChange={(value) =>
                props?.updateRequestState("subCategory", value)
              }
              labelColor="#071741"
              menuOptionHeight={200}
              isError={props?.request?.error?.subCategory?.length > 0}
              errorMessage={props?.request?.error?.subCategory}
              menuPlacement={"top"}
            />
          </Box>
          <Box mt={2}>
            <TextBox
              isRequired
              label={t("Title")}
              color="#091B29"
              placeholder={t("Enter Title")}
              value={props?.request?.problemTitle}
              onChange={(value) =>
                props?.updateRequestState("problemTitle", value.target.value)
              }
              labelColor="#071741"
              isError={props?.request?.error?.problemTitle?.length > 0}
              errorMessage={props?.request?.error?.problemTitle}
            />
          </Box>
          <Box mt={2}>
            <TextBox
              isRequired
              multiline
              color="#091B29"
              label={t("Description")}
              value={props?.request?.problemDescription}
              placeholder={t("Describeprobleminbriefifany")}
              onChange={(value) =>
                props?.updateRequestState(
                  "problemDescription",
                  value.target.value
                )
              }
              labelColor="#071741"
              isError={props?.request?.error?.problemDescription?.length > 0}
              errorMessage={props?.request?.error?.problemDescription}
            />
          </Box>
          <Box mt={2}>
            <DatePickerNew
              isRequired
              disableFuture={true}
              disablePast={false}
              label={t("ProblemSince")}
              maxDate={new Date()}
              placeholder={t("ProblemSince")}
              value={props?.request?.problemSince}
              onChange={(value) =>
                props?.updateRequestState("problemSince", value)
              }
              labelColor="#071741"
              isError={props?.request?.error?.problemSince?.length > 0}
              errorMessage={props?.request?.error?.problemSince}
            />
          </Box>
          <Box mt={2}>
            <Typography className={classes.dateTime}>{t("Preferred Visit Date & Time")}</Typography>
          </Box>
          <Box mt={1}>
            <DatePickerNew
              isRequired
              // isTime
              disableFuture={true}
              disablePast={false}
              minDate={new Date()}
              label={t("Date")}
              placeholder={t("Choose Date")}
              value={props?.request?.preferredTime}
              onChange={(value) =>
                props?.updateRequestState("preferredTime", value, "selectedSlot", "")
              }
              labelColor="#071741"
              isError={props?.request?.error?.preferredTime?.length > 0}
              errorMessage={props?.request?.error?.preferredTime}
            />
          </Box>
          <Box mt={1}>
            <Typography className={classes.slotTitle}>{t("Time Slots")}<Typography variant="caption" color="error" sx={{ marginLeft: "px" }}>*</Typography></Typography>
          </Box>
          <Box mt={1}>
            <Grid container spacing={1} alignItems={"center"}>
              {slotsData?.map((x) => {
                return (
                  <Grid item xs={6} sm={4} md={3} lg={3} onClick={() => handleChangeSlot(x)}>
                    <Box className={props?.request?.selectedSlot?.value === x?.value ? classes.selectedSlotBox : classes.slotBox}>
                      <Typography className={classes.slotTime} style={{ color: props?.request?.selectedSlot?.value === x?.value ? "#5078E1" : "#091B29" }}>{x?.label}</Typography>
                    </Box>
                  </Grid>
                )
              })}

            </Grid>
          </Box>
        </Box>
      </Box>
      <Container maxWidth="sm" className={classes.btnRoot}>
        <Grid
          container
          spacing={2}
          className={classes.saveNUpdateButtonContainerStyle}
        >
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              className={classes.btn}
              onClick={() => {
                props.next(2);
              }}
            >
              {t("Next")}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("maintance")(Step1), props)