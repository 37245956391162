import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { TextBox } from "../../../components";
import BulkImageUpload from "../../../components/bulkImageUpload";
import ButtonGroupSelect from "../../../components/ButtonGroupSelect";
import { SelectBox } from "../../../components/dropDown";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, allowed_file_size, enumCatagory, enumSelect, generateImageUrl, img_size, NetWorkCallMethods, singleFileUpload } from "../../../utils";
import { WorkerContext } from "../context";
import AddWorkersCard from "./cards/addWorkersCard";
import RegWorkerList from "./cards/regWorkerList";
import { useStyles } from "./styles";

const AddWorkers = () => {
  const { t = () => false, data, setData, updateState, get_reg_worker_profiles,property, selectWorkerFormSubmit, addWorker } =
    React.useContext(WorkerContext);
    const [proofData, setProofData] = React.useState([]);
  React.useEffect(()=>{
    getEnum();
  getProofData();
  // eslint-disable-next-line
}, []);
  const [gender, setGender] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const classes = useStyles();
  const addIDProof = (imgData) => {
    updateState("select_workers", "workerIdProof", imgData,  data?.select_workers?.findIndex(x => x.view_type === "edit"));
  };
  const updateObject = (key, value, index) => {
    updateState("select_workers", key, value, index);
  };
  const uploadS3 = async (data, index) => {
    let uploaded_file = await singleFileUpload(data, { tenantId: `${config.tenantid}` }, alert, allowed_file_size)
    if (uploaded_file?.[0]?.url) {
      updateState("select_workers", "workerProfile", uploaded_file?.[0]?.url, index);
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("SomethingWentwrong"),
      });
    }
  };
  const getEnum = async () => {
    const result = await enumSelect([
      enumCatagory.sex_type,
    ]);
    setGender(result?.sex_type);
  };
  const cancelWorker = (index) => {
    if(typeof data?.select_workers?.[index]?.previous_state === "object"){
    let temp_worker = []

    for (let i = 0; i < data?.select_workers?.length; i++) {
      temp_worker = [
        ...temp_worker,
        i === index ?
          { ...data?.select_workers?.[i]?.previous_state, previous_state: "" } :
          { ...data?.select_workers[i] }
      ]
    }
    setData({ ...data, select_workers: temp_worker });
  }else{
    const temp = data?.select_workers?.filter((val,i)=>i!==index);
    setData({ ...data, select_workers: temp });
  }
}
const getProofData = () => {
  const params = {
    api: true,
    limit: null
  };
  NetworkCall(
    `${config.api_url}/queries/identification_master`,
    NetWorkCallMethods.post,
    params,
    null,
    true,
    false
  )
    .then((response) => {
      setProofData(response.data.data);
    })
    .catch(() => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("SomethingWentwrong"),
      });
    });
};

const getRegWorkers = (search)=>{
  get_reg_worker_profiles({search:search, offset:0, is_concate:false, propertyId:property?.id})
}
  return (
    data?.select_workers_object?.view_type !== "hide" &&
    <>
      {data?.select_workers?.map((worker, index) => (<>

        {worker?.view_type === "edit" &&
          <>
            <Box className={classes.root}> 
              <Box className={classes.mainPadding}>
                <Box className={classes.input}>
                  <ButtonGroupSelect
                    labelColor={"#4E5A6B"}
                    isRequired
                    index={index}
                    label={t("Add Worker Type")}
                    data={workersList}
                    state={worker}
                    stateName={"workerType"}
                    updateState={updateObject}
                  />
                </Box>
                {worker?.workerType?.label ==="New Worker" ?
              <>
                <Box className={classes.input}>
                  <Typography className={classes.label}>
                    New Worker Details{" "}
                    {/* <Typography color="error" variant="caption">
                      &nbsp;*
                    </Typography> */}
                  </Typography>
                  <center>
                    {worker?.workerProfile ? (
                      <div className={classes.imgdiv}>
                        <img
                          style={{
                            height: "100px",
                            width: "100px",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          alt=" "
                          src={generateImageUrl(worker?.workerProfile, img_size.small_square)}
                          className={classes.profileicons}
                        />

                        <CloseIcon
                          className={classes.close}
                          onClick={() => {
                            updateState("select_workers", "workerProfile", "", index);
                          }}
                        />
                      </div>
                    ) : (
                      <IconButton className={classes.profileimg}>
                        <label htmlFor="uplodebtn">
                          <PersonOutlineIcon className={classes.profileicon} />
                          <Avatar
                            sx={{ width: 24, height: 24 }}
                            className={classes.profileimgs}
                            type="file"
                          >
                            <img
                              src="/images/icons8-camera (1).svg"
                              alt=" "
                              className={classes.profileicons}
                            />
                          </Avatar>
                        </label>
                        <input
                          id="uplodebtn"
                          type="file"
                          name="img"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(value) => {
                            uploadS3(value.target.files[0], index);
                          }}
                        />
                      </IconButton>
                    )}
                    <Typography className={classes.labelError}>
                      {worker?.error?.workerProfile}
                    </Typography>
                  </center>
                </Box>
                <Box className={classes.input}>
                  <Typography className={classes.label}>
                    Worker Proof ID Picture{" "}
                    {/* <Typography color="error" variant="caption">
                      &nbsp;*
                    </Typography> */}
                  </Typography>
                  <BulkImageUpload
                    labelColor="#4E5A6B"
                    bulkUrl={worker?.workerIdProof}
                    setBulkUrl={addIDProof}
                    setDeletedImage={() => ""}
                  />
                  <Typography className={classes.labelError}>
                    {worker?.error?.workerIdProof}
                  </Typography>
                </Box>
                <Box className={classes.input}>
                  <TextBox
                    isRequired
                    color
                    labelColor={"#4E5A6B"}
                    label={"Worker Name"}
                    placeholder="Enter Worker Name"
                    value={worker?.workerName}
                    onChange={(value) =>
                      updateState("select_workers", "workerName", value.target.value, index)
                    }
                    isError={worker?.error?.workerName?.length > 0}
                    errorMessage={worker?.error?.workerName}
                  />
                </Box>
                <Box className={classes.input}>
                  <ButtonGroupSelect
                    labelColor={"#4E5A6B"}
                    isRequired
                    label={t("Worker Gender")}
                    data={gender}
                    state={worker}
                    stateName={"workerGender"}
                    updateState={updateObject}
                    index={index}
                  />
                </Box>
                <Box className={classes.input}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <SelectBox
                        isRequired
                        label={"Worker ID Type"}
                        placeholder="Enter ID Type"
                        labelColor={"#4E5A6B"}
                        value={worker?.workerId}
                        options={proofData}
                        onChange={(value) => {
                          updateState("select_workers", "workerId", value, index);
                        }}
                        isError={worker?.error?.workerId?.length > 0}
                        errorMessage={worker?.error?.workerId}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextBox
                        isRequired
                        color
                        labelColor={"#4E5A6B"}
                        label={"ID Number"}
                        placeholder="Enter ID Number"
                        value={worker?.idNumber}
                        onChange={(value) =>
                          updateState("select_workers", "idNumber", value.target.value, index)
                        }
                        isError={worker?.error?.idNumber?.length > 0}
                        errorMessage={worker?.error?.idNumber}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.input}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={worker?.groupMember}
                          onChange={() =>
                            updateState(
                              "select_workers",
                              "groupMember",
                              !data?.select_workers?.groupMember, index
                            )
                          }
                          name="groupMember"
                          icon={<RadioButtonUncheckedIcon sx={{ color: "#CED3DD" }} />}
                          checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />}
                        />
                      }
                      label={
                        <Typography component={"span"} className={classes.label}>
                          Group Member
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Box>
                </> :
                <>
                <Box className={classes.input}>
                  <TextBox
                    isRequired
                    color
                    labelColor={"#4E5A6B"}
                    label={"Search Worker"}
                    placeholder="Search Worker"
                    startAdornment={<img src="/images/icons8-search (2).svg" alt="" />}
                    onClick={() => getRegWorkers("")}
                    onChange={(value) =>
                      getRegWorkers(value.target.value)
                    }
                    // value={worker?.workerName}
                    // onChange={(value) =>
                    //   updateState("select_workers", "workerName", value.target.value, index)
                    // }
                    // isError={worker?.error?.workerName?.length > 0}
                    // errorMessage={worker?.error?.workerName}
                  />
                </Box>
                <Box className={classes.input}>
                    <RegWorkerList  index={index} state={worker} />
                </Box>
                </>
              }
              </Box>
              <Box className={classes.borderBottom} />
              <Grid
                container
                className={classes.btnroot}
                justifyContent="space-between"
                alignItems={"center"}
                spacing={0}
              >
                <Grid item xs={5}>
                  <Button
                    fullWidth
                    className={classes.secondary}
                    variant="contained"
                  onClick={() => cancelWorker(index)}
                  >
                    {t("Cancel")}
                  </Button>
                </Grid>
                <Divider
                  orientation="vertical"
                  sx={{ borderLeft: "1px solid #98A0AC", padding: "14px 0" }}
                />
                <Grid item xs={5}>
                  <Button
                    fullWidth
                    className={classes.primary}
                    variant="contained"
                    onClick={() => selectWorkerFormSubmit(index)}
                  >
                    {t("Next")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box height={"16px"} />
          </>
        }
      </>))}
      <Box className={classes.root}>
        <Stack direction={"column"} divider={<Box className={classes.borderBottom} />}>
          {data?.select_workers?.map((worker, index) => (<>
            {worker?.view_type === "view" &&
                <AddWorkersCard worker={worker} index={index}/>
            }
          </>))}
          {(data?.unit_and_schedule?.group === false && data?.select_workers?.length >= 1) && data?.select_workers?.filter(({ is_deleted }) => is_deleted === true).length !== data?.select_workers?.length ? null : (
            <Box className={classes.mainPadding}>
              <Box className={classes.addContainer} onClick={() => addWorker()}>
                <Typography className={classes.add} component={"h6"} align={"center"}>
                  + Add Another Worker
                </Typography>
              </Box>
            </Box>)}
        </Stack>
      </Box>
    </>
  );
};

export default AddWorkers;
const workersList = [
  {
    label: "New Worker",
    value: "New Worker",
  },
  {
    label: "Registered Worker",
    value: "Registered Worker",
  },
];