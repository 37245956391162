import CloseIcon from "@mui/icons-material/Close";
import { Badge, Box, Button, Container, Dialog, Drawer, Grid, Hidden, IconButton, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { SelectBox } from "../../components/dropDown";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods, remCalculate, removeDuplicatesByKey } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import Delivery from "./delivery";
import Parking from "./parking";
import Swiftinout from "./swiftinout";
import Visitors from "./visitors";
import { ListingTypeCard } from "./components/listTypeCard";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { useHistory } from "react-router-dom";
import Walkinout from "./walkinout";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F6F6F6",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  field: {
    marginTop: "12px"
  },
  submitBtn: {
    borderRadius: "4px",
    padding: "10px 8px",
    marginTop: "24px",
    boxShadow: "none",
  },
  header: {
    background: "#fff",
    padding: 16,
    boxShadow: "0px 2px 6px #00000014"
  },
  headerTitle: {
    fontSize: remCalculate(16),
    color: theme.typography.color.primary,
    textTransform: "capitalize",
    fontFamily: FontFamilySwitch().bold
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
    padding: "8px",
    height: "40px"
  },
}));
const InitialState = {
  property: "",
  unit: "",
  type: "",
  error: {
    property: "",
    unit: "",
    type: "",
  },
};
const Entries = (props) => {
  const { t = () => false } = props;
  const agreement_id = JSON.parse(localStorage.getItem(LocalStorageKeys?.agreement))?.id ?? "";
  const classes = useStyles();
  const size = useWindowDimensions();
  const [open, setOpen] = React.useState(false)
  const [property_unit_option, set_property_unit_option] = React.useState();
  const [formData, setFormData] = React.useState({ ...InitialState });
  const alert = React.useContext(AlertContext);
  const [search, setSearch] = React.useState({
    property: "",
    unit: "",
    type: "",
  })
  const [selectedUnit, setSelectedUnit] = React.useState("")
  const [selectedTab, setSelectedTab] = React.useState("")
  const [filter, setFilter] = React.useState(false);
  console.log("filter from index", filter)
  const history = useHistory()
  const backdrop = React.useContext(BackdropContext);
  const typeList = [
    {
      value: "VGR/WGR",
      label: "Visitor & Worker",
    },
    {
      value: "swift",
      label: "Swift in & Swift out"
    },
    {
      value: "PSR",
      label: "Parking slot"
    },
    {
      value: "DSR",
      label: "Delivery Collections"
    }
  ]

  const typeListing = [
    {
      id: 1,
      title: t("Gate Passes"),
      image: "/images/visitorIMG.svg",
      bgColor: "#FFF8E7"
    },
    {
      id: 2,
      title: t("Parking Slot"),
      image: "/images/parkingIMG.svg",
      bgColor: "#F1F6FF"
    },
    {
      id: 3,
      title: t("Delivery Collection"),
      image: "/images/delivery_collection_icon.svg",
      bgColor: "#F1F6FF"
    },
    {
      id: 4,
      title: t("Shift In & Out"),
      image: "/images/shift_in_out_icon.svg",
      bgColor: "#F1F6FF"
    },
    {
      id: 5,
      title: "Visitor Entry Request",
      image: "/images/visitor_entry_request_icon.svg",
      bgColor: "#F1F6FF"
    }
  ]

  const updateState = (key, value) => {
    let error = formData.error;
    error[key] = "";
    if (key === "property") {
      setFormData({ ...formData, [key]: value, unit: "", error });
    } else {
      setFormData({ ...formData, [key]: value, error });
    }
  };

  const getAggreement = (initial) => {
    NetworkCall(
      `${config.api_url}/agreement-unit-info/switcher`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    ).then((response) => {
      let agreementUnits = agreement_id
        ? response?.data?.data?.find((val) => val?.id === agreement_id)?.agreement_unit
        : response?.data?.data?.[0]?.agreement_unit;
      let temp_property_1 = agreementUnits?.length > 0 ? agreementUnits.map((agreement_unit) => {
        return {
          ...agreement_unit,
          label: agreement_unit?.property_name,
          value: agreement_unit?.property_id,
        }
      }) : []

      let temp_property_2 = removeDuplicatesByKey(temp_property_1, "value");

      let final_property = temp_property_2?.map?.((property) => {
        return {
          ...property,
          units: agreementUnits?.filter((agreement_unit_f) =>
            property?.value === agreement_unit_f?.property_id)
            ?.map((agreement_unit_m) => {
              return {
                ...agreement_unit_m,
                label: agreement_unit_m?.name,
                value: agreement_unit_m?.id
              }
            })
        }
      });

      set_property_unit_option(final_property);

      if (initial) {
        let initial_search = {
          property: final_property?.[0] ?? "",
          unit: final_property?.[0]?.units?.[0] ?? "",
          type: typeList?.[0] ?? "",
        }
        setFormData({
          ...initial_search,
          error: {
            property: "",
            unit: "",
            type: "",
          },
        })
        setSearch(initial_search)
        // handleTopBarState({
        //   data: initial_search,
        //   text: t("EntryLogs"),
        //   entries: false,
        //   handleClick: () => setOpen(true),
        //   option: property_unit_option,
        //   agreement_id: agreement_id,
        //   selectedUnit: selectedUnit,
        //   units: true,
        //   onChangeUnit: (value) => setSelectedUnit(value)
        // })
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      }
    })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };


  React.useEffect(() => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    getAggreement(true)
    getUnitData()
    // eslint-disable-next-line
  }, [])
  const isIamValide = () => {
    let isValid = true;
    let error = formData.error;
    //Checking unit
    if (formData.unit.length === 0) {
      isValid = false;
      error.unit = t("UnitisRequired");
    }
    //Checking property
    if (formData.property.length === 0) {
      isValid = false;
      error.property = t("PropertyisRequired");
    }
    //Checking name
    if (formData.type.length === 0) {
      isValid = false;
      error.type = t("TypeisRequired");
    }
    setFormData({ ...formData, error });
    return isValid;
  };
  const apply = () => {
    if (isIamValide()) {
      let temp_search = {
        property: formData.property,
        unit: formData.unit,
        type: formData.type,
      }
      setSearch(temp_search)
      // handleTopBarState({
      //   data: `temp_search`,
      //   text: t("EntryLogs"),
      //   entries: true,
      //   handleClick: () => setOpen(true)
      // })
      setOpen(false)
    } else {
      return false
    }
  }
  const manualOptions = (array) => {
    const result = array?.data?.map((e) => {
      return {
        label: `${e?.name}-(${e?.unit_no})`,
        value: e?.id,
        ...e
      }
    })
    return result
  }
  const renderSelectForm = () => {
    return (
      <div>
        <Box display="flex" alignItems="center" className={classes.titleroot} p={1}>
          <Box flexGrow={1}>
            <Typography variant="subtitle2" className={classes.bottomBarTitle}>{t("EntryLogs")}</Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setOpen(false)}><CloseIcon /></IconButton>
          </Box>
        </Box>
        <Box p={1} height="380px" overflow="auto">
          <div className={classes.field}>
            <SelectBox label={t("Property")} placeholder={t("SelectProperty")} options={property_unit_option}
              value={formData?.property}
              onChange={(value) => {
                updateState("property", value)
              }}
              isError={formData?.error?.property?.length > 0}
              errorMessage={formData?.error?.property}
            />
          </div>
          <div className={classes.field}>
            <SelectBox label={t("UnitID")} placeholder={t("SelectUnitID")} options={formData?.property?.units}
              value={formData?.unit}
              onChange={(value) => updateState("unit", value)}
              isError={formData?.error?.unit?.length > 0}
              errorMessage={formData?.error?.unit}
            />
          </div>
          <div className={classes.field}>
            <SelectBox label={t("EntryType")} placeholder={t("SelectEntryType")} options={typeList}
              value={formData?.type}
              onChange={(value) => updateState("type", value)}
              isError={formData?.error?.type?.length > 0}
              errorMessage={formData?.error?.type}
            />
          </div>
          <Button fullWidth className={classes.submitBtn} variant="contained" onClick={apply}>{t("Confirm")}</Button>
        </Box>
      </div>
    )
  }
  const goBack = () => {
    if (selectedTab !== "") {
      setSelectedTab("")
    } else {
      history.goBack()
    }
  }

  const getUnitData = () => {
    NetworkCall(
      `${config.api_url}/agreement-unit-info/agreement_unit_by_agreement`,
      NetWorkCallMethods.post,
      { agreement_id: agreement_id },
      null,
      true,
      false
    ).then((res) => {
      const resData = res?.data?.data?.data?.[0]

      if (Object.keys(resData)?.length) {
        setSelectedUnit({
          ...resData,
          label: `${resData?.name}-(${resData?.unit_no})`,
          value: resData?.id
        })
      }
    })
      .catch((error) => {
        console.log(error)
      })
  }
  return (
    <Container maxWidth="sm" className={classes.root} style={{ height: size?.height }}>
      {/* <TitleBar data={search} text={t("EntryLogs")} goBack={() => history.goBack()} entries handleClick={() => setOpen(true)} /> */}
      <Box className={classes.header}>
        <Stack direction={"row"} alignItems={"center"}>
          <IconButton size="small" onClick={() => goBack()}>
            <ArrowBackIosIcon
              fontSize="small"
              style={{ color: props?.color ? "white" : "#091B29" }}
            />
          </IconButton>
          <Box sx={{ width: "100%" }}>
            <center><Typography className={classes.headerTitle}>{selectedTab === "" ? t("EntryLogs") : `${t("EntryLogs")} - ${selectedTab?.title}`}</Typography></center>
          </Box>
        </Stack>
        <Box sx={{ width: "100%", marginTop: "8px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 1 }}>
          <Box sx={{ width: "100%" }}>
            <SelectBox
              isPaginate
              loadOptions={(search, array, handleLoading) =>
                loadOptionsApis(
                  "agreement-unit-info/agreement_unit_by_agreement",
                  {
                    agreement_id: agreement_id
                  },
                  search,
                  array,
                  handleLoading,
                  "data",
                  {},
                  manualOptions
                )}
              debounceTimeout={800}
              value={selectedUnit}
              onChange={(value) => {
                setSelectedUnit(value === null ? "" : value)
              }}
              placeholder={"Select Unit"}
              isDisabled={selectedTab !== ""}
            />
          </Box>
          {
            selectedTab?.id &&
            <Box>
              <IconButton
                className={classes.filter}
                onClick={() => setFilter(!filter)}
              >
                <Badge variant="dot" color="primary">
                  <img src="/images/icons8-funnel.svg" alt="filter" />
                </Badge>
              </IconButton>
            </Box>
          }
        </Box>
      </Box>
      {selectedTab === "" && <Grid Container p={2}>
        {
          typeListing?.map((val) => {
            return (
              <Grid item xs={12}>
                <ListingTypeCard details={val} onClick={(id) => setSelectedTab(val)} />
              </Grid>
            )
          })
        }
      </Grid>}

      {selectedTab?.id === 1 &&
        <Visitors open={open} units={search?.unit?.value} unitlist={formData?.property?.units} selectedUnit={selectedUnit} filter={filter} setFilter={setFilter} />
      }
      {selectedTab?.id === 2 &&
        <Parking units={[search.unit?.value]} unitlist={formData?.property?.units} selectedUnit={selectedUnit} filter={filter} setFilter={setFilter} />
      }
      {selectedTab?.id === 3 &&
        <Delivery units={[search.unit?.value]} unitlist={formData?.property?.units} selectedUnit={selectedUnit} filter={filter} setFilter={setFilter} />
      }
      {selectedTab?.id === 4 &&
        <Swiftinout
          units={search?.unit?.unit_id}
          unitlist={formData?.property?.units}
          property={formData?.property}
          unit={formData?.unit}
          selectedUnit={selectedUnit}
          filter={filter} setFilter={setFilter}
        />
      }
      {
        selectedTab?.id === 5 &&
        <Walkinout open={open} units={[search?.unit?.value]} unitlist={formData?.property?.units} selectedUnit={selectedUnit} filter={filter} setFilter={setFilter} />
      }
      <Hidden smDown>
        <Dialog className={classes.dialog} maxWidth="sm" fullWidth open={open} >
          {renderSelectForm()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)} className={classes.drawer}>
          {renderSelectForm()}
        </Drawer>
      </Hidden>
    </Container>
  );
};
export default withNamespaces("entriesLog")(Entries)