//import ImageViewer from "react-simple-image-viewer";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, Grid, IconButton, List, ListItem, Typography } from "@mui/material";
import React from 'react';
import Carousel, { consts } from "react-elastic-carousel";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
// import { DocumentViewer } from "../../../components/fileViewer/index";

import { BackdropContext } from "../../contexts";
import { img_size, NetWorkCallMethods } from "../../utils";
import { assestType, generateImageUrl } from '../../utils/common';
import { DocumentViewer } from "../pdfviewer";
import { filterStyles } from "./styles";
import { NetworkCall } from '../../networkcall';
import { config } from '../../config';
export const Assets = (props) => {
    const classes = filterStyles()
    const [value, setValue] = React.useState(2);
    const [listing, setlisting] = React.useState([])
    const breakPoints = [{ width: 1, itemsToShow: 1 }];
    const backdrop = React.useContext(BackdropContext);
    const [asset, setAsset] = React.useState({
        open: false,
        url: "",
        title: ""
    })
    React.useEffect(() => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Loading",
        });
        NetworkCall(
            `${config.api_url}/queries/asset_master/get`,
            NetWorkCallMethods.post,
            {},
            null,
            true,
            false
        ).then((res) => {
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "Some Thing Went Wrong",
            });
            setlisting(res.data?.data?.assets_master)
        }).catch((err) => {
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "Some Thing Went Wrong",
            });
        })
        // eslint-disable-next-line
    }, [])

    const handleList = (val) => {
        setValue(val);
    };
    const onClose = () => {
        setAsset({
            open: false,
            title: "",
            url: ""
        })
    }
    const onOpen = (title, url) => {
        setAsset({
            open: true,
            title: title,
            url: url
        })
    }
    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />
        return (
            <IconButton size="small" style={{ fontSize: "1.25rem" }} onClick={onClick} disabled={isEdge}>{pointer}</IconButton>
        )
    }
    return (
        <>
            <Box display="flex" alignItems="center" p={1} className={classes.titleBar}>
                <Box flexGrow={1}>
                    <Typography className={classes.detailTitle}>{props?.title}</Typography>
                </Box>
                <Box>
                    <IconButton size="small" onClick={props?.onClose}>
                        <CloseIcon style={{ fontSize: "1.25rem" }} />
                    </IconButton>
                </Box>
            </Box>
            <Box className={classes.imagebox1} >

                <>

                    <List style={{ display: 'flex' }} className={classes.list}>

                        {
                            listing.filter(x => x?.id !== assestType?.Thumbnails).map((val) => {
                                return (
                                    <ListItem className={val.id === value ? classes.listText : classes.list}
                                        onClick={() => handleList(val.id)}
                                    >
                                        <Typography className={classes.tabname} noWrap>{val.type}</Typography>
                                    </ListItem>
                                )
                            })
                        }
                    </List>

                    <div style={{ marginTop: "8px" }}>
                        <Grid container >
                            {
                                props?.assets?.filter(x => x?.id !== assestType?.Thumbnails)?.map((val) => {
                                    return (
                                        <>
                                            {
                                                val?.asset_type === value &&
                                                <Grid item xs={2} style={{ margin: "4px", borderRadius: "12px", overflow: "hidden" }}>
                                                    {
                                                        val?.asset_type === assestType?.Documents ?
                                                            <div style={{ height: "150px", border: "2px solid #E4E8EE", overflow: "hidden" }} onClick={() => onOpen("Pdf", val?.url)}>
                                                                <DocumentViewer url={val?.url} />
                                                            </div>
                                                            :
                                                            <>
                                                                {
                                                                    val?.asset_type === assestType?.Videos ?
                                                                        <video
                                                                            style={{
                                                                                objectFit: "cover",
                                                                                height: "150px",
                                                                                width: "100%",
                                                                                borderRadius: "12px",
                                                                            }}
                                                                            onClick={() => onOpen("Video", val?.url)}
                                                                            controls
                                                                        >
                                                                            <source src={val.url} type="video/mp4" />
                                                                        </video>
                                                                        : val?.asset_type === assestType?.Three_sixty_Images ?
                                                                            <img
                                                                                src={generateImageUrl(val.url, img_size.small_rectangle)}
                                                                                alt=""
                                                                                onClick={() => onOpen("360", val?.url)}
                                                                                style={{
                                                                                    objectFit: "cover",
                                                                                    height: "150px",
                                                                                    width: "100%",
                                                                                    borderRadius: "12px",
                                                                                    cursor: "pointer",
                                                                                    border: "2px solid #E4E8EE"

                                                                                }}
                                                                            />
                                                                            :
                                                                            val?.asset_type === assestType?.Facility_Images ?
                                                                                <img
                                                                                    src={generateImageUrl(val.url, img_size.small_rectangle)}
                                                                                    alt=""
                                                                                    onClick={() => onOpen("Facility Images", val?.url)}
                                                                                    style={{
                                                                                        objectFit: "cover",
                                                                                        height: "150px",
                                                                                        width: "100%",
                                                                                        borderRadius: "12px",
                                                                                        cursor: "pointer",
                                                                                        border: "2px solid #E4E8EE"

                                                                                    }}
                                                                                />
                                                                                :
                                                                                val?.asset_type === assestType?.General_Images ?
                                                                                    <img
                                                                                        src={generateImageUrl(val.url, img_size.small_rectangle)}
                                                                                        alt=""
                                                                                        onClick={() => onOpen("General Images", val?.url)}
                                                                                        style={{
                                                                                            objectFit: "cover",
                                                                                            height: "150px",
                                                                                            width: "100%",
                                                                                            borderRadius: "12px",
                                                                                            cursor: "pointer",
                                                                                            border: "2px solid #E4E8EE"

                                                                                        }}
                                                                                    />
                                                                                    :

                                                                                    <img
                                                                                        src={generateImageUrl(val.url, img_size.small_rectangle)}
                                                                                        alt=""
                                                                                        onClick={() => {
                                                                                            onOpen("Image", val?.url)
                                                                                        }}
                                                                                        style={{
                                                                                            objectFit: "cover",
                                                                                            height: "150px",
                                                                                            width: "100%",
                                                                                            borderRadius: "12px",
                                                                                            cursor: "pointer",
                                                                                            border: "2px solid #E4E8EE"

                                                                                        }}
                                                                                    />
                                                                }
                                                            </>

                                                    }

                                                </Grid>
                                            }
                                        </>
                                    )
                                })
                            }

                        </Grid>


                    </div>
                </>
            </Box>
            <Dialog open={asset?.open} maxWidth="sm" onClose={onClose} fullWidth className={classes.dialog} >

                <Box display="flex" alignItems="center" p={1} className={classes.titleBar}>
                    <Box flexGrow={1}>
                        <Typography className={classes.detailTitle}>{asset?.title}</Typography>
                    </Box>
                    <Box>
                        <IconButton size="small" onClick={onClose}>
                            <CloseIcon style={{ fontSize: "1.25rem" }} />
                        </IconButton>
                    </Box>
                </Box>
                <div >
                    {
                        asset?.title === "Image" &&

                        <Carousel renderArrow={myArrow} showArrows={true} breakPoints={breakPoints} >


                            {
                                props?.assets?.filter((i) => i?.asset_type === 2).map((val) => {
                                    return (
                                        <img src={generateImageUrl(val.url, img_size.medium_rectangle)} alt="" height="500px" width="100%" />
                                    )
                                })
                            }

                        </Carousel>

                    }
                    {
                        asset?.title === "Pdf" &&
                        <>
                            {
                                props?.assets?.filter((i) => i?.asset_type === 4).map((val) => {
                                    return (
                                        <div style={{ height: "500px", marginLeft: "40px" }}>
                                            <DocumentViewer url={val?.url} />
                                        </div>
                                    )
                                })
                            }</>
                        // <Carousel renderArrow={myArrow} showArrows={true} breakPoints={breakPoints}  >


                        //     {
                        //         props?.assets?.filter((i) => i?.asset_type === 4).map((val) => {
                        //             return (
                        //                 <div style={{ height: "500px" }}>
                        //                     <DocumentViewer url={val?.url} />
                        //                 </div>
                        //             )
                        //         })
                        //     }

                        // </Carousel>

                    }
                    {
                        asset?.title === "Video" &&

                        <Carousel renderArrow={myArrow} showArrows={true} breakPoints={breakPoints} >


                            {
                                props?.assets?.filter((i) => i?.asset_type === 3).map((val) => {
                                    return (

                                        <video
                                            style={{
                                                objectFit: "cover",
                                                height: "500px",
                                                width: "100%",
                                            }}
                                            controls
                                        >
                                            <source src={val.url} type="video/mp4" />
                                        </video>
                                    )
                                })
                            }

                        </Carousel>

                    }
                    {
                        asset?.title === "360" &&

                        <Carousel renderArrow={myArrow} showArrows={true} breakPoints={breakPoints} >


                            {
                                props?.assets?.filter((i) => i?.asset_type === 5).map((val) => {
                                    return (
                                        <img src={generateImageUrl(val.url, img_size.medium_rectangle)} alt="" height="500px" width="100%" />
                                    )
                                })
                            }

                        </Carousel>

                    }

                    {
                        asset?.title === "Facility Images" &&

                        <Carousel renderArrow={myArrow} showArrows={true} breakPoints={breakPoints} >


                            {
                                props?.assets?.filter((i) => i?.asset_type === assestType.Facility_Images).map((val) => {
                                    return (
                                        <img src={generateImageUrl(val.url, img_size.medium_rectangle)} alt="" height="500px" width="100%" />
                                    )
                                })
                            }

                        </Carousel>

                    }

                    {
                        asset?.title === "General Images" &&

                        <Carousel renderArrow={myArrow} showArrows={true} breakPoints={breakPoints} >


                            {
                                props?.assets?.filter((i) => i?.asset_type === assestType.General_Images).map((val) => {
                                    return (
                                        <img src={generateImageUrl(val.url, img_size.medium_rectangle)} alt="" height="500px" width="100%" />
                                    )
                                })
                            }

                        </Carousel>

                    }

                </div>
            </Dialog>

        </>
    )
}
