import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: "12px 16px",
    borderBottom:  (props) => props?.transparent ? "none":`1px solid ${theme.palette.border.secondary}`,
    boxShadow: (props) => props?.transparent ? "none":"0px 2px 6px #00000014",
    backgroundColor: (props) =>
      props?.color ? props?.color : theme.palette.background.paper,
  },
  title: {
    fontSize: "1rem",
    textTransform: "capitalize",
    fontFamily: FontFamilySwitch().bold,
  },
  arrow: {
    fontSize: "1.25rem",
  },
  id: {
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem",
    textAlign: "left"
  },
  agreement: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.palette.borderRadius,
    padding: "4px 8px",
    marginTop: "12px",
    cursor: "pointer",
  },
}));

export const TitleBar = (props) => {
  const classes = useStyles(props);
  const size = useWindowDimensions();
  return (
    <Container maxWidth="sm" style={{ padding: "0px" }}>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Box class={"navbarArrow"}>
                <IconButton size="small" onClick={props.goBack}>
                  <ArrowBackIosIcon
                    fontSize="small"
                    className={classes.arrow}
                    style={{ color: props?.color ? "white" : "#091B29" }}
                  />
                </IconButton>
              </Box>
              <Box flexGrow={1}>
                <Typography
                  className={classes.title}
                  style={{ color: props?.color ? "white" : "black", maxWidth: size?.width - 50 }}
                  noWrap
                >
                  {props?.text}
                </Typography>
              </Box>
              <Box width={"30px"} height={"30px"} />
              <Box>
                {/* {props.clearFilter && (
                <Typography className={classes.clear} onClick={props.onClear}>
                  Clear Filter
                </Typography>
              )} */}
              </Box>
            </Box>
            {props?.entries && (
              <Box display="flex" alignItems="center" className={classes.agreement} onClick={props.handleClick}>
                <Box flexGrow={1}>
                  <Typography className={classes.id}>
                    {
                      props?.data && <>{props?.data?.property?.label},{props?.data?.unit?.label}</>
                    }

                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    size="small" onClick={props.handleClick} style={{ color: "#091B29", }}><KeyboardArrowDownIcon />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Grid>
          {
            props?.isUtilities &&
            <Grid item xs={12} marginTop="8px">
              {
                props?.component
              }
            </Grid>
          }
        </Grid>
      </div>
    </Container>
  );
};
