import { Avatar, Box, Divider, Grid, Popover, Radio, Stack, Switch, Typography } from "@mui/material"
import React from "react";
import PassDetails from "../../assets/passDetails"
import { AddWorkerPassContext } from "./addWorkerPassContext"
import PassUnits from "../../assets/passUnits";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DatePickerNew, DialogDrawer, TextBox } from "../../components";
import { EntryType } from "./utils";

const Step1 = () => {
    const {
        t = () => false,
        classes,
        data,
        unitList,
        isUnitListOpen,
        setIsUnitListOpen,
        updateStateNew,
        current_date,
        handleEntryType,
        startHourMeridiemAnchorEl,
        setStartHourMeridiemAnchorEl,
        isStartHourMeridiemOpen,
        startHourMeridiemID,
        handleStartHourMeridiem,
        endHourMeridiemAnchorEl,
        setEndHourMeridiemAnchorEl,
        isEndHourMeridiemOpen,
        endHourMeridiemID,
        handleEndHourMeridiem,
    } = React.useContext(AddWorkerPassContext);

    return (
        <>
            <div className={classes.step_root}>
                <Box className={classes.visiterJourney}>
                    <div className={classes.innerPadding}>
                        {/* PASS DETAILS */}
                        <Stack direction={"row"}>
                            <Avatar variant="rounded" className={classes.avatar}>
                                <PassDetails />
                            </Avatar>
                            <Stack sx={{ marginInlineStart: "12px" }} direction={"column"} spacing={"4px"}>
                                <Typography className={classes.title}>{t("Worker Details")}</Typography>
                                <Typography className={classes.step}>{t("STEP")} 2/3</Typography>
                            </Stack>
                        </Stack>
                        <div className={classes.borederBottom}></div>
                        {/* UNIT DETAILS */}
                        <Box className={classes.input}>
                            <div className={classes.unitBox}>
                                <Stack direction={"row"} alignItems="center">
                                    <Avatar variant="rounded" className={classes.unitAvatar}>
                                        <PassUnits />
                                    </Avatar>
                                    <Box sx={{ marginInlineStart: "12px" }} flexGrow={1}>
                                        <Stack direction={"column"} spacing={"4px"}>
                                            <Typography className={classes.unitNumber}>{data?.pass_details?.unit?.unit_no}</Typography>
                                            <Typography className={classes.unitName}>{data?.pass_details?.unit?.property_name}</Typography>
                                        </Stack>
                                    </Box>
                                    {unitList?.length > 1 &&
                                        <Typography onClick={() => setIsUnitListOpen(true)} className={classes.change}>{t("Change")}</Typography>}
                                </Stack>
                            </div>
                            {data?.pass_details?.error?.unit?.length > 0 &&
                                <Typography className={classes.error} >{data?.pass_details?.error?.unit}</Typography>}
                        </Box>
                        {/* VISITOR ENTRY TYPE */}
                        <Box className={classes.input}>
                            <Typography mb={"8px"} className={classes.label}>{t("Visitor Entry Type")}</Typography>
                            <Stack direction={"column"} className={classes.stayContainer}
                                divider={<Divider variant="fullWidth" />}>
                                {EntryType(t)?.map((_) => {
                                    return (
                                        <Stack direction={"row"} alignItems="start" padding="16px 12px" style={{ display: "flex", width: "inherit" }}>
                                            <Radio
                                                checked={data?.pass_details?.entry_type?.value === _?.value}
                                                style={{ padding: "0px" }} size="small"
                                                onClick={() => handleEntryType(_)} />
                                            <Stack sx={{ marginInlineStart: "8px" }} direction={"column"} spacing={"4px"} width="100%">
                                                <Typography className={classes.label} onClick={() => handleEntryType(_)}>
                                                    {_?.label}
                                                </Typography>
                                                <Typography className={classes.info}>{_?.description}</Typography>
                                                {data?.pass_details?.entry_type?.value === _?.value &&
                                                    <Box flexGrow={1}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6}>
                                                                <DatePickerNew
                                                                    isRequired
                                                                    dateFormat={"dd-MMM-yy"}
                                                                    placeholder={_?.start_date_placeholder}
                                                                    value={data?.pass_details?.start_date}
                                                                    onChange={(value) => updateStateNew("pass_details", { _k: "start_date", _v: value })}
                                                                    isError={data?.pass_details?.error?.start_date?.length > 0}
                                                                    errorMessage={data?.pass_details?.error?.start_date}
                                                                    minDate={current_date} />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <DatePickerNew
                                                                    isRequired
                                                                    dateFormat={"dd-MMM-yy"}
                                                                    placeholder={_?.start_date_placeholder}
                                                                    value={data?.pass_details?.end_date}
                                                                    minDate={data?.pass_details?.start_date}
                                                                    onChange={(value) => updateStateNew("pass_details", { _k: "end_date", _v: value })}
                                                                    isError={data?.pass_details?.error?.end_date?.length > 0}
                                                                    errorMessage={data?.pass_details?.error?.end_date} />
                                                            </Grid>
                                                            {/* WORK HOURS FROM */}
                                                            <Grid item xs={6}>
                                                                <TextBox
                                                                    isRequired
                                                                    color
                                                                    type={"number"}
                                                                    labelColor={"#4E5A6B"}
                                                                    label={t("Work Hour Start")}
                                                                    placeholder={t("Enter Hour")}
                                                                    value={data?.pass_details?.start_hour}
                                                                    onChange={(value) => updateStateNew("pass_details", { _k: "start_hour", _v: value.target.value })}
                                                                    endAdornment={
                                                                        <Box className={classes.endAdornment}
                                                                            onClick={(e) => setStartHourMeridiemAnchorEl(e?.currentTarget)} >
                                                                            <Typography className={classes.time} noWrap>
                                                                                {data?.pass_details?.start_hour_meridiem}
                                                                            </Typography>
                                                                            <KeyboardArrowDownIcon sx={{ fontSize: "0.875rem", marginTop: "4px" }} />
                                                                        </Box>
                                                                    }
                                                                    isError={data?.pass_details?.error?.start_hour?.length > 0}
                                                                    errorMessage={data?.pass_details?.error?.start_hour} />
                                                                <Popover
                                                                    id={startHourMeridiemID}
                                                                    open={isStartHourMeridiemOpen}
                                                                    anchorEl={startHourMeridiemAnchorEl}
                                                                    onClose={() => handleStartHourMeridiem()}
                                                                    anchorOrigin={{ vertical: "bottom", horizontal: "left", }}>
                                                                    <Typography
                                                                        onClick={() => handleStartHourMeridiem(t("AM"))}
                                                                        className={classes.timeVariant}>
                                                                        {t("AM")}
                                                                    </Typography>
                                                                    <Typography
                                                                        onClick={() => handleStartHourMeridiem(t("PM"))}
                                                                        className={classes.timeVariant}>
                                                                        {t("PM")}
                                                                    </Typography>
                                                                </Popover>
                                                            </Grid>
                                                            {/* WORK HOURS TO */}
                                                            <Grid item xs={6}>
                                                                <TextBox
                                                                    isRequired
                                                                    color
                                                                    type={"number"}
                                                                    labelColor={"#4E5A6B"}
                                                                    label={t("Work Hour End")}
                                                                    placeholder={t("Enter Hour")}
                                                                    value={data?.pass_details?.end_hour}
                                                                    onChange={(value) => updateStateNew("pass_details", { _k: "end_hour", _v: value.target.value })}
                                                                    endAdornment={
                                                                        <Box className={classes.endAdornment}
                                                                            onClick={(e) => setEndHourMeridiemAnchorEl(e?.currentTarget)} >
                                                                            <Typography className={classes.time} noWrap>
                                                                                {data?.pass_details?.end_hour_meridiem}
                                                                            </Typography>
                                                                            <KeyboardArrowDownIcon sx={{ fontSize: "0.875rem", marginTop: "4px" }} />
                                                                        </Box>
                                                                    }
                                                                    isError={data?.pass_details?.error?.end_hour?.length > 0}
                                                                    errorMessage={data?.pass_details?.error?.end_hour} />
                                                                <Popover
                                                                    id={endHourMeridiemID}
                                                                    open={isEndHourMeridiemOpen}
                                                                    anchorEl={endHourMeridiemAnchorEl}
                                                                    onClose={() => handleEndHourMeridiem()}
                                                                    anchorOrigin={{ vertical: "bottom", horizontal: "left", }}>
                                                                    <Typography
                                                                        onClick={() => handleEndHourMeridiem(t("AM"))}
                                                                        className={classes.timeVariant}>
                                                                        {t("AM")}
                                                                    </Typography>
                                                                    <Typography
                                                                        onClick={() => handleEndHourMeridiem(t("PM"))}
                                                                        className={classes.timeVariant}>
                                                                        {t("PM")}
                                                                    </Typography>
                                                                </Popover>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                }
                                            </Stack>
                                        </Stack>
                                    )
                                })}
                            </Stack>
                        </Box>
                        <Box className={classes.groupWorkers}>
                            <Stack direction={"row"} justifyContent="space-between" alignItems={"center"}>
                                <Typography className={classes.label}>{t("Pass for group of workers")}</Typography>
                                <Switch checked={data?.pass_details?.is_group}
                                    onChange={() => updateStateNew("pass_details", { _k: "is_group", _v: !data?.pass_details?.is_group })} />
                            </Stack>
                            {data?.pass_details?.is_group &&
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextBox
                                            endAdornment={t("Worker")}
                                            color="#4E5A6B"
                                            type="number"
                                            placeholder={("Enter Group Count")}
                                            value={data?.pass_details?.group_count}
                                            onChange={(value) => updateStateNew("pass_details", { _k: "group_count", _v: value?.target?.value })}
                                            isError={data?.pass_details?.error?.group_count?.length > 0}
                                            errorMessage={data?.pass_details?.error?.group_count} />
                                    </Grid>
                                </Grid>
                            }
                        </Box>
                    </div>
                </Box>
            </div>
            {/* UNIT DRAWER */}
            <DialogDrawer
                open={isUnitListOpen}
                onClose={() => setIsUnitListOpen(false)}
                maxWidth="sm"
                header={t("Unit Switcher")}
                component={
                    <Box className={classes.box}>
                        {unitList?.map((value) => {
                            return (
                                <>
                                    <Stack my={"12px"} direction={"row"} alignItems="center"
                                        onClick={() => {
                                            updateStateNew("pass_details", { _k: "unit", _v: value });
                                            setIsUnitListOpen(false)
                                        }}>
                                        <Avatar variant="rounded" className={classes.unitAvatar}>
                                            <PassUnits />
                                        </Avatar>
                                        <Box sx={{ marginInlineStart: "12px" }} flexGrow={1}>
                                            <Stack direction={"column"} spacing={"4px"}>
                                                <Typography className={classes.unitNumber}>{value?.name}</Typography>
                                                <Stack direction={"row"} alignItems="center" spacing={"6px"} divider={<Box className={classes.dot}></Box>}>
                                                    <Typography className={classes.unitName}>{value?.property_name}</Typography>
                                                </Stack>
                                            </Stack>
                                        </Box>
                                        <Radio
                                            checked={data?.pass_details?.unit?.id === value?.id}
                                            sx={{ color: "#CED3DD" }} />
                                    </Stack>
                                    <Box className={classes.border} />
                                </>
                            )
                        })}
                    </Box>
                }
            />
        </>
    )
}

export default Step1;