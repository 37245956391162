import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { FontFamilySwitch } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  propertyName: {
    color: "white",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "1rem",
    width: "70%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    marginLeft: "10px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
  propertyDetail: {
    fontSize: "0.75rem",
    color: "#fff",
    textTransform: "capitalize",
    marginTop: "10px",
    fontFamily: FontFamilySwitch().bold,
    bottom: "20px",
  },
  propertyDetailsroot: {
    backgroundPosition: "100% 100%",
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      height: "260px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "185px",
    },
    borderRadius: theme.palette.borderRadius2,
    position: "relative",
    boxShadow: "0px 3px 6px #5C86CB42",

  },
  productDetails: {
    background: "linear-gradient(#091B2900, #000000C4)",
    width: "100%",
    height: "100%",
    position: "absolute",
    borderRadius: theme.palette.borderRadius2,
  },
  propertyimage: {
    objectFit: "contain",
    borderRadius: "20px",
  },
  till: {
    position: "absolute",
    color: "#fff",
    bottom: "16px",
    left: "16px",
    width: "100%",
  },
}));

const PropertyCarousel = (props) => {
  const classes = useStyles(props);
  const language = localStorage.getItem("i18nextLng")
  const settings = {
    dots: true,
    infinite: true,
    // slidesToShow: 3,
    // slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  };

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} alignItems={"center"}>
          <Slider {...settings}>
            {props.data.map((item, index) => (
              <Box
              key={index}
                className={classes.propertyDetailsroot}
                sx={{ backgroundImage: `url(${item?.asset_url})` }}
              >
                <div className={classes.productDetails}>
                  <Box className={classes.till}>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ width: "99%" }}
                    >
                      <Box flexGrow={1}>
                        <Typography sx={{ marginRight: language === "ar" && "35px" }} className={classes.propertyName} noWrap>
                          {item?.property_name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      marginTop="18px"
                      p={1}
                      sx={{ width: "65%", marginRight: language === "ar" && "30px" }}
                    >
                      <Box>
                        <center>
                          <img src="/images/units.svg" alt="units" />
                          <Typography className={classes.propertyDetail}>
                            {item?.units}&nbsp;{item?.unitName}
                          </Typography>
                        </center>
                      </Box>
                      <Box>
                        <center>
                          <img src="/images/sqft.svg" alt="sqft" />
                          <Typography className={classes.propertyDetail}>
                            {item?.total_area}&nbsp;{item?.uom_name}
                          </Typography>
                        </center>
                      </Box>
                      <Box>
                        <center>
                          <img src="/images/rent.svg" alt={item?.type} />
                          <Typography className={classes.propertyDetail}>
                            {item?.lease_period}
                          </Typography>
                        </center>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Box>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </div>
  );
};
export default PropertyCarousel;
