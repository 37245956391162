import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { DatePickerNew, DialogDrawer, TextBox } from "../../components";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ButtonGroupSelect from "../../components/ButtonGroupSelect";
import { SelectBox } from "../../components/dropDown";
import BulkImageUpload from "../../components/bulkImageUpload";
import { NetworkCall } from "../../networkcall";
import {
  AlertProps,
  allowed_file_size,
  enumCatagory,
  enumSelect,
  generateImageUrl,
  img_size,
  NetWorkCallMethods,
  singleFileUpload,
} from "../../utils";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import ImageAndPdfViewer from "../../components/assetViewer/imageAndPdfViewer";
import { withBottombar } from "../../HOCs";

const AddOccupants = (props) => {
  const InitialState = {
    occupants_id: "",
    name: "",
    gender: "",
    relationship: "",
    proofType: "",
    idNumber: "",
    note: "",
    url: "",
    bulkUrl: "",
    date_of_birth: "",
    relationship_name: "",
    error: {
      occupants_id: "",
      name: "",
      gender: "",
      relationship: "",
      proofType: "",
      idNumber: "",
      note: "",
      url: "",
      bulkUrl: "",
      date_of_birth: "",
      relationship_name: "",
    },
  };
  const classes = useStyles(props);
  const history = useHistory();
  const size = useWindowDimensions();
  let fullSize = size?.height - 140;
  const { t = () => false, handleTopBarState } = props;
  const { state } = useLocation();
  const [isEdit, setIsEdit] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const [bulkUrl, setBulkUrl] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [data, setdata] = React.useState({ ...InitialState });
  const [deletedImage, setDeletedImage] = React.useState([]);
  const [imageViewer, setImageViewer] = React.useState(false);


  const updateState = (key, value) => {
    let error = data?.error;
    error[key] = "";
    setdata({ ...data, [key]: value, error });
  };
  const alert = React.useContext(AlertContext);
  // const getProofData = () => {
  //   const params = {
  //     query: GET_IDENTIFICATION_MASTER,
  //     variable: {client: client?.id}
  //   };
  //   NetworkCall(
  //     `${config.graphql_url}`,
  //     NetWorkCallMethods.post,
  //     params,
  //     null,
  //     true,
  //     false
  //   )
  //     .then((response) => {
  //       getEnum(response.data.data.Identification_master);
  //     })
  //     .catch(() => {
  //       alert.setSnack({
  //         ...alert,
  //         open: true,
  //         severity: AlertProps.severity.error,
  //         msg: t("SomethingWentwrong"),
  //       });
  //     });
  // };

  const getProofData = () => {

    const payload = {
      search: "",
      offset: 0,
      limit: null,
      api: true
    }

    NetworkCall(
      `${config?.api_url}/queries/identification_master`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      getEnum(response?.data?.data);
    })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: ("Something Went Wrong"),
        });
      });
  };


  const getTitle = () => {
    let title = ""
    if (state?.update) {
      title = t("Save Occupants")
    } else {
      title = t("Add Occupants")
    }
    return title
  }

  // text={isEdit ? "Edit Occupants" : "Add Occupants"}

  React.useEffect(() => {
    handleTopBarState({
      text: getTitle()
    })
    getProofData();
    if (state?.update) {
      const update = state?.update;
      setUrl(update?.profile_picture);
      setIsEdit(true);
      setdata({
        ...data,
        occupants_id: update?.occupants_id,
        name: update?.occupant_name,
        gender: { label: update?.gender, value: update?.gender },
        relationship: {
          label: update?.relationship,
          value: update?.relationship,
        },
        proofType: { label: update?.proof_type, value: update?.proof_id },
        idNumber: update?.identification_proof_no,
        note: update?.notes,
        date_of_birth: update?.date_of_birth?.length > 0 ? new Date(update?.date_of_birth) : "",
        relationship_name: update?.relationship_name,
      });
      setBulkUrl(update?.identification_assets);
    } else {
      setIsEdit(false);
    }
    // eslint-disable-next-line
  }, []);

  const [optionValue, setOptionValue] = React.useState({
    sex_type: [],
    relationship_type: [],
    proof_type: [],
  });
  const isValidData = () => {
    let isValid = true;
    let error = data.error;

    if (data?.name?.length === 0) { isValid = false; error.name = t("Occupants name is required"); } else { error.name = ""; }
    if (data?.gender?.length === 0) { isValid = false; error.gender = t("Gender is required"); } else { error.gender = ""; }
    if (data?.relationship?.length === 0) { isValid = false; error.relationship = t("Relationship is required"); } else { error.relationship = ""; }
    if (data?.date_of_birth?.length === 0) { isValid = false; error.date_of_birth = t("Date of Birth is required"); } else { error.date_of_birth = ""; }

    setdata({ ...data, error });
    return isValid;
  };
  const submitData = () => {
    if (isValidData()) {
      setLoading(true);
      if (state?.update) {
        updateOccupantsData();
      } else {
        addOccupantsData();
      }
    }
  };

  const addOccupantsData = () => {
    const reqData = {
      agreement_unit_id: state?.agreement?.id,
      occupant_name: data?.name,
      notes: data?.note,
      gender: data?.gender?.value,
      relationship: data?.relationship?.value,
      profile_picture: url,
      identification_proof: data?.proofType?.value,
      identification_proof_no: data?.idNumber,
      assets: bulkUrl,
      date_of_birth: data?.date_of_birth,
      relationship_name: data?.relationship_name,
    };
    NetworkCall(
      `${config?.api_url}/occupants/create`,
      NetWorkCallMethods.post,
      reqData,
      null,
      true,
      false
    )
      .then(() => {
        goBack();
        setLoading(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Occupants added successfully"),
        });
      })
      .catch(() => {
        setLoading(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  const updateOccupantsData = () => {
    let added_assets = bulkUrl?.filter(
      (val) => !Boolean(val?.occupants_asset_id)
    );
    let deleted_assets = deletedImage.map((val) => val?.occupants_asset_id);
    const reqData = {
      agreement_unit_id: state?.agreement?.id,
      occupant_name: data?.name,
      notes: data?.note,
      gender: data?.gender?.value,
      relationship: data?.relationship?.value,
      profile_picture: url,
      identification_proof: data?.proofType?.value,
      identification_proof_no: data?.idNumber,
      deleted_assets: deleted_assets,
      occupants_id: data?.occupants_id,
      added_assets: added_assets,
      date_of_birth: data?.date_of_birth,
      relationship_name: data?.relationship_name,
    };
    NetworkCall(
      `${config?.api_url}/occupants/edit`,
      NetWorkCallMethods.post,
      reqData,
      null,
      true,
      false
    )
      .then(() => {
        goBack();
        setLoading(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Occupants added successfully"),
        });
      })
      .catch(() => {
        setLoading(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  const uploadS3 = async (data) => {
    let uploaded_file = await singleFileUpload(data, { tenantId: `${config.tenantid}` }, alert, allowed_file_size)
    if (uploaded_file?.[0]?.url) {
      setUrl(uploaded_file?.[0]?.url);
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("SomethingWentwrong"),
      });
    }
  };

  const getEnum = async (proof_type) => {
    const result = await enumSelect([
      enumCatagory.emergency_relationship_type,
      enumCatagory.sex_type,
    ]);
    setOptionValue({
      relationship_type: result?.emergency_relationship_type?.filter(
        (val) => val?.label !== "None"
      )?.map((x) => {
        return {
          ...x,
          label: t(x?.label) ?? x?.label
        }
      }),
      sex_type: result?.sex_type?.map((x) => {
        return {
          ...x,
          label: t(x?.label) ?? x?.label
        }
      }),
      proof_type: proof_type,
    });
  };
  const goBack = () => {
    // history.push({
    //   pathname: Routes.occupants,
    //   state: {
    //     main: {
    //       agreement: agreement,
    //       selected_agreement: state?.agreement
    //     },
    //   }
    // });
    history.goBack(-1);
  };
  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TitleBar
        text={isEdit ? "Edit Occupants" : "Add Occupants"}
        goBack={() => history.goBack(-1)}
      /> */}
      <div
        className={classes.mainPadding2}
        style={{ height: fullSize, overflow: "auto" }}
      >
        <Box className={classes.formContainer}>
          <div className={classes.mainPadding}>
            <div className={classes.input}>
              <center>
                {url ? (
                  <div className={classes.imgdiv}>
                    <img
                      style={{
                        height: "100px",
                        width: "100px",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                      alt=""
                      onClick={() => setImageViewer(true)}
                      src={generateImageUrl(url, img_size.small_square)}
                      className={classes.profileicons}
                    />

                    <CloseIcon
                      className={classes.close}
                      onClick={() => {
                        setUrl("");
                      }}
                    />
                  </div>
                ) : (
                  <IconButton className={classes.profileimg}>
                    <label htmlFor="uplodebtn">
                      <PersonOutlineIcon className={classes.profileicon} />
                      <Avatar
                        sx={{ width: 24, height: 24 }}
                        className={classes.profileimgs}
                        type="file"
                      >
                        <img
                          src="/images/icons8-camera (1).svg"
                          alt=" "
                          className={classes.profileicons}
                        />
                      </Avatar>
                    </label>
                    <input
                      id="uplodebtn"
                      type="file"
                      name="img"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(value) => {
                        uploadS3(value.target.files[0]);
                      }}
                    />
                  </IconButton>
                )}
              </center>
              {data?.error?.url?.length > 0 && (
                <Typography
                  component={"h6"}
                  align="center"
                  style={{ marginTop: "6px" }}
                  color="error"
                  variant="caption"
                >
                  {data?.error?.url}
                </Typography>
              )}
            </div>
            <Box height={"10px"} />
            <Typography className={classes.supTitle}>
              {t("Occupants Details")}
            </Typography>
            <div className={classes.input}>
              <TextBox
                isRequired
                color
                labelColor={"#98A0AC"}
                label={t("Occupants Name")}
                placeholder={t("Enter Occupants Name")}
                value={data.name}
                onChange={(value) => updateState("name", value.target.value)}
                isError={data?.error?.name?.length > 0}
                errorMessage={data?.error?.name}
              />
            </div>
            <div className={classes.input}>
              <ButtonGroupSelect
                isRequired
                label={t("Gender")}
                data={optionValue?.sex_type}
                state={data}
                stateName={"gender"}
                updateState={updateState}
              />
            </div>
            <div className={classes.input}>
              <ButtonGroupSelect
                isRequired
                label={t("Relationship")}
                data={optionValue?.relationship_type}
                state={data}
                stateName={"relationship"}
                updateState={updateState}
              />
            </div>
            <div className={classes.input}>
              <DatePickerNew
                isRequired
                date_format={"dd MMM yyyy"}
                disableFuture={true}
                disablePast={false}
                maxDate={new Date()}
                label={t("Date Of Birth")}
                placeholder={t("Choose Date")}
                value={data?.date_of_birth}
                onChange={(value) => updateState("date_of_birth", value)}
                labelColor="#98A0AC"
                isError={data?.error?.date_of_birth?.length > 0}
                errorMessage={data?.error?.date_of_birth}
              />
            </div>
            <div className={classes.input}>
              <TextBox
                color
                labelColor={"#98A0AC"}
                label={t("Relationship Name")}
                placeholder={t("Enter Name")}
                value={data.relationship_name}
                onChange={(value) => updateState("relationship_name", value.target.value)}
                isError={data?.error?.relationship_name?.length > 0}
                errorMessage={data?.error?.relationship_name}
              />
            </div>
            <div className={classes.input}>
              <TextBox
                color
                labelColor={"#98A0AC"}
                label={t("Note")}
                multiline={true}
                rows={3}
                placeholder={t("Enter Note")}
                value={data.note}
                onChange={(value) => updateState("note", value.target.value)}
                isError={data?.error?.note?.length > 0}
                errorMessage={data?.error?.note}
              />
            </div>
          </div>
          <Divider sx={{ borderColor: "#E4E8EE", marginBottom: "10px" }} />
          <div className={classes.mainPadding}>
            <Typography className={classes.supTitle}>
              {t("ID Proof Details")}
            </Typography>
            <div className={classes.input}>
              <SelectBox
                // isRequired
                label={t("Proof Type")}
                labelColor={"#98A0AC"}
                placeholder={t("Select Proof Type")}
                value={data.proofType}
                // loadOptions
                options={optionValue?.proof_type}
                onChange={(value) => {
                  updateState("proofType", value);
                }}
                isError={data?.error?.proofType?.length > 0}
                errorMessage={data?.error?.proofType}
              />
            </div>
            <div className={classes.input}>
              <TextBox
                // isRequired
                color
                labelColor={"#98A0AC"}
                label={t("ID Number")}
                placeholder={t("Enter ID Number")}
                value={data.idNumber}
                onChange={(value) =>
                  updateState("idNumber", value.target.value)
                }
                isError={data?.error?.idNumber?.length > 0}
                errorMessage={data?.error?.idNumber}
              />
            </div>
          </div>
          <Divider sx={{ borderColor: "#E4E8EE", marginBottom: "10px" }} />
          <div className={classes.mainPadding}>
            <Typography className={classes.label}>
              {t("ID Proof Attachment")}
            </Typography>
            <BulkImageUpload
              bulkUrl={bulkUrl}
              setBulkUrl={setBulkUrl}
              setDeletedImage={setDeletedImage}
            />
          </div>
        </Box>
      </div>
      <Grid Container p={2} sx={{ backgroundColor: "#fff" }}>
        <Grid item xs={12}>
          <Button
            fullWidth
            onClick={submitData}
            className={classes.primary}
            variant="contained"
            disabled={loading}
          >
            {isEdit ? t("Save Occupants") : t("Save Changes")}
          </Button>
        </Grid>
      </Grid>
      <DialogDrawer
        open={imageViewer}
        onClose={() => setImageViewer(false)}
        isnotTitle={true}
        padding={0}
        overflow={"overlay"}
        component={
          <ImageAndPdfViewer
            assets={url}
            onClose={() => setImageViewer(false)}
          />
        }
      />
    </Container>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("occupants")(AddOccupants), props);
