import {
  Backdrop, Box, Button, CircularProgress, Container, Grid, Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import "../../App.css";
import { TextBox } from "../../components";
// import { SelectBox } from "../../components/dropDown";
// import MyGoogleMap from "../../components/interactiveMap/myGoogleMaps";
import { withNamespaces } from "react-i18next";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { withBottombar } from "../../HOCs";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  AlertProps,
  FontFamilySwitch,
  // city,
  // country,
  LocalStorageKeys,
  NetWorkCallMethods,
  RetainLocalStorage,
} from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.tertiary1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  screen: {
    borderRadius: "4px",
    border: "1px solid #F5F7FA",
    background: theme.palette.background.tertiary1,
  },
  screenScrollEffect: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  iconButtonStyle: {
    height: "44px",
    padding: "12px 16px 12px 16px",
    borderRadius: "4px",
    color: "#5078E1",
    background: "#5078E1",
    backgroundColor: "#5078E1",
    boxShadow: "0px 2px 6px #00000021",
  },
  addAddressButtonTextStyle: {
    fontSize: "0.875rem",
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  addAddressButtonContainerStyle: {
    padding: "16px",
    background: "white",
    border: "1px solid #E4E8EE",
    boxShadow: "0px -7px 20px #0717410D",
    // position: "fixed",
    width: "-webkit-fill-available",
  },
  addressBoxStyle: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    padding: "12px",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "100%",
    backgroundPositionY: "100%",
  },
  addressFieldsContainerStyle: {
    padding: "24px 16px 32px 16px",
    background: "white",
    // borderRadius: "16px 16px 0px 0px",
    border: "1px solid #E4E8EE",
    boxShadow: "0px -7px 20px #0717410D",
    // position: "fixed",
    width: "100%",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  addressFieldsContainerScrollEffect: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const AddAddress = (props) => {
  const { t = () => false, handleTopBarState } = props
  const classes = useStyles();
  const history = useHistory();
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const roleDate = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))
  const [initialData, setInitialData] = React.useState({
    userProfileID: "",
    contactID: "",
    firstName: "",
    lastName: "",
    profileImageID: "",
    profileImage: "",
    userType: "Tenant",
    clientRoleNo: "",
    primaryMobileNo: "",
    alternativeMobileNo: "",
    primaryEmailID: "",
    alternativeEmailID: "",
    address: "",
    addressLineOne: "",
    addressLineTwo: "",
    addressLineThree: "",
    district: { label: "", value: "" },
    city: "",
    state: "",
    zipCode: "",
    country: "",
    latitude: "",
    longitude: "",
  });
  // const [initial, setInitial] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const goBack = () => {
    window.history.go(-1);
  };
  const onAddressLine1Change = (event) => {
    setInitialData({
      ...initialData,
      addressLineOne: event.target.value,
    });
  };
  const onAddressLine2Change = (event) => {
    setInitialData({
      ...initialData,
      addressLineTwo: event.target.value,
    });
  };
  const onAddressLine3Change = (event) => {
    setInitialData({
      ...initialData,
      addressLineThree: event.target.value,
    });
  };
  const onCityChange = (value) => {
    setInitialData({
      ...initialData,
      city: value,
    });
  };
  const onStateChange = (value) => {
    setInitialData({
      ...initialData,
      state: value,
    });
  };
  const onCountryChange = (value) => {
    setInitialData({
      ...initialData,
      country: value,
    });
  };
  const onPincodeChange = (event) => {
    setInitialData({
      ...initialData,
      zipCode: event.target.value,
    });
  };
  // const mapResult = (data) => {
  //   if (!initial && data.result) {
  //     initialData.addressLineOne = "";
  //     initialData.addressLineTwo = "";
  //     initialData.addressLineThree = "";
  //     initialData.city.label = "";
  //     initialData.city.value = "";
  //     initialData.state.label = "";
  //     initialData.state.value = "";
  //     initialData.country.label = "";
  //     initialData.country.value = "";
  //     initialData.zipCode = "";
  //     initialData.latitude = data.lat;
  //     initialData.longitude = data.lng;
  //     for (let i = 0; i < data.result.address_components.length; i++) {
  //       switch (data.result.address_components[i].types[0]) {
  //         case "establishment":
  //           if (initialData.addressLineOne !== "") {
  //             initialData.addressLineOne =
  //               initialData.addressLineOne +
  //               " " +
  //               data.result.address_components[i].long_name;
  //           } else {
  //             initialData.addressLineOne =
  //               data.result.address_components[i].long_name;
  //           }
  //           break;
  //         case "street_number":
  //           if (initialData.addressLineOne !== "") {
  //             initialData.addressLineOne =
  //               initialData.addressLineOne +
  //               " " +
  //               data.result.address_components[i].long_name;
  //           } else {
  //             initialData.addressLineOne =
  //               data.result.address_components[i].long_name;
  //           }
  //           break;
  //         case "premise":
  //           if (initialData.addressLineOne !== "") {
  //             initialData.addressLineOne =
  //               initialData.addressLineOne +
  //               " " +
  //               data.result.address_components[i].long_name;
  //           } else {
  //             initialData.addressLineOne =
  //               data.result.address_components[i].long_name;
  //           }
  //           break;
  //         case "neighborhood":
  //           if (initialData.addressLineTwo !== "") {
  //             initialData.addressLineTwo =
  //               initialData.addressLineTwo +
  //               " " +
  //               data.result.address_components[i].long_name;
  //           } else {
  //             initialData.addressLineTwo =
  //               data.result.address_components[i].long_name;
  //           }
  //           break;
  //         case "route":
  //           if (initialData.addressLineTwo !== "") {
  //             initialData.addressLineTwo =
  //               initialData.addressLineTwo +
  //               " " +
  //               data.result.address_components[i].long_name;
  //           } else {
  //             initialData.addressLineTwo =
  //               data.result.address_components[i].long_name;
  //           }
  //           break;
  //         case "political":
  //           if (initialData.addressLineThree !== "") {
  //             initialData.addressLineThree =
  //               initialData.addressLineThree +
  //               " " +
  //               data.result.address_components[i].long_name;
  //           } else {
  //             initialData.addressLineThree =
  //               data.result.address_components[i].long_name;
  //           }
  //           break;
  //         case "locality":
  //           if (initialData.addressLineThree !== "") {
  //             initialData.addressLineThree =
  //               initialData.addressLineThree +
  //               " " +
  //               data.result.address_components[i].long_name;
  //           } else {
  //             initialData.addressLineThree =
  //               data.result.address_components[i].long_name;
  //           }
  //           break;
  //         case "administrative_area_level_2":
  //           initialData.city.label =
  //             data.result.address_components[i].long_name;
  //           initialData.city.value =
  //             data.result.address_components[i].long_name;
  //           break;
  //         case "administrative_area_level_1":
  //           initialData.state.label =
  //             data.result.address_components[i].long_name;
  //           initialData.state.value =
  //             data.result.address_components[i].long_name;
  //           break;
  //         case "country":
  //           initialData.country.label =
  //             data.result.address_components[i].long_name;
  //           initialData.country.value =
  //             data.result.address_components[i].long_name;
  //           break;
  //         case "postal_code":
  //           initialData.zipCode = data.result.address_components[i].long_name;
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //   } else {
  //     setInitial(false);
  //   }
  //   setInitialData({
  //     ...initialData,
  //   });
  // };
  React.useEffect(() => {
    handleTopBarState({
      text: "Address"
    })
    if (authToken !== null && authToken !== "") {
      const payload = {
        userID: localStorage.getItem(LocalStorageKeys.userProfileId),
        roleID: roleDate?.[0]?.id
      };
      NetworkCall(
        `${config.api_url}/queries/user_profile/get`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          const otherDetails = response?.data?.data;

          const userProfile =
            response?.data?.data?.user_profiles?.[0] !== null
              ? response?.data?.data?.user_profiles?.[0]
              : null;
  
          if (userProfile !== null) {
            initialData.userProfileID =
              userProfile?.id !== null ? userProfile?.id : "";
            initialData.contactID =
              userProfile?.contact_id !== null ? userProfile?.contact_id : "";
            initialData.firstName =
              userProfile?.first_name !== null ? userProfile?.first_name : "";
            initialData.lastName =
              userProfile?.last_name !== null ? userProfile?.last_name : "";
            initialData.primaryMobileNo =
              userProfile?.mobile_no !== null ? userProfile?.mobile_no : "";
            initialData.alternativeMobileNo =
              userProfile?.alternative_mobile_no !== null
                ? userProfile?.alternative_mobile_no
                : "";
            initialData.primaryEmailID =
              userProfile?.email_id !== null ? userProfile?.email_id : "";
            initialData.alternativeEmailID =
              userProfile?.alternative_email_id !== null
                ? userProfile?.alternative_email_id
                : "";
  
            if (otherDetails !== null) {
              if (
                otherDetails?.assets !== null &&
                otherDetails?.assets?.length > 0
              ) {
                initialData.profileImageID =
                  otherDetails?.assets?.[0]?.id !== null
                    ? otherDetails?.assets?.[0]?.id
                    : "";
                initialData.profileImage =
                  otherDetails?.assets?.[0]?.url !== null
                    ? otherDetails?.assets?.[0]?.url
                    : "";
              }
              if (
                otherDetails?.client_user_roles !== null &&
                otherDetails?.client_user_roles?.length > 0
              ) {
                initialData.clientRoleNo =
                  otherDetails?.client_user_roles?.[0]?.client_role_no !==
                    null
                    ? otherDetails?.client_user_roles?.[0]?.client_role_no
                    : "";
              }
              if (
                otherDetails?.contact !== null &&
                otherDetails?.contact?.length > 0
              ) {
                initialData.addressLineOne =
                  otherDetails?.contact?.[0]?.street_1 !== null
                    ? otherDetails?.contact?.[0]?.street_1
                    : "";
                initialData.addressLineTwo =
                  otherDetails?.contact?.[0]?.street_2 !== null
                    ? otherDetails?.contact?.[0]?.street_2
                    : "";
                initialData.addressLineThree =
                  otherDetails?.contact?.[0]?.street_3 !== null
                    ? otherDetails?.contact?.[0]?.street_3
                    : "";
  
                initialData.district =
                  otherDetails?.contact?.[0]?.district !== null
                    ? otherDetails?.contact?.[0]?.district
                    : "";
  
                initialData.city =
                  otherDetails?.contact?.[0]?.city !== null
                    ? otherDetails?.contact?.[0]?.city
                    : "";
  
                initialData.state =
                  otherDetails?.contact?.[0]?.state !== null
                    ? otherDetails?.contact?.[0]?.state
                    : "";
                initialData.country =
                  otherDetails?.contact?.[0]?.country !== null
                    ? otherDetails?.contact?.[0]?.country
                    : "";
  
                initialData.zipCode =
                  otherDetails?.contact?.[0]?.zipcode !== null
                    ? otherDetails?.contact?.[0]?.zipcode
                    : "";
                initialData.latitude =
                  otherDetails?.contact?.[0]?.latitude !== null
                    ? parseFloat(otherDetails?.contact?.[0]?.latitude)
                    : 0.0;
                initialData.longitude =
                  otherDetails?.contact?.[0]?.longitude !== null
                    ? parseFloat(otherDetails?.contact?.[0]?.longitude)
                    : 0.0;
                initialData.address =
                  (otherDetails?.contact?.[0]?.street_1 !== null &&
                    otherDetails?.contact?.[0]?.street_1 !== ""
                    ? otherDetails?.contact?.[0]?.street_1
                    : "") +
                  (otherDetails?.contact?.[0]?.street_2 !== null &&
                    otherDetails?.contact?.[0]?.street_2 !== ""
                    ? ", " + otherDetails?.contact?.[0]?.street_2
                    : "") +
                  (otherDetails?.contact?.[0]?.street_3 !== null &&
                    otherDetails?.contact?.[0]?.street_3 !== ""
                    ? ", " + otherDetails?.contact?.[0]?.street_3
                    : "") +
                  (otherDetails?.contact?.[0]?.district !== null &&
                    otherDetails?.contact?.[0]?.district !== ""
                    ? ", " + otherDetails?.contact?.[0]?.district
                    : "") +
                  (otherDetails?.contact?.[0]?.city !== null &&
                    otherDetails?.contact?.[0]?.city !== ""
                    ? ", " + otherDetails?.contact?.[0]?.city
                    : "") +
                  (otherDetails?.contact?.[0]?.state !== null &&
                    otherDetails?.contact?.[0]?.state !== ""
                    ? ", " + otherDetails?.contact?.[0]?.state
                    : "") +
                  (otherDetails?.contact?.[0]?.country !== null &&
                    otherDetails?.contact?.[0]?.country !== ""
                    ? ", " + otherDetails?.contact?.[0]?.country
                    : "") +
                  (otherDetails?.contact?.[0]?.zipcode !== null &&
                    otherDetails?.contact?.[0]?.zipcode !== ""
                    ? ", " + otherDetails?.contact?.[0]?.zipcode
                    : "");
              }
              setInitialData({ ...initialData });
              setLoading(false);
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some thing went wrong"),
          });
        });
    } else {
      RetainLocalStorage();
      history.push(Routes.welcome);
    }
    // eslint-disable-next-line
  }, []);

  const saveAddress = () => {
    const updateAddressPayload = {
      id: initialData?.contactID,
      update: {
        street_1: initialData?.addressLineOne,
        street_2: initialData?.addressLineTwo,
        street_3: initialData?.addressLineThree,
        district: initialData?.district.value,
        city: initialData?.city,
        state: initialData?.state,
        zipcode: initialData?.zipCode,
        country: initialData?.country,
        latitude: initialData?.latitude?.toString(),
        longitude: initialData?.longitude?.toString(),
      }
    }
    NetworkCall(
      `${config.api_url}/queries/contact/update`,
      NetWorkCallMethods.post,
      updateAddressPayload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Address Updated Successfully."),
        });
        goBack();
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing Went wrong"),
        });
      });
  };


  return (
    <div className={classes.root}>
      <Container maxWidth="sm" style={{ padding: "0px" }}>
        {loading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            {" "}
            <Grid
              className={classes.screen}
              style={{ height: size?.height }}
              item
              xs={12}
            >
              {/* <TitleBar text={t("Address")} goBack={goBack} /> */}
              <Grid
                className={classes.screenScrollEffect}
                style={{ height: size?.height - (78 + 55) }}
              >
                {/* <Grid style={{ height: "315px", width: "100%" }}>
                  <MyGoogleMap
                    lat={initialData?.latitude}
                    lng={initialData?.longitude}
                    center={{
                      lat: initialData?.latitude,
                      lng: initialData?.longitude,
                    }}
                    mapResult={mapResult}
                  />
                </Grid> */}
                <Grid className={classes.addressFieldsContainerStyle}>
                  <Typography
                    style={{
                      fontFamily:
                        FontFamilySwitch().bold,
                      fontSize: "1rem",
                      color: "#091B29",
                    }}
                  >
                    {t("Address")}
                  </Typography>
                  <Grid>
                    <Box height="16px" />
                    <TextBox
                      label={t("AddressLine1")}
                      value={initialData?.addressLineOne}
                      onChange={onAddressLine1Change}
                    />
                    <Box height="16px" />
                    <TextBox
                      label={t("AddressLine2")}
                      value={initialData?.addressLineTwo}
                      onChange={onAddressLine2Change}
                    />
                    <Box height="16px" />
                    <TextBox
                      label={t("AddressLine3")}
                      value={initialData?.addressLineThree}
                      onChange={onAddressLine3Change}
                    />
                    <Box height="16px" />
                    <Grid container direction={"row"}>
                      <Grid xs={6} style={{ paddingLeft: "8px" }}>
                        <TextBox
                          label={t("City")}
                          placeholder={t("City")}
                          value={initialData?.city}
                          onChange={(e) => onCityChange(e.target.value)}
                        />
                      </Grid>
                      <Grid xs={6} style={{ paddingLeft: "8px" }}>
                        <TextBox
                          label={t("State")}
                          placeholder={t("State")}
                          value={initialData?.state}
                          onChange={(e) => onStateChange(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <Box height="16px" />
                    <Grid container direction={"row"}>
                      <Grid xs={6} style={{ paddingLeft: "8px" }}>
                        <TextBox
                          label={t("Country")}
                          placeholder={t("Country")}
                          value={initialData?.country}
                          onChange={(e) => onCountryChange(e.target.value)}
                        />
                      </Grid>
                      <Grid xs={6} style={{ paddingLeft: "8px" }}>
                        <TextBox
                          label={t("Pincode")}
                          fullWidth
                          value={initialData?.zipCode}
                          onChange={onPincodeChange}
                        />
                      </Grid>
                    </Grid>
                    <Box height="16px" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} className={classes.addAddressButtonContainerStyle}>
                <Button
                  onClick={saveAddress}
                  variant="contained"
                  fullWidth
                  className={classes.iconButtonStyle}
                >
                  <Typography className={classes.addAddressButtonTextStyle}>
                    {t("Add Address")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </div>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("addAddress")(AddAddress), props)