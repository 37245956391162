import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import { format } from "date-fns";
import moment from "moment-timezone";
import React, { useEffect, useRef } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import { withBottombar } from "../../HOCs";
import ArrowRight from "../../assets/arrowRightCircle";
import { DetailsIcon } from "../../assets/detailsIcon";
import { EditOutlineIcon } from "../../assets/editOutlineIcon";
import { DialogDrawer } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./style";
import ImageAndPdfViewer from "../../components/assetViewer/imageAndPdfViewer";
import MicIcon from '@mui/icons-material/Mic';
import { VideoCard } from "./videoCard";
const useUnload = (fn) => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args) => cb.current?.(...args);

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);
};
const Step4 = (props) => {
  const match = useMediaQuery('(min-width:602px)')
  const { t = () => false, handleTopBarState } = props
  const [imageViewer, setImageViewer] = React.useState(false);
  const language = localStorage.getItem("i18nextLng")
  const history = useHistory();
  const classes = useStyles();
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const [open, setOpen] = React.useState(false)
  const [disable, setDisable] = React.useState(null);
  const goBack = () => {
    history.goBack(-1);
  };

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: match ? 3 : 2.5,
    slidesToScroll: 2,
    autoplay: false,
    speed: 1000,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  };
  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });
  const userProfileId = localStorage.getItem("userProfileId");
  const saveMaintenanceRequest = () => {
    setDisable(true);
    const payload = {
      tenantId: `${config.tenantid}`,
      type: 2,
      subject: props?.request?.problemTitle,
      description: props?.request?.problemDescription,
      category: props?.request?.category?.value,
      sub_category: props?.request?.subCategory?.value,
      problem_since: props?.request?.problemSince,
      preferred_time: props?.request?.preferredTime,
      contact_name: props?.request?.contactName,
      contact_mobile: props?.request?.primaryNumber,
      mobile_country_code: props?.request?.primaryCountryCode,
      contact_alternative_mobile: props?.request?.alternateNumber?.length > 0 ? props?.request?.alternateNumber : undefined,
      alternative_mobile_country_code: props?.request?.alternateNumberCountry?.length > 0 ? props?.request?.alternateNumberCountryCode : undefined,
      image_urls: props?.images?.map((x) => {
        return {
          url: x.url,
          "file_meta": {
            "file_name": x?.name ?? null,
            "file_size": x?.size ?? null,
            "file_type": x?.type ?? null
          }
        }
      }),
      units: [props?.unit?.selectedUnit?.value],
      userProfileId,
      inspection_mapping_id: props?.product?.selectedProduct?.id ?? null,
      agreement_unit_id: props?.unit?.selectedUnit?.agreement_id,
      propertyId: props?.unit?.selectedUnit?.property?.value,
      companyId: props?.unit?.selectedUnit?.property?.company?.value,
      is_active: true,
      location_id: props?.request?.location?.value ?? null,
      priority: "medium",
      slot_id: props?.request?.selectedSlot?.value ?? undefined,
      slot_name: props?.request?.selectedSlot?.label ?? undefined
    };
    NetworkCall(
      `${config.api_url}/request/create`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("RequestCreatedsuccessfully"),
        });
        setDisable(false);
        goBack();
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
        setDisable(false);
      });
  };

  React.useEffect(() => {
    handleTopBarState({
      text: t("New Maintenance Request"),
      customBackHandle: () => props?.goBack()
    })
    // eslint-disable-next-line
  }, []);
  const handleroutes = (type) => {
    history.push({
      pathname: Routes.maintenanceParent,
      state: {
        maintenanceRequestType: type
      }
    })
    props?.next(0)
  }

  return (
    <div>
      <Container maxWidth="sm" className={classes.root}>
        <Box className={classes.content} style={{ height: size?.height - (55 + 110), overflow: "scroll" }}>
          <Box p={2}>
            <Stack direction="row" alignItems="center">
              <Box style={{ marginInlineEnd: "8px" }}><DetailsIcon /></Box>
              <Box>
                <Typography className={classes.serviceTitle}>{t("Service Details")}</Typography>
                <Typography className={classes.steptitle}>{t("STEP")} {props?.step} / 4</Typography>
              </Box>
            </Stack>
          </Box>
          <Box>
            <Divider />
          </Box>
          <Box p={2}>
            {(props?.product?.selectedProduct?.item_name?.length > 0 || props?.product?.selectedProduct?.name?.length > 0) &&
              <Box className={classes.productBox}>
                <>
                  <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems="center">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Avatar src={props?.product?.selectedProduct?.assets !== null ? JSON.parse(props?.product?.selectedProduct?.assets)?.[0]?.url : "../images/inspectionItem.svg"} variant="square" className={classes.inspectionItemIcon} />
                      <Box style={{ marginInlineStart: "8px" }}>
                        <Typography className={classes.title}>{props?.product?.selectedProduct?.item_name ?? props?.product?.selectedProduct?.name ?? "-"}</Typography>
                        <Typography className={classes.subTitle}>{props?.product?.selectedProduct?.manufacturer ?? props?.product?.selectedProduct?.manufaturer_name ?? "-"}</Typography>
                      </Box>
                    </Stack>
                    <Box style={{ cursor: "pointer" }} onClick={() => setOpen(true)}>
                      <EditOutlineIcon />
                    </Box>
                  </Box>
                  <Divider />

                  <Box p={2}>
                    <Slider {...settings}>
                      {props?.product?.selectedProduct?.location_id !== null &&
                        <Grid item xs={6} sm={6} md={6}>
                          <Stack direction="row" alignItems="center">
                            <Box style={{ marginInlineEnd: "8px" }}><img src="../images/locationAsset.svg" alt="icon" /></Box>
                            <Box>
                              <Typography className={classes.productDetails} noWrap>{props?.product?.selectedProduct?.location_name ?? "-"}</Typography>
                              <Typography className={classes.productDetailsTitle} noWrap>{t("Location")}</Typography>
                            </Box>
                          </Stack>
                        </Grid>
                      }

                      <Grid item xs={6} sm={6} md={6}>
                        <Stack direction="row" alignItems="center">
                          <Box style={{ marginInlineEnd: "8px" }}><img src="../images/serialNumber.svg" alt="icon" /></Box>
                          <Box>
                            <Typography className={classes.productDetails} noWrap>{props?.product?.selectedProduct?.serial_number ?? "-"}</Typography>
                            <Typography className={classes.productDetailsTitle} noWrap>{t("Serial Number")}</Typography>
                          </Box>
                        </Stack>
                      </Grid>


                      <Grid item xs={6} sm={6} md={6}>
                        <Stack direction="row" alignItems="center">
                          <Box style={{ marginInlineEnd: "8px" }}><img src="../images/modelNumber.svg" alt="icon" /></Box>
                          <Box>
                            <Typography className={classes.productDetails} noWrap>{props?.product?.selectedProduct?.model_number ?? "-"}</Typography>
                            <Typography className={classes.productDetailsTitle} noWrap>{t("Model Number")}</Typography>
                          </Box>
                        </Stack>
                      </Grid>

                      {props?.product?.last_service_date !== null &&
                        <Grid item xs={6} sm={6} md={6}>
                          <Stack direction="row" alignItems="center">
                            <Box style={{ marginInlineEnd: "8px" }}><img src="../images/lastServiceAsset.svg" alt="icon" /></Box>
                            <Box>
                              <Typography className={classes.productDetails} noWrap>{moment(props?.product?.last_service_date).format("DD MMM YYYY")}</Typography>
                              <Typography className={classes.productDetailsTitle} noWrap>{t("Last Service")}</Typography>
                            </Box>
                          </Stack>
                        </Grid>
                      }
                      {props?.product?.selectedProduct?.item_condition !== null &&
                        <Grid item xs={6} sm={6} md={6}>
                          <Box className={classes.itemBox} mt={0.5}>
                            <Typography className={classes.itemCondition}>{props?.product?.selectedProduct?.item_condition}</Typography>
                          </Box>
                        </Grid>
                      }
                    </Slider>
                  </Box>

                </>
              </Box>
            }
            <Box mt={1}>
              <Typography className={classes.problemTitle}>
                {props?.request?.problemTitle}
              </Typography>
            </Box>
            <Box mt={1}>
              {props?.request?.problemDescription?.split('\n').map((item, i) => <Typography className={classes.subTitle} key={i}>{item}</Typography>)}
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop="16px"
            >
              <Box>
                <Typography className={classes.productvalue}>
                  {t("ProblemSince")}
                </Typography>
                <Stack direction={"row"} alignItems={"center"}>
                  <img src="/images/icons8_calendar.svg" alt="img" />
                  &nbsp;&nbsp;
                  <Typography className={classes.subTitleValues}>
                    &#x202a;{`${format(new Date(props?.request?.problemSince), language === "en" ? "dd MMM yyyy" : "dd MMM yyyy")}`}&#x202c;
                  </Typography>
                </Stack>
              </Box>
              {props?.unit?.selectedUnit?.label?.length > 0 &&
                <Box>
                  <Typography className={classes.productvalue}>{t("Unit")}</Typography>
                  <Stack direction={"row"} alignItems={"center"}>
                    <img src="/images/build.svg" alt="img" />
                    &nbsp;&nbsp;
                    <Typography className={classes.subTitleValues}>
                      {props?.unit?.selectedUnit?.label}
                    </Typography>
                  </Stack>
                </Box>
              }
              <Box>
                <Typography className={classes.productvalue}>
                  {t("RaisedOn")}
                </Typography>
                <Stack direction={"row"} alignItems={"center"}>
                  <img src="/images/icons8_calendar.svg" alt="img" />
                  &nbsp;&nbsp;
                  <Typography className={classes.subTitleValues}>
                    &#x202a;{moment(Date()).tz(moment.tz.guess()).format("DD MMM YY")}&#x202c;
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Box>
          <Box mt={1}>
            <Divider />
          </Box>
          {props?.request?.selectedImages?.length > 0 &&
            <>
              <Box p={2}>
                <Typography className={classes.productvalue}>Images</Typography>
                <Box>
                  <Stack direction={"row"} spacing={2} flexWrap={"wrap"}>
                    {props?.request?.selectedImages?.map((val, index) => {
                      return (
                        <Box onClick={() => setImageViewer(true)}>
                          {val?.file_meta?.file_type === "mp4"
                            ? (val?.blob
                              ? <VideoCard
                                muted={true}
                                style={{
                                  height: "75px",
                                  width: "75px",
                                  borderRadius: "6px",
                                  marginTop: "12px",
                                }}
                                videoBlob={val?.blob} />
                              : <Box height={"75px"} width={"75px"} marginTop={"12px"} />)
                            : val?.file_meta?.file_type === "mpeg"
                              ? (val?.url
                                ? <Box
                                  style={{
                                    height: "75px",
                                    width: "75px",
                                    borderRadius: "6px",
                                    marginTop: "12px",
                                    border: "1px solid grey",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}>
                                  <MicIcon fontSize="large" htmlColor="#5078e1" />
                                </Box>
                                : <Box height={"75px"} width={"75px"} marginTop={"12px"} />)
                              : <img
                                height={75}
                                width={75}
                                style={{ borderRadius: "6px", marginTop: "12px" }}
                                src={val?.url}
                                alt="img"
                              />}
                        </Box>
                      );
                    })}
                  </Stack>
                </Box>
              </Box>
              <Box mt={1} mb={1}>
                <Divider />
              </Box>
            </>
          }

          <Box p={2}>
            <Typography className={classes.productvalue}>
              {t("PreferredVisitDateAndTime")}
            </Typography>
            <Stack direction={"row"} alignItems={"center"} mt={0.5}>
              <img src="/images/icons8_calendar.svg" alt="img" />
              &nbsp;&nbsp;
              <Typography className={classes.subTitleValues}>
                &#x202a;{`${format(new Date(props?.request?.preferredTime), language === "en" ?
                  "dd MMM yyyy" : "dd MMM yyyy")}`}&#x202c;
              </Typography>
              <Typography className={classes.subTitleValues}>
                {` , ${props?.request?.selectedSlot?.label}`}
              </Typography>
            </Stack>
          </Box>
          <Box mt={1}>
            <Divider />
          </Box>
          <Box p={2}>
            <Typography className={classes.productvalue}>{t("Contact Details")}</Typography>
            <Box display={"flex"} justifyContent={"space-between"} alignItems="center" mt={1}>
              <Stack direction="row" alignItems="center">
                <Box style={{ marginInlineEnd: "8px" }}><Avatar src={props?.request?.contact_image} /></Box>
                <Box>
                  <Typography className={classes.unitTitle}>{props?.request?.contactName}</Typography>
                  <Box display={"flex"} alignItems="center" mt={0.5}>
                    {props?.request?.primaryNumber?.length > 0 &&
                      <Typography className={classes.productDetailsTitle}>{props?.request?.primaryCountryCode + " " + props?.request?.primaryNumber}</Typography>

                    }
                    {props?.request?.alternateNumber?.length > 0 &&
                      <>
                        <Box className={classes.verticalBorder}></Box>
                        <Typography className={classes.productDetailsTitle}>{props?.request?.alternateNumberCountryCode + " " + props?.request?.alternateNumber}</Typography>
                      </>
                    }
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>

        <Container maxWidth="sm" className={classes.btnRoot}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                className={classes.btnPrevious}
                onClick={() => props.previous(3)}
              >
                {t("Previous")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                className={classes.btn}
                onClick={saveMaintenanceRequest}
                disabled={disable}
              >
                {t("Submit")}
              </Button>
            </Grid>
          </Grid>
          {/* <br /> */}
        </Container>
        <DialogDrawer
          // height={"300px"}
          maxWidth={"sm"}
          open={open}
          onClick={() => setOpen(false)}
          header={t("Choose Another Asset / Item")}
          component={
            <>
              <Paper elevation={0}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6} sm={6} mt={1}>
                      <Box className={classes.scanAssetBox} style={{ backgroundPosition: language === "ar" ? "10%" : "90%" }} position="relative" onClick={() => handleroutes("scan")}>
                        <Typography className={classes.assettext}>{t("Scan Asset / Item QR Code")}</Typography>
                        <Box className={classes.fixbtn}><ArrowRight /></Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6} sm={6} mt={1}>
                      <Box className={classes.searchAssetBox} style={{ backgroundPosition: language === "ar" ? "10%" : "90%" }} position="relative" onClick={() => handleroutes("search")}>
                        <Typography className={classes.assettext}>{t("Search Asset / Item")}</Typography>
                        <Box className={classes.fixbtn}><ArrowRight /></Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </>
          }
          onClose={() => setOpen(false)}
        />

      </Container>
      {/* Image Viewer */}
      <DialogDrawer
        open={imageViewer}
        onClose={() => setImageViewer(false)}
        isnotTitle={true}
        padding={0}
        overflow={"overlay"}
        component={
          <ImageAndPdfViewer
            title={t("Assets")}
            assets={props?.request?.selectedImages?.map((val) => {
              return {
                meta_data: {
                  file_type: val?.file_meta?.file_type ?? "image",
                },
                file_url: val?.url,
                blob: val?.blob,
              };
            })}
            onClose={() => setImageViewer(false)}
          />
        }
      />

    </div>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("maintance")(Step4), props);