import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useLocation } from "react-router-dom";
import { TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { withBottombar } from "../../HOCs";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, enumCatagory, enumSelect, FontFamilySwitch, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    padding: "0px",
  },
  rootCard: {
    backgroundColor: "white",
    borderRadius: "4px",
    boxShadow: "0px 3px 16px #F2F5FA",
  },
  img: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  title: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary,
  },
  sub: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.Tertiary,
  },
  approved: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
    display: "inline",
  },
  progress: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: "4px",
    display: "inline",
  },
  received: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#FF9340",
    padding: "1px 8px",
    display: "inline",
    borderRadius: "4px",
  },
  receivedcollected: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
    display: "inline",
    borderRadius: "4px",
  },
  round: {
    color: "#F2F5FA",
    fontSize: "40px",
  },
  border: {
    border: "1px solid #E4E8EE",
    marginTop: "-4px",
    backgroundColor: "#E4E8EE",
  },
  heading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "10px",
  },
  name: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "4px 8px 8px 8px",
  },

  cancelbtn: {
    padding: "12px",
    border: "1px solid #5078E1",
    backgroundColor: "white",
    borderRadius: "4px",
    textTransform: "capitalize"

  },

  response: {
    backgroundColor: "#FFF4EB",
    color: "#FF9340",
    textAlign: "center",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  qr: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  submitbtn: {
    borderRadius: "4px",
    padding: "12px 8px",
    textTransform: "capitalize"

  },
  delete: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: "4px",
    display: "inline",
  },
}));
const InitialState = {
  remarks: "",
  error: {
    remarks: "",
  },
};
const DeliveryDetails = ({ t = () => false, handleTopBarState }) => {
  const size = useWindowDimensions();
  const searchURL = useLocation().search;
  const classes = useStyles();
  const [details, setDetails] = React.useState([]);
  const passId = new URLSearchParams(searchURL).get("passId");
  const [canceldata, setCancelData] = React.useState({ ...InitialState });
  const [cancel, setCancel] = React.useState(false);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const [enumValue, setenumValue] = React.useState({
    status: {},
    type: {}
  });
  const getPassDetails = () => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_id: passId,
    };
    NetworkCall(
      `${config.api_url}/security/request`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setDetails(response.data.data[0]);
        backdrop.setBackDrop({ ...backdrop, open: false });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  const getEnum = async (data) => {
    const result = await enumSelect([enumCatagory?.delivery_status_type, enumCatagory?.security_request_type])
    let requestSourceMap = result?.security_request_type.map((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestType = Object.keys(requestSourceMap).map(key => requestSourceMap[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})

    let requestSourceMapStatus = result?.delivery_status_type.map((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestTypeStatus = Object.keys(requestSourceMapStatus).map(key => requestSourceMapStatus[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})



    setenumValue({ ...enumValue, status: requestTypeStatus, type: requestType })
  }
  React.useEffect(() => {
    handleTopBarState({
      text: t("Delivery Order Collection")
    })
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    getEnum()
    getPassDetails();
    // eslint-disable-next-line
  }, []);

  const updateState = (key, value) => {
    let error = canceldata.error;
    error[key] = "";
    setCancelData({ ...canceldata, [key]: value, error });
  };
  const isIamValideTostep = () => {
    let isValid = true;
    let error = canceldata.error;
    //Checking Pass Type
    if (canceldata.remarks.length === 0) {
      isValid = false;
      error.remarks = t("RemarksisRequired");
    }

    setCancel({ ...canceldata, error });
    return isValid;
  };
  const cancelPass = () => {
    if (isIamValideTostep()) {
      const data = {
        id: passId,
        update: {
          delivery_status: "Cancelled",
          cancel_remarks: canceldata?.remarks,
        }
      };
      NetworkCall(
        `${config.api_url}/queries/security_request/updateByID`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          getPassDetails();
          setCancel(false);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
    } else {
      return false;
    }
  };
  const renderForm = () => {
    return (
      <div style={{ padding: "12px" }}>
        <Box display="flex" alignItems="center" className={classes.titleroot}>
          <Box flexGrow={1}>
            <Typography variant="subtitle2" className={classes.bottomBarTitle}>
              {t("CancelDelivery")}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setCancel(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box height="12px" />
        <TextBox
          color
          label={t("Remarks")}
          placeholder={t("EnterRemarks")}
          value={canceldata.remarks}
          multiline
          onChange={(value) => updateState("remarks", value.target.value)}
          isError={canceldata?.error?.remarks?.length > 0}
          errorMessage={canceldata?.error?.remarks}
        />
        <Box height="12px" />
        <Button
          fullWidth
          className={classes.submitbtn}
          variant="contained"
          onClick={cancelPass}
        >
          {t("Submit")}
        </Button>
      </div>
    );
  };

  return (
    <Container className={classes.root} maxWidth="sm" sx={{ padding: "0px" }}>
      {/* <TitleBar
        text={t("DeliveryOrderCollection")}
        goBack={() => history.goBack()}
      /> */}
      <Grid
        container
        className={classes.screenScrollEffect}
        style={{
          height:
            details?.delivery_status === "Yet To Receive"
              ? size?.height - 140
              : size?.height - 60,
          overflow: "scroll",
        }}
      >
        <Grid item xs={12} sx={{ padding: "12px 6px 12px 12px" }}>
          <div className={classes.rootCard}>
            <Box display="flex" alignItems="center" p={3}>
              <Box className={classes.img}>
                <img
                  src="/images/deliverycard.svg"
                  alt=""
                  style={{ objectFit: "contain", marginTop: "4px" }}
                />
              </Box>
              <Box flexGrow={1} marginLeft="14px">
                <Typography variant="subtitle2" className={classes.title}>
                  {details?.name}
                </Typography>
                <Typography variant="subtitle2" className={classes.sub}>
                  {t("Requestedon")}{" "}
                  {moment(details?.request_from)
                    .tz(moment.tz.guess())
                    .format("DD MMM")}
                </Typography>


                {details?.delivery_status === "Yet To Receive" && (
                  <Typography
                    variant="subtitle2"
                    noWrap
                    className={classes.progress}
                  >
                    {details?.delivery_status}
                  </Typography>
                )}
                {details?.delivery_status === "Approved" && (
                  <Typography
                    variant="subtitle2"
                    className={classes.approved}
                    style={{ backgroundColor: "#CED3DD" }}
                  >
                    {details?.delivery_status}
                  </Typography>
                )}
                {details?.delivery_status === "Received At Gate" && (
                  <Typography
                    variant="subtitle2"
                    className={classes.received}
                  >
                    {details?.delivery_status}
                  </Typography>
                )}
                {details?.delivery_status === "Collected" && (
                  <>
                    <Typography
                      variant="subtitle2"
                      className={classes.receivedcollected}
                    >
                      {details?.delivery_status}
                    </Typography>
                  </>
                )}
                {details?.delivery_status === "Cancelled" && (
                  <>
                    <Typography
                      variant="subtitle2"
                      className={classes.delete}
                    >
                      {details?.delivery_status}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
            {/* -------------------- */}
            {details?.delivery_status === "Collected" && (
              <Box
                className={classes.response}
                style={{
                  padding: "4px",
                  marginBottom: "26px",
                  backgroundColor: "#EEF9EE",
                  color: "#5AC782",
                }}
              >
                <Typography
                  className={classes.response}
                  style={{
                    backgroundColor: "#EEF9EE",
                    color: "#5AC782",
                  }}
                >
                  {t("Collectedby")} {details?.delivery_collected_by} on{" "}
                  {moment(details?.delivery_collected_date)
                    .tz(moment.tz.guess())
                    .format("DD MMM YY hh:mm a")}
                </Typography>
              </Box>
            )}
            {details?.delivery_status === "Received At Gate" && (
              <>
                <Box
                  className={classes.response}
                  style={{
                    padding: "4px",
                    marginBottom: "26px",
                  }}

                >
                  <Typography className={classes.response}>
                    {t("ReceivedBy")} {details?.delivery_collected_by} On{" "}
                    {moment(details?.received_date)
                      .tz(moment.tz.guess())
                      .format("DD MMM YY hh:mm a")}
                  </Typography>
                </Box>
              </>
            )}
            {details?.delivery_status === "Approved" && (
              <Box
                className={classes.response}
                style={{
                  padding: "4px",
                  marginBottom: "26px",
                  backgroundColor: "#F2F4F7",
                }}
              >
                <Typography
                  className={classes.response}
                  style={{
                    backgroundColor: "#F2F4F7",
                    color: "#4E5A6B",
                  }}
                >
                  {t("Canceledby")} {details?.requested_by}
                </Typography>
              </Box>
            )}
            <Box display="flex" alignItems="center" marginTop="-20px">
              <Box style={{ overflow: "hidden" }}>
                <CircleIcon
                  className={classes.round}
                  style={{ marginLeft: "-20px" }}
                />
              </Box>
              <Box flexGrow={1}>
                <Box className={classes.border} />
              </Box>
              <Box style={{ overflow: "hidden" }}>
                <CircleIcon
                  className={classes.round}
                  style={{ marginRight: "-20px" }}
                />
              </Box>
            </Box>
            <Box p={3} marginTop="-28px">
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("REFERENCEID")}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title}>
                    {details?.request_no}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("GATEENTRY")}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title}>
                    {details?.gate}
                  </Typography>
                  {details?.delivery_status === "Cancelled" &&
                    details?.cancel_remarks ? (
                    <>
                      <Typography
                        variant="subtitle2"
                        className={classes.heading}
                      >
                        {t("REMARKS")}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.title}>
                        {details?.cancel_remarks}
                      </Typography>
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("UNIT")}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title}>
                    {details?.unit?.[0]?.name}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("TENTATIVEDELIVERY")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    noWrap
                  >
                    {moment(details?.request_from)
                      .tz(moment.tz.guess())
                      .format("DD MMM YY hh:mm a")}
                  </Typography>
                </Grid>
              </Grid>
              <br />
            </Box>
            {details?.delivery_status === "Received At Gate" ||
              details?.delivery_status === "Collected" ? (
              <>
                <Box display="flex" alignItems="center" marginTop="-20px">
                  <Box flexGrow={1}>
                    <Box className={classes.border} />
                  </Box>
                </Box>
                <Box p={3} marginTop="-14px">
                  <Grid container>
                    <Grid item xs={7}>
                      <Typography
                        variant="subtitle2"
                        className={classes.heading}
                      >
                        {t("RECEIVEDBY")}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.title}>
                        {details?.delivery_status === "Received At Gate" ? details?.delivery_collected_by : details?.requested_by}
                      </Typography>
                      {
                        details?.received_date &&
                        <>
                          <Typography
                            variant="subtitle2"
                            className={classes.heading}
                          >
                            {t("RECEIVEDTIME")}
                          </Typography>
                          <Typography variant="subtitle2" className={classes.title}>
                            {moment(details?.received_date)
                              .tz(moment.tz.guess())
                              .format("DD MMM YY hh:mm a")}
                          </Typography>
                        </>
                      }

                    </Grid>

                    <Grid item xs={5}>
                      <Typography
                        variant="subtitle2"
                        className={classes.heading}
                      >
                        {t("RECEIVEDGATE")}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.title}>
                        {details?.collected_access_gate ?? "-"}
                      </Typography>
                    </Grid>
                    {
                      details?.remarks && (
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle2"
                            className={classes.heading}
                          >
                            {t("REMARKS")}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                          >
                            {details?.remarks}
                          </Typography>
                        </Grid>
                      )}
                  </Grid>
                  <br />
                </Box>
              </>
            ) : (
              ""
            )}
            {details?.delivery_status === "Collected" ? (
              <>
                <Box display="flex" alignItems="center" marginTop="-20px">
                  <Box flexGrow={1}>
                    <Box className={classes.border} />
                  </Box>
                </Box>
                <Box p={3} marginTop="-14px">
                  <Grid container>
                    <Grid item xs={7}>
                      <Typography
                        variant="subtitle2"
                        className={classes.heading}
                      >
                        {t("COLLECTEDBY")}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.title}>
                        {details?.delivery_collected_by}
                      </Typography>
                      {
                        details?.delivery_collected_date &&
                        <>
                          <Typography
                            variant="subtitle2"
                            className={classes.heading}
                          >
                            {t("COLLECTEDTIME")}
                          </Typography>
                          <Typography variant="subtitle2" className={classes.title}>
                            {moment(details?.delivery_collected_date)
                              .tz(moment.tz.guess())
                              .format("DD MMM YY hh:mm a")}
                          </Typography>
                        </>
                      }

                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        variant="subtitle2"
                        className={classes.heading}
                      >
                        {t("COLLECTEDGATE")}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.title}>
                        {details?.collected_access_gate}
                      </Typography>
                    </Grid>
                    {details?.collected_remarks && (
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2"
                          className={classes.heading}
                        >
                          {t("REMARKS")}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          {details?.collected_remarks}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <br />
                </Box>
              </>
            ) : (
              ""
            )}
          </div>
        </Grid>
      </Grid>
      {details?.delivery_status === "Yet To Receive" && (
        <div
          style={{
            padding: "12px",
            backgroundColor: "white",
            boxShadow: "0px -1px 6px #00000021",
          }}
        >
          <Button
            fullWidth
            className={classes.cancelbtn}
            onClick={() => setCancel(true)}
          >
            {t("CancelService")}
          </Button>
        </div>
      )}
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={cancel}
          onClose={() => setCancel(false)}
        >
          {renderForm()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer1}
          anchor="bottom"
          open={cancel}
          onClose={() => setCancel(false)}
        >
          {renderForm()}
        </Drawer>
      </Hidden>
    </Container>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("deliveryInfo")(DeliveryDetails), props)