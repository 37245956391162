import React from "react"
import InventoryCard from "./components/inventoryCard"
import { useStyles } from "./style"
import { withBottombar } from "../../HOCs"
import { withNamespaces } from "react-i18next"
import { Avatar, Box, Container, Divider, Stack, Typography } from "@mui/material"
import Laundry from "../../assets/laundry"
import { useLocation } from "react-router"
import { Routes } from "../../router/routes"

const InspectionItemList = ({ t = () => false, handleTopBarState }) => {

    const classes = useStyles();
    const state = useLocation()?.state;

    React.useEffect(() => {
        handleTopBarState({
            text: t("Inspection"),
            customBackpath: Routes.inventoryAndServices + `?unit_id=${state?.unit?.unit?.unit_id}`
        })
        // eslint-disable-next-line 
    }, [])

    const item_condition_class = {
        default: classes.default,
        New: classes.new,
        Refurbished: classes.used,
        Used: classes.used,
        Repaired: classes.repaired,
        Obselete: classes.repaired,
        Damaged: classes.damaged,
        Poor: classes.damaged,
        EndOfLife: classes.damaged
    }

    return (
        <Container maxWidth="sm" className={classes.root}>
            <div style={{ padding: "16px" }}>
                <div className={classes.serviceBox}>
                    <InventoryCard data={state?.data} t={t} />
                </div>
                <Box mt={"20px"} mb={2}>
                    <Typography className={classes.quantities}>{t("Details")}</Typography>
                </Box>
                <div className={classes.quantityBox}>
                    {state?.data?.addon_items?.map((_) => {
                        return (<>
                            <Stack my={"12px"} direction={"row"} spacing={"14px"} alignItems={"center"}>
                                <Avatar className={classes.avatar}>
                                    <Laundry />
                                </Avatar>
                                <Box flexGrow={1}>
                                    <Stack direction={"column"} spacing={"4px"}>
                                        <Typography className={classes.company}>{_?.manufacturer_name}</Typography>
                                        <Stack direction={"row"} spacing={"6px"}>
                                            <Typography className={classes.model}>{t("Model")}:{" "}{_?.model_number ?? "-"}</Typography>
                                            <Box className={classes.divider} />
                                            <Typography className={classes.model}>{t("Serial")}:{" "}{_?.serial_number ?? "-"}</Typography>
                                        </Stack>
                                    </Stack>
                                </Box>
                                {_?.item_condition !== null &&
                                    <Typography className={item_condition_class[_?.item_condition] ?? item_condition_class?.default}>{_?.item_condition}</Typography>
                                }
                            </Stack>
                            <Divider />
                        </>
                        )
                    })}
                </div>
            </div>
        </Container>
    )
}

const props = {
    top_bar: "title_bar",
}

export default withBottombar(withNamespaces("inspection")(InspectionItemList), props);