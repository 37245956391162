import { Box } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { NewAnnouncementCard } from "./components";
import {useHistory} from "react-router-dom"
import { Routes } from "../../../router/routes";

export const CarouselSliders = (props) => {
    const history=useHistory()
    var settings = {
        dots: true,
        infinite: false,
        // slidesToShow: 3,
        // slidesToScroll: 1,
        // autoplay: autoScroll,
        speed: 1000,
        // autoplaySpeed: 2000,
        cssEase: "linear",
        arrows: false
    };
    const { list } = props;

   
    const handleEventNews = (data) =>{
        if(data.redirect_url!==null){
        history.push(`${data.redirect_url}`)
        }
        else{
            history.push(
                Routes.announcementDetails + "?Id=" + data?.id
            )
        }
    }
    return (
        <Box>
            <Slider {...settings}>
                {list?.map((_, index) =>
                (

                    <NewAnnouncementCard
                        data={_}
                        isCommunity={props?.isCommunity}
                        isAll={props?.isAll}
                        hideDot={true}
                        viewNotification={handleEventNews}
                    // logo={_?.description?.length > 0 ? _?.description[0]?.images : '/images/announsmentnot.svg'}
                    // category={_?.category ? _?.category : "-"}
                    // title={_?.title ? _?.title : "-"}
                    // postedOn={_?.triggered_at ? `Posted at ${moment(new Date(_?.triggered_at)).fromNow()}` : "-"}
                    // onClickSlider={() => onClickSlider(_)}
                    />

                ))
                }
            </Slider>
        </Box>

    );
};
