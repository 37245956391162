import Box from "@mui/material/Box";
// import styled from '@mui/material/styles/styled';
// import useTheme from '@mui/material/styles/useTheme';
import React from "react";

import { Avatar, Stack, Typography, useMediaQuery } from "@mui/material";
import { t } from "i18next";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { CommentsIcon } from "../../../../assets/commentIcon";
import { FilledLikeIcon } from "../../../../assets/likeFilledIcon";
import { LikeIcon } from "../../../../assets/likeIcon";
import { Routes } from "../../../../router/routes";
import { stringAvatar } from "../../../../utils";
import { useStyles } from "./style";

// const CustomImage = styled('img')(({ theme }) => ({
//     width: 73,
//     height: 73,
//     borderRadius: 12
// }))

export const NewAnnouncementCard = ({ data = {}, isAll = false, isCommunity = false, addLike = () => false,viewNotification = () => false, hideDot = false  }) => {
    const classes = useStyles()
    const history = useHistory()
    const match = useMediaQuery('(min-width:602px)')
    return (
        <Box className={classes.root} height={isCommunity ? "300px" : (match ? "420px" : "320px")} margin={isAll ? "0px 0px 12px 0px" : "0px 12px 12px 12px"} position={"relative"}>
             {(!data?.is_read && !hideDot && !isCommunity) && <Box className={classes.unreadDot}></Box>}
            <Box onClick={() => isCommunity ?
                history.push(
                    Routes.discussions + "?discussionId=" + data?.id
                )
                : viewNotification(data)
            }>
                <Box display="flex" justifyContent={"space-between"} alignItems="center">
                    <Stack direction={"row"} spacing={1} alignItems="center">
                        <Avatar src={data?.profile_url} {...stringAvatar(data?.first_name ?? null)} />
                        <Box>
                            <Typography className={classes.first_name}>{data?.first_name ?? "-"}</Typography>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography className={classes.posted_at}>{data?.category ?? data?.unit_name}</Typography>
                                <Box className={classes.dot} />
                                <Typography className={classes.posted_at} noWrap>{moment(new Date(data?.triggered_at)).fromNow()}</Typography>
                            </Stack>
                        </Box>
                    </Stack>
                    <Box className={classes.attachBox}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            {/* <AttachFile sx={{fontSize:"12px"}}/> */}
                            <Typography className={classes.attachFile} noWrap>{`${data?.description?.map((x) => { return x?.images })?.filter((val) => (val !== "" && val !== null))?.length ?? 0} Attachment`}</Typography>
                        </Stack>
                    </Box>
                </Box>
                <Box mt={1}>
                    <Typography className={classes.title}>{data?.title}</Typography>
                </Box>
                <Box mt={0.5}>
                    {data?.description !== null && (data?.description?.[0]?.Description !== "" && data?.description?.[0]?.Description !== null) ?
                        <Box>
                            <Typography className={classes.description} >
                                {data?.description?.[0]?.Description?.replace(/(<([^>]+)>)/gi, "")?.substring(0, match ? 165 : 100)?.replace(/&nbsp;/g, ' ') + `${(match ? data?.description?.[0]?.Description?.length > 165 : data?.description?.[0]?.Description?.length > 100) ? "..." : ""}`}
                                <span className={classes.seeMoreLessTextStyle}>
                                    {(match ? data?.description?.[0]?.Description?.length > 165 : data?.description?.[0]?.Description?.length > 100) ?
                                        "Show More" :
                                        ""}
                                </span>
                            </Typography>
                            {/* {item?.move_out_details?.discrepancy_note?.split('\n').map((item, i) => <Typography className={classes.subtitle2} key={i}>{item}</Typography>)} */}
                        </Box>
                        :
                        <Box display={"flex"} justifyContent={"center"} height="70px" mt={2}>
                            <Typography className={classes.description}>{t("No Description Found")}</Typography>
                        </Box>
                    }
                </Box>
                {!isCommunity &&
                    <>
                        <Box className={classes.bottomImagefix}>
                            {(data?.description !== null && data?.description?.[0]?.images !== "" && data?.description?.[0]?.images !== null) ?
                                <img src={data?.description?.[0]?.images} alt="announcement_image" height={match ? "255px" : "155px"} width="100%" />
                                :
                                <img src={"../images/announcementPlaceholder.png"} width="100%" height={match ? "255px" : "165px"} alt="placeholder" />
                            }
                        </Box>
                    </>
                }
            </Box>
            {isCommunity &&
                <Box mt={2}>
                    {data?.assets?.length > 0 ?
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            {data?.assets?.map((x) => {
                                return (
                                    // <Grid item xs={3}>
                                    <Avatar src={x?.url} alt="assets" variant="square" style={{ width: "70px", height: "70px", borderRadius: "4px" }} />
                                    // </Grid>
                                )
                            })}
                        </Stack>
                        :
                        <Box display="flex" justifyContent={"center"}>
                            <Typography className={classes.description}>{t("No Assets Found")}</Typography>
                        </Box>
                    }
                </Box>
            }
            {isCommunity &&
                <Box className={classes.bottomFix}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Box display={"flex"} alignItems={"center"}>
                            <Box onClick={() => addLike(data)}>
                                {data?.liked_by?.some(
                                    (val) => val === localStorage.getItem("userProfileId")
                                ) ? <FilledLikeIcon />
                                    : <LikeIcon />}
                            </Box>
                            <Box>
                                <Typography className={classes.likeIcon}>{data?.reactions ?? 0}</Typography>
                            </Box>
                        </Box>
                        <Box display={"flex"} alignItems={"center"}>
                            <CommentsIcon />
                            <Typography className={classes.likeIcon}>{data?.comments ?? 0}</Typography>
                        </Box>
                    </Stack>
                </Box >
            }
        </Box >
    );
};
