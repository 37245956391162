import AddIcon from "@mui/icons-material/Add";
import { Box, Container, Divider, Fab, Grid, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { LoderSimmer, TextBox, UseDebounce } from "../../components";
import Bicycle from "../../components/svg/bicycle";
import { AlertContext } from "../../contexts";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, generateImageUrl, img_size, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import jwt_decode from "jwt-decode";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F6F6F6",
    overflow: "hidden",
  },
  tag: {
    padding: "3px 6px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#5D427F",
    backgroundColor: "#F1E6FE",
    borderRadius: "4px"
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "4px",
      height: 50,
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
    "& .MuiOutlinedInput-root": {
      paddingRight: "14px !important",
    }
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  roots: {
    margin: "0px 12px",
    padding: "0px 4px",
  },
  image: {
    objectFit: "fill",
    borderRadius: theme.palette.borderRadius2,
    height: "48px",
    width: "48px"
  },

  card: {
    backgroundColor: "white",
    padding: "12px",
    marginTop: "12px",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 4px #00000029",
    cursor: "pointer",
  },
  name: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  total: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
    marginTop: "12px",
  },
  arrow: {
    border: "1px solid #E4E8EE",
    backgroundColor: "#F2F4F7",
    fontSize: "0.75rem",
  },
  vehicleNoStyle: {
    color: "#4E5A6B",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
  },
  unitStyle: {
    color: "#4E5A6B",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    margin: "0px 6px 0px 12px"
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      bottom: "35px",
      right: "35px",
    },
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "35px",
      right: "35px",
    },
  },
}));

const Vehicle = (props) => {
  const { t = () => false, handleTopBarState } = props
  const classes = useStyles();
  const history = useHistory();
  const debounce = UseDebounce()
  const size = useWindowDimensions();
  const [datas, setDatas] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const location = useLocation();
  const alert = React.useContext(AlertContext);
  const [search, setSearch] = React.useState("")
  const [offset, setOffset] = React.useState(0);
  const limit = 20;
  const searchURL = location.search;
  const jwt = new URLSearchParams(searchURL).get("vehicle_data");
  const parking_slot_id = new URLSearchParams(searchURL).get("parkingSlotID")
  const token = jwt && jwt_decode(jwt)
  const [aggrementIdList, setAggrementIdList] = React.useState([]);
  React.useEffect(() => {
    handleTopBarState({
      text: t("Vehicles")
    })
    getAggrement();

    if (jwt) {
      history.push({ pathname: Routes.viewVehicle, state: { main: { parkingSlotID: token?.parkingSlotID } } })
    } else if (parking_slot_id) {
      history.push({ pathname: Routes.viewVehicle, state: { main: { parkingSlotID: parking_slot_id } } })
    }
    // eslint-disable-next-line
  }, []);

  const getVehicleList = async (search, offset, limit, isSearch, aggrementId) => {
    const payload = {
      agreement_ids: aggrementId,
      parkingStatus: "Occupied",
      search,
      offset,
      limit,
    }

    NetworkCall(
      `${config.api_url}/queries/vehicle/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      if (isSearch) {
        setDatas(response?.data?.data?.parking_slot);
      } else {
        setDatas(datas.concat(response?.data?.data?.parking_slot));
      }
    }).catch((error) => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("SomethingWentwrong"),
      });
    });

    setLoading(false);
  }

  const handleSearch = (value) => {
    setLoading(true)
    setSearch(value)
    setOffset(0);
    debounce(() => getVehicleList(value, 0, limit, true, aggrementIdList), 800)
  }

  const fetchMoreData = () => {
    setOffset(offset + limit);
    getVehicleList(search, offset + limit, limit, false);
  };
  const getAggrement = () => {
    NetworkCall(
      `${config.api_url}/agreement-unit-info/switcher `,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    ).then((response) => {
      const tempId = response?.data?.data?.map(val => val?.id)
      setAggrementIdList(tempId);
      getVehicleList(search, offset, limit, true, tempId);
    });
  }
  return (
    <Container className={classes.root} maxWidth="sm">
      <div
        style={{
          height: size?.height - 60,
          overflow: "overlay",
        }}
        className={classes.roots}
      >
        <Box display="flex" alignItems="center" style={{ marginTop: "8px" }} >
          <Box flexGrow={1}>
            <TextBox
              size="small"
              variant="outlined"
              color="secondary"
              placeholder={t("Search for parking")}
              style={{ backgroundColor: "white", borderRadius: "10px" }}
              fullWidth
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
              className={classes.search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small">
                      <img src="/images/icons8-search (2).svg" alt="" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        {loading ? (
          <LoderSimmer card count={10} size={70} />
        ) : (
          <>
            {datas?.length > 0 ? (
              <InfiniteScroll
                dataLength={datas.length}
                next={fetchMoreData}
                hasMore={true}
                height={size?.height - 130}
              >
                {datas?.map((val) => {
                  return (
                    <>
                      <Stack
                        direction={"row"}
                        alignItems="center"
                        className={classes.card}
                        onClick={() => history.push({ pathname: Routes.viewVehicle, state: { main: { parkingSlotID: val?.id } } })}>
                        {/* {val?.vehicle_image ? <img
                          src={generateImageUrl(val?.vehicle_image, img_size.small_square)}
                          alt=""
                          className={classes.image} /> :
                          <Box style={{ borderRadius: "4px", backgroundColor: "#F0F4FF", height: "48px", width: "48px", justifyContent: "center", alignItems: "center", display: "flex", marginInlineEnd: "16px" }}>
                            {val?.vehicle_image
                              ? <img
                                src={generateImageUrl(val?.vehicle_image, img_size.small_square)}
                                alt=""
                                className={classes.image} />
                              : <Bicycle />}
                          </Box>} */}
                        <Box style={{ borderRadius: "4px", backgroundColor: "#F0F4FF", height: "48px", width: "48px", justifyContent: "center", alignItems: "center", display: "flex", marginInlineEnd: "16px" }}>
                          {val?.vehicle_image
                            ? <img
                              src={generateImageUrl(val?.vehicle_image, img_size.small_square)}
                              alt=""
                              className={classes.image} />
                            : <Bicycle />}
                        </Box>
                        <Stack direction={"column"} spacing={"4px"}>
                          <Typography className={classes.name} noWrap>
                            {(val?.vehicle_type === "BI-CYCLE" || val?.vehicle_type === "TWO-WHEELER") ?
                              val?.parking_areaByID?.vehicle_parking_area : val?.parking_masterByID?.parking_no}
                          </Typography>
                          <Typography className={classes.vehicleNoStyle} sx={{ maxWidth: size?.width - 150 }} noWrap>
                            {val?.propertyByID?.name}
                          </Typography>
                          <Stack direction={"row"} divider={<Divider sx={{ marginInline: "6px" }} orientation="vertical" flexItem />} sx={{ maxWidth: size?.width - 150 }}>
                            <Typography className={classes.vehicleNoStyle}>
                              {val?.number_plate}
                            </Typography>
                            <Typography className={classes.tag} noWrap>
                              {val?.vehicle_type}
                            </Typography>
                          </Stack>
                          <Typography className={classes.unitStyle}>
                            {val?.agreement_unitsByID?.unitByID?.name}
                          </Typography>
                        </Stack>
                      </Stack>
                    </>
                  );
                })}
              </InfiniteScroll>
            ) : (
              <Typography
                style={{
                  fontSize: "0.875rem",
                  fontFamily: FontFamilySwitch().semiBold,
                  textAlign: "center",
                  marginTop: "40px"
                }}>
                {t("NoVehiclesFound")}
              </Typography>
            )}
          </>
        )}
      </div>
      <Grid container>
        <Grid item xs={12} style={{ position: "relative" }}>
          <Box sx={{ display: "flex", float: "right" }}>
            <Box>
              <Fab
                color="primary"
                className={classes.fab}
                aria-label="add"
                onClick={() => history.push({ pathname: Routes.addVehicle, state: { main: { agreementID: aggrementIdList } } })}
              >
                <AddIcon />
              </Fab>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
export default withNamespaces("vehicle")(Vehicle)