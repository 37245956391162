import { Box, Button, Container, Dialog, Drawer, Grid, Hidden, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { FontFamilySwitch, getTotalReduce } from "../../../utils";
// import AlreadyPaidForm from "./alreadyPaidForm";
import InvoiceList from "./invoiceList";
import { useEffect } from "react";
import AlreadyPaidForm from "./alreadyPaidForm";
const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
    },
    totalAmount: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
    },
    subTitle: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary,
        margin: "10px 0 8px 0",
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "4px",
            padding: "0px",
        },
    },
    btn: {
        padding: "10px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        border: "1px solid #5078e1",
        backgroundColor: "#5078e1",
        color: "#fff",
        "&:hover": {
            boxShadow: "none",
            backgroundColor: "#5078e1",
            opacity: "0.9"
        },
    },
    btn1: {
        padding: "10px",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#fff",
        border: "1px solid #5078e1",
        color: "#5078e1",
        boxShadow: "none",
        textTransform: "capitalize",
        "&:hover": {
            boxShadow: "none",
            backgroundColor: "#fff",
            opacity: ".8",
        },
    },
}));

const PayNowInvoiceList = ({
    t = () => false,
    invoice_list = [],
    currency = "",
    handleDelete = () => false,
    handleViewInvoice = () => false,
}) => {
    const classes = useStyles();
    const [is_pay_now_disabled, set_is_pay_now_disabled] = React.useState(false);
    const [is_already_paid_form_open, set_is_already_paid_form_open] = React.useState(false);

    useEffect(() => {
        handleAlreadyPaidActive(invoice_list)
    }, [invoice_list])

    const handleAlreadyPaidActive = (invoiceList) => {
        let is_checked_count = 0;
        for (let i = 0; i < invoiceList?.length; i++) {
            if (invoiceList?.[i]?.is_checked) {
                is_checked_count = is_checked_count + 1;
            }
        }

        set_is_pay_now_disabled(!(is_checked_count === 1));
    }
    return (
        <div className={classes.root}>
            <Container maxWidth="sm" sx={{ padding: "0px" }}>
                <Grid Container>
                    {invoice_list?.map((_) => {
                        return (
                            <>
                                {_?.is_checked === true && (
                                    <Grid xs={12}>
                                        <InvoiceList
                                            invoice={_}
                                            currency={currency}
                                            handleDelete={handleDelete}
                                            handleViewInvoice={handleViewInvoice} />
                                    </Grid>
                                )}
                            </>
                        );
                    })}
                    <Grid item xs={12}>
                        <Grid
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                            spacing={0}
                        >
                            <Grid
                                item
                                xs={12}
                                style={{
                                    display: "flex",
                                    padding: "12px 0",
                                    borderBottom: "2px dashed #CED3DD",
                                    borderTop: "2px dashed #CED3DD",
                                }}
                            >
                                <Grid item xs={6}>
                                    <Typography className={classes.totalAmount}>
                                        {t("total_amount_to_pay")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={{ float: "right" }}>
                                        <Typography className={classes.totalAmount}>
                                            {(currency?.symbol ?? "") + (" ") +
                                                Intl.NumberFormat("en-IN", {
                                                    minimumFractionDigits: 0,
                                                }).format(Math.round(getTotalReduce(invoice_list?.filter((x) => x?.is_checked), "invoice_due_amount")))}
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                            <Box margin={"10px"} />
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        className={classes.btn1}
                                        onClick={() => set_is_already_paid_form_open(true)}
                                        fullWidth
                                        disabled={is_pay_now_disabled}
                                    >
                                        {t("already_paid")}
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        disabled={true}
                                        variant="contained"
                                        className={classes.btn}
                                        fullWidth
                                        onClick={() => { }}
                                    >
                                        {t("pay_now")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box height="12px" />
                    </Grid>
                </Grid>
            </Container>
            <Hidden smDown>
                <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth
                    open={is_already_paid_form_open}
                    onClose={() => set_is_already_paid_form_open(false)}>
                    <div>
                        <AlreadyPaidForm
                            handleClose={() => set_is_already_paid_form_open(false)}
                            invoice={invoice_list?.[0]} />
                    </div>
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    anchor="bottom"
                    className={classes.drawer}
                    open={is_already_paid_form_open}
                    onClose={() => set_is_already_paid_form_open(false)}>
                    <div>
                        <AlreadyPaidForm
                            handleClose={() => set_is_already_paid_form_open(false)}
                            invoice={invoice_list?.[0]} />
                    </div>
                </Drawer>
            </Hidden>
        </div>
    );
};
export default withNamespaces("invoice")(PayNowInvoiceList);
