/* eslint-disable array-callback-return */
import {
    Box, Dialog,
    Drawer, Hidden,
    Stack,
    Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Filter, LoderSimmer, ParkingCard } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, NetWorkCallMethods, enumSelect, enumCatagory, FontFamilySwitch, LocalStorageKeys } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F6F6F6",
        padding: "0px",
        overflow: "hidden",
    },
    filter: {
        borderRadius: "4px",
        border: "1px solid #E4E8EE",
        color: "black",
        backgroundColor: "white",
    },

    drawer: {
        "& .MuiDrawer-paper": {
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
        },
        "& .MuiContainer-root": {
            padding: "0px 8px",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "4px",
            padding: "0px",
        },
    },
    total: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().bold,
        backgroundColor: "white",
        borderRadius: "4px",
        padding: "6px"
    },
    titleRoot: {
        padding: "12px"
    },
    nodata: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().bold,
        color: "gray",
        textAlign: "center"
    }
}));
const InitialDate = {
    startDate: null,
    endDate: null,
};
const Walkinout = (props) => {
    const { t = () => false, filter = false, setFilter = () => false } = props
    const size = useWindowDimensions();
    const classes = useStyles();
    const alert = React.useContext(AlertContext);
    const [gatelist, setGateList] = React.useState([]);
    // const [unitList, setUnitList] = React.useState([]);
    const [selectedFilter, setSelectedFilter] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [offset, setOffset] = React.useState(0);
    const [dateFilter, setdateFilter] = React.useState({ ...InitialDate });
    const [statusType, setStatusType] = React.useState([]);
    const updateState = (key, value) => {
        setdateFilter({ ...dateFilter, [key]: value });
    };

    const userProfileId = localStorage.getItem(LocalStorageKeys.userProfileId)
    const getPassList = (unit, status, filter, offset) => {
        console.log("props: ", props)

        const data = {
            tenantId: `${config.tenantid}`,
            request_type: ["VGR"],
            status: status,
            offset: offset,
            limit: 20,
            // entries: true,
            unit_ids: props?.selectedUnit?.unit_id ? [props?.selectedUnit?.unit_id] : null,
            user_profile_id: userProfileId
            // ,
            // start_date:
            //   dateFilter?.startDate?.toString().length > 0
            //     ? new Date(dateFilter?.startDate).toISOString()
            //     : undefined,
            // end_date:
            //   dateFilter?.endDate?.toString().length > 0
            //     ? new Date(dateFilter?.endDate).toISOString()
            //     : undefined,
        };
        NetworkCall(
            `${config.api_url}/walkInout/list`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                if (filter === true) {
                    setGateList(response?.data?.data);
                } else {
                    setGateList(gatelist.concat(response?.data?.data));
                }
                setFilter(false);
                setLoading(false);
            })
            .catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };
    const getEnum = async (data) => {
        const stayType = await enumSelect([enumCatagory?.security_status_enum_type])
        // const request_statusType = keyMapSource({ enumType: stayType?.security_status_enum_type })
        setStatusType(stayType?.security_status_enum_type?.filter(_ => !["Received At Gate", "Cancelled"].includes(_?.value)))
        setSelectedFilter({
            status: stayType?.security_status_enum_type?.filter(_ => !["Received At Gate", "Cancelled"].includes(_?.value))?.map((e) => e?.value),
            unit: props?.units,
        });
        getPassList(
            props?.units,
            stayType?.security_status_enum_type?.filter(_ => !["Received At Gate", "Cancelled"].includes(_?.value))?.map((e) => e?.value)
        );

    }
    React.useEffect(() => {
        // setUnitList(props?.unitlist);
        getEnum()
        // eslint-disable-next-line
    }, []);
    const filterData = [
        {
            id: "1",
            title: "Status",
            key: "status",

            filterType: "CHECKBOX",
            values: statusType ?? [],
        },
        // {
        //     id: "2",
        //     title: "Unit",
        //     key: "unit",
        //     filterType: "CHECKBOX",
        //     values: unitList,
        // },
        // {
        //     id: "3",
        //     title: "Date",
        //     key: "date",
        //     filterType: "DATE",
        //     values: [],
        // },
    ];

    const applyFilter = (data) => {
        if (data?.status?.length === 0) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Atleastselectonestatus"),
            });
            return false
        }
        if (data?.unit?.length === 0) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Atleastselectoneunit"),
            });
            return false
        }
        setOffset(0);
        setSelectedFilter({
            status: data.status,
            unit: data.unit,
        });
        getPassList(data.unit, data.status, true, 0);
    };
    const fetchMoreData = () => {
        setOffset(offset + 20);
        getPassList(selectedFilter.unit, selectedFilter.status, false, offset + 20);
    };

    return (
        <div className={classes.root} maxWidth="sm">
            {/* <Box display="flex" alignItems="center" className={classes.titleRoot}>
          <Box flexGrow={1} >
            <Typography className={classes.total}>
              {t("Parking")}
            </Typography>
          </Box>
          <Box sx={{ marginInlineStart: "10px" }}>
            <IconButton
              className={classes.filter}
              onClick={() => setFilter(true)}
            >
              <Badge variant="dot" color="primary">
                <img src="/images/icons8-funnel.svg" alt="filter" />
              </Badge>
            </IconButton>
          </Box>
        </Box> */}
            <div style={{ marginRight: "-6px" }}>
                <Box backgroundColor="white">
                    {loading ? (
                        <LoderSimmer count={10} />
                    ) : <>
                        {
                            gatelist.length > 0 ?

                                <InfiniteScroll
                                    dataLength={gatelist.length}
                                    next={fetchMoreData}
                                    hasMore={true}
                                    height={size?.height - 120}
                                >
                                    <Stack padding={"16px"} spacing={2}>
                                        {gatelist?.map((item) => {
                                            return <Box marginBottom={"12px"} sx={{boxShadow: "0px 3px 30px #00000021"}}>
                                                <ParkingCard t={t} data={item} walkinout />
                                            </Box>;
                                        })}
                                    </Stack>
                                </InfiniteScroll>
                                :
                                <Box 
                                sx={{
                                    height: "calc(100vh - 100px)",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                  }}
                                className={classes.root}>
                                    <Typography className={classes.nodata}>{t("NoDataFound")}</Typography>
                                </Box>

                        }

                    </>
                    }
                </Box>
            </div>
            <Hidden smDown>
                <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth
                    open={filter}
                    onClose={() => setFilter(false)}
                >
                    <Filter
                        filterData={filterData}
                        selectedList={filterData[0]}
                        filters={{
                            status: selectedFilter.status,
                            unit: selectedFilter.unit,
                        }}
                        setSelectedFilter={setSelectedFilter}
                        onGoBack={() => setFilter(false)}
                        onApply={applyFilter}
                        onClose={() => setFilter(false)}
                        updateState={updateState}
                    //   data={dateFilter}
                    />
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer open={filter} onClose={() => setFilter(false)}>
                    <Filter
                        filterData={filterData}
                        selectedList={filterData[0]}
                        filters={{
                            status: selectedFilter.status,
                            unit: selectedFilter.unit,
                        }}
                        setSelectedFilter={setSelectedFilter}
                        onGoBack={() => setFilter(false)}
                        onApply={applyFilter}
                        onClose={() => setFilter(false)}
                        updateState={updateState}
                    //   data={dateFilter}
                    />
                </Drawer>
            </Hidden>
        </div>
    );
};
export default withNamespaces("entriesLog")(Walkinout)