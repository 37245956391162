import { Box, Button, CircularProgress, Container, Dialog, IconButton, Stack, Typography } from "@mui/material"
import { withNamespaces } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { NetworkCall } from "../../networkcall";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods, getTotalReduce, remCalculate } from "../../utils";
import { config } from "../../config";
import CloseIcon from '@mui/icons-material/Close';
import { PaymentCard } from "./card";
import { AlertContext } from "../../contexts";
import { razorpayCall } from "crayond-payment-pg";
import { LoadingSection } from "../homeloader";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";

const useStyles = makeStyles((theme) => ({
  btn: {
    padding: "10px",
    boxShadow: "none"
  },
  body: {
    height: "calc(100vh - 125px)",
    overflow: "auto",
    padding: 16,
    background: "#F2F5FA"
  },
  body1: {
    height: "calc(100vh - 60px)",
    overflow: "auto",
    padding: 16,
    background: "#F2F5FA"
  },
  bottom: {
    boxShadow: "0px -1px 6px #00000021",
    background: "#fff",
    padding: 12
  },
  nextBtn: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: 14,
    height: 40,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`
  },
  submitBtn: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: 14,
    height: 40,
    color: "#fff",
    background: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.main
    }
  },
  topBar: {
    height: 58,
    width: "100%"
  },
  topBarTitle: {
    textAlign: "center",
    fontSize: remCalculate(16),
    fontFamily: FontFamilySwitch().bold
  }
}));


export const PaymentButton = ({onClose=()=>false ,t = () => false, company_id, invoices = [], currency, currency_id, account_no, contact = "" , invoice_total , currency_code }) => {
  const classes = useStyles();
  const alert = React.useContext(AlertContext)
  const history = useHistory()
  const [state, setState] = React.useState({
    gateway_list: [],
    is_disabled: true,
    step: 1,
    seletedPaymentType: "",
    seletedTransaction: ""
  })
  const [payment , setPayment] = React.useState({
    is_loading:false,
    is_active:false
  })
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const total = !!invoice_total ? invoice_total : getTotalReduce(invoices , "OutsandingAmount")
  const updateState = (key, value) => {
    if(key === "seletedPaymentType"){
      setState({
        ...state,
        [key]: value,
        step:state?.step + 1
      })
    }
    else{
      setState({
        ...state,
        [key]: value
      })
    }
  }

  React.useEffect(() => {
    if (company_id) {
      getDetails()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const getDetails = () => {
    setPayment({
      ...payment,
      is_loading:true,
    })
    const payload = {
      company_id: company_id,
      client:JSON.parse(localStorage.getItem(LocalStorageKeys?.client))?.id
    }
    NetworkCall(
      `${config.payment_api_url}/api/v1/payment_gateway_master`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((res) => {
      if(!res?.data?.data?.length){
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "There is no gateway configure",
        });
        setPayment({
          ...payment,
          is_loading:false,
          is_active:true
        })
        return setOpen(false)
      }
      setPayment({
        ...payment,
        is_loading:false,
        is_active:false
      })
      setState((prevState) => ({
        ...prevState,
        paymentData: res?.data?.data,
      }))
    }).catch((error) => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Payment is not possible at this time",
      });
      console.log(error)
      setPayment({
        ...payment,
        is_loading:false,
        is_active:true
      })
      setOpen(false)
    })
  }

  const handleOpen = () => {
    // getDetails()
    setOpen(true)
  }

  const handleClose = () => {
    setState({
      is_disabled: true,
      step: 1,
      seletedPaymentType: "",
      seletedTransaction: ""
    })
    setOpen(false)
  }

  const hide = ["cardless_emi" , "paylater" , "app" , "emi"]
  const paymentSubmit = () => {
    let hidden = JSON.parse(state?.seletedPaymentType?.transaction_charge_config)?.filter((c)=>c?.id !== state?.seletedTransaction?.id)
    hidden = hidden?.map((c)=>c?.payment_mode)
    const extraCharge = state?.seletedTransaction?.charge_type === "percentage" ? (total / 100) * state?.seletedTransaction?.value : state?.seletedTransaction?.value
    const orderamount = total + extraCharge
    razorpayCall({
      baseURL: config.payment_api_url,
      orderAmount: parseInt(orderamount),
      name: state?.seletedPaymentType?.name,
      description: "Payment",
      preFillName: contact?.name,
      preFillEmail: contact?.email_id ?? "",
      preFillContactNumber: `${contact?.mobile_no_country_code}${contact?.mobile_no}`,
      notesAddress: "notes",
      theme: "",
      paymentType: state?.seletedPaymentType?.id,
      setLoading: (value) => setLoading(value),
      callBackResponse: (res) => handlePaymentData(res),
      is_error_track:true,
      meta: {
        key_secret:state?.seletedPaymentType?.secret,
        razor_pay_key:state?.seletedPaymentType?.key_id,
        client:JSON.parse(localStorage.getItem(LocalStorageKeys?.client))?.id,
        user_profile_id:localStorage.getItem(LocalStorageKeys?.userProfileId),
        currency:{
          symbol:currency,
          code:currency_code,
          id:currency_id
        },
        invoice_id:invoices?.map((e) => { return { invoice_no: e?.invoice_no , account_no:e?.account_no , id:e?.id } }),
        company_id:company_id,
        base_amount:total,
        paymentType: state?.seletedTransaction,
        account_no
      },
      headers:{
        "payment-x-api-key":config.payment_api_key
      },
      config:{
        display: {
          hide: [...hide , ...hidden]?.map((x)=>{
            return {
              method:x
            }
          }),
        },
        display_emi:0
      },
      ondismiss:()=>{
        handleClose()
      },
      errHandler:(err)=>{
        console.log(err , "erorrrr")
      }
  
    })
  }
  const handlePaymentData = (res) => {
    success()
  }
  const success = () => {
    history.push({
      pathname: Routes.success,
      state: {
        customLabel: "Payment Done",
      }
    })
  }

  return (
    <Box>
      <Button className={classes.btn} disabled={(payment?.is_loading || payment?.is_active)}  variant="contained" fullWidth onClick={() => handleOpen()}>
        {payment?.is_loading ? <Box>
          <CircularProgress size={20} thickness={4}/>
        </Box> : t("dashPayNow")}
        </Button>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <Container maxWidth={"sm"} sx={{ padding: "0px !important" }}>
          <Stack direction={"row"} alignItems={"center"} className={classes.topBar}>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
            <Typography width={"100%"} className={classes.topBarTitle}>
              {
                state?.step === 1 ?
                  "Choose Payment Gateway" :
                  "Choose you preferred payment type"
              }
            </Typography>
          </Stack>

          <Box className={(state?.step === 2) ? classes.body : classes.body1}>
            <>
              {
                loading ?
                  <LoadingSection /> :
                  <>
                    {
                      state?.step === 1 &&
                      <Stack spacing={2}>
                        {
                          state?.paymentData?.map((e) => {
                            return (
                              <PaymentCard data={e} selected={state?.seletedPaymentType} onClick={(val) => updateState("seletedPaymentType", val)} />
                            )
                          })
                        }
                      </Stack>
                    }
                                        {
                      state?.step === 2 &&
                      <Stack spacing={2}>
                        {
                          JSON.parse(state?.seletedPaymentType?.transaction_charge_config)?.map((e) => {
                            return (
                              <PaymentCard
                                data={e}
                                selected={state?.seletedTransaction}
                                onClick={(val) => updateState("seletedTransaction", val)}
                                total_amount={total}
                                currency_symbol={currency}
                                type="transaction" />
                            )
                          })
                        }
                      </Stack>
                    }
                  </>
              }
            </>
          </Box>
          {
              state?.step === 2 && 
              <Stack className={classes.bottom} direction={"row"} spacing={2}>
            {state?.step === 2 && <Button variant="outlined" fullWidth className={classes.nextBtn}
              disabled={loading}
              onClick={() => updateState("step", state?.step - 1)}>
              Back
            </Button>}
            
              <Button variant={state?.step === 2 ? "contained" : "outlined"}
              fullWidth className={state?.step === 2 ? classes.submitBtn : classes.nextBtn}
              disabled={loading ? loading : state?.step === 2 ? !state?.seletedTransaction?.label : !state?.seletedPaymentType?.id}
              onClick={() => state?.step === 1 ? updateState("step", state?.step + 1) : paymentSubmit()}
            >
              {state?.step === 1 ? "Next" : "Submit"}
            </Button>
           
          </Stack>
          }
          
        </Container>
      </Dialog>
    </Box>
  )
}
export default withNamespaces("dashboard")(PaymentButton)
