import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Avatar, Box, Container, Grid, IconButton, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React, { useEffect } from "react";
import { useHistory,useLocation } from "react-router-dom";
import { AlertContext, AuthContext } from "../../contexts";
import { BackendRoutes, Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, LanguageOptions, LocalStorageKeys, NetWorkCallMethods, generateImageUrl, img_size, stringAvatar } from "../../utils";
import { ReadNotificationIcon } from "./assets/readNotificationIcon";
import { UnreadNotificationIcon } from "./assets/unreadNotificationIcon";
import { LanguageIcon } from "./assets/languageIcon";
import { AgreementList } from "../agreementlist";
import { DialogDrawer } from "../dialogDrawer";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
const useStyles = makeStyles((theme) => ({
  grow: {},
  appBar: {
    background: theme.palette.background.tertiary1,
    padding: "12px 20px",
    minHeight: "54px",
    boxShadow: "0px 2px 6px #00000014"
  },
  navbarimg: {
    height: "36px",
    marginTop: "4px",
    cursor:"pointer",
    objectFit: "cover"
  },
  id: {
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().extraBold,
    fontSize: "0.875rem",
  },
  containers: {
    "&.MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    overflow: "hidden",
  },
  agreement: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: "4px",
    padding: "4px 8px",
    marginTop: "4px",
    cursor: "pointer",
  },
  exporie: {
    backgroundColor: "#5078E114",
    padding: "2px 4px",
    borderRadius: "4px",
    color: theme.palette.primary.main,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
  },
  profileImg: {
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    cursor: "pointer",
  },
}));

export const TopNavBars = (props) => {
  const {noUser=false}=props;
  const authToken = localStorage.getItem(LocalStorageKeys?.authToken);
  const auth = React.useContext(AuthContext)
  const alert = React.useContext(AlertContext);
  const {theme } = props
  const classes = useStyles(props);
  const history = useHistory();
  const location = useLocation()
  const [newNotification, setNewNotification] = React.useState(false)
  const [openLanguage, setOpenLanguage] = React.useState(false)
  const [selectedLanguage, setSelectedLanguage] = React.useState(LanguageOptions?.[0])
  const [user_details_state, set_user_details_state] = React.useState({})
  const clientLogo = localStorage.getItem("clientLogo")
  const app = config?.app_x_build === "PG-TE-08" ? "Tenant2" : "Tenant"

  const logo =  JSON.parse(localStorage.getItem("accessType")) ? JSON.parse(localStorage.getItem("accessType")).filter((val) => val.name === app).find((item) => item?.company_logo !== null) : null

  const updateLanguage = (data) => {
    setSelectedLanguage(data)
    saveLanguage(data)
  }

  const openLanguageSwitcher = () => {
    let getselectedlag = props?.i18n.language
    setSelectedLanguage(LanguageOptions.find((val) => val.value === getselectedlag) ?? LanguageOptions[0])
    setOpenLanguage(true)
  }

  const saveLanguage = (language) => {
    props?.i18n.changeLanguage(language?.value);
    setOpenLanguage(false)
    auth.setAuth({ ...auth })
  };

  useEffect(() => {
    if (authToken !== null && authToken !== "" && !noUser) {
      NetworkCall(
        `${config?.api_url}${BackendRoutes?.user_info}`,
        NetWorkCallMethods?.get, null, null, true, false
      ).then((response) => {
        set_user_details_state({
          details: response?.data?.data?.user_profiles?.[0],
          role: response?.data?.data?.role?.[0],
        });
      }).catch((error) => {
        alert.setSnack({
          ...alert, open: true, msg: "Something went wrong",
          severity: AlertProps.severity.error,
        });
      });
    } else {
      // RetainLocalStorage();
      // history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    getNotificationNewCheck()
  }, [location.pathname])

  const getNotificationNewCheck = () => {
    NetworkCall(
      `${config.api_url}/notifications/unread`,
      NetWorkCallMethods.post, {}, null, true, false
    ).then((res) => {
      setNewNotification(res?.data?.data?.is_unread)
    }).catch((err) => console.log(err))
  }
  return (
    <Container maxWidth="sm" style={{padding:"0px"}}>
    <Grid container className={classes.appBar} alignItems={"center"}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            {
              (props?.company_logo || logo?.company_logo || clientLogo) &&
              <img
                onClick={()=>history.push(Routes.dashboard)}
                src={props?.company_logo ? props?.company_logo : logo?.company_logo ?? clientLogo}
                alt="logo"
                className={classes.navbarimg}
              />

            }

          </Box>
          {props?.note && (

            <Stack
              columnGap={"12px"}
              alignItems={"center"}
              direction="row"
            >
              <IconButton
                sx={{ padding: "0px"}}
                size="small"
                onClick={props.handleClicknote}
              >
                   {newNotification ? (
                    <UnreadNotificationIcon color={theme?.palette?.primary?.main}/>
                  ) : (
                    <ReadNotificationIcon color={theme?.palette?.primary?.main}/>
                  )}
              </IconButton>
              <IconButton
                sx={{ padding: "0px"}}
                size="small"
                onClick={openLanguageSwitcher}
              >
                <LanguageIcon />
              </IconButton>
              <IconButton
                sx={{ padding: "0px"}}
                size="small"
                onClick={() => (window?.location?.pathname !== Routes.profileView) && history.push(Routes.profileView)}
              >
              <Avatar
                src={generateImageUrl(user_details_state?.details?.image_url, img_size?.small_square)}
                {...stringAvatar(user_details_state?.details?.first_name)}
                className={classes.profileImg}
                alt=""
              ></Avatar>
              </IconButton>
            </Stack>
          )}

        </Box>
      </Grid>

      <Grid item xs={12}>
        {props?.home && (
          <Box
            display="flex"
            alignItems="center"
            className={classes.agreement}
            onClick={props.handleClick}
          >
            <Box flexGrow={1}>
              <Typography className={classes.id}>
                {props?.agreementList?.map((val, index) => {
                  return (
                    <span key={index}>
                      {val?.id === props?.agreement?.id
                        ? val?.agreement_no
                        : ""}
                    </span>
                  );
                })}
              </Typography>
            </Box>
            <Box>
              {props?.agreementList?.map((val, index) => {
                return (
                  <Box key={index}>
                    {val?.id === props?.agreement?.id ? (
                      <>
                        {val?.expiry_on && (
                          <Typography  className={classes.exporie}>
                            &nbsp;&nbsp;{props?.expire}&nbsp;
                            {moment(val?.expiry_on)
                              .tz(moment.tz.guess())
                              .format("DD MMM YY")}
                            &nbsp;&nbsp;
                          </Typography>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                );
              })}
            </Box>
            <Box>
              <IconButton
                size="small"
                onClick={props.handleClick}
                style={{
                  color: "black",
                }}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </Box>
          </Box>
        )}
      </Grid>



      {
        props?.isUtilities &&
        <Grid item xs={12}>
          {
            props?.component
          }
        </Grid>
      }

        <DialogDrawer
          height={"400px"}
          open={openLanguage}
          header={"Choose Language"}
          header_text_transform={"capitalize"}
          padding={"0px 16px 16px 16px"}
          component={
            <AgreementList
              languageOptions={LanguageOptions}
              selectedLanguage={selectedLanguage}
              updateLanguage={updateLanguage}
            />
          }
          onClose={() => setOpenLanguage(false)} />

    </Grid>
    </Container>
  );
};