import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useStyles } from "./styles";
import Carousel, { consts } from "react-elastic-carousel";
import { assestType, generateImageUrl, img_size } from "../../utils";
import CloseIcon from "@mui/icons-material/Close";
import { DocumentViewer } from "../pdfviewer";

const AssetSlideViewer = (props) => {
  const { asset, onClose } = props;
  const classes = useStyles();
  const breakPoints = [{ width: 1, itemsToShow: 1 }];

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />;
    return (
      <IconButton
        size="small"
        style={{ fontSize: "1.25rem" }}
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </IconButton>
    );
  };
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        p={1}
        className={classes.titleBar}
      >
        <Box flexGrow={1}>
          <Typography className={classes.detailTitle}>
            {asset?.title}
          </Typography>
        </Box>
        <Box>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon style={{ fontSize: "1.25rem" }} />
          </IconButton>
        </Box>
      </Box>
      <div>
        {[
          assestType.Facility_Images,
          assestType.Three_sixty_Images,
          assestType.General_Images,
          assestType.Floor_Plan
        ].includes(asset?.asset_type) && (
          <Carousel
            renderArrow={myArrow}
            showArrows={true}
            breakPoints={breakPoints}
          >
            {[asset]
              ?.filter((i) => i?.asset_type === asset?.asset_type)
              .map((val) => {
                return (
                  <img src={generateImageUrl(val.url,img_size.medium_square)} alt="" height="500px" width="100%" />
                );
              })}
          </Carousel>
        )}
        {asset?.title === "Pdf" && (
          <>
            {props?.assets
              ?.filter((i) => i?.asset_type === 4)
              .map((val) => {
                return (
                  <div style={{ height: "500px", marginLeft: "40px" }}>
                    <DocumentViewer url={val?.url} />
                  </div>
                );
              })}
          </>
        )}
        {asset?.title === "Video" && (
          <Carousel
            renderArrow={myArrow}
            showArrows={true}
            breakPoints={breakPoints}
          >
            {props?.assets
              ?.filter((i) => i?.asset_type === 3)
              .map((val) => {
                return (
                  <video
                    style={{
                      objectFit: "cover",
                      height: "500px",
                      width: "100%",
                    }}
                    controls
                  >
                    <source src={val.url} type="video/mp4" />
                  </video>
                );
              })}
          </Carousel>
        )}
      </div>
    </>
  );
};

export default AssetSlideViewer;
