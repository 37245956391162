import { Badge, Container, Stack, Box, Typography, Skeleton } from '@mui/material';
import React from 'react'
import { withNamespaces } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import NoDataFound from '../../components/noDataFound/noDataFound';
import { config } from '../../config';
import { AlertContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { Routes } from '../../router/routes';
import { AlertProps, NetWorkCallMethods } from '../../utils';
import useWindowDimensions from '../../utils/useWindowDimensions'
import FeedbackListCard from './components/feedbackListCard';
import { useStyles } from './styles';
import { DialogDrawer } from '../../components';
import Filter from '../../components/filter';

const FeedbackList = (props) => {
    const { t = () => false, handleTopBarState } = props;
    const classes = useStyles(props);
    const size = useWindowDimensions();
    const history = useHistory();
    const alert = React.useContext(AlertContext);
    const [offset, setOffset] = React.useState(0)
    const [feedbackList, setFeedbackList] = React.useState([]);
    const contact = localStorage.getItem("contactID")
    const [loading, setLoading] = React.useState(true)
    const [openFilter, setOpenFilter] = React.useState(false);
    const filterData = {
        status: [
            { label: "Open", value: "Open" },
            { label: "Submitted", value: "Submitted" },
        ],
    };
    const [selectedFilter, setSelectedFilter] = React.useState({
        status: ["Open", "Submitted"]
    });
    const myFilterData = [
        {
            id: "1",
            title: t("Status"),
            key: "status",
            showSearch: true,
            filterType: "CHECKBOX",
            values: filterData?.status,
        },
    ];
    const applyFilter = (val) => {
        console.log("applyFilter: ", val);
        if (val?.status?.length > 0) {
            setSelectedFilter(val);
            GetFeedback(0, 10, false, val)
            setOpenFilter(false);
        } else {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Select atleast one status"),
            });
        }
    };
    const goView = (val) => {
        history.push({
            pathname: Routes.feedbackForm,
            state: {
                data: val,
            },
        });
    }

    const GetFeedback = (offset = 0, limit = 10 , is_filter, filter) => {
        const payload = {
            contact: [contact],
            start: offset,
            length: limit,
            status: filter?.status?.length > 0 ? filter.status : [],
        }
        NetworkCall(
            `${config?.api_url}/customer_feedback_replies`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                if(is_filter){
                    setFeedbackList(feedbackList.concat(response?.data?.data));
                }
                else{
                    setFeedbackList(response?.data?.data);

                }
                setLoading(false)
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };
    //fetch more data
    const fetchMoreData = () => {
        setOffset(offset + 10)
        GetFeedback(offset + 10, 10, true, selectedFilter);
    }
    React.useEffect(() => {
        handleTopBarState({
            text: t("Feedback")
        })
        GetFeedback(0, 10, false, selectedFilter);
        // eslint-disable-next-line
    }, [])

    return (
        <Container className={classes.root} maxWidth="sm">
            {/* <TitleBar text={t("Feedback")} goBack={goBack} /> */}
            <div style={{ backgroundColor: "#F8F8F8" }}>
                <Stack
                    pt={1}
                    mb={1}
                    pr={2}
                    pl={2}
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"space-between"}
                >
                    <Typography className={classes.heading}>
                        {selectedFilter?.status?.length === 1 ? selectedFilter?.status?.[0] : t("Total")} ({feedbackList.length})
                    </Typography>
                    <Box
                        className={classes.filterBlock}
                    onClick={() => setOpenFilter(true)}
                    >
                        <Badge color="primary" variant="dot">
                            <img src="/images/funnel.svg" alt="" />
                        </Badge>
                    </Box>
                </Stack>
                <div
                    className={classes.mainPadding}
                    style={{
                        height: size?.height - 104, overflow: "overlay",
                    }}
                >
                    {loading ?
                        <>
                            <Box className={classes.listRoot}>
                                <Stack direction={"row"} spacing={2}>
                                    <Skeleton variant="circular" width={48} height={48} />
                                    <Stack direction={"column"}>
                                        <Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
                                    </Stack>
                                </Stack>
                            </Box>
                        </>
                        : <>
                            {feedbackList.length > 0 ?


                                <InfiniteScroll
                                    dataLength={feedbackList?.length ?? 10}
                                    next={fetchMoreData}
                                    hasMore={true}
                                    height={size?.height - 180}
                                >
                                    {feedbackList.map((data) => (
                                        <>
                                            <Box className={classes.listRoot}>
                                                <FeedbackListCard t={t} data={data} goView={goView} />
                                            </Box >
                                            <Box height={"12px"} />
                                        </>
                                    ))}
                                </InfiniteScroll>
                                : <>
                                    <NoDataFound
                                        height={210}
                                        content={t("No Data Found!")}
                                    /></>
                            }
                        </>}
                </div>
            </div>
            <DialogDrawer
                open={openFilter}
                onClose={() => setOpenFilter(false)}
                onOpen={() => setOpenFilter(true)}
                isnotTitle={true}
                padding={0}
                height={size?.width <= 600 ? size?.height : "auto"}
                component={
                    <Filter
                        filterData={myFilterData}
                        selectedList={myFilterData[0]}
                        filters={{
                            status: selectedFilter.status,
                        }}
                        onApply={applyFilter}
                        onGoBack={() => setOpenFilter(false)}
                        onClose={() => setOpenFilter(false)}
                    />
                }
            />
        </Container >
    )
}

export default withNamespaces("feedback")(FeedbackList)