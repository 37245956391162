import React from "react";
import {
  Box,
  Container,
  Paper,
  Typography,
  Divider,
  Stack,
} from "@mui/material";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useStyles } from "./style";
import GreenTickIcon from "../../../assets/greenTickIcon";
import CheckIn from "../../../assets/checkIn";
import InspectionReport from "../../../assets/inspectionReport";
import moment from "moment";
import { DialogDrawer } from "../../dialogDrawer";
import InspectionReportDocument from "./inspectionReportDocument";
import { withNamespaces } from "react-i18next";
import { generateImageUrl, img_size } from "../../../utils";

const CompleteInspection = (props) => {
  console.log(props, "props")
  const { t = () => false } = props
  const classes = useStyles();
  const [drawer, setDrawer] = React.useState(false);
  const language = localStorage.getItem("i18nextLng")
  return (
    <Box className={classes.CompleteInspection}>
      <Container maxWidth="sm">
        {/* INSPECTION REPORT */}
        <Paper elevation={0}>
          <Box
            mt={3}
            p={2}
            sx={{ boxShadow: "0px 3px 30px #4250682E", borderRadius: "4px" }}
          >
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box>
                <Typography className={classes.completeTitle}>
                  {t("Inspection")} {props?.tenant?.status}
                </Typography>
                {props?.tenant?.excution && <Typography className={classes.description}>
                  {t("On")} {moment(props?.tenant?.excution).format("DD MMM YY, HH:mm")}
                </Typography>}
              </Box>
              {props?.tenant?.status === "Completed" && (
                <GreenTickIcon width="40px" height="40px" />
              )}
            </Box>
            <Box
              mt={2}
              display={"flex"}
              justifyContent={"space-between"}
              className={classes.insBox}
              onClick={() => setDrawer(true)}
            >
              <Stack
                direction={"row"}
                justifyContent="center"
                alignItems="center"
              >
                <InspectionReport />
                <Typography className={classes.instext}>
                  {t("Inspection Report")}
                </Typography>
              </Stack>
              {language === "ar" ?
                <ChevronLeftIcon className={classes.arrowIcon} /> :
                <ChevronRight className={classes.arrowIcon} />
              }

            </Box>
            <Box mt={2} mb={2}>
              <Divider />
            </Box>

            <Stack
              className={classes.container}
              direction={"row"}
              alignItems="center"
            >
              {/* <Avatar className={classes.propertyImage}> */}
              <img
                style={{
                  width: "60px",
                  height: "60px",
                  objectFit: "cover"
                }}
                src={props?.data?.property_unit_details?.logo}
                alt=""
                className={classes.image}
              />
              {/* </Avatar> */}

              <Box className={classes.content}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography
                    className={classes.propertyId}
                    sx={{ marginBottom: "15px" }}
                  >
                    {props?.data?.property_unit_details?.unit_name}
                  </Typography>
                  <Typography
                    sx={{
                      backgroundColor: "#F1F7FF",
                      color: "#78B1FE",
                      height: "22px",
                    }}
                    className={classes.status_success}
                  >
                    {t(props?.tenant?.request_type)}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography className={classes.propertyName}>
                    {props?.data?.property_unit_details?.property_name},{" "}
                    {props?.data?.property_unit_details?.type}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Paper>
        {/* INSPECTOR */}
        {props?.data?.resource?.length>0 && <Box
          mt={2}
          sx={{ boxShadow: "0px 3px 30px #4250682E", borderRadius: "4px" }}
        >
          <Paper elevation={0}>
            <Box p={2}>
              <Typography className={classes.inspector}>
                {t("INSPECTOR DETAILS")}
              </Typography>

              <Stack
                className={classes.container}
                direction={"row"}
                alignItems="center"
                mt={1}
              >
                {/* <Avatar className={classes.inspectorImage}> */}

                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    objectFit: "cover"
                  }}
                  src={props?.data?.resource?.[0]?.image_url ?
                    generateImageUrl(props?.data?.resource?.[0]?.image_url, img_size.small_square) :
                    "images/profileBlue.svg"} alt="" />
                {/* </Avatar> */}

                <Box className={classes.content}>
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography className={classes.propertyId}>
                      {props?.data?.resource?.[0]?.first_name}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography className={classes.propertyName}>
                      {props?.data?.resource?.[0]?.email_id}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
              {props?.data?.track?.length > 0 &&
                <>
                  <Stack direction={"row"} mt={2} ml={1}>
                    <Box className={classes.userIcon} mt={1}>
                      <CheckIn />
                    </Box>
                    <Box>
                      <Typography className={classes.date}>
                        {moment(
                          props?.data?.track?.[0]?.pause_date_time
                        ).format("DD MMM YY HH:mm a")}
                      </Typography>
                      <Typography
                        className={classes.type}
                        sx={{ marginTop: "8px" }}
                      >
                        {t("Checked In")}
                      </Typography>
                    </Box>
                  </Stack>

                  <Stack direction={"row"} mt={2} ml={1}>
                    <Box className={classes.userIcon2} mt={1}>
                      <span className={classes.iconSize}>
                        <GreenTickIcon width="25px" height="25px" />
                      </span>
                    </Box>
                    <Box>
                      <Typography className={classes.date}>
                        {moment(props?.data?.track?.[props?.data?.track?.length-1]?.end_date_time).format(
                          "DD MMM YY HH:mm a"
                        )}
                      </Typography>
                      <Typography
                        className={classes.type}
                        sx={{ marginTop: "8px" }}
                      >
                        {t("Checked Out")}
                      </Typography>
                    </Box>
                  </Stack>
                </>
              }
            </Box>
          </Paper>
        </Box>}
        <DialogDrawer
          open={drawer}
          onClose={() => setDrawer(false)}
          onOpen={() => setDrawer(true)}
          isnotTitle={false}
          header={t("Inspection Report")}
          component={
            <InspectionReportDocument
              data={props.inspectionReportDetails}
              reference_id={props?.tenant?.reference_id}
              loading={props?.loading}
            />
          }
        />
      </Container>
    </Box>
  );
};

export default withNamespaces("inspection")(CompleteInspection);