import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, FontSizeCalc } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
  },
  total_text: {
    fontSize: FontSizeCalc(16),
    fontFamily: FontFamilySwitch()?.bold,
    color: "#071741",
  },
}));