import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { useStyles } from "./style";
import { FontFamilySwitch, FontSizeCalc } from "../../../utils";
import { TextBox } from "../../../components";

export const Form = ({
  t = () => false,
  isDisabled = true,
  status = "Submitted",
  list = [],
  update = () => false,
}) => {
  const disabled = (isDisabled || status === "Submitted");
  const classes = useStyles();
  return (
    list?.map?.((item, index) => {
      let finalElement = "";

      const label = (
        item?.label?.length > 0
          ? <Stack direction={"row"} justifyContent={"start"} alignItems={"start"}>
            <Typography className={classes.label}>
              {`${item?.order}. ${item?.label}`}
            </Typography>
            {item?.isRequired &&
              <Typography color="error" variant="caption">
                &nbsp;*
              </Typography>}
          </Stack>
          : <></>
      );

      const error = (
        item?.error?.length > 0
          ?
          <Typography color="error" variant="caption">
            {item?.error}
          </Typography>
          : <></>
      );

      switch (item?.type) {
        case "boolean":
          finalElement = (
            <Stack direction={"column"} rowGap={"8px"}>
              {label}
              <FormControl>
                <RadioGroup value={item?.value}
                  onChange={(e) => !disabled && update({ index, type: item?.type, value: e?.target?.value })}>
                  {item?.options?.map?.(_ => {
                    return <FormControlLabel
                      disabled={disabled}
                      label={<Typography className={classes.radioButtonLabel}>{_?.label}</Typography>}
                      value={_?.value}
                      control={<Radio className={classes.radioButton} />} />
                  })}
                </RadioGroup>
              </FormControl>
              {error}
            </Stack>
          );
          break;
        case "check_box":
          finalElement = (
            <Stack direction={"column"} rowGap={"8px"}>
              {label}
              <FormGroup>
                {item?.options?.map?.(_ => {
                  return <FormControlLabel
                    disabled={disabled}
                    label={<Typography className={classes.checkBoxLabel}>{_?.label}</Typography>}
                    control={
                      <Checkbox className={classes.checkBox}
                        checked={item?.value?.includes(_?.value)}
                        onChange={(e) => !disabled && update({ index, type: item?.type, value: _?.value, checked: e?.target?.checked })} />
                    } />
                })}
              </FormGroup>
              {error}
            </Stack>
          );

          break;
        case "rating":
          finalElement = (
            <Stack direction={"column"} rowGap={"8px"}>
              {label}
              <Grid container justifyContent={"start"} gap={"8px"}>
                {item?.options?.map?.(_ => {
                  return <Box sx={{
                    cursor: (disabled)
                      ? "default" : "pointer", display: "flex",
                    justifyContent: "center", alignItems: "center",
                    height: "35px", width: "40px", borderRadius: "4px",
                    backgroundColor: _?.value === item?.value
                      ? ((disabled)
                        ? "#A4B2C1"
                        : "#5078E1")
                      : "#F2F4F7",
                  }}
                    onClick={() => !disabled && update({ index, type: item?.type, value: _?.value })}>
                    <Typography sx={{
                      color: _?.value === item?.value
                        ? "#FFFFFF" : "#4E5A6B",
                      fontSize: FontSizeCalc?.(12),
                      fontFamily: FontFamilySwitch?.()?.regular,
                    }}>
                      {_?.label}
                    </Typography>
                  </Box>
                })}
              </Grid>
              {error}
            </Stack>
          );

          break;
        case "text":
          finalElement = (
            <Stack direction={"column"} rowGap={"8px"}>
              {label}
              <TextBox
                multiline
                disabled={disabled}
                isReadonly={disabled}
                placeholder={t("Enter")}
                value={item?.value}
                onChange={(e) => !disabled && update({ index, type: item?.type, value: e?.target?.value })} />
              {error}
            </Stack>
          );

          break;

        default:
          break;
      }
      return finalElement;
    })
  );
}