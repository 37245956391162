import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { AuthContext } from '../../../contexts'
import { generateImageUrl, img_size } from '../../../utils'
import { DetailHeadCardStyle } from './style'

export const DetailHeadCard = ({ open = "", data = {} }) => {
    const auth = React.useContext(AuthContext)
    const classes = DetailHeadCardStyle({ language: auth?.auth?.auth?.language })
    return (
        <Box>
            <Stack direction={"row"} p={1} className={classes.detailHeadCardRoot}>
                <Avatar className={classes.avatar} src={generateImageUrl(data?.property_facility?.facility_assets?.[0]?.url, img_size.small_square)}></Avatar>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems="center" width={"100%"}>
                    <Stack width={"90%"}>
                        <Typography className={classes.title}>{data?.description}</Typography>
                        <Typography className={classes.content} noWrap>{data?.booking_id}</Typography>
                    </Stack>
                    <Stack>
                        <Typography className={classes.title1} sx={{ textAlign: "right" }}>
                            {data?.currency} {(data?.paid_amount !== null && data?.no_of_slots !== null) ? Number(data?.paid_amount).toFixed(2) ?? 0 / data?.no_of_slots : 0}
                        </Typography>
                        {data?.is_chargeable &&
                            <Box>
                                {
                                    open ?
                                        <Typography className={classes.paid} noWrap>Added to unit Invoice</Typography> :
                                        <Typography className={classes.paid}>{data?.paid_on ? "Paid" : "Unpaid"}</Typography>
                                }
                            </Box>
                        }
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    )
}