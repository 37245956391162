
import { Box } from "@mui/material";
import React from "react";
// import { useHistory } from "react-router-dom";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall/index";
// import { Routes } from "../../../router/routes";
import { AlertContext } from "../../../contexts";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { Body } from "./formgenerator";
const initialState = {
    date: "",
    text: "",
}
export const StatusFrom = ({ t = () => false, details = {}, quotation_id = "", headers = {}, reload = () => false, closeAction = () => false }) => {
    const [data, setData] = React.useState({ ...initialState })
    // const history = useHistory()
    const alert = React.useContext(AlertContext);

    const updateState = (key, value) => {
        setData({ ...data, [key]: value });
    };

    const statusSubmit = (key) => {
        const variable = {
            id: quotation_id,
            update:
            {

                notes: data?.text,
                quote_status: details?.value,
                updated_at: new Date().toISOString(),
            }
        };
        NetworkCall(
            `${config.api_url}/queries/quotation/update`,
            NetWorkCallMethods.post,
            variable,
            headers,
            false,
            false
        )
            .then((response) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Accepted Successfully"),
                });
                closeAction()
                reload()
                // history.push({
                //     pathname: Routes.success,
                //     state: {
                //         customLabel: t("Request Processed"),
                //     },
                // });
            })
            .catch((error) => {
                closeAction()
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
                console.log(error)
            });
    };

    const formData = [
        {
            isActive: details?.id === 2 ? true : false,
            component: "date",
            label: t("Visit Date and Time"),
            value: data?.date,
            onChange: (value) => updateState("date", value),
            placeholder: t("Visit Date and Time"),
        },
        {
            isActive: true,
            component: "text",
            label: t("Remarks (Optional)"),
            value: data?.text,
            multiline: true,
            onChange: (value) => updateState("text", value.target.value),
            placeholder: t("Remarks"),
        },
        {
            isActive: true,
            component: "button",
            label: t("Submit"),
            onClick: () => statusSubmit()
        },


    ]

    return (
        <Box>
            <Body components={formData} />
        </Box>
    )
}