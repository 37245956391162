import { Box, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { FontFamilySwitch, remCalculate } from '../../utils';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const useStyles = makeStyles((theme) => ({
    box: {
        padding: 16,
        borderRadius: "8px",
        boxShadow: "0px 0px 16px #00000014",
        border: "2px solid transparent",
        background: "#fff"
    },
    selectedBox: {
        padding: 16,
        borderRadius: "8px",
        boxShadow: "0px 0px 16px #00000014",
        border: `2px solid ${theme.palette.primary.main}`,
        background: "#fff"
    },
    arrowBox: {
        height: 32,
        width: 32,
        borderRadius: 50,
        border: `1.5px solid ${theme.palette.primary.main}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    arrow: {
        color: theme.palette.primary.main
    },
    label: {
        fontSize: remCalculate(16),
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    subTitle: {
        fontSize: remCalculate(14),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.Tertiary
    },
    amountTitle: {
        fontSize: remCalculate(14),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary
    }
}))

export const PaymentCard = ({
    data = "",
    selected = "",
    onClick = () => false,
    type = "",
    total_amount = 0,
    currency_symbol = ""
}) => {
    const classes = useStyles()
    const transIcons = {
        "Net banking": <AccountBalanceIcon />,
        "Card": <CreditCardIcon />,
        "UPI": <QrCodeScannerIcon />,
        "Wallet": <AccountBalanceWalletIcon />
    }

    const calculateAmount = () => {
        if (data?.charge_type === "percentage") {
            return ((total_amount / 100) * data?.value).toFixed(2)
        } else {
            return data?.value
        }
    }

    return (
        <>
            {
                type === "transaction" ?
                    <>
                        <Stack onClick={() => onClick(data)} className={selected?.label === data?.label ? classes.selectedBox : classes.box}
                            direction={"row"} alignItems={"center"} justifyContent={"space-between"}>

                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                {transIcons[data?.label]}
                                <Stack spacing={1}>
                                    <Typography className={classes.label}>{data?.label}</Typography>
                                    <Typography className={classes.subTitle}>Convenience fee charges: <span className={classes.amountTitle}>{currency_symbol}{calculateAmount()}</span>, Total to pay: <span className={classes.amountTitle}>{currency_symbol}{total_amount}</span></Typography>
                                </Stack>
                            </Stack>


                            <KeyboardArrowRightIcon className={classes.arrow} />
                        </Stack>
                    </>
                    :
                    <Stack onClick={() => onClick(data)} className={selected?.id === data?.id ? classes.selectedBox : classes.box}
                        direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <img src={"/images/razorpayicon.png"} alt='' style={{ height: "28px", width: "120px", objectFit: "contain" }} />
                        <Box className={classes.arrowBox}>
                            <KeyboardArrowRightIcon className={classes.arrow} />
                        </Box>
                    </Stack>
            }

        </>
    )
}