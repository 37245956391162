import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { DatePickerNew, TextBox } from "../../components";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./styles";
import { SelectBox } from "../../components/dropDown";
import BulkImageUpload from "../../components/bulkImageUpload";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { AlertContext } from "../../contexts";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { config } from "../../config";
import { withBottombar } from "../../HOCs";

const AddIdProof = (props) => {
  const InitialState = {
    name: "",
    proofNumber: "",
    startData: "",
    endDate: "",
    issuingCountry: "",
    mandatoryVerify: "",
    bulkUrl: "",
    error: {
      name: "",
      proofNumber: "",
      startData: "",
      endDate: "",
      issuingCountry: "",
      mandatoryVerify: "",
      bulkUrl: ""
    },
  };
  const classes = useStyles(props);
  const history = useHistory();
  const alert = React.useContext(AlertContext);
  const {state}  = useLocation();
  const size = useWindowDimensions();
  let fullSize = size?.height - 140;
  const { t = () => false, handleTopBarState } = props;
  const [bulkUrl, setBulkUrl] = React.useState([]);
  const [data, setdata] = React.useState({ ...InitialState });
  const [disableButton, setDisableButton] = React.useState(false);
  const updateState = (key, value) => { 
    let error = data?.error;
    error[key] = "";
    setdata({ ...data, [key]: value, error });
  };
  
  const isValidData = () => {
    let isValid = true;
    let error = data.error;
    // name: "",
    // proofNumber: "",
    // startData: "",
    // endDate: "",
    // issuingCountry: "",
    // mandatoryVerify: "",
    if (data?.name?.length === 0) {
      isValid = false;
      error.name = t("Occupants name is required");
    } else {
      isValid = true;
      error.name = "";
    }
    //proofNumber
    if (data?.proofNumber?.length === 0) {
      isValid = false;
      error.proofNumber = t("Proof Number is required");
    } else {
      isValid = true;
      error.proofNumber = "";
    }
    //issuingCountry
    if (data?.issuingCountry?.length === 0) {
      isValid = false;
      error.issuingCountry = t("Issuing Country is required");
    } else {
      isValid = true;
      error.issuingCountry = "";
    }
    //mandatoryVerify
    // if (data?.mandatoryVerify?.length === 0) {
    //   isValid = false;
    //   error.mandatoryVerify = "Mandatory Verify is required";
    // } else {
    //   isValid = true;
    //   error.mandatoryVerify = "";
    // }
    //Checking Start Date
    if (data?.startData?.length === 0) {
      isValid = false;
      error.startData = t("Start Date is Required");
    }
    if (data?.endDate?.length === 0) {
      isValid = false;
      error.endDate = t("End Date is Required");
    }
    // Upload Image
    // if (bulkUrl?.length === 0) {
    //   isValid = false;
    //   error.bulkUrl = "ID Proof Attachment Is Required"
    // } else {
    //   isValid = true;
    //   error.bulkUrl = ""
    // }

    setdata({ ...data, error });
    return isValid;
  };

  const submitData = () => {
    if (isValidData()) {
      setDisableButton(true);
      UpdateKycListResident();
    }
  };


  const UpdateKycListResident = (value) => {
    const payload = {
      name: data?.name,
      number: data?.proofNumber,
      valid_to: data?.endDate,
      valid_from: data?.startData,
      is_mandatory: "No",
      country_id: data?.issuingCountry?.value,
      assets: bulkUrl?.map((value)=>{
        return {
          src: value?.file_url,
          name: value?.meta_data?.file_name,
          type: value?.meta_data?.file_type,
          ext: value?.meta_data?.file_ext,
        }
      }),
      proof_id: state?.data?.value,
      proof_type: "Contact",
      kyc_type: "Contact",
    };
    NetworkCall(
      `${config?.api_url}/kyc/create_resident_kyc`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("KYC Added Successfully"),
        });
        history.goBack(-1);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong"),
        });
      setDisableButton(false);
      });
  };

  React.useEffect(() => {
    handleTopBarState({
      text: t("Add ID Proof")
    })
    // eslint-disable-next-line
  }, [])

  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TitleBar text={t("Add ID Proof")} goBack={() => history.goBack(-1)} /> */}
      <div
        className={classes.mainPadding2}
        style={{ height: fullSize, overflow: "auto" }}
      >
        <Box className={classes.formContainer}>
          <div className={classes.mainPadding}>
            <Typography className={classes.supTitle}>{t("Proof Details")}</Typography>
            <div className={classes.input}>
              <TextBox
                isRequired
                color
                labelColor={"#98A0AC"}
                label={t("Name As In Proof")}
                placeholder={t("Enter Name As In Proof")}
                value={data.name}
                onChange={(value) => updateState("name", value.target.value)}
                isError={data?.error?.name?.length > 0}
                errorMessage={data?.error?.name}
              />
            </div>
            <div className={classes.input}>
              <TextBox
                isRequired
                color
                labelColor={"#98A0AC"}
                label={t("ID Proof Number")}
                placeholder={t("Enter ID Proof Number")}
                value={data.proofNumber}
                onChange={(value) =>
                  updateState("proofNumber", value.target.value)
                }
                isError={data?.error?.proofNumber?.length > 0}
                errorMessage={data?.error?.proofNumber}
              />
            </div>
            <div className={classes.input}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DatePickerNew
                    isRequired
                    label={t("Valid From")}
                    labelColor={"#98A0AC"}
                    placeholder={t("Valid From")}
                    value={data?.startData ?? ""}
                    onChange={(value) => updateState("startData", value)}
                    isError={data?.error?.startData?.length > 0}
                    errorMessage={data?.error?.startData}
                    maxDate={new Date()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePickerNew
                    isRequired
                    label={t("Valid To")}
                    labelColor={"#98A0AC"}
                    placeholder={t("Valid To")}
                    value={data?.endDate ?? ""}
                    onChange={(value) => updateState("endDate", value)}
                    isError={data?.error?.endDate?.length > 0}
                    errorMessage={data?.error?.endDate}
                    minDate={new Date(data?.startData)}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.input}>
              <SelectBox
                isRequired
                label={t("Issuing Country")}
                labelColor={"#98A0AC"}
                placeholder={t("Select Issuing Country")}
                loadOptions={(search, array, handleLoading) =>
                  loadOptionsApis(
                    "queries/opportunity/country_master",
                    {},
                    search,
                    array,
                    handleLoading,
                    "data",                    
                  )
                }
                isPaginate
                debounceTimeout={800}
                value={data.issuingCountry}
                onChange={(value) => {
                  updateState("issuingCountry", value);
                }}
                isError={data?.error?.issuingCountry?.length > 0}
                errorMessage={data?.error?.issuingCountry}
              />
            </div>
            {/* <div className={classes.input}>
              <ButtonGroupSelect
                label={t("Mandatory Verify")}
                data={mandatoryVerify}
                state={data}
                stateName={"mandatoryVerify"}
                updateState={updateState}
                isRequired
              />
            </div> */}
          </div>
          <Divider sx={{ borderColor: "#E4E8EE", marginBottom: "10px" }} />
          <div className={classes.mainPadding}>
            <Typography sx={{color:"#98A0AC !important"}} className={classes.supTitle}>
              {t("ID Proof Attachment")}
            </Typography>
            <Box height={"8px"} />
            <BulkImageUpload
              bulkUrl={bulkUrl}
              setBulkUrl={setBulkUrl}
            />
          </div>
        </Box>
      </div>
      <Grid Container p={2} sx={{ backgroundColor: "#fff" }}>
        <Grid item xs={12}>
          <Button
            fullWidth
            onClick={submitData}
            className={classes.primary}
            variant="contained"
            disabled={disableButton}
          >
            {t("Add Proof")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("kycInformation")(AddIdProof), props);
