export const Currency = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Currency Switcher">
            <path
                fill="#5078e1"
                stroke="#5078e1"
                strokeLinecap="round"
                strokeWidth={0.2}
                d="M8.4 3a5.4 5.4 0 0 0 0 10.8 4.681 4.681 0 0 0 .711-.049 6.568 6.568 0 0 1 .652-1.539 4.056 4.056 0 1 1 2.449-2.449 6.568 6.568 0 0 1 1.539-.652A4.681 4.681 0 0 0 13.8 8.4 5.4 5.4 0 0 0 8.4 3Zm8.475 0a.521.521 0 0 0-.371.154L15.454 4.2a.524.524 0 0 0 0 .742L16.5 6a.525.525 0 0 0 .9-.372V5.25h.225a1.115 1.115 0 0 1 1.125 1.125v.9a.675.675 0 1 0 1.35 0v-.9A2.485 2.485 0 0 0 17.625 3.9H17.4v-.374A.527.527 0 0 0 16.875 3ZM8.4 5.587a2.467 2.467 0 0 0-2.269 2.25h-.318a.563.563 0 1 0 0 1.125h.318a2.467 2.467 0 0 0 2.269 2.25 2.4 2.4 0 0 0 2.21-1.535.563.563 0 1 0-1.042-.424 1.163 1.163 0 0 1-1.167.834A1.349 1.349 0 0 1 7.25 8.962h.587a.563.563 0 1 0 0-1.125H7.25A1.349 1.349 0 0 1 8.4 6.712a1.163 1.163 0 0 1 1.167.834.563.563 0 1 0 1.042-.424A2.4 2.4 0 0 0 8.4 5.587Zm7.2 4.613a5.4 5.4 0 1 0 5.4 5.4 5.4 5.4 0 0 0-5.4-5.4Zm0 1.35a4.04 4.04 0 1 1-2.867 1.183A4.05 4.05 0 0 1 15.6 11.55Zm-.009.465a.563.563 0 0 0-.554.57v.345a1.628 1.628 0 0 0 .309 3.232h.619a.507.507 0 1 1 0 1.012h-.8a.85.85 0 0 1-.567-.207.562.562 0 1 0-.931.631 1.856 1.856 0 0 0 1.368.693v.323a.563.563 0 1 0 1.125 0v-.334a1.626 1.626 0 0 0-.2-3.243h-.619a.507.507 0 1 1 0-1.013h.592a.534.534 0 0 1 .423.185.563.563 0 0 0 .954-.6 1.643 1.643 0 0 0-1.152-.694v-.335a.563.563 0 0 0-.571-.57ZM4.564 16.04a.675.675 0 0 0-.664.685v.9A2.485 2.485 0 0 0 6.375 20.1H6.6v.374a.525.525 0 0 0 .9.372L8.55 19.8a.524.524 0 0 0 0-.742L7.5 18a.525.525 0 0 0-.9.372v.374h-.225a1.115 1.115 0 0 1-1.125-1.121v-.9a.675.675 0 0 0-.686-.685Z"
                data-name="icons8-currency-exchange (1)"
            />
        </g>
    </svg>
)