import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, remCalculate } from "../../utils";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const useStyles = makeStyles((theme) => ({
      backgroundImage: {
            backgroundColor: "#F2F4F7",
            height: "213px",
            backgroundImage: `url("/images/Select house-cuate.svg")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "100%"
      },
      content: {
            width: "inherit",
      },
      text: {
            fontSize: "1.5rem",
            fontFamily: FontFamilySwitch().bold,
            color: theme.typography.color.primary,
      },
      btn: {
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 6px 10px #00000014",
            padding: "16px",
            "&:hover": {
                  color: "#FFFFFF",
                  backgroundColor: "#5078E1",
            },
      },
      buttonTextStyle: {
            fontSize: "0.875rem",
            color: "#FFFFFF",
            fontFamily: FontFamilySwitch().bold,
      },
      img: {
            height: "8%",
            width: "28%",
      },
      contentBottom: {
            padding: "12px",
      },

      companyName: {
            color: theme.typography.color.secondary,
            fontFamily: FontFamilySwitch().bold,
            fontSize: "0.875rem",
            marginInlineStart: '12px'
      },
      companySelect: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
      },
      comapnyImage: {
            width: 50,
            height: 50,
            borderRadius: '0px'
      },
      selectCompany: {
            padding: '14px',
            boxShadow: '0px 3px 30px #5C86CB2E',
            margin: '8px 0px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer'
      },
      selectCompanyBorder: {
            padding: '14px',
            boxShadow: '0px 3px 30px #5C86CB2E',
            margin: '8px 0px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: `1px solid ${theme.palette.primary.main}`,
            cursor: 'pointer'
      },
      goBackStyle: {
            textAlign: 'center',
            textDecoration: 'underline',
            cursor: 'pointer',
            color: "#5078E1",
            fontSize: "0.875rem",
            fontFamily: FontFamilySwitch().bold,
            marginTop: '14px'
      },
      selectMain: {
            padding: '16px'
      },
      paddingCompany: {
            padding: "16px 16px 25px 16px"
      },
      version: {
            fontFamily: FontFamilySwitch().semiBold,
            fontSize: "0.75rem",
            color: "#091B29"
      }
}));

export const welcomePageUseStyles = makeStyles((theme) => ({
      root: {
            height: "100vh",
            backgroundColor: "white",
      },
      grid: {
            height: ({ size }) => size?.height,
            alignItems: "center",
      },
      left_section: {
            height: ({ size }) => size?.height,
            alignItems: "center",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            [theme.breakpoints.down("sm")]: {
                  display: "none"
            },
      },
      right_section: {
            height: ({ size }) => size?.height,
            alignItems: "center",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            display: "flex",
            justifyContent: "end",
      },
      web_right_section_card: {
            display: "contents",
            height: ({ size }) => size?.height,
            padding: "0px",
      },
      web_content: {
            height: "100vh",
            overflow: "overlay",
            display: "flex",
            position: "relative",
            padding: "40px 0px",
            backgroundColor: "white",
      },
      web_logo: {
            height: "32px",
            objectFit: "contain",
      },
      web_description: {
            fontSize: remCalculate(20),
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.secondary,
      },
      web_set_up_new_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius3,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: theme.palette.background.button_background_1,
            border: "1px solid #5078E1",
      },
      web_set_up_new_button_text: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.contrastText,
      },
      web_log_in_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius3,
            boxShadow: "0px 6px 10px #00000014",
            border: "1px solid #5078E1",
            "&:hover": {
                  backgroundColor: theme.palette.background.tertiary1,
            },
      },
      web_log_in_button_text: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.primary_2,
      },
      web_terms_of_use_description: {
            textAlign: "center",
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().regular,
            color: theme.typography.color.secondary,
      },
      web_terms_of_use_word: {
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().bold,
            color: theme.typography.color.secondary,
            textDecoration: "underline",
            display: "inline",
            cursor: "pointer",
      },
      web_version_no: {
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().regular,
            color: theme.typography.color.Tertiary,
      },
      web_powered_by_logo: {
            height: "11px",
            objectFit: "contain",
      },
      mob_right_section_card: {
            height: ({ size }) => size?.height,
            alignItems: "center",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            padding: "0px",
      },
      mob_content: {
            paddingTop: ({ size }) => size?.height - 336,
            overflow: "overlay",
      },
      mob_content_welcome: {
            height: ({ size }) => size?.height,
            overflow: "overlay",
      },
      mob_items: {
            padding: "16px 16px 18px 16px",
      },
      mob_logo: {
            height: "32px",
            objectFit: "contain",
      },
      mob_description: {
            fontSize: remCalculate(20),
            fontFamily: FontFamilySwitch().semiBold,
            color: "#FEF4F4",
            textShadow: "0px 6px 10px #00000033",
      },
      mob_set_up_new_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: "#5078E1",
            border: "1px solid #5078E1",
            "&:hover": {
                  backgroundColor: "#5078E1",
            },
      },
      mob_set_up_new_button_text: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().semiBold,
            color: "white",
      },
      mob_log_in_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: "#ffffff75",
            "&:hover": {
                  backgroundColor: "#ffffff75",
            },
      },
      mob_log_in_button_text: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().semiBold,
            color: "white",
      },
      mob_terms_of_use_description: {
            width: "100%",
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().regular,
            color: "white",
      },
      mob_terms_of_use_word: {
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().bold,
            color: "white",
            textDecoration: "underline",
            display: "inline",
            cursor: "pointer",
      },
      drawer: {
            "& .MuiDrawer-paper": {
                  minWidth: ({ size }) => size?.width > 599 && "500px",
                  maxWidth: ({ size }) => size?.width > 599 && "500px",
                  borderTopRightRadius: "4px",
                  borderTopLeftRadius: "4px",
            },
            "& .MuiContainer-root": {
                  padding: "0px 8px",
            },
      },
      drawer_header_grid: {
            display: "flex",
            alignItems: "center",
            justifyContent: ({ size }) => size?.width > 599 ? "start" : "end",
            padding: "16px",
      },
      drawer_header_text: {
            marginLeft: "12px",
            fontSize: remCalculate(16),
            fontFamily: FontFamilySwitch().extraBold,
            color: theme.typography.color.primary,
      },
      drawer_content_box: {
            height: ({ size }) => size?.width > 599 ? "calc(100vh - 145px)" : "450px",
            overflow: "overlay",
            padding: "16px",
      },
      drawer_button_box: {
            padding: "16px",
            borderTop: `1px solid ${theme.palette.border.secondary}`
      },
      agree_and_start_button: {
            height: "48px",
            fontFamily: FontFamilySwitch().bold,
      },
      welcome_backdrop: {
            width: "100%",
            objectFit: "contain",
      },
}));

export const setupAccountUseStyles = makeStyles((theme) => ({
      root: {
            height: "100vh",
            backgroundColor: "white",
      },
      grid: {
            height: ({ size }) => size?.height,
            alignItems: "center",
      },
      left_section: {
            height: ({ size }) => size?.height,
            alignItems: "center",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            [theme.breakpoints.down("sm")]: {
                  display: "none"
            },
      },
      right_section: {
            height: ({ size }) => size?.height,
            alignItems: "center",
            display: "flex",
            justifyContent: "end",
      },
      web_right_section_card: {
            display: "contents",
            height: ({ size }) => size?.height,
            padding: "0px",
      },
      web_back_button: {
            cursor: "pointer",
            height: "32px",
            width: "32px",
      },
      web_content: {
            height: "100vh",
            overflow: "overlay",
            display: "flex",
            position: "relative",
            padding: "40px",
            backgroundColor: "white",
      },
      web_logo: {
            height: "32px",
            objectFit: "contain",
      },
      web_title: {
            fontSize: remCalculate(16),
            fontFamily: FontFamilySwitch().bold,
            color: theme.typography.color.primary,
      },
      web_description: {
            fontSize: remCalculate(16),
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.secondary,
      },
      web_forget_password: {
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.primary_2,
            textAlign: "end",
            cursor: "pointer",
      },
      web_login_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius_2,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: theme.palette.background.button_background_1,
            border: "1px solid #5078E1",
      },
      web_login_button_text: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.white,
      },
      web_log_in_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius_2,
            boxShadow: "0px 6px 10px #00000014",
            border: "1px solid #5078E1",
            "&:hover": {
                  backgroundColor: theme.palette.background.tertiary1,
            },
      },
      web_log_in_button_text: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.primary_2,
      },
      mob_right_section_card: {
            padding: "0px",
      },
      // mob_content: {
      //       height: "calc(100vh - 54px)",
      //       overflow: "overlay",
      // },
      mob_body_items: {
            padding: "16px",
      },
      mob_background: {
            height: "235px",
            borderRadius: theme.palette.borderRadius,
      },
      mob_background_image: {
            height: "235px",
            width: "100%",
            borderRadius: theme.palette.borderRadius,
      },
      mob_bottom_items: {
            padding: "16px",
      },
      mob_logo: {
            height: "32px",
            objectFit: "contain",
      },
      mob_description: {
            fontSize: remCalculate(20),
            fontFamily: FontFamilySwitch().semiBold,
            color: "#FEF4F4",
            textShadow: "0px 6px 10px #00000033",
      },
      mob_set_up_new_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: "#5078E1",
            border: "1px solid #5078E1",
            "&:hover": {
                  backgroundColor: "#5078E1",
            },
      },
      mob_set_up_new_button_text: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().semiBold,
            color: "white",
      },
      mob_log_in_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: "#ffffff75",
            "&:hover": {
                  backgroundColor: "#ffffff75",
            },
      },
      mob_log_in_button_text: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().semiBold,
            color: "white",
      },
      mob_terms_of_use_description: {
            width: "100%",
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().regular,
            color: "white",
      },
      mob_terms_of_use_word: {
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().bold,
            color: "white",
            textDecoration: "underline",
            display: "inline",
            cursor: "pointer",
      },
      version_no: {
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().regular,
            color: theme.typography.color.tertiary,
      },
      terms_of_use_word: {
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().regular,
            color: theme.typography.color.tertiary,
            cursor: "pointer",
      },
      powered_by_logo: {
            height: "10px",
            objectFit: "contain",
      },
      drawer: {
            "& .MuiDrawer-paper": {
                  minWidth: ({ size }) => size?.width > 599 && "500px",
                  maxWidth: ({ size }) => size?.width > 599 && "500px",
                  borderTopRightRadius: "4px",
                  borderTopLeftRadius: "4px",
            },
            "& .MuiContainer-root": {
                  padding: "0px 8px",
            },
      },
      drawer_header_grid: {
            display: "flex",
            alignItems: "center",
            justifyContent: ({ size }) => size?.width > 599 ? "start" : "end",
            padding: "16px",
      },
      drawer_header_text: {
            marginLeft: "12px",
            fontSize: remCalculate(16),
            fontFamily: FontFamilySwitch().extraBold,
            color: theme.typography.color.primary,
      },
      drawer_content_box: {
            height: ({ size }) => size?.width > 599 ? "calc(100vh - 64px)" : "450px",
            overflow: "overlay",
            padding: "16px",
      },
      drawer_button_box: {
            padding: "16px",
            borderTop: `1px solid ${theme.palette.border.secondary}`
      },
      region_flag: {
            borderRadius: "50%",
            objectFit: "contain",
            height: "18px",
            width: "18px",
      },
      flag_dropdown: {
            cursor: "pointer",
            height: "48px",
            padding: "14px",
            border: "1px solid #E4E8EE",
            borderRadius: theme.palette.borderRadius,
      },
      region_text: {
            fontSize: "0.875rem",
      },
}));

export const LightTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props}
            classes={{
                  popper: className,
                  arrow: {
                        backgroundColor: "#525252",
                        cursor: "pointer",
                  },
            }} />
))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
            borderRadius: "4px",
            padding: "6px",
            backgroundColor: "#525252",
            color: theme?.typography?.color?.white,
            fontSize: "0.875rem",
            fontFamily: FontFamilySwitch().semiBold,
            cursor: "pointer",
      },
}));

export const companySelectUseStyles = makeStyles((theme) => ({
      root: {
            height: "100vh",
            backgroundColor: "white",
      },
      grid: {
            height: ({ size }) => size?.height,
            alignItems: "center",
      },
      left_section: {
            height: ({ size }) => size?.height,
            alignItems: "center",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            [theme.breakpoints.down("sm")]: {
                  display: "none"
            },
      },
      right_section: {
            height: ({ size }) => size?.height,
            alignItems: "center",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            display: "flex",
            justifyContent: "end",
      },
      web_right_section_card: {
            display: "contents",
            height: ({ size }) => size?.height,
            padding: "0px",
      },
      web_back_button: {
            cursor: "pointer",
            height: "32px",
            width: "32px",
      },
      web_content: {
            height: "100vh",
            overflow: "overlay",
            display: "flex",
            position: "relative",
            padding: "40px",
            backgroundColor: "white",
      },
      web_logo: {
            height: "32px",
            objectFit: "contain",
      },
      web_title: {
            fontSize: remCalculate(16),
            fontFamily: FontFamilySwitch().bold,
            color: theme.typography.color.primary,
      },
      web_description: {
            fontSize: remCalculate(16),
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.secondary,
      },
      web_forget_password: {
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.primary_2,
            textAlign: "end",
            cursor: "pointer",
      },
      web_login_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius_2,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: theme.palette.background.button_background_1,
            border: "1px solid #5078E1",
      },
      web_login_button_text: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.white,
      },
      web_log_in_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius_2,
            boxShadow: "0px 6px 10px #00000014",
            border: "1px solid #5078E1",
            "&:hover": {
                  backgroundColor: theme.palette.background.tertiary1,
            },
      },
      web_log_in_button_text: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.primary_2,
      },
      drawer: {
            "& .MuiDrawer-paper": {
                  minWidth: ({ size }) => size?.width > 599 && "500px",
                  maxWidth: ({ size }) => size?.width > 599 && "500px",
                  borderTopRightRadius: "4px",
                  borderTopLeftRadius: "4px",
            },
            "& .MuiContainer-root": {
                  padding: "0px 8px",
            },
      },
      drawer_header_grid: {
            display: "flex",
            alignItems: "center",
            justifyContent: ({ size }) => size?.width > 599 ? "start" : "end",
            padding: "16px",
      },
      drawer_header_text: {
            marginLeft: "12px",
            fontSize: remCalculate(16),
            fontFamily: FontFamilySwitch().extraBold,
            color: theme.typography.color.primary,
      },
      drawer_content_box: {
            height: ({ size }) => size?.width > 599 ? "calc(100vh - 64px)" : "450px",
            overflow: "overlay",
            padding: "16px",
      },
      drawer_button_box: {
            padding: "16px",
            borderTop: `1px solid ${theme.palette.border.secondary}`
      },
      selectCompanyBorder: {
            padding: '14px',
            boxShadow: '0px 3px 30px #5C86CB2E',
            margin: '8px 0px',
            borderRadius: theme.palette.borderRadius,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: `2px solid ${theme.palette.primary.main}`,
            cursor: 'pointer',
            width: '100%'
      },
      selectCompany: {
            padding: '14px',
            border: `2px solid ${theme.palette.border.secondary}`,
            margin: '8px 0px',
            borderRadius: theme.palette.borderRadius,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            width: '100%'
      },
      companySelect: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
      },
      comapnyImage: {
            width: "50px",
            height: "50px",
            borderRadius: theme.palette.borderRadius,
            border: `1px solid ${theme.palette.border.secondary}`,
      },
      companyName: {
            color: theme.typography.color.primary,
            fontFamily: FontFamilySwitch().bold,
            fontSize: "0.875rem",
            marginLeft: '12px'
      },
      tickIcon: {
            color: "#5078e1",
            fontSize: "2.5rem"
      },
      mob_right_section_card: {
            padding: "0px",
      },
      // mob_content: {
      //       height: "calc(100vh - 54px)",
      //       overflow: "overlay",
      // },
      mob_body_items: {
            padding: "16px",
      },
      mob_background: {
            height: "235px",
            borderRadius: theme.palette.borderRadius,
      },
      mob_background_image: {
            height: "235px",
            width: "100%",
            borderRadius: theme.palette.borderRadius,
      },
      mob_bottom_items: {
            padding: "16px",
      },
      mob_logo: {
            height: "32px",
            objectFit: "contain",
      },
      mob_description: {
            fontSize: remCalculate(20),
            fontFamily: FontFamilySwitch().semiBold,
            color: "#FEF4F4",
            textShadow: "0px 6px 10px #00000033",
      },
      mob_set_up_new_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: "#5078E1",
            border: "1px solid #5078E1",
            "&:hover": {
                  backgroundColor: "#5078E1",
            },
      },
      mob_set_up_new_button_text: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().semiBold,
            color: "white",
      },
      mob_log_in_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: "#ffffff75",
            "&:hover": {
                  backgroundColor: "#ffffff75",
            },
      },
      mob_log_in_button_text: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().semiBold,
            color: "white",
      },
      mob_terms_of_use_description: {
            width: "100%",
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().regular,
            color: "white",
      },
      mob_terms_of_use_word: {
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().bold,
            color: "white",
            textDecoration: "underline",
            display: "inline",
            cursor: "pointer",
      },
      version_no: {
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().regular,
            color: theme.typography.color.tertiary,
      },
      terms_of_use_word: {
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().regular,
            color: theme.typography.color.tertiary,
            cursor: "pointer",
      },
      powered_by_logo: {
            height: "11px",
            objectFit: "contain",
      },
}));

export const loginUseStyles = makeStyles((theme) => ({
      root: {
            height: "100vh",
            backgroundColor: "white",
      },
      grid: {
            height: ({ size }) => size?.height,
            alignItems: "center",
      },
      left_section: {
            height: ({ size }) => size?.height,
            alignItems: "center",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            [theme.breakpoints.down("sm")]: {
                  display: "none"
            },
      },
      right_section: {
            height: ({ size }) => size?.height,
            alignItems: "center",
            display: "flex",
            justifyContent: "end",
      },
      web_right_section_card: {
            display: "contents",
            height: ({ size }) => size?.height,
            padding: "0px",
      },
      web_back_button: {
            cursor: "pointer",
            height: "32px",
            width: "32px",
      },
      web_content: {
            height: "100vh",
            overflow: "overlay",
            display: "flex",
            position: "relative",
            padding: "40px",
            backgroundColor: "white",
      },
      web_logo: {
            height: "32px",
            objectFit: "contain",
      },
      web_title: {
            fontSize: remCalculate(16),
            fontFamily: FontFamilySwitch().bold,
            color: theme.typography.color.primary,
      },
      web_description: {
            fontSize: remCalculate(16),
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.secondary,
      },
      web_forget_password: {
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.primary_2,
            textAlign: "end",
            cursor: "pointer",
      },
      web_login_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius_2,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: theme.palette.background.button_background_1,
            border: "1px solid #5078E1",
      },
      web_login_button_text: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.white,
      },
      web_log_in_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius_2,
            boxShadow: "0px 6px 10px #00000014",
            border: "1px solid #5078E1",
            "&:hover": {
                  backgroundColor: theme.palette.background.tertiary1,
            },
      },
      web_log_in_button_text: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.primary_2,
      },
      mob_right_section_card: {
            padding: "0px",
      },
      // mob_content: {
      //       height: "calc(100vh - 54px)",
      //       overflow: "overlay",
      // },
      mob_body_items: {
            padding: "16px",
      },
      mob_background: {
            height: "235px",
            borderRadius: theme.palette.borderRadius,
      },
      mob_background_image: {
            height: "235px",
            width: "100%",
            borderRadius: theme.palette.borderRadius,
      },
      mob_bottom_items: {
            padding: "16px",
      },
      mob_logo: {
            height: "32px",
            objectFit: "contain",
      },
      mob_description: {
            fontSize: remCalculate(20),
            fontFamily: FontFamilySwitch().semiBold,
            color: "#FEF4F4",
            textShadow: "0px 6px 10px #00000033",
      },
      mob_set_up_new_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: "#5078E1",
            border: "1px solid #5078E1",
            "&:hover": {
                  backgroundColor: "#5078E1",
            },
      },
      mob_set_up_new_button_text: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().semiBold,
            color: "white",
      },
      mob_log_in_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: "#ffffff75",
            "&:hover": {
                  backgroundColor: "#ffffff75",
            },
      },
      mob_log_in_button_text: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().semiBold,
            color: "white",
      },
      mob_terms_of_use_description: {
            width: "100%",
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().regular,
            color: "white",
      },
      mob_terms_of_use_word: {
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().bold,
            color: "white",
            textDecoration: "underline",
            display: "inline",
            cursor: "pointer",
      },
      version_no: {
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().regular,
            color: theme.typography.color.tertiary,
      },
      terms_of_use_word: {
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().regular,
            color: theme.typography.color.tertiary,
            cursor: "pointer",
      },
      powered_by_logo: {
            height: "11px",
            objectFit: "contain",
      },
      drawer: {
            "& .MuiDrawer-paper": {
                  minWidth: ({ size }) => size?.width > 599 && "500px",
                  maxWidth: ({ size }) => size?.width > 599 && "500px",
                  borderTopRightRadius: "4px",
                  borderTopLeftRadius: "4px",
            },
            "& .MuiContainer-root": {
                  padding: "0px 8px",
            },
      },
      drawer_header_grid: {
            display: "flex",
            alignItems: "center",
            justifyContent: ({ size }) => size?.width > 599 ? "start" : "end",
            padding: "16px",
      },
      drawer_header_text: {
            marginLeft: "12px",
            fontSize: remCalculate(16),
            fontFamily: FontFamilySwitch().extraBold,
            color: theme.typography.color.primary,
      },
      drawer_content_box: {
            height: ({ size }) => size?.width > 599 ? "calc(100vh - 64px)" : "450px",
            overflow: "overlay",
            padding: "16px",
      },
      drawer_button_box: {
            padding: "16px",
            borderTop: `1px solid ${theme.palette.border.secondary}`
      },
      region_box: {
            cursor: "pointer",
            height: "40px",
            padding: "7px 4px 7px 8px",
            display: "flex",
            alignItems: "center",
            border: "1px solid #E4E8EE",
            borderRadius: "20px",
            columnGap: "2px",
      },
      region_flag: {
            borderRadius: "50%",
            objectFit: "contain",
            height: "18px",
            width: "18px",
      },
      region_menu_list: {
            "& .MuiPopover-paper": {
                  maxHeight: "250px",
                  minWidth: "200px",
                  marginTop: "10px",
                  padding: "8px 16px",
                  boxShadow: "0 0px 8px -4px #f2f3f5",
                  border: "1px solid #f2f3f5",
                  borderRadius: "5px",
            },
      },
      selected_region_menu_item: {
            border: "1px solid #5078E1",
            borderRadius: theme.palette.borderRadius,
            padding: "12px 5px",
            backgroundColor: "#F1F7FF",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
      },
      unselected_region_menu_item: {
            borderRadius: theme.palette.borderRadius,
            padding: "12px 5px",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
      },
      selected_region_name: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.selected_1
      },
      unselected_region_name: {
            fontSize: remCalculate(14),
            fontFamily: FontFamilySwitch().regular,
            color: theme.typography.color.primary
      },
      exploreCard: {
            cursor: "pointer",
            backgroundColor: "#F8EBFF",
            borderRadius: theme.palette.borderRadius3,
            border: "1px solid #E8D5F2",
            padding: "10px 12px",
      },
      exploreInformation: {
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().bold,
            color: "#4A0A77",
      },
      exploreCTA: {
            fontSize: remCalculate(12),
            fontFamily: FontFamilySwitch().regular,
            color: "#4A0A77",
      },
}));