import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  Container,
  Stack,
  IconButton,
  Divider,
} from "@mui/material";
import moment from "moment-timezone";
import { NetWorkCallMethods, AlertProps, LocalStorageKeys } from "../../utils";
import { withBottombar } from "../../HOCs";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import InfiniteScroll from "react-infinite-scroll-component";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { LoderSimmer, DialogDrawer } from "../../components";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import NoDataFound from "../../components/noDataFound/noDataFound";
import { Routes } from "../../router/routes";
import AgreementDownlaod from "./agreementDownlaod";
import { withNamespaces } from "react-i18next";

const Allagreement = (props) => {
  const { handleTopBarState, t } = props
  const history = useHistory();
  const alert = useContext(AlertContext);
  const classes = useStyles();
  const [state, setState] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loader, setLoader] = useState(true);
  const size = useWindowDimensions();
  const [drawer, setDrawer] = React.useState(false);
  const [agreement, setAgreement] = React.useState({});
  const [pdfData, setPdfData] = React.useState({ load: false, data: "" });
  const getAgreementData = (offset, filter) => {
    const data = {
      start: offset ?? 0,
      end: 20,
    };
    NetworkCall(
      `${config.api_url}/agreement/get_all_agreements`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter) {
          setState(state.concat(response?.data?.data));
        } else {
          setState(response?.data?.data);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went wrong!"),
        });
      });
  };

  const fetchMoreData = () => {
    const tempOffset = offset + 10;
    setOffset(tempOffset);
    getAgreementData(tempOffset, true);
  };

  const statusComponent = (val) => {
    switch (val) {
      case "upcoming":
        return <span className={classes.upcoming}>{val}&nbsp;</span>;
      case "terminated":
        return <span className={classes.expired}>{val}&nbsp;</span>;
      default:
        return null;
    }
  };

  useEffect(() => {
    handleTopBarState({
      text: t("All Agreements")
    })
    setLoader(true);
    getAgreementData();
    // eslint-disable-next-line
  }, []);
  const updateagrement = (data) => {
    localStorage.setItem(LocalStorageKeys.agreement, JSON.stringify(data));
    history.replace(Routes.agreement);
  };
  
  const downloadPdf = (data) => {
    setPdfData({...pdfData, load: true});
    setDrawer(true)
    setAgreement(data);

    const payload = {
      id: data?.id,
    }
    NetworkCall(
      `${config.api_url}/agreement/template`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setPdfData({ load: false, data: response?.data ?? "" });

      })
      .catch((error) => {
        setPdfData({ load: false });

        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing went wrong"),
        });
      });
  };
  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TitleBar text={"All Agreements"} goBack={() => history.goBack(-1)} /> */}
      <Stack
        mt={1}
        mb={0.6}
        pr={2}
        pl={2}
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Typography className={classes.Tittle}>
          ({state?.length <= 9 ? "0" + state?.length : state?.length})
          {t("Agreements")}
        </Typography>
        {/* <Box
          className={classes.filterBlock}
          //   onClick={() => setOpenFilter(true)}
        >
          <Badge color="primary" variant="dot">
            <img src="/images/funnel.svg" alt="" />
          </Badge>
        </Box> */}
      </Stack>
      <div
        className={classes.main}
        style={{ height: size?.height - 155, overflow: "overlay" }}
      >
        {loader ? (
          <LoderSimmer card count={10} size={100} />
        ) : (
          <>
            {state.length > 0 ? (
              <InfiniteScroll
                dataLength={state?.length}
                next={fetchMoreData}
                hasMore={true}
                height={size?.height - 165}
              >
                {state?.length > 0 &&
                  state?.map((val) => {
                    return (
                      <>
                        <Box className={classes.list}>
                          <Box className={classes.agreementBlock}>
                            {/* agreement image */}
                            <Box
                              className={classes.agreementImg}
                              onClick={() =>
                                val?.status === "expired"
                                  ? updateagrement(val)
                                  : ""
                              }
                            >
                              <img
                                src="/images/agreementnew.svg"
                                alt="agreement_img"
                              />
                            </Box>
                            {/* Agreement number */}
                            <Box
                              style={{ marginInlineStart: "10px" }}
                              flexGrow={1}
                              onClick={() =>
                                val?.status === "expired"
                                  ? updateagrement(val)
                                  : ""
                              }>
                              <div>
                                <Stack direction={"row"} alignItems={"center"}>
                                  <Typography className={classes.cardTittle}>
                                    {val?.agreement_no ?? ""}
                                  </Typography>
                                  <Typography sx={{ marginInlineStart: "8px", height: "24px" }} className={classes.cardTittle}>
                                    {statusComponent(val?.status)}
                                  </Typography>
                                </Stack>
                                <Box className={classes.box}>
                                  <Typography className={classes.lease}>
                                    {val?.lease_start_date &&
                                      moment(val?.lease_start_date).format(
                                        "DD MMM YY"
                                      )}
                                    &nbsp;{" - "}
                                  </Typography>

                                  <Typography className={classes.lease}>
                                    &nbsp;
                                    {moment(val?.lease_end_date).format(
                                      "DD MMM YY"
                                    )}
                                  </Typography>
                                </Box>
                              </div>
                            </Box>
                            <IconButton
                              size="small"
                              onClick={() => {
                                downloadPdf(val);
                              }}
                            >
                              <img
                                className={classes.downlaodImg}
                                src="/images/download.svg"
                                alt=""
                              />
                            </IconButton>
                          </Box>
                          <Box className={classes.bottomBorder} />
                          <Box className={classes.propertyBlock}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-around"}
                              divider={
                                <Divider
                                  orientation="horizontal"
                                  sx={{
                                    borderColor: "#E4E8EE",
                                    borderWidth: "1px",
                                  }}
                                />
                              }
                            >
                              <Stack
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                              >
                                <img src="/images/apartmentIcon.svg" alt="" />
                                <Typography sx={{ marginInlineStart: "8px"}} className={classes.lease}>
                                  {val?.total_properties}{" "} {t("Properties")}
                                </Typography>
                              </Stack>
                              <Stack
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                              >
                                <img src="/images/unitss.svg" alt="" />
                                <Typography sx={{ marginInlineStart: "8px"}} className={classes.lease}>
                                  {val?.total_units}{" "} {t("Units")}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Box>
                        </Box>
                      </>
                    );
                  })}
              </InfiniteScroll>
            ) : (
              <NoDataFound
                height={size?.height - 210}
                content={t("No Data Found!")}
              />
            )}
          </>
        )}
      </div>
      <DialogDrawer
        open={drawer}
        onClose={() => {
          setDrawer(false)
          setPdfData({ data: "", load: false })
        }}
        isnotTitle={false}
        header={agreement?.agreement_no}
        component={<AgreementDownlaod t={t} agreementData={agreement} pdfData={pdfData} />}
      />
    </Container>
  );
};

const props = {
  top_bar: "title_bar",
}


export default withNamespaces("agreement")(withBottombar(Allagreement, props));
