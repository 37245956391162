import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { AsPerAgreement, ArrowIcon } from "../../../assets";
import { useStyles } from "./style";
import { AgreementRenewalType } from "../../../utils";
export const TypeOfRenewal = ({ t = () => false ,handleClick=()=>false , allow_extension=false}) => {
  const classes = useStyles();
  const language = localStorage.getItem("i18nextLng")
  return (
    <Box p={2}>
      <Grid container spacing={2}>
        {AgreementRenewalType(t , allow_extension)?.filter((c)=>c?.is_active === true)?.map((x , i) => {
          return (
            <Grid item xs={12}>
              <Box
                className={classes.card}
                style={{ backgroundColor: x?.backGroundColor }}
                onClick={()=>handleClick(i + 1)}
              >
                <Box display="flex" alignItems="center">
                  <Box>
                    {" "}
                    <AsPerAgreement />
                  </Box>
                  <Box width={"10px"} />
                  <Box>
                    <Typography className={classes.asPerAgreement}>
                      {x?.title}
                    </Typography>
                    <Typography className={classes.asPerAgreementSub}>
                      {x?.subTitle}
                    </Typography>
                  </Box>
                </Box>
                <div style={{ rotate: language === "ar" && ("180deg")}}>
                <ArrowIcon />
                </div>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
