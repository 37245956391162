import { Button, Grid } from "@mui/material";
import React from "react";
import { withBottombar } from "../../HOCs";
import { AddWorkerPassContext } from "./addWorkerPassContext";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";

const AddWorkerPass = ({ handleTopBarState }) => {
    const {
        t = () => false,
        classes,
        step,
        handlePrevious,
        handleNext,
        isDisableBtn
    } = React.useContext(AddWorkerPassContext);

    React.useEffect(() => {
        handleTopBarState({
            text: t("Add Worker Pass")
        })
        // eslint-disable-next-line
    }, []);
    return (
        <>
            <div className={classes.root}>
                {step === 0 && <Step3 />}
                {step === 1 && <Step1 />}
                {step === 2 && <Step2 />}
            </div>
            <Grid
                container
                className={classes.btnroot}
                justifyContent="space-around"
                spacing={step === 0 ? 0 : 1}>
                {step !== 0 &&
                    <Grid item xs={6}>
                        <Button
                            className={classes.secondary}
                            fullWidth
                            onClick={handlePrevious}
                            variant="outlined">
                            {t("Previous")}
                        </Button>
                    </Grid>}
                <Grid item xs={step === 0 ? 12 : 6}>
                    <Button
                        className={classes.primary}
                        fullWidth
                        variant="contained"
                        onClick={handleNext}
                        disabled={isDisableBtn}>
                        {t(step === 2 ? "Submit" : "Next")}
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

const props = { top_bar: "title_bar" }

export default withBottombar(AddWorkerPass, props);