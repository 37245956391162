/* eslint-disable array-callback-return */
import {
  Box, Dialog,
  Drawer, Hidden,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Filter, LoderSimmer, SwiftCard } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, enumCatagory, enumSelect, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    padding: "0px",
    overflow: "hidden",
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },

  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  total: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "6px"
  },
  titleRoot: {
    padding: "12px"
  },
  nodata: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "gray",
    textAlign: "center"
  }
}));

const Swiftinout = (props) => {
  const { t = () => false, filter = false, setFilter = () => false } = props
  const size = useWindowDimensions();
  const classes = useStyles();
  const [gatelist, setGateList] = React.useState([]);
  // const [unitList, setUnitList] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [offset, setOffset] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const alert = React.useContext(AlertContext);
  const clientID = JSON.parse(localStorage.getItem(LocalStorageKeys.client))
  const [statusType, setStatusType] = React.useState([]);
  const getPassList = (unit, type, filter, offset) => {
    const data = {
        visitorType: type,
        unit_id: props?.selectedUnit?.value ? [props?.selectedUnit?.value] : null,
        offset: offset,
        client: clientID?.id,
        limit: 10
    };
    NetworkCall(
      `${config.api_url}/queries/swift_in_out_log/get`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let YetToCheckout = response?.data?.data?.swift_in_out_log.filter(
          (val) => val.check_in_time !== null && val.check_out_time === null
        );
        let Checkout = response?.data?.data?.swift_in_out_log.filter(
          (val) => val.check_in_time !== null && val.check_out_time !== null
        );
        let dataList = YetToCheckout.concat(Checkout)?.map((val) => {
          if (val?.visitor_type === "SP") {
            return {
              id: val?.id,
              title: t("ServiceProvider"),
              subtitle1: val?.access_gates_master?.name,
              subtitle2: val?.service_providers_master?.name,
              type: val?.visitor_type,
              img: val?.service_providers_master?.image_url,
              check_out_time: val?.check_out_time,
              check_in_time: val?.check_in_time,
              property: props?.property?.label,
              unit: props?.unit?.label,
              referenceId: val?.reference_id
            };
          }
          if (val?.visitor_type === "DH") {
            return {
              id: val?.id,
              title: t("DomesticHelper"),
              subtitle1: val?.access_gates_master?.name,
              subtitle2: val?.domestic_helper_master?.name ?? `${val?.domestic_helper_request?.helper?.name}
                  (${val?.domestic_helper_request?.helper?.profession_master?.description})`,

              type: val?.visitor_type,
              img: "/images/pass.svg",
              check_out_time: val?.check_out_time,
              check_in_time: val?.check_in_time,
              subtitle2SP: val?.service_providers_master?.name,
              property: props?.property?.label,
              unit: props?.unit?.label,
              referenceId: val?.reference_id

            };
          }
          if (val?.visitor_type === "VE") {
            return {
              id: val?.id,
              title: t("Vendor"),
              subtitle1: val?.access_gates_master?.name,
              subtitle2: val?.vendor_master?.name,
              type: val?.visitor_type,
              img: val?.vendor_master?.image_url,
              check_out_time: val?.check_out_time,
              check_in_time: val?.check_in_time,
              property: props?.property?.label,
              unit: props?.unit?.label,
              referenceId: val?.reference_id

            };
          }
        });
        setGateList(dataList);
        setLoading(false)

      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
        setLoading(false)
      });
  };
  const getEnum = async (data) => {
    const stayType = await enumSelect([enumCatagory?.swift_type])
    const replaceLabels = {
      "VE": "Vendor Entry",
      "SP": "Service Provider",
      "DH": "Domestic Helper",
    }
    setStatusType(stayType?.swift_type?.map(_ => {
      return {
        ..._,
        label: replaceLabels[_?.value]
      }
    }))
    setSelectedFilter({
      type: stayType?.swift_type?.map((val) => val?.value),
      unit: [props?.units],

    });
    getPassList([props?.units], stayType?.swift_type?.map((val) => val?.value), false, 0)
    // let unitList = props?.unitlist?.map((val) => {
    //   return {
    //     value: val?.unit_id,
    //     label: val?.label
    //   }
    // })
    // setUnitList(unitList);
  }

  React.useEffect(() => {
    getEnum()
    // eslint-disable-next-line
  }, []);
  const filterData = [
    {
      id: "1",
      title: "Type",
      key: "type",
      showSearch: true,
      filterType: "CHECKBOX",
      values: statusType,
    },
    // {
    //   id: "3",
    //   title: "Unit",
    //   key: "unit",
    //   filterType: "CHECKBOX",
    //   values: unitList,
    // },
  ];
  const applyFilter = (data) => {
    setSelectedFilter({
      type: data.type,
      unit: data.unit,
    });
    if (data?.type?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectonetype"),
      });
      return false;
    }
    if (data?.unit?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectoneunit"),
      });
      return false;
    }
    getPassList(data.unit, data.type, true, 0);
    setOffset(0);
    setFilter(false)
  };
  const fetchMoreData = () => {
    setOffset(offset + 10);
    getPassList(
      selectedFilter.unit,
      selectedFilter.type,
      false,
      offset + 10
    );

  };

  return (
    <div className={classes.root} maxWidth="sm">
      {/* <Box display="flex" alignItems="center" className={classes.titleRoot}>
        <Box flexGrow={1} >
          <Typography className={classes.total}>
            {t("SwiftinandSwiftout")}
          </Typography>
        </Box>
        <Box sx={{ marginInlineStart: "10px" }}>
          <IconButton
            className={classes.filter}
            onClick={() => setFilter(true)}
          >
            <Badge variant="dot" color="primary">
              <img src="/images/icons8-funnel.svg" alt="filter" />
            </Badge>
          </IconButton>
        </Box>
      </Box> */}
      <div style={{ marginRight: "-6px" }}>
        {
          loading ? <LoderSimmer count={10} /> :
            <>
              {gatelist?.length > 0 ? <InfiniteScroll
                dataLength={gatelist.length}
                next={fetchMoreData}
                hasMore={true}
                height={size?.height - 160}
              >
                <Box padding={"16px 22px 16px 16px"}>
                  {gatelist?.map((item) => {
                    return <Box marginBottom={"10px"}><SwiftCard t={t} data={item} parking />
                    </Box>
                  })}
                </Box>
              </InfiniteScroll>
                :
                <Box
                  sx={{
                    height: "calc(100vh - 100px)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Typography className={classes.nodata}>{t("NoDataFound")}</Typography>
                </Box>
              }
            </>
        }

      </div>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={filter}
          onClose={() => setFilter(false)}
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              type: selectedFilter.type,
              unit: selectedFilter.unit,
            }}
            onGoBack={() => setFilter(false)}
            onApply={applyFilter}
            onClose={() => setFilter(false)}

          />
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer open={filter} onClose={() => setFilter(false)}>
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              type: selectedFilter.type,
              unit: selectedFilter.unit,
            }}
            onGoBack={() => setFilter(false)}
            onApply={applyFilter}
            onClose={() => setFilter(false)}

          />
        </Drawer>
      </Hidden>
    </div>
  );
};
export default withNamespaces("entriesLog")(Swiftinout)