import { Box, Container } from "@mui/material";
import { withNamespaces } from "react-i18next";
import { Actioncard, DialogDrawer, DocumentViewer, LoadingSection, TopNavBars } from "../../components";
import React, { useContext } from "react";
import { TopDetailCard, AgreementDetailCard, StatusForm } from "./components";
import { useStyles } from "./styles";
import jwt_decode from "jwt-decode";
import { returnActionCard } from "./utils"
import useWindowDimensions from "../../utils/useWindowDimensions";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
const AgreementApproval = ({ t = () => false }) => {


    const classes = useStyles()
    const size = useWindowDimensions()
    const search = useLocation().search;
    const alert = useContext(AlertContext)
    const backdrop = useContext(BackdropContext)

    const jwt = new URLSearchParams(search).get("token");
    const token = jwt_decode(jwt)
    const headers = {
        Slug: `${token?.tenant?.slug}`,
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
    };
    const [details, setDetails] = React.useState({
        agreement: {},
        pdf: null,
        company_logo: null,
        loading: true,
        open_form: false,
        fromTitle: "",
        approval: {},
        open_bottom: false,
        type: false
    })
    const [openAsset, setAsset] = React.useState(false)


    const getAgreementDetails = () => {

        const data = {
            agreement_id: token?.agreement_id
        }

        NetworkCall(
            `${config.api_url}/tenant/agreement`,
            NetWorkCallMethods.post,
            data,
            headers,
            false,
            false
        )
            .then((res) => {
                console.log(res)
                setDetails({
                    ...details,
                    agreement: res?.data?.agreement,
                    approval: res?.data?.approval?.[0],
                    loading: false,
                    company_logo: res?.data?.agreement?.company_logo,
                    open_bottom: res?.data?.approval?.[0]?.status ? false : true,
                    open_form: false
                })


            })
            .catch((err) => {
                console.log(err)
            });
    }
    const getAgreementPdf = () => {

        if (!details?.pdf) {
            backdrop.setBackDrop({
                ...backdrop,
                open: true,
                message: "Loading pdf",
            });
            const data = {
                agreement_id: token?.agreement_id
            }

            NetworkCall(
                `${config.api_url}/tenant/agreement/pdf`,
                NetWorkCallMethods.post,
                data,
                headers,
                false,
                false
            )
                .then((res) => {
                    setDetails({
                        ...details,
                        pdf: res?.data?.pdf
                    })
                    setAsset(true)
                    backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                    });

                })
                .catch((err) => {
                    console.log(err)
                    backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                    });
                });
        } else {
            setAsset(true)
        }
    }
    const updateRequestStatus = (remarks) => {
        const data = {
            agreement_id: token?.agreement_id,
            track_id: details?.approval?.id,
            status: details?.type,
            remarks,
        }
        NetworkCall(
            `${config.api_url}/tenant/agreement/request_update`,
            NetWorkCallMethods.post,
            data,
            headers,
            false,
            false
        )
            .then((res) => {
                getAgreementDetails()
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Status Updated"),
                });
            })
            .catch((err) => {
                console.log(err)
            });

    }
    React.useEffect(() => {
        getAgreementDetails()
        // eslint-disable-next-line
    }, [])
    const updateState = ({ key, value, title, type }) => {
        setDetails({
            ...details,
            [key]: value,
            fromTitle: title ?? undefined,
            type
        })
    }

    return (
        <Container maxWidth="sm" sx={{ padding: "0px" }}>
            <Box sx={{ backgroundColor: "#F6F6F6", }}>
                <TopNavBars noUser={true} note={false} home={false} company_logo={details?.company_logo} />
                {
                    details?.loading ?
                        <Box height={size?.height - 80}>
                            <LoadingSection top={"30vh"} />
                        </Box>
                        :
                        <Box>
                            <Box p={2} className={classes.root} height={size?.height - (details?.open_bottom ? 225 : 80)}>
                                {
                                    !details?.open_bottom &&
                                    <TopDetailCard t={t}

                                        data={details?.approval}
                                    />
                                }



                                <AgreementDetailCard t={t} data={details?.agreement} onViewPdf={getAgreementPdf} />
                                {/* <Box marginTop="12px">
                                <Quotation
                                    t={t}
                                    borderRadius="12px"
                                    onClickDownload={getAgreementPdf}
                                    data={{ title: t("View Draft Agreement"), agreement: true }}
                                /></Box>
                                */}

                            </Box>

                            {details?.open_bottom &&
                                <Box className={classes.actionRoot}>
                                    {
                                        returnActionCard({ t })?.map((val) => {
                                            return (
                                                <Actioncard t={t} action={(e) => updateState({ key: "open_form", value: true, title: e?.title, type: e?.type })} data={val} />
                                            )
                                        })
                                    }

                                </Box>
                            }
                        </Box>

                }


                <DialogDrawer
                    header={details?.fromTitle}
                    onClose={() => updateState({ key: "open_form", value: false })}
                    open={details?.open_form}
                    status={details?.fromTitle}
                    updateRequestStatus={updateRequestStatus}
                    component={<StatusForm t={t} updateRequestStatus={updateRequestStatus} />}
                />
                {/* <DialogDrawer
                    open={openAsset}
                    onClose={() => setAsset(false)}
                    maxWidth="sm"
                    header={"Agreement #" + details?.agreement?.agreement_no}
                    component={
                        details?.pdf ?
                            <>
                                <DocumentViewer url={`data:application/pdf;base64,${details?.pdf}`} />
                            </>
                            : t("No Date Found")
                    }
                /> */}

                <DialogDrawer
                    height={"500px"}
                    open={openAsset}
                    header={"Agreement #" + details?.agreement?.agreement_no}
                    maxWidth="sm"
                    component={details?.pdf ?
                        <>
                            <DocumentViewer url={`data:application/pdf;base64,${details?.pdf}`} />

                        </>
                        : t("No Date Found")}
                    onClose={() => setAsset(false)} />

            </Box>
        </Container>

    )
}
export default withNamespaces("agreementApproval")(AgreementApproval)