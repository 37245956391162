import { CssBaseline } from "@mui/material";
import React from "react";
import AppAlert from "./App.alert";
import AppAuth from "./App.auth";
import AppBackdrop from "./App.backdrop";
import AppDialog from "./App.dialog";
import AppDrawer from "./App.drawer";
import AppErrorBoundary from "./App.errorBoundry";
import AppFirebase from "./App.firebase"; //For Push Notification thing
import AppTheme from "./App.theme";
import RouterApp from "./router";

const App = () => {
  return (
      <AppErrorBoundary>
        <AppAuth>
          <AppTheme>
            <CssBaseline />
            <AppAlert>
              <AppDialog>
                <AppDrawer>
                  <AppBackdrop>
                    <AppFirebase>
                      <RouterApp />
                    </AppFirebase>
                  </AppBackdrop>
                </AppDrawer>
              </AppDialog>
            </AppAlert>
          </AppTheme>
        </AppAuth>
      </AppErrorBoundary>
  );
}
export default App;