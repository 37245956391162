import React from "react";
import { Avatar, Box, Grid, Stack, Typography } from "@mui/material"
import { amenityCard } from "./style";
import ScrollContainer from "react-indiana-drag-scroll";
import moment from "moment/moment";
import { stringAvatar } from "../../../utils";
const AmneityCheckoutCard = (props) => {
    const classes = amenityCard();
    return (
        <ScrollContainer className="scroll-container">
            <Grid container>
                <Grid item xs={12}>
                    <Box display={"flex"}>
                        {props?.data?.map((val) => {
                            let temp_check_in_time_array = val?.check_logs?.[0]?.checkin_time?.split(":")
                            let temp_check_in_time = temp_check_in_time_array?.[0] + ":" + temp_check_in_time_array?.[1]
                            return (
                                <div className={classes.checkoutCard} onClick={() => props?.onClick(val?.booking_id)}>
                                    <Box textAlign={"center"}>
                                        <Typography className={classes.check}>{props?.t("Yet to checkout")}</Typography>
                                    </Box>
                                    <Box px={2} pb={2}>
                                        <Stack direction={"row"} spacing={"10px"}>
                                            <Avatar variant="rounded" className={classes.avatar} {...stringAvatar(val?.description)}
                                            src={val?.property_facility?.facility_assets?.[0]?.url}></Avatar>
                                            <Stack direction={"column"} spacing={"5px"}>
                                                <Typography noWrap className={classes.amenityName}>{val?.description}</Typography>
                                                <Typography className={classes.time}>
                                                    {
                                                        `${props?.t("Checked in on")} ${moment(new Date(val?.check_logs?.[0]?.checkin_date)).format("DD MMM YY")}${(val?.check_logs?.[0]?.checkin_time ? `, ${temp_check_in_time}` : ``)}`
                                                    }</Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </div>
                            )
                        })}
                    </Box>
                </Grid>
            </Grid>
        </ScrollContainer>
    )
};

export default AmneityCheckoutCard;