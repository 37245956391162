import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  MobileNumberInputComponent,
  TextBox,
} from "../../components";
import { SelectBox } from "../../components/dropDown";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, RetainLocalStorage } from "../../utils";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
// import { useApolloClient } from "@apollo/client";
import { withNamespaces } from "react-i18next";
import { withBottombar } from "../../HOCs";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    padding: "0px",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  UnitStatusactive: {
    padding: "10px 16px",

    textAlign: "center",
    borderRadius: "4px",
    color: "white",
    fontSize: "0.875rem",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  UnitStatus: {
    padding: "10px 16px",
    textAlign: "center",
    borderRadius: "4px",
    fontSize: "0.875rem",
    cursor: "pointer",

    backgroundColor: "#ffffff",
  },
  divider: {
    border: "2px solid #F5F7FA",
    marginTop: "18px",
  },
  Label: {
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.75rem",
  },
  field: {
    marginTop: "10px",
  },
  error: {
    fontFamily: FontFamilySwitch().semiBold,
    color: "#d32f2f",
    fontSize: "0.75rem"
  }
}));
const Contacts = (props) => {
  const { t = () => false, handleTopBarState } = props
  const InitialState = {
    contactName: "",
    selectProfession: "",
    unitReference: "",
    contactNumber: "",
    // countryCode: "",
    makeItPublic: "",
    gender: "",
    error: {
      contactName: "",
      selectProfession: "",
      unitReference: "",
      contactNumber: " ",
      // countryCode: "",
      makeItPublic: "",
      gender: "",
    },
  };
  const classes = useStyles(props);
  const history = useHistory();
  const size = useWindowDimensions();
  const [createData, setCreateData] = React.useState({ ...InitialState });
  const search = useLocation().search;
  const alert = React.useContext(AlertContext);
  const [loading, setLoading] = React.useState(true);
  const [disable, setDisable] = React.useState(null);
  const [property, setProperty] = React.useState({});
  const [agreementList, setAgreementLists] = React.useState([]);
  const action = new URLSearchParams(search).get("action");
  const action_id = new URLSearchParams(search).get("id");
  const updateState = (key, value) => {
    let error = createData.error;
    error[key] = "";
    setCreateData({ ...createData, [key]: value, error });
  };

  const isIamValide = () => {
    let isValid = true;
    let error = createData.error;
    //contactName name
    if (createData.contactName.length === 0) {
      isValid = false;
      error.contactName = t("Contact Name is Required");
    }
    // contactNumber
    if (createData.contactNumber.length === 0 || createData.contactNumber?.mobile?.length === 0) {

      isValid = true;
      error.contactNumber = t("Contact Number is Required");
    }
    //unitReference name
    if (createData.unitReference.length === 0) {
      isValid = false;
      error.unitReference = t("Unit Reference is Required");
    }
    //selectProfession name
    if (createData.selectProfession.length === 0) {
      isValid = false;
      error.selectProfession = t("Select Profession is Required");
    }
    // Gender
    if (createData.gender.length === 0) {
      isValid = false;
      error.gender = t("Gender is Required");
    }
    // MAKE IY PUBLIC
    if (createData.makeItPublic.length === 0) {
      isValid = false;
      error.makeItPublic = t("Select Yes or No");
    }

    setCreateData({ ...createData, error });

    return isValid;
  };

  const submit = () => {
    if (action === "create_contact") {
      if (isIamValide()) {
        const data = {
          tenantId: `${config.tenantid}`,
          property_id: createData?.unitReference?.property_id,
          unit_id: createData?.unitReference?.unit_id,
          agreement_unit_id: createData?.unitReference?.value,
          user_profile_id: localStorage.getItem("userProfileId"),
          name: createData?.contactName,
          gender: createData?.gender,
          contact_number: createData?.contactNumber?.mobile,
          country_code: createData?.contactNumber?.mobile_code,
          profession_id: createData?.selectProfession?.value,
          is_share: createData?.makeItPublic,
        };
        NetworkCall(
          `${config.api_url}/community/contact/create`,
          NetWorkCallMethods.post,
          data,
          null,
          true,
          false
        )
          .then((response) => {
            if (response.status === 200) {
              setDisable(true);
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("ContactCreatedSuccesfully") + "!",
              });
              history.goBack(-1);
            }
          })
          .catch((err) => {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: t("SomethingWentwrong"),
            });
          });
      } else {
      }
    }
    const action_3 = "edit_contact";
    if (action === "view_contact") {
      history.push(
        Routes.contacts + "?action=" + action_3 + "&id=" + action_id
      );
    }
    if (action === "edit_contact") {
      if (isIamValide()) {
        editContactUpdate();
      }
    }
  };

  //api
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);


  const getUnitIDS = () => {
    NetworkCall(
      `${config.api_url}/community/community_units`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let data = response?.data?.data?.map(x => x?.agreement_unit).flat()
        const _tempUnitList = data?.map(
          (_uitem) => {
            let _u;
            _u = {
              label: _uitem?.name ?? "",
              value: _uitem?.id ?? "",
              unit_id: _uitem?.unit_id ?? "",
              property_id: _uitem?.property_id ?? "",
              company_id: _uitem?.company_id ?? "",
            };

            return _u;
          }
        );
        setAgreementLists(_tempUnitList);
        setLoading(false);

      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  }

  const getTitle = () => {
    let title = ""
    switch (action) {
      case "create_contact":
        title = t("Create New Contact")
        break;
      case "edit_contact":
        title = t("Edit Contact")
        break;
      case "view_contact":
        title = t("View Contact")
        break;
      default:
        break;
    }
    return title
  }

  React.useEffect(() => {
    handleTopBarState({
      text: getTitle()
    })
    if (authToken !== null && authToken !== "") {
      getUnitIDS()
    } else {
      RetainLocalStorage();
      history.push(Routes.welcome);
    }
    // eslint-disable-next-line
  }, []);

  // options for unitreferenece
  //get details of contact
  const detailsOfContact = () => {
    const data = {
      id: action_id
    };
    NetworkCall(
      `${config.api_url}/queries/community_contacts/getByID`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let data = response?.data?.data?.community_contacts[0];
        setLoading(false);
        const InitialState = {
          contactName: data?.name,
          selectProfession: {
            label: data?.profession_master?.name,
            value: data?.profession_master?.id,
          },
          unitReference: {
            label: data?.unit?.name,
            value: data?.agreement_units?.id,
            unit_id: data?.unit?.id,
            propertyId: data?.unit?.property?.id,
          },
          contactNumber: {
            mobile_code: data?.country_code, mobile: data?.contact_number
          },
          // countryCode: data?.countryCode,
          makeItPublic: data?.is_share,
          gender: data?.gender,
          error: {
            contactName: "",
            selectProfession: "",
            unitReference: "",
            contactNumber: " ",
            // countryCode: "",
            makeItPublic: "",
            gender: "",
          },
        };
        setCreateData(InitialState);
        setProperty({
          id: data?.unit?.property?.id,
          name: data?.unit?.property?.name
        })
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  const editContactUpdate = () => {
    const data = {
      tenantId: `${config.tenantid}`,
      property_id: property?.id,
      unit_id: createData?.unitReference?.unit_id,
      agreement_unit_id: createData?.unitReference?.value,
      contact_id: action_id,
      user_profile_id: localStorage.getItem("userProfileId"),
      name: createData?.contactName,
      gender: createData?.gender,
      contact_number: createData?.contactNumber?.mobile,
      // country_code: createData?.countryCode,
      country_code: createData?.contactNumber?.mobile_code,
      profession_id: createData?.selectProfession?.value,
      is_share: createData?.makeItPublic,
    };
    NetworkCall(
      `${config.api_url}/community/contact/update`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (response.status === 200) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: t("ContactEditedSuccesfully") + "!",
          });
          history.goBack(-1);
          history.goBack(-1);
        }
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  React.useEffect(() => {
    if (action === "view_contact") {
      detailsOfContact();
    }
    // eslint-disable-next-line
  }, [action]);

  // const loadOptions = async (search = "", array, type) => {
  //   setLoading1(type);
  //   let result,
  //     offset = 0;

  //   if (search && !Boolean(array?.length)) {
  //     offset = 0;
  //   } else {
  //     offset = array?.length;
  //   }

  //   switch (type) {
  //     case "Profession":
  //       result = await client.query({
  //         query: GET_CONTACT_DROPDOWN(),
  //         variables: {
  //           offset,
  //           limit: 10,
  //           search,
  //         },
  //       });
  //       setLoading1(null);
  //       return {
  //         options: [...result?.data?.profession_master],
  //         hasMore:
  //           array?.length + result?.data?.profession_master?.length <
  //           result?.data?.count[0]?.count,
  //       };

  //     default:
  //       return { options: [] };
  //   }
  // };
  const manualProfessionResponse = (array) => {
    return array?.profession_master
  }
  return (
    <>
      <Container className={classes.root} maxWidth="sm">
        {loading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            {/* <TitleBar
              text={
                <>
                  {action === "create_contact" && "Create New Contact"}
                  {action === "edit_contact" && "Edit Contact"}
                  {action === "view_contact" && "View Contact"}
                </>
              }

              goBack={goBack}
            /> */}
            <div
              style={{
                height: size.height - 135,
                padding: "12px",
                overflow: "auto",
                backgroundColor: "#F6F6F6"
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} mt={1}>
                  <TextBox
                    color
                    isRequired
                    isReadonly={action === "view_contact" ? true : false}
                    label={t("Contact Name")}
                    value={createData?.contactName}
                    placeholder={t("EnterContactName")}
                    onChange={(value) =>
                      updateState("contactName", value.target.value)
                    }
                    isError={createData?.error?.contactName?.length > 0}
                    errorMessage={createData?.error?.contactName}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <MobileNumberInputComponent
                    isRequired
                    isReadonly={action === "view_contact" ? true : false}
                    label={t("Contact Number")}

                    value={createData?.contactNumber}
                    placeholder={t("EnterContactNumber")}

                    handleChange={(value) => {
                      updateState("contactNumber", value);
                    }}
                    isError={createData?.error?.contactNumber?.length > 0}
                    errorMessage={createData?.error?.contactNumber}
                  />
                </Grid>
                <Grid item xs={12} className={classes.field} mt={1}>
                  <Typography
                    variant="body1"
                    className={classes.Label}
                    gutterBottom
                  >
                    {t("Gender")}<sup style={{ color: "red" }}>*</sup>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    onClick={() =>
                      action === "view_contact"
                        ? false
                        : updateState("gender", "Male")
                    }
                    className={
                      createData.gender === "Male"
                        ? classes.UnitStatusactive
                        : classes.UnitStatus
                    }
                    isReadonly={action === "view_contact" ? true : false}
                  >
                    {t("Male")}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    onClick={() =>
                      action === "view_contact"
                        ? false
                        : updateState("gender", "Female")
                    }
                    className={
                      createData.gender === "Female"
                        ? classes.UnitStatusactive
                        : classes.UnitStatus
                    }
                    isReadonly={action === "view_contact" ? true : false}
                  >
                    {t("Female")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    onClick={() =>
                      action === "view_contact"
                        ? false
                        : updateState("gender", "Others")
                    }
                    className={
                      createData.gender === "Others"
                        ? classes.UnitStatusactive
                        : classes.UnitStatus
                    }
                    isReadonly={action === "view_contact" ? true : false}
                  >
                    {t("Others")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.error}>{createData?.error?.gender ? (createData?.error?.gender) : ("")}</Typography>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <SelectBox
                    isRequired
                    isDisabled={action === "view_contact" ? true : false}
                    value={createData?.unitReference}
                    options={agreementList}
                    label={t("UnitReference")}
                    placeholder={t("SelectUnitReference")}
                    onChange={(value) => updateState("unitReference", value)}
                    isError={createData?.error?.unitReference?.length > 0}
                    errorMessage={createData?.error?.unitReference}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <SelectBox
                    key={createData?.unitReference?.company_id}
                    isRequired
                    isDisabled={action === "view_contact" ? true : (createData?.unitReference?.company_id ? false : true)}
                    value={createData?.selectProfession}
                    label={t("SelectProfession")}
                    placeholder={t("SelectProfession")}
                    isPaginate
                    loadOptions={(search, array, handleLoading) =>
                      // loadOptions(search, array, "Profession")
                      loadOptionsApis(
                        "queries/profession_master/list",
                        { company_id: createData?.unitReference?.company_id },
                        search,
                        array,
                        handleLoading,
                        "data",
                        {},
                        manualProfessionResponse
                      )
                    }
                    // loading={loading1 === "Profession"}
                    debounceTimeout={800}
                    onChange={(value) => updateState("selectProfession", value)}
                    isError={createData?.error?.selectProfession?.length > 0}
                    errorMessage={createData?.error?.selectProfession}
                  />
                </Grid>
                <Grid item xs={12} className={classes.field}>
                  <Typography
                    variant="body1"
                    className={classes.Label}
                    gutterBottom
                  >
                    {t("MakeItPublic")}<sup style={{ color: "red" }}>*</sup>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    onClick={() =>
                      action === "view_contact"
                        ? false
                        : updateState("makeItPublic", true)
                    }
                    className={
                      createData.makeItPublic === true
                        ? classes.UnitStatusactive
                        : classes.UnitStatus
                    }
                    isReadonly={action === "view_contact" ? true : false}
                  >
                    {t("Yes")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    onClick={() =>
                      action === "view_contact"
                        ? false
                        : updateState("makeItPublic", false)
                    }
                    className={
                      createData.makeItPublic === false
                        ? classes.UnitStatusactive
                        : classes.UnitStatus
                    }
                    isReadonly={action === "view_contact" ? true : false}
                  >
                    {t("No")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography mt={"5px"} className={classes.error}>{createData?.error?.makeItPublic ? (createData?.error?.makeItPublic) : ("")}</Typography>
              </Grid>
            </div>
            <Grid container className={classes.btncontainer} spacing={1}>
              <Grid item xs={6}>
                <Button
                  variant="contain"
                  fullWidth={true}
                  className={classes.cancelbtn}
                  onClick={() => history.goBack(-1)}
                >
                  {t("Cancel")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth={true}
                  disable={disable}
                  className={classes.applybtn}
                  onClick={submit}
                >
                  {action === "view_contact" && t("Edit")}
                  {action === "edit_contact" && t("Save")}
                  {action === "create_contact" && t("Save")}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </>
  );
};

const props = {
  top_bar: "title_bar",
}


export default withBottombar(withNamespaces("contacts")(Contacts), props);