/* eslint-disable array-callback-return */
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DatePickerNew } from "../../components";
import { SelectBox } from "../../components/dropDown";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { withBottombar } from "../../HOCs";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, FontFamilySwitch, generateImageUrl, img_size, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    padding: "0px",
  },
  input: {
    marginTop: "10px",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
    textTransform: "capitalize"
  },
  profession: {
    height: "44px",
    backgroundColor: "white",
    borderRadius: "4px",
    fontSize: "0.875rem",
    color: "gray",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    border: "1px solid #E4E8EE",
    margin: "8px 0px"
  },
  label: {
    marginTop: "10px",
    fontSize: "0.75rem",
    color: "#98A0AC",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  title: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "4px 8px 8px 8px",
  },
  helperImg: {
    height: "40px",
    borderRadius: theme.palette.borderRadius,
    width: "40px",
    objectFit: "cover",
  },
  helperRoot: {
    borderTop: "1px solid #E4E8EE",
    cursor: "pointer",
  },
  name: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  pname: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.secondary,
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #CED3DD",
    backgroundColor: "white",
    borderRadius: "4px",
    color: theme.typography.color.primary,
    textTransform: "capitalize"

  },
}));
const InitialState = {
  unit: "",
  helper: [],
  fromDate: "",
  toDate: "",
  error: {
    unit: "",
    helper: "",
    fromDate: "",
    toDate: ""
  },
};
const CreateDomestic = ({ t = () => false, handleTopBarState }) => {
  const size = useWindowDimensions();
  const classes = useStyles();
  const history = useHistory();
  const [data, setdata] = React.useState({ ...InitialState });
  const [drawer, setDrawer] = React.useState(false);
  const [helper, setHelper] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const [disable, setDisable] = React.useState(null);
  // const [getunit, setgetUnit] = React.useState(null);
  const [agreementList, setAgreementLists] = React.useState([]);
  const [property, setProperty] = React.useState({});


  const getHelper = (v, searchdata) => {
    const params = {
        property_id: [v?.id],
        search: searchdata ?? "",
        offset: 0,
        limit: 1000,
    }
    NetworkCall(
      `${config.api_url}/queries/domestic_helper_master/get`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        setHelper(response.data.data?.domestic_helper_master);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  const getUnitIDS = () => {
    NetworkCall(
      `${config.api_url}/community/community_units`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let data = response?.data?.data?.map(x => x?.agreement_unit).flat()
        const _tempUnitList = data?.map(
          (_uitem) => {
            let _u;
            _u = {
              label: _uitem?.name ?? "",
              value: _uitem?.id ?? "",
              unit_id: _uitem?.unit_id ?? "",
            };

            return _u;
          }
        );
        setAgreementLists(_tempUnitList);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  }

  const getpropertyUnitid = (v, x) => {
    const params = {
      ids: [v?.unit_id],
      offset: 0,
      limit: 10,
      search: "",
    }
    
    NetworkCall(
      `${config.api_url}/queries/get_unit_detail_by_ids`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    ).then((res) => {
      setProperty(res?.data?.data?.unit?.[0]?.property)
      getHelper(res?.data?.data?.unit?.[0]?.property, "");

    }).catch((err) => {

    })
  }
  React.useEffect(() => {
    handleTopBarState({
      text: t("Create Registered Worker Request")
    })
    getUnitIDS()
    // eslint-disable-next-line
  }, []);
  const updateState = (key, value) => {
    if (key === 'unit') {
      getpropertyUnitid(value)
    }
    let error = data.error;
    error[key] = "";
    setdata({ ...data, [key]: value, error });
  };
  const isIamValideTostep1 = () => {
    let isValid = true;
    let error = data.error;
    //Checking unit
    if (data.unit.length === 0) {
      isValid = false;
      error.unit = t("UnitisRequired");
    }
    //Checking helper
    if (data.helper.length === 0) {
      isValid = false;
      error.helper = t("DomesticHeplerisRequired");
    }

    setdata({ ...data, error });
    return isValid;
  };
  const createPass = () => {
    if (isIamValideTostep1()) {
      setDisable(true);
      const payload = {
        tenantId: `${config.tenantid}`,
        helper_id: data?.helper?.id,
        user_profile_id: localStorage.getItem("userProfileId"),
        property_id: property?.id,
        unit_id: data?.unit?.unit_id,
        agreement_unit_id: data?.unit?.value,
        from: data?.fromDate,
        to: data?.toDate
      };
      NetworkCall(
        `${config.api_url}/services/domestic/create`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          setDisable(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: t("RequestSuccessfullyCreated"),
          });
          history.goBack(-1);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
          setDisable(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
    } else {
      return false;
    }
  };
  const renderHelper = () => {
    return (
      <div>
        <Box display="flex" alignItems="center" className={classes.titleroot}>
          <Box flexGrow={1}>
            <Typography variant="subtitle2" className={classes.bottomBarTitle}>
              {t("Registered Worker")}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <div style={{ padding: "12px", height: "500px", overflow: "auto" }}>
          <TextField
            size="small"
            variant="outlined"

            placeholder="Search"
            style={{
              backgroundColor: "#F5F7FA",
              borderRadius: "4px",
            }}
            onChange={(e) => getHelper(property, e.target.value)}
            startAdornment={
              <InputAdornment>
                <img src="public/images/icons8-search (2).svg" alt="" />
              </InputAdornment>
            }
            fullWidth
            className={classes.search}
          />
          <br />
          <br />
          {helper.length > 0 && (
            <Typography className={classes.pname} marginBottom="-8px">
              {t("Results")}
            </Typography>
          )}

          {helper.length > 0 ? (
            helper?.map((val) => {
              return (
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.helperRoot}
                  p={1.4}
                  onClick={() => {
                    updateState("helper", val);
                    setDrawer(false);
                  }}
                >
                  <Box>
                    <img
                      src={generateImageUrl(val?.helper_image, img_size.small_square)}
                      alt=""
                      className={classes.helperImg}
                    />
                  </Box>
                  <Box sx={{ marginInlineStart: "8px" }}>
                    <Typography className={classes.name}>
                      {val?.name}
                    </Typography>
                    <Typography className={classes.pname}>
                      {val?.profession_master?.name}
                    </Typography>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Typography className={classes.name}>
              {t("No Registered Worker Found")}
            </Typography>
          )}
        </div>
      </div>
    );
  };
  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TitleBar
        text={t("CreateDomesticHelpRequest")}

        goBack={() => history.push(Routes.domesticHelp)}
      /> */}
      <Grid container>
        <Grid
          item
          xs={12}
          style={{ height: size?.height - 135, overflow: "scroll" }}
        >
          <div style={{ padding: "12px 8px 8px 12px" }}>
            <div className={classes.input}>
              <SelectBox
                isRequired
                label={t("Choose Unit")}
                placeholder={t("Choose Unit")}
                value={data.unit}
                options={agreementList}
                // isPaginate
                // loadOptions={(search, array) =>
                //   loadOptions(search, array, "unit")
                // }
                // loading={loading1 === "unit"}
                // debounceTimeout={800}
                onChange={(value) => {
                  updateState("unit", value);
                }}
                isError={data?.error?.unit?.length > 0}
                errorMessage={data?.error?.unit}
              />
            </div>
            <Typography className={classes.label}>
              {t("Choose Registered Worker")}<sup style={{ color: "red" }}>*</sup>
            </Typography>
            <Box className={classes.profession} onClick={() => setDrawer(true)}>
              &nbsp;&nbsp;
              {data?.helper?.name ? (
                <>
                  {data?.helper?.name}&nbsp;-&nbsp;
                  {data?.helper?.profession_master?.name}
                </>
              ) : (
                t("Choose Registered Worker")
              )}
            </Box>
            {data?.error?.helper?.length > 0 && (
              <Typography variant={"caption"} color={"error"}>
                {data?.error?.helper}
              </Typography>
            )}
            <DatePickerNew
              isRequired
              label={t("From Date")}
              placeholder={t("From Date")}
              value={data?.fromDate ?? ""}
              onChange={(value) => {
                console.log("date picker value", value)
                updateState("fromDate", value);
              }}
              isError={data?.error?.fromDate?.length > 0}
              errorMessage={data?.error?.fromDate}
              minDate={new Date()}
            />
            <Box sx={{ height: "8px" }} />
            <DatePickerNew
              isRequired
              label={t("To Date")}
              placeholder={t("To Date")}
              value={data?.toDate ?? ""}
              onChange={(value) => {
                console.log("date picker value", value)
                updateState("toDate", value);
              }}
              isError={data?.error?.toDate?.length > 0}
              errorMessage={data?.error?.toDate}
              minDate={new Date(data?.fromDate)}
            />
          </div>
        </Grid>
        <Grid
          container
          spacing={1}
          item
          xs={12}
          style={{
            padding: "12px",
            backgroundColor: "white",
            boxShadow: "0px -1px 6px #00000021",
          }}
        >
          <Grid item xs={6}>
            <Button
              fullWidth
              className={classes.cancelbtn}
              onClick={() => history.goBack(-1)}
            >
              {t("Cancel")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              className={classes.applybtn}
              variant="contained"
              onClick={createPass}
              disabled={disable}
            >
              {t("Submit")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderHelper()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderHelper()}
        </Drawer>
      </Hidden>
    </Container>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("domesticHelp")(CreateDomestic), props)