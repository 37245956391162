import Box from "@mui/material/Box";
// import styled from '@mui/material/styles/styled';
// import useTheme from '@mui/material/styles/useTheme';
import React from "react";

import { Avatar, Stack, Typography, useMediaQuery } from "@mui/material";
import { t } from "i18next";
import moment from "moment";
import { useHistory } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { CommentsIcon } from "../../../assets/commentIcon";
import { FilledLikeIcon } from "../../../assets/likeFilledIcon";
import { LikeIcon } from "../../../assets/likeIcon";
import { Routes } from "../../../router/routes";
import { stringAvatar } from "../../../utils";
import { CommunityCardStyles } from "./styles";
// const CustomImage = styled('img')(({ theme }) => ({
//     width: 73,
//     height: 73,
//     borderRadius: 12
// }))

export const NewCommunityCard = ({ data = {}, isAll = false, isCommunity = false, addLike = () => false, height = "auto", isWidget = false,updateState=()=>false,disableBtn=false }) => {
    const classes = CommunityCardStyles()
    const history = useHistory()
    const match = useMediaQuery('(min-width:602px)')


    return (
        <Box className={classes.root} margin={isAll ? "0px 0px 12px 0px" : "0px 12px 12px 12px"} position={"relative"} height={height ?? "auto"}>
            <Box onClick={() => isCommunity ?
                history.push(
                    Routes.discussions + "?discussionId=" + data?.id
                )
                : history.push(
                    Routes.announcementDetails + "?Id=" + data?.id
                )}>

                <Stack direction={"row"} spacing={1} alignItems="center">
                    <Avatar src={data?.profile_url} {...stringAvatar(data?.first_name ?? null)} />
                    <Box>
                        <Typography className={classes.first_name} noWrap>{data?.first_name ?? "-"}</Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography className={classes.posted_at} noWrap>{data?.category ?? data?.unit_name}</Typography>
                            <Box className={classes.dot} />
                            <Typography className={classes.posted_at} noWrap>{moment(new Date(data?.triggered_at)).fromNow()}</Typography>
                        </Stack>
                    </Box>
                </Stack>
                {data?.description !== null ?
                    <Box mt={1}>
                        <Typography className={classes.title}>{`${data?.title?.substring(0, 80)}${data?.title?.length > 80 ? "..." : ""
                            } `}</Typography>
                    </Box>
                    :
                    <Box className={classes.titleBox} width={"100%"} mt={2} position={"relative"}>
                        <Typography className={classes.title} sx={{ padding: "70px 20px" }}>{`${data?.title?.substring(0, 80)}${data?.title?.length > 80 ? "..." : ""
                            }`}</Typography>
                    </Box>
                }
                <Box mt={0.5}>
                    {data?.description !== null && (data?.description?.[0]?.Description !== "" && data?.description?.[0]?.Description !== null) ?
                        <Box>
                            <ShowMoreText
                                lines={data?.assets?.length > 0 ? 2 : 5}
                                more="View More"
                                less="view Less"
                                className={classes.description}
                                anchorClass={classes.seeMoreLessTextStyle}
                                expanded={false}
                                truncatedEndingComponent={"... "}
                            >
                                {(data?.description?.[0]?.Description)?.match(/\n+/g) ?
                                    <Typography className={classes.description} dangerouslySetInnerHTML={{ __html: (data?.description?.[0]?.Description).replace(/\n+/g, "<br />") ?? "" }}>
                                    </Typography>
                                    :
                                    data?.description?.[0]?.Description
                                }
                                {/* {item?.move_out_details?.discrepancy_note?.split('\n').map((item, i) => <Typography className={classes.subtitle2} key={i}>{item}</Typography>)} */}
                            </ShowMoreText>
                        </Box>
                        :
                        <Box display={"flex"} justifyContent={"center"} height="70px" mt={2}>
                            <Typography className={classes.description}>{t("No Description Found")}</Typography>
                        </Box>
                    }
                </Box>
                {!isCommunity &&
                    <>
                        <Box className={classes.attachBox}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                {/* <AttachFile sx={{fontSize:"12px"}}/> */}
                                <Typography className={classes.attachFile}>{`${data?.description?.map((x) => { return x?.images })?.filter((val) => (val !== "" && val !== null))?.length ?? 0} Attachment`}</Typography>
                            </Stack>
                        </Box>
                        <Box mt={1.5}>
                            {(data?.description !== null && data?.description?.[0]?.images !== "" && data?.description?.[0]?.images !== null) ?
                                <img src={data?.description?.[0]?.images} alt="announcement_image" height={match ? "285px" : "175px"} width="100%" />
                                :
                                <img src={"../images/announcementPlaceholder.png"} width="100%" height={match ? "285px" : "auto"} alt="placeholder" />
                            }
                        </Box>
                    </>
                }
            </Box>
            {isCommunity &&
                <Box mt={2} mb={2}>
                    {data?.assets?.length > 0 &&
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            {data?.assets?.length <= 3 ? data?.assets?.map((x, i) => {
                                return (
                                    // <Grid item xs={3}>
                                    <Avatar src={x?.url} alt="assets" variant="square" style={{ width: match ? "120px" : "70px", height: match ? "120px" : "70px", borderRadius: "4px" }} onClick={() => updateState(0, false,[x])} />
                                    // </Grid>
                                )
                            }) :
                                <>
                                    <Avatar src={data?.assets?.[0]?.url} alt="assets" variant="square" style={{ width: match ? "120px" : "70px", height: match ? "120px" : "70px", borderRadius: "4px" }} onClick={() => updateState(0, false,[data?.assets?.[0]])}/>
                                    <Avatar src={data?.assets?.[1]?.url} alt="assets" variant="square" style={{ width: match ? "120px" : "70px", height: match ? "120px" : "70px", borderRadius: "4px" }} onClick={() => updateState(0, false,[data?.assets?.[1]])}/>
                                    <Avatar src={data?.assets?.[2]?.url} alt="assets" variant="square" style={{ width: match ? "120px" : "70px", height: match ? "120px" : "70px", borderRadius: "4px" }} onClick={() => updateState(0, false,[data?.assets?.[2]])}/>
                                    <Box className={classes.moreImages} position={"relative"} onClick={() => updateState(0,false,data?.assets)} style={{ width: match ? "120px" : "70px", height: match ? "120px" : "70px" }}>
                                        <Box display={"flex"} justifyContent={"center"} mt={match ? "45%" : 3.1}>
                                            <Typography className={classes.title}>+ {data?.assets?.length - 3}</Typography>
                                        </Box>
                                    </Box>
                                </>
                            }
                        </Stack>
                    }
                </Box>
            }
            {isCommunity &&
                <>
                    <Box height="20px" />
                    <Box className={classes.bottomFix}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box display={"flex"} alignItems={"center"}>
                                <Box onClick={() => disableBtn?false:addLike(data)}>
                                    {data?.liked_by?.some(
                                        (val) => val === localStorage.getItem("userProfileId")
                                    ) ? <FilledLikeIcon />
                                        : <LikeIcon />}
                                </Box>
                                <Box>
                                    <Typography className={classes.likeIcon}>{data?.reactions ?? 0}</Typography>
                                </Box>
                            </Box>
                            <Box display={"flex"} alignItems={"center"}>
                                <CommentsIcon />
                                <Typography className={classes.likeIcon}>{data?.comments ?? 0}</Typography>
                            </Box>
                        </Stack>
                    </Box >
                </>
            }

        </Box >
    );
}
