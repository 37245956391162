import React from "react";
import { withBottombar } from "../../HOCs";
import SurveyList from "./surveyList";

class SurveyListParent extends React.Component {
    render() {
        return <SurveyList {...this.props} />;
    }
}

const props = {
    top_bar: "title_bar",
}

export default withBottombar(SurveyListParent, props);