import AddIcon from "@mui/icons-material/Add";
import {
  Badge,
  Box,
  Container,
  Dialog,
  Drawer,
  Fab,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";
import { Filter, LoderSimmer, TitleBar } from "../../components";
import NoDataFound from "../../components/noDataFound/noDataFound";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { NewCommunityCard } from "./components/communityNewCard";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    padding: "0px",
  },

  title: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      bottom: "35px",
      right: "35px",
    },
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "35px",
      right: "35px",
    },
  },
  paddingTitle: {
    padding: '8px 14px 0px'
  }
}));

const CommunityDiscussion = (props) => {
  const { t = () => false } = props
  const classes = useStyles(props);
  const history = useHistory();
  const size = useWindowDimensions();
  const [drawer, setDrawer] = React.useState(false);
  const [communitiydata, setCommunityData] = React.useState([]);
  const [offSet, setOffSet] = React.useState(0);
  const [property, setproperty] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [selectedFilter, setSelectedFilter] = React.useState([]);
  const [proppertyFilter, setPropertyFilter] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const alert = React.useContext(AlertContext);
  const [disableBtn, setIsDisableBtn] = React.useState(false)
  const [isViewerOpen, setIsViewerOpen] = React.useState({
    bool: false,
    data: 0
  });
  const updateState = (index, close, data) => {
    if (close) {
      setIsViewerOpen({ ...isViewerOpen, bool: !isViewerOpen?.bool })
    }
    else {
      setIsViewerOpen({ data: index, bool: !isViewerOpen?.bool, assets: data })
    }

  }
  const getCommunities = (propertyid, filter, offset) => {
    const data = {
      tenantId: `${config.tenantid}`,
      property_id: propertyid,
      offset: offset,
      limit: 20,
    };
    NetworkCall(
      `${config.api_url}/community/fetch`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter) {
          setCommunityData(response?.data?.data?.threads);
        } else {
          setCommunityData([...communitiydata, ...response?.data?.data?.threads]);
        }
        setDrawer(false);
        setCount(response?.data?.data?.totalCount);
        setLoading(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing Went wrong."),
        });
      });
  };
  //get property
  const getProperty = () => {
    const data = {
    };
    NetworkCall(
      `${config.api_url}/resident/access_unit_community`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let resposepropertyId = response?.data?.data?.map(
          (val) => val?.property_id
        );
        let uniqArray = [...new Set(resposepropertyId)];
        setproperty(uniqArray);
        if (uniqArray?.length > 0) {
          getCommunities(uniqArray, false, 0);
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("No Property Found"),
          });
          return false;
        }
        //for filter
        const propertyFilter = response?.data?.data?.map((val, index) => {
          let _d;
          try {
            _d = {
              value: val?.property_id,
              label: val?.property_name,
              key: "property",
              all: "true" + index,
            };
          } catch (err) {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: t("Some thing Went wrong."),
            });
          }
          return _d;
        });
        const key = "value";
        const u_arr = [
          ...new Map(propertyFilter.map((item) => [item[key], item])).values(),
        ];
        setPropertyFilter(u_arr);
        setSelectedFilter(u_arr.map((val) => val.value));
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing Went wrong."),
        });
      });
  }

  React.useEffect(() => {
    // handleTopBarState({
    //   text: t("CommunityDiscussion")
    // })
    getProperty()
    // eslint-disable-next-line
  }, []);

  const filterData = [
    {
      id: "1",
      title: t("Property"),
      key: "property",
      showSearch: true,
      filterType: "CHECKBOX",
      values: proppertyFilter,
    },
  ];
  const applyFilter = (result) => {
    if (result?.property?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleast select one property"),
      });
      return false;
    }
    setOffSet(0);
    setSelectedFilter(result.property);
    getCommunities(result.property, true, 0);
  };
  const addLike = (val) => {
    setIsDisableBtn(true)
    let check = val?.liked_by?.some(
      (val) => val === localStorage.getItem("userProfileId")
    );

    const payload = {
      params: [
        {
          community_discussion: val.id,
          created_by: localStorage.getItem("userProfileId"),
          updated_by: localStorage.getItem("userProfileId"),
          is_active: true,
          client: JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id
        }
      ] 
    }

    const deletePayload = {
      params: [
        {
          id: val?.like_id,
          is_active: false
        }
      ]
    }

    NetworkCall(
      `${config.api_url}/queries/community_discussion_reactions/upsert`,
      NetWorkCallMethods.post,
      check ? deletePayload : payload,
      null,
      true,
      false
    )
      .then((response) => {
        const data = {
          tenantId: `${config.tenantid}`,
          property_id: property,
          offSet: offSet,
          limit: 7,
        };
        NetworkCall(
          `${config.api_url}/community/fetch`,
          NetWorkCallMethods.post,
          data,
          null,
          true,
          false
        )
          .then((response) => {
            if (response?.data?.data?.threads?.length > 0) {
              setCommunityData(response?.data?.data?.threads);
              setCount(response?.data?.data?.totalCount);
              setIsDisableBtn(false)
            }
          })
          .catch((err) => {
            setIsDisableBtn(false)
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: t("Some thing Went wrong."),
            });
          });
      })
      .catch((err) => {
        setIsDisableBtn(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing Went wrong."),
        });
      });
  };
  const fetchMoreData = () => {
    setOffSet(offSet + 20);
    getCommunities(selectedFilter, false, offSet + 20);
  };
  return (
    <>
      <Container className={classes.root} maxWidth="sm">
        <TitleBar text={t("CommunityDiscussion")} goBack={() => history.push(Routes?.service)} />
        <div style={{ height: size?.height - 55, overflow: "overlay" }}>
          <Box display="flex" alignItems="center" className={classes.paddingTitle}>
            <Box flexGrow={1}>
              <Typography className={classes.title}>
                {t("Discussion")}({count})
              </Typography>
            </Box>
            <Box marginLeft="10px">
              <IconButton
                className={classes.filter}
                onClick={() => setDrawer(true)}
              >
                <Badge variant="dot" color="primary">
                  <img src="/images/icons8-funnel.svg" alt="filter" />
                </Badge>
              </IconButton>
            </Box>
          </Box>
          <div
            style={{
              padding: "0px 6px 8px 12px",
              // overflow: "hidden",
              // height: 'calc(100vh - 110px)'
            }}
          >
            {loading ? (
              <LoderSimmer count={10} />
            ) : (
              <>
                {communitiydata?.length > 0 ? (
                  <InfiniteScroll
                    dataLength={communitiydata.length}
                    next={fetchMoreData}
                    hasMore={true}
                    height={size?.height - 130}
                  >
                    {communitiydata?.map((val) => {
                      return (
                        <Box item key={val.id} marginTop="6px">
                          <NewCommunityCard data={{
                            ...val, profile_url: val?.url, triggered_at: val?.created_at, description: [{
                              Description: val?.description,
                              images: val?.images
                            }]
                          }} isAll={true} isCommunity={true} addLike={addLike} t={t} updateState={updateState} disableBtn={disableBtn} />
                          {/* <CommunityCard data={val} handleClick={addLike} likes={t("Likes")} comments={t("Comments")} t={t} /> */}
                        </Box>
                      );
                    })}
                  </InfiniteScroll>
                ) : (
                  <NoDataFound
                    height={210}
                    content={t("No Active Discussions available")}
                  />
                )}
              </>
            )}

          </div>
        </div>
        {!isViewerOpen?.bool &&
          <Grid container>
            <Grid item xs={12} style={{ position: "relative" }}>
              <Box sx={{ display: "flex", float: "right" }}>
                <Box>
                  <Fab
                    color="primary"
                    className={classes.fab}
                    aria-label="add"
                    onClick={() => history.push(Routes.createDiscussion)}
                  >
                    <AddIcon />
                  </Fab>
                </Box>
              </Box>
            </Grid>
          </Grid>
        }
      </Container>
      <Hidden smDown>
        <Dialog
          open={drawer}
          onClose={() => setDrawer(false)}
          fullWidth
          className={classes.dialog}
          maxWidth="sm"
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              property: selectedFilter,
            }}
            onGoBack={() => setDrawer(false)}
            onClose={() => setDrawer(false)}
            onApply={applyFilter}
          />
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          open={drawer}
          onClose={() => setDrawer(false)}
          className={classes.dialog}
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              property: selectedFilter,
            }}
            onApply={applyFilter}
            onGoBack={() => setDrawer(false)}
            onClose={() => setDrawer(false)}
          />
        </Drawer>
      </Hidden>
      {isViewerOpen?.bool && (
        <ImageViewer
          src={
            isViewerOpen?.assets?.map((x) => { return x?.url }) ?? []
          }
          currentIndex={isViewerOpen?.data}
          onClose={() => setIsViewerOpen({ ...isViewerOpen, bool: false })}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )
      }
    </>
  );
};

export default withNamespaces("communityDiscussion")(CommunityDiscussion)